/*
   This code snippet is a React functional component named `Header`. It is importing necessary
   dependencies such as React, CSS styles, icons, and components from Material-UI library. The
   component takes props `onChangeLocation`, `districtName`, `stateName`, and `language`.
*/
import React from 'react';
import Styles from './style.module.css'; // Import CSS styles
import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded'; // Import icon from Material-UI
import { Box } from '@mui/material'; // Import Box component from Material-UI
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography'; // Import Typography component
import { MenuRounded, Person2Outlined } from '@mui/icons-material';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import jsonParse from '@mono-farmart-web/farmart-web-common/utils/jsonParser';

const Header = ({
  onChangeLocation,
  districtName,
  stateName,
  language,
  onClickMyProfile,
  onClickFaq,
}) => {
  // Define the Header component
  return (
    <Box className={Styles.mainContainer}>
      {/* Location container */}
      <Box
        onClick={onChangeLocation}
        className={Styles.locationContainer}
        id="tradeListChangeLocation"
      >
        {/* Location label container */}
        <Box className={Styles.loactionLabelContainer}>
          {/* Display district name and state name */}
          <MuiTypography
            level="body-sm"
            textColor={'#171A1C'}
            id="homescreenChangeLocationid"
            onClick={onChangeLocation}
          >
            {jsonParse(localStorage?.getItem('districtName'))},{' '}
            {jsonParse(localStorage?.getItem('stateName'))}
          </MuiTypography>
          {/* Dropdown icon */}
          <KeyboardArrowDownRounded color={'action'} fontSize={'small'} />
        </Box>
        {/* Text for changing location */}
        <MuiTypography
          color="primary"
          level="label-md"
          borderBottom=" 1px dashed #019881"
        >
          {language?.changeLocationText}
        </MuiTypography>
      </Box>
      {
        <div className={Styles?.myProfileContainer}>
          <MuiButton
            size="sm"
            color="warning"
            className={Styles?.helpButtonContainer}
            onClick={onClickFaq}
            id="helpFaqPwaId"
          >
            {language?.help}
          </MuiButton>
          <div onClick={onClickMyProfile} id={'myProfilePwaId'}>
            <Person2Outlined className={Styles?.myProfileIconContainer} />
            <MenuRounded className={Styles?.menuIcon} />
          </div>
        </div>
      }
    </Box>
  );
};

export default Header; // Export the Header component
