import React from 'react';
import Styles from './style.module.css';
import {
  type Crop,
  CropVariety,
} from '@mono-farmart-web/farmart-web-common/modal/Traders/types';
import ArrowDropDownRounded from '@mui/icons-material/ArrowDropDownRounded';

import { Box, Chip, ChipDelete } from '@mui/joy';
import { CloseRounded, SearchRounded } from '@mui/icons-material';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import type { BuyerDataProps } from '../../../hooks/types';

interface PayloadType {
  handleCropListModal: () => void;
  handleCropVarietyListModal: () => void;
  handleBuyerLocationListModal: () => void;
  activeCrop: Crop;
  activeVariety: number[];
  activeLocations: string[];
  language: { [key: string]: string };
  onClickRemoveCropFilter: () => void;
  onClickRemoveVarietyFilter: () => void;
  onClickRemoveLocationFilter: () => void;
  locationFilterApplied: boolean;
  variertFilterApplied: boolean;
  searchedBuyerDetail: BuyerDataProps;
  onClickRemoveBuyerSearch: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  onClickBuyerSearch: () => void;
}

const Header = ({
  handleCropListModal,
  handleCropVarietyListModal,
  handleBuyerLocationListModal,
  activeCrop,
  activeVariety,
  activeLocations,
  language,
  onClickRemoveCropFilter,
  onClickRemoveVarietyFilter,
  onClickRemoveLocationFilter,
  locationFilterApplied,
  variertFilterApplied,
  searchedBuyerDetail,
  onClickRemoveBuyerSearch,
  onClickBuyerSearch,
}: PayloadType) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling
    });
  };
  return (
    <Box className={Styles.mainContainer}>
      <Box
        height={40}
        display={'flex'}
        border={searchedBuyerDetail?.buyer_name ? 2 : 1}
        margin={'0px 16px'}
        marginTop={'12px'}
        borderRadius={'8px'}
        borderColor={
          searchedBuyerDetail?.buyer_name
            ? 'primary.500'
            : 'neutral.outlinedBorder'
        }
        bgcolor={'background.surface'}
        alignItems={'center'}
        gap={'8px'}
        onClick={onClickBuyerSearch}
      >
        {!searchedBuyerDetail?.buyer_name && (
          <Box display={'flex'} alignItems={'center'} marginLeft={'12px'}>
            <SearchRounded
              fontSize={'large'}
              style={{
                fontSize: 24,
              }}
              id="searchicon_tradescreen"
              data-testid="searchicon_tradescreen"
            />
          </Box>
        )}

        <Box
          flex={1}
          id="searchbuyername_inputfield_tradescreen"
          data-testid-="searchbuyername_inputfield_tradescreen"
        >
          {searchedBuyerDetail?.buyer_name ? (
            <MuiTypography
              textColor={'text.primary'}
              sx={{
                marginLeft: '12px',
              }}
            >
              {searchedBuyerDetail?.buyer_name}
            </MuiTypography>
          ) : (
            <MuiTypography
              textColor={'text.tertiary'}
              sx={{
                marginLeft: '12px',
              }}
            >
              {language?.searchBuyer}
            </MuiTypography>
          )}
        </Box>

        {searchedBuyerDetail?.buyer_name && (
          <Box
            display={'flex'}
            alignItems={'center'}
            marginRight={'12px'}
            justifyContent={'flex-end'}
            onClick={(event) => onClickRemoveBuyerSearch(event)}
            zIndex={100}
          >
            <CloseRounded
              data-testid="searchIcon"
              fontSize={'large'}
              style={{
                fontSize: 24,
              }}
            />
          </Box>
        )}
      </Box>

      <Box display={'flex'} marginTop={'16px'} flexDirection={'column'}>
        <Box
          margin={'0px 16px'}
          display={'flex'}
          alignItems={'flex-start'}
          gap={'8px'}
        >
          <Box>
            {activeCrop?.id === null ? (
              <Chip
                onClick={() => handleCropListModal()}
                size="lg"
                color="neutral"
                endDecorator={<ArrowDropDownRounded />}
                variant="outlined"
              >
                {language?.cropFilterLabel}
              </Chip>
            ) : (
              <Chip
                onClick={() => handleCropListModal()}
                size="lg"
                color="primary"
                endDecorator={
                  <ChipDelete
                    onDelete={() => onClickRemoveCropFilter()}
                    id="cropclosebutton_searchscreen"
                    data-testid="cropclosebutton_searchscreen"
                  />
                }
                variant="soft"
              >
                {language?.cropFilterLabel}
              </Chip>
            )}
          </Box>
          <Box>
            {!variertFilterApplied ? (
              <Chip
                onClick={() => handleCropVarietyListModal()}
                size="lg"
                color="neutral"
                endDecorator={<ArrowDropDownRounded />}
                variant="outlined"
                disabled={activeCrop?.id === null ? true : false}
              >
                {language?.cropVarietyFilterLabel}
              </Chip>
            ) : (
              <Chip
                onClick={() => handleCropVarietyListModal()}
                size="lg"
                color="primary"
                endDecorator={
                  <ChipDelete
                    onDelete={() => onClickRemoveVarietyFilter()}
                    id="varietyclosebutton_searchscreen"
                    data-testid="varietyclosebutton_searchscreen"
                  />
                }
                variant="soft"
                disabled={activeCrop?.id === null ? true : false}
              >
                {language?.cropVarietyFilterLabel}
              </Chip>
            )}
          </Box>
          <Box>
            {locationFilterApplied ? (
              <Chip
                onClick={() => handleBuyerLocationListModal()}
                size="lg"
                color="primary"
                endDecorator={
                  <ChipDelete
                    onDelete={() => onClickRemoveLocationFilter()}
                    id="locationclosebutton_searchscreen"
                    data-testid="locationclosebutton_searchscreen"
                  />
                }
                variant="soft"
              >
                {language?.locationFilterLabel}
              </Chip>
            ) : (
              <Chip
                onClick={() => handleBuyerLocationListModal()}
                size="lg"
                color="neutral"
                endDecorator={<ArrowDropDownRounded />}
                variant="outlined"
              >
                {language?.locationFilterLabel}
              </Chip>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
