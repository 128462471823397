/**
 * Import necessary dependencies and assets.
 */
/**
 * The function `DownloadAppModalContent` displays modal content for downloading an app based on the
 * selected feature type.
 * @param {ModalContentProps} props - The `props` object in the `DownloadAppModalContent` component
 * consists of the following properties:
 * @returns The `DownloadAppModalContent` component is being returned. It contains JSX elements for
 * displaying modal content with images, text, buttons, and other UI elements based on the
 * `featureType` provided as a prop.
 */
import React from 'react';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton'; // Import button component
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography'; // Import typography component
import Styles from './style.module.css'; // Import CSS styles

/**
 * Define props interface for the DownloadAppModalContent component.
 */
interface ModalContentProps {
  closeModal?: (feature: number) => void; // Function to close modal
  featureType?: number; // Type of feature for which the modal content is being displayed
  onClickDownloadAppNow?: (feature: number) => void; // Function to trigger app download
  language?: { [key: string]: any }; // Object containing language-specific text and translations
  homeScreenAssets: { [key: string]: string }; // Object containing image URLs for home screen assets
}

/**
 * Define the DownloadAppModalContent component.
 */
const DownloadAppModalContent = (props: ModalContentProps) => {
  // Destructure props
  const {
    closeModal,
    featureType,
    onClickDownloadAppNow,
    language,
    homeScreenAssets,
  } = props;

  // Function to get details for modal content based on feature type
  function getModalContentDetails(featureType: number) {
    let title = '';
    let image = '';
    let cardImage = '';
    let cardText = '';

    // Switch statement to determine details based on feature type
    switch (featureType) {
      case 1:
        title = language?.rake;
        image = homeScreenAssets.rakeImage as unknown as string;
        cardImage = homeScreenAssets.rakeCardImage as unknown as string;
        cardText = language?.rakeCardText;
        break;
      case 2:
        title = language?.bahiKhata;
        image = homeScreenAssets.bahiKhataImage as unknown as string;
        cardImage = homeScreenAssets.bahiKhataCardImage as unknown as string;
        cardText = language?.bahiKhataCardText;
        break;
      case 3:
        title = language?.distributor;
        image = homeScreenAssets.distributorImage as unknown as string;
        cardImage = homeScreenAssets.distributorCardImage as string;
        cardText = language?.distributorCardText;
        break;
      case 4:
        title = language?.dukaanPrachar;
        image = homeScreenAssets.dukaanPrachaarImage as unknown as string;
        cardImage =
          homeScreenAssets.dukaanPrachaarCardImage as unknown as string;
        cardText = language?.dukaanPracharCardText;
        break;
      case 5:
        title = language?.cropAdvisory;
        image = homeScreenAssets.cropAdvisoryImage as unknown as string;
        cardImage = homeScreenAssets.cropAdvisoryCardImage as unknown as string;
        cardText = language?.cropAdvisoryCardText;
        break;
      case 6:
        title = language?.cropAdvisory;
        image = homeScreenAssets.cropAdvisoryImage as unknown as string;
        cardImage = homeScreenAssets.cropAdvisoryCardImage as unknown as string;
        cardText = language?.cropAdvisoryCardText;
        break;
      case 11:
        title = language?.quiz;
        image = homeScreenAssets.quizImage as unknown as string;
        cardImage = homeScreenAssets.quizCardImage as unknown as string;
        cardText = language?.quizCardText;
      default:
        break;
    }

    return { title, image, cardImage, cardText };
  }

  // Function to render download app card
  const downloadAppCard = () => {
    return (
      <div className={Styles.downloadAppCard}>
        <div className={Styles.cardImageContainer}>
          <img
            src={getModalContentDetails(featureType)?.cardImage}
            height={66}
            width={66}
            alt="Feature Modal Image"
            loading="eager"
          />
        </div>
        <div className={Styles.cardTextContainer}>
          <div className={Styles.cardTextTitle}>
            <MuiTypography level="body-xs">{language?.getTheApp}</MuiTypography>
          </div>
          <MuiTypography level="title-md">
            {getModalContentDetails(featureType)?.cardText}
          </MuiTypography>
        </div>
      </div>
    );
  };

  // Return JSX for the DownloadAppModalContent component
  return (
    <div className={Styles.modalContent}>
      <div className={Styles.imageContainer}>
        <img
          src={homeScreenAssets.backgroundImage as unknown as string}
          height={297}
          className={Styles.image}
          alt="Background Image"
          loading="eager"
        />
        <div className={Styles.imageTitle}>
          <MuiTypography level="h2" textColor={'#9A5B13'}>
            {getModalContentDetails(featureType)?.title}
          </MuiTypography>
        </div>
        <div className={Styles.overlappingImageContainer}>
          <img
            src={getModalContentDetails(featureType)?.image}
            className={Styles.overlappingImage}
            height={217}
            width={312}
            alt="Modal Image"
            loading="eager"
          />
        </div>
      </div>
      <div className={Styles.logoContainer}>
        <img
          src={homeScreenAssets.farmartLogo2 as unknown as string}
          className={Styles.logo}
          alt="Farmart Logo"
          loading="eager"
        />
      </div>
      <div className={Styles.contentContainer}>
        <div className={Styles.cardContainer}>{downloadAppCard()}</div>
        <div className={Styles.buttonContainer}>
          <div className={Styles.button}>
            <MuiButton
              size="lg"
              color="primary"
              onClick={() => {
                onClickDownloadAppNow(featureType);
              }}
              id="homeScreenDownloadAppModalDownloadAppButtonId"
            >
              {language?.downloadAppNow}
            </MuiButton>
          </div>
          <div className={Styles.button}>
            <MuiButton
              size="sm"
              color="primary"
              variant="plain"
              onClick={() => {
                closeModal(featureType);
              }}
              id="homeScreenDownloadAppModaolNotNowButtonId"
            >
              {language?.notNow}
            </MuiButton>
          </div>
        </div>
      </div>
    </div>
  );
};

// Export the DownloadAppModalContent component
export default DownloadAppModalContent;
