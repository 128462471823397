import getConfigByKeys from "../../config/config";
import { configKeys } from "../../config/types";
import handleHttpError from "../../utils/api/httpErrorMapping";
import HttpStatusCode from "../types/httpStatusCodesEnums.type";
import type UnifiedResponse from "../types/unifiedResponse.type";

import { DEFAULT_LANGUAGE_ID } from "../../constant/language";

import { getUserLanguageFromStorage } from "../../modal/auth/auth.model";
import { apiEndPoints } from "../endpoints";
import { isFarmartWeb } from "../../utils/common";

const { API_URL } = getConfigByKeys([configKeys.API_URL]);

export default async function PostComment(payload): Promise<UnifiedResponse> {
	let userLanguage = getUserLanguageFromStorage()?.langId || null;

	if (!userLanguage) {
		userLanguage = DEFAULT_LANGUAGE_ID;
	}

	const path = apiEndPoints.validateOTP;

	let configObj: any = {
		Accept: "application/json",
		"Content-Type": "application/json",
		language: userLanguage.toString(),
	};

	if (isFarmartWeb) {
		configObj = {
      Accept: "application/json",
			"Content-Type": "application/json",
			authorization: null,
			language: userLanguage.toString(),
    };
	}

	const response = await fetch(`${API_URL}${path}`, {
		method: "post",
		headers: configObj,
		body: JSON.stringify(payload),
	});

	if (response.status !== HttpStatusCode.Ok) {
		throw handleHttpError(response.status, path);
	}

	const data = await response.json();
	return { data, response };
}
