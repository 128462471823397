function correctUrl(url: string): string {
  /**
   * This function removes any extra slashes at the end of a URL.
   * If the URL is "/", it returns "/" directly.
   *
   * @param {string} url - The URL to be corrected.
   * @returns {string} - The corrected URL.
   */
  if (url === "/") {
    return url;
  }
  return url.replace(/\/+$/, '');
}

export default correctUrl;
