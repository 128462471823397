import React from "react";
import CircularProgressVariants from "../../atoms/loading/CircularProgress";
import { Box } from "../../atoms/layout";

const PageLoader = () => {
	return (
		<Box
			bgcolor={"background.level1"}
			position="fixed"
			top={0}
			left={0}
			right={0}
			bottom={0}
			minHeight={"100vh"}
			height="auto"
			overflow="hidden"
		>
			<Box
				position="absolute"
				left={0}
				top={0}
				right={0}
				bottom={0}
				boxShadow={"0 15px 25px rgba(129, 124, 124, 0.2)"}
				display="flex"
				alignItems={"center"}
				justifyContent={"center"}
				sx={{
					backdropFilter: "blur(5px)",
					backgroundColor: "rgba(255, 255, 255, 0.2)",
				}}
			>
				<CircularProgressVariants />
			</Box>
		</Box>
	);
};

export default PageLoader;
