import React, { Suspense } from 'react';
import Style from './style.module.css';
import { Box } from '@mui/joy';
import Header from './Header/Header';
import FAQAlert from './FAQAlert/FAQAlert';
import Footer from './Footer/Footer';
import MuiSkeleton from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';
import { FAQQuestionsProps } from '@mono-farmart-web/farmart-web-common/hooks/FAQQuestions/types';

const QuestionList = React.lazy(() => import('./QuestionList/QuestionList'));

const FAQQuestions = ({
  faqQuestionList, // List of FAQ questions.
  onClickBackFaqScreen, // Handler for back button click.
  loadingQuestion, // Loading state for FAQ questions.
  onClickFaqQuestionItem, // Handler for clicking an FAQ question item.
  onClickNeedMoreHelp, // Handler for clicking the "Need More Help" button.
  showFaqAlert, // State to show or hide the FAQ alert.
  setShowFaqAlert, // Setter function for showFaqAlert state.
  language, // Language data for the FAQ screen.
  faqAssets, // Assets for the FAQ screen.
}: FAQQuestionsProps) => {
  const skeleton = () => {
    return (
      <Box className={Style.skeletonList}>
        <MuiSkeleton
          itemNumber={12}
          sx={{
            height: '48px',
            width: '100%',
            marginBottom: 3,
            borderRadius: 4,
          }}
        />
      </Box>
    );
  };

  return (
    <Box className={Style.container}>
      <Header
        onClickBackFaqScreen={onClickBackFaqScreen} // Pass handler for back button click.
        language={language} // Pass language data.
      />
      <Suspense fallback={skeleton()}>
        <QuestionList
          faqQuestionList={faqQuestionList} // Pass FAQ questions list.
          onClickFaqQuestionItem={onClickFaqQuestionItem} // Pass handler for FAQ question item click.
          loadingQuestion={loadingQuestion} // Pass loading state.
        />
      </Suspense>
      <FAQAlert
        showFaqAlert={showFaqAlert} // Pass state for showing FAQ alert.
        setShowFaqAlert={setShowFaqAlert} // Pass setter function for FAQ alert state.
        language={language} // Pass language data.
      />
      <Footer
        onClickNeedMoreHelp={onClickNeedMoreHelp} // Pass handler for "Need More Help" button click.
        language={language} // Pass language data.
        whatsappIcon={faqAssets.whatsappIcon}
      />
    </Box>
  );
};

export default FAQQuestions;
