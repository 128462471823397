import { Box, Skeleton } from '@mui/joy';
import React from 'react';

interface TradeSkeletonProps {
  count: number;
}

export default function TradeSkeleton({ count }: TradeSkeletonProps) {
  return Array.from({ length: count }).map((_, index) => (
    <Box
      key={'tradeSkeleton' + index}
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        gap: '8px',
        padding: '16px',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          height: '141px',
          width: '100%',
        }}
      >
        <Skeleton
          sx={{
            flex: 1,
            borderRadius: '4px',
            backgroundColor: '#F0F4F8',
            position: 'relative',
          }}
        />
      </Box>
      <Box
        sx={{
          height: '32px',
          width: '100%',
          display: 'flex',
          position: 'relative',
          gap: '2px',
        }}
      >
        <Skeleton
          key={'skeleton1-' + index}
          sx={{
            height: '100%',
            width: '100%',
            borderRadius: '4px',
            backgroundColor: '#F0F4F8',
            position: 'relative',
          }}
        />
        <Skeleton
          key={'skeleton2-' + index}
          sx={{
            height: '100%',
            width: '100%',
            borderRadius: '4px',
            backgroundColor: '#F0F4F8',
            position: 'relative',
          }}
        />
      </Box>
    </Box>
  ));
}
