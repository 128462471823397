import { useState, useEffect } from "react";
import type { PinCodeProps, PinCodeDataObj } from "./types";
import GetRequest from "../../utils/apiCaller/getRequest";
import { apiEndPoints } from "../../apis/endpoints";
import { useNavigate, useLocation } from "react-router-dom";
import { getPincodeAssets } from "../../constant/imageUrls";
import { getLanguageService } from "./../../utils/getLanguageService";
import { languageUrls } from "../../constant/languageUrls/index";

export default function PinCodeHook(): PinCodeProps {
	const navigate = useNavigate(); // Navigation function
	const { state } = useLocation();
	const prevPath = state?.prevPath;
	const [language, setLanguageFile] = useState({});
	const [pinCodeSearchValue, setPinCodeSearchValue] = useState("");
	const [pageNumber, setPageNumber] = useState(0);
	const [loading, setLoading] = useState(false);
	const [totalPinCodeCounts, setTotalPinCodeCounts] = useState(0);
	const [pinCodeList, setPinCodeList] = useState<PinCodeDataObj[]>([]);

	const langId = JSON.parse(localStorage.getItem("userLanguage"));

	const pincodeAssets = getPincodeAssets(langId?.langCode);

	useEffect(() => {
		getLanguageJson();
	}, []);

	async function getLanguageJson() {
		try {
			const url = languageUrls?.pinCodeScreenLangageUrl;
			const result = await getLanguageService(url);
			setLanguageFile(result);
		} catch (error) {
			// console.log("object");
		} finally {
			// console.log("object");
		}
	}

	async function onSearchPinCode(event: React.ChangeEvent<HTMLInputElement>) {
		setPinCodeSearchValue(event?.target?.value);

		if (event?.target?.value?.length > 3) {
			setLoading(true);
			setPageNumber(1);
			const pinCodeApiData = await fetchPiCode({
				pincode: event?.target?.value,
				pageNumber: 1,
			});

			if (pinCodeApiData?.data?.status) {
				setPageNumber(2);
				setTotalPinCodeCounts(pinCodeApiData?.data?.data?.count);
				setPinCodeList(pinCodeApiData?.data?.data?.rows);
			}
			setLoading(false);
		}
	}

	async function fetchPiCode({ pincode, pageNumber }) {
		try {
			const fetchPinCodeParam = {
				search_param: pincode,
				page_no: pageNumber,
				limit: 10,
			};
			const pinCodeApiResponse = await GetRequest({
				payload: fetchPinCodeParam,
				uri: apiEndPoints.getPinCode,
			});
			return pinCodeApiResponse;
		} catch (error) {
			console.log(error);
		}
	}

	function onClickLocation(selectedLocation: PinCodeDataObj) {
		navigate(`${prevPath}`, {
			state: { changedLocation: selectedLocation, locationChanged: true },
		});
	}

	function onPressBack() {
		navigate(-1);
	}

	function onContactUs() {
		navigate("/faq/questions");
	}

	return {
		pinCodeSearchValue,
		onSearchPinCode,
		loading,
		totalPinCodeCounts,
		pinCodeList,
		onClickLocation,
		onPressBack,
		language,
		pincodeAssets,
		onContactUs,
	};
}
