import React from 'react';
import { Box } from '@mui/material';
import { FAQAnswersScreenProps } from '@mono-farmart-web/farmart-web-common/hooks/FAQAnswers/types';
import Header from './Header/Header';
import AnswerDetail from './AnswerDetail/AnswerDetail';
import FAQAlert from './FAQAlert/FAQAlert';
import Footer from './Footer/Footer';
import Styles from './style.module.css';

// Functional component definition with destructured props

const FAQAnswers = ({
  faqAnswer,
  onClickBackFaqAnswerScreen,
  onClickNeedMoreHelp,
  showFaqAlert,
  setShowFaqAlert,
  language,
  faqAssets,
}: FAQAnswersScreenProps) => {
  return (
    <Box className={Styles.container}>
      <Header onClickBackFaqAnswerScreen={onClickBackFaqAnswerScreen} />
      <AnswerDetail faqAnswer={faqAnswer} />
      <FAQAlert
        showFaqAlert={showFaqAlert} // Passing showFaqAlert prop to FAQAlert component
        setShowFaqAlert={setShowFaqAlert} // Passing setShowFaqAlert prop to FAQAlert component
        language={language}
      />
      <Footer
        onClickNeedMoreHelp={onClickNeedMoreHelp}
        language={language}
        whatsappIcon={faqAssets.whatsappIcon}
      />
    </Box>
  );
};

export default FAQAnswers; // Exporting the FAQAnswers component as the default export
