// Import React library for building user interfaces
import React from 'react';
// Import the MyProfile component from the Pages directory
import MyProfile from '../../Pages/MyProfile/MyProfile';
// Import the custom hook useMyProfilehook from the hooks directory
import useMyProfilehook from '@mono-farmart-web/farmart-web-common/hooks/MyProfile/myProfileHook';

// Define and export the MyProfileMain functional component
export default function MyProfileMain() {
  // Destructure properties from the useMyProfilehook custom hook. This hook likely provides
  // state and functions related to the user's profile and settings, such as language selection
  // and logout functionality.
  const {
    language, // Current language setting
    profile, // User profile information
    onClickMyProfile, // Function to handle clicking on the profile
    handleLanguageChange, // Function to handle changes in language selection
    handleLanguageSelect, // Function to handle final selection of a language
    isLanguageModalOpen, // Boolean indicating if the language selection modal is open
    isLanguageSelectedInProgress, // Boolean indicating if language selection is in progress
    onLanguageModalToggle, // Function to toggle the language selection modal
    selectedLanguage, // The currently selected language
    redirectToPlayStore, // Function to redirect the user to the Play Store (likely for app updates)
    help, // Help information or function
    onClickPrivacyPolicy, // Function to handle clicking on the privacy policy
    onClickLogout, // Function to handle logout action
    appVersion, // Current version of the app
    onClickBack, // Function to handle back navigation
    logOutModalVisible, // Boolean indicating if the logout modal is visible
    onClickCloseLogOutModal, // Function to close the logout modal
    confirmLogout, // Function to confirm the logout action
    loading, // Boolean indicating if the component is in a loading state
    onClickMyFarmer, // Function to handle clicking on the "My Farmer" button
    occupation, // The occupation of the user
    error, // Boolean indicating if there is an error
    myProfileAssets,
    languageData,
  } = useMyProfilehook();

  // Render the MyProfile component with the destructured properties passed as props.
  // This setup suggests a pattern where the MyProfileMain component acts as a container
  // that fetches and manages state through the useMyProfilehook, then passes this state
  // and functions down to the MyProfile component for presentation.
  return (
    <MyProfile
      language={language}
      profile={profile}
      onClickMyProfile={onClickMyProfile}
      handleLanguageChange={handleLanguageChange}
      handleLanguageSelect={handleLanguageSelect}
      isLanguageModalOpen={isLanguageModalOpen}
      isLanguageSelectedInProgress={isLanguageSelectedInProgress}
      onLanguageModalToggle={onLanguageModalToggle}
      selectedLanguage={selectedLanguage}
      redirectToPlayStore={redirectToPlayStore}
      help={help}
      onClickPrivacyPolicy={onClickPrivacyPolicy}
      onClickLogout={onClickLogout}
      appVersion={appVersion}
      onClickBack={onClickBack}
      logOutModalVisible={logOutModalVisible}
      onClickCloseLogOutModal={onClickCloseLogOutModal}
      confirmLogout={confirmLogout}
      loading={loading}
      onClickMyFarmer={onClickMyFarmer}
      occupation={occupation}
      error={error}
      myProfileAssets={myProfileAssets}
      languageData={languageData}
    />
  );
}
