import React, { useRef } from "react";
import Modal from "../../../../ComponentV2/Modal";
import Styles from "./style.module.css";
import Button from "../../../../ComponentV2/Button";
import OtpInput from "react-otp-input";
import CountdownTimer, {
	type CountdownTimerRef,
} from "../../../../utils/countDownTimer";
import { isFarmartWeb } from "../../../../utils/common";

export default function Otp({
	otpModalVisible,
	getUserOtpFromInput,
	userOtp,
	onClickValidateOtpProceed,
	changeMobileNumber,
	resendOtp,
	error,
	loading,
	userMobileNumber,
	language,
	disableResend,
	setDisableResend,
}) {
	const countdownTimerRef = useRef<CountdownTimerRef>(null);
	const handleResetClick = () => {
		if (countdownTimerRef.current) {
			setDisableResend(true);
			countdownTimerRef.current.reset();
			resendOtp();
		}
	};

	const otpInputBoxes = isFarmartWeb ? 6 : 4;
	const renderTextWithColors = (text) => {
		if (!text) return "";
		const splitText = text.split(/{|}/);

		return splitText.map((item, index) => {
			if (item.startsWith("(") && item.endsWith(")")) {
				return (
					<span
						key={index + "userMobileNumber"}
						className={Styles.mobileNumberText}
					>
						{userMobileNumber}
					</span>
				);
			}
			return <div key={index + "userMobileNumberText"}>{item}</div>;
		});
	};

	const otp = (
		<div className={Styles.otpContainer}>
			<div className={Styles.otpChangeContainer}>
				<label className={Styles.textChangeContainer}>
					{renderTextWithColors(language?.otpSentOnMobile)}
				</label>
				<label
					id="ChangeMobileNUmber"
					className={Styles.chnageTextButton}
					onClick={changeMobileNumber}
				>
					{language?.changeNumber}
				</label>
			</div>
			<OtpInput
				value={userOtp}
				onChange={getUserOtpFromInput}
				numInputs={otpInputBoxes}
				renderInput={(props) => (
					<input {...props} className={Styles.otpBox} type="tel" />
				)}
				containerStyle={Styles.otpBoxInputConatiner}
				skipDefaultStyles={true}
				inputType="tel"
			/>
			{error && (
				<label className={Styles.wrongMobileNumberText}>
					{language?.otpErrorMessage}
				</label>
			)}
			<div className={Styles.resendContainer}>
				<label className={Styles.otpResendLabelsContainer}>
					<label>{language?.didNotGetOtp}</label>
					<button
						disabled={disableResend}
						className={
							disableResend
								? Styles.resendAgainTextDisable
								: Styles.resendAgainText
						}
						onClick={handleResetClick}
						id="ResendOtpAgainButton"
					>
						{language?.sendAgain}
					</button>
					<CountdownTimer
						initialTime={30}
						className={Styles.timeOutText}
						ref={countdownTimerRef}
						onTimeChange={(isInRange) => {
							// Handle the timer range changes here
							setDisableResend(isInRange);
						}}
					/>
				</label>
			</div>
			<div className={Styles.proceedButtonContainer}>
				<Button
					title={language?.selectLanguageButton}
					onClick={onClickValidateOtpProceed}
					loading={loading}
					id={"OtpInputModalProceedButton"}
				/>
			</div>
		</div>
	);
	return (
		<Modal
			isOpen={otpModalVisible}
			onClose={() => {}}
			children={otp}
			title={language?.otpTitle}
			id={"OtpInputModal"}
		/>
	);
}
