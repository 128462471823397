/**
 * The `SmsHistoryCard` component in TypeScript React renders a card displaying SMS history data with
 * dynamic styling based on the type and record type of the SMS item.
 * @param {smsCardProp} props - The `SmsHistoryCard` component takes in the following props:
 * @returns The `SmsHistoryCard` component is being returned. It takes in props `item`, `onClickSms`,
 * and `language`, and renders a card displaying SMS history information. The card includes details
 * such as the SMS icon, heading, date, and SMS count with color coding based on certain conditions.
 * The `ColorAndCalculation` function determines the color and calculation symbol based on the type
 */
import { Box } from '@mui/joy';
import React from 'react';
import { smsData } from '@mono-farmart-web/farmart-web-common/hooks/SmsHistoryHook/types';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';

interface smsCardProp {
  item: smsData;
  onClickSms: (smsDetail: smsData) => void;
  language: { [key: string]: string };
  index: number;
}

export default function SmsHistoryCard(props: smsCardProp) {
  const { item, onClickSms, language, index } = props;

  const longDateIN = new Date(item.created_at).toLocaleString('en-IN', {
    timeZone: 'Asia/Kolkata',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  function ColorAndCalculation() {
    if (item?.type === 6) {
      return {
        color: 'text.secondary',
        calculation: '-',
        disable: true,
      };
    } else if (item?.record_type === 1) {
      return {
        color: 'danger.plainColor',
        calculation: '-',
        disable: false,
      };
    }
    return {
      color: 'success.plainColor',
      calculation: '+',
      disable: true,
    };
  }

  const replacePlaceholders = ({ template, values }) => {
    return template
      .split(/(<[^>]+>)/)
      .map((part, index) =>
        part.startsWith('<') && part.endsWith('>') ? (
          <span key={index}>{values[part.replace(/<|>/g, '')]}</span>
        ) : (
          <span key={index}>{part}</span>
        ),
      );
  };

  const numberOfSmsSent =
    item?.no_of_sms_sent && language?.numberOfdFarmerGotMessage
      ? replacePlaceholders({
          template: language?.numberOfdFarmerGotMessage,
          values: { numberOfFarmer: item?.no_of_sms_sent },
        })
      : '';

  return (
    <Box
      margin={'0px 16px 12px 16px'}
      padding={'12px'}
      borderRadius={8}
      display={'flex'}
      bgcolor={'background.level1'}
      justifyContent={'space-between'}
      onClick={
        ColorAndCalculation().disable ? () => {} : () => onClickSms(item)
      }
      id={'smsHistoryCardId' + index}
      data-testid={'smsHistoryCardId' + index}
    >
      <Box display={'flex'} alignItems={'center'} gap={'8px'}>
        <Box
          height={36}
          width={36}
          bgcolor={'background.level2'}
          borderRadius={4}
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
        >
          <img
            src={item?.icon}
            style={{ height: 28, width: 28 }}
            loading="lazy"
          />
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={'2px'}>
          <MuiTypography level="body-xxs" textColor="text.primary">
            {item?.heading}
          </MuiTypography>
          <MuiTypography textColor="text.secondary" level="label-sm">
            {numberOfSmsSent} {longDateIN}
          </MuiTypography>
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <MuiTypography level="body-xs" textColor={ColorAndCalculation().color}>
          {ColorAndCalculation().calculation} {item?.sms_count}
        </MuiTypography>
      </Box>
    </Box>
  );
}
