/**
 * The `OccupationCard` function renders a card component displaying information based on the selected
 * user persona with an option to change the role.
 * @param  - The `OccupationCard` component takes three props as parameters:
 * @returns The OccupationCard component is being returned. It is a functional component that displays
 * information based on the user's selected persona role. The component includes typography for the
 * persona role and description, an image based on the persona role, and a button to change the persona
 * role. The onClickpersonaRole function is triggered when the button is clicked.
 */
import React from 'react';
import styles from './style.module.css';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { Box } from '@mui/material';
import { userType } from '@mono-farmart-web/farmart-web-common/constant/enums/userType';
import OccupationImages from '@mono-farmart-web/farmart-web-common/constant/images';

/**
 * The `OccupationCard` function renders a card component displaying information based on the selected
 * user persona with an option to change the role.
 * @param {Object} props - The props object containing the following properties:
 *   - onClickpersonaRole: Function triggered when the button to change the persona role is clicked.
 *   - language: Object containing language-specific text and translations.
 *   - userSelectedPersona: The selected user persona role.
 * @returns {JSX.Element} The OccupationCard component is being returned. It is a functional component that displays
 * information based on the user's selected persona role. The component includes typography for the
 * persona role and description, an image based on the persona role, and a button to change the persona
 * role. The onClickpersonaRole function is triggered when the button is clicked.
 */

export default function OccupationCard({
  onClickpersonaRole,
  language,
  userSelectedPersona,
}) {
  return (
    <Box className={styles.wrapper} bgcolor={'background.level1'}>
      <div className={styles?.mainContainer}>
        <div className={styles?.infoContainer}>
          <div className={styles?.contentContainer}>
            <MuiTypography level="title-md">
              {userType['retailer'] == userSelectedPersona
                ? language?.retailer
                : userType['trader'] == userSelectedPersona
                ? language?.distributer
                : language?.farmer}
            </MuiTypography>
            <MuiTypography level="body-xxs">
              {userType['retailer'] == userSelectedPersona
                ? language?.retailerDescp
                : userType['trader'] == userSelectedPersona
                ? language?.distributerDescp
                : language?.farmerDescp}
            </MuiTypography>
          </div>
          <img
            className={styles.profileImageContainer}
            src={OccupationImages?.occupationImages[userSelectedPersona]}
            alt="personaImage"
            loading="eager"
          />
        </div>
        <MuiButton
          size="md"
          color="neutral"
          endDecorator={<KeyboardArrowDownRounded />}
          variant="soft"
          onClick={onClickpersonaRole}
          id="OccupationCardChangeRoleButtonId"
        >
          {language?.changeRole}
        </MuiButton>
      </div>
    </Box>
  );
}
