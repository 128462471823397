export const languageUrls = {
  homeScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/29ada2b7-a956-445f-9582-66e9717dc4eb_homescreen.json',
  loginScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/110e39d7-f080-4d1d-ac56-7059eb6c1fe0_login.json',
  otpScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/d2d7ae37-8578-4dd2-be4a-3b5d1953ca99_otp.json',
  registerScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/63700205-3a45-43f5-9935-0b371f655db3_userRegistration.json',
  addFarmerAcknowledgementScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/8e6a0de0-cc8e-4dfa-8719-fab4e62c1283_acknowledgement.json',
  addFarmerScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/3b5e7a76-dc15-4162-a241-10820f037ae9_addFarmer.json',
  myFarmerScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/09f1fa56-0053-48a8-9ccf-cb8add42dfd5_myfarmer.json',
  rakeDetailListScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/f6bffa64-f2e3-42f6-b610-1c64321269f2_rakeDetailList.json',
  rakeListScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/a1502a94-7aa1-4656-9335-c7a6c5f4b709_rakeList.json',
  faqAnswers:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/88fdeda2-c494-480f-b6a7-58b0606702c5_faqAnswers.json',

  faqQuestions:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/1ab2a55f-9f1d-4979-a2a0-39c03154c1ae_faqQuestions.json',
  tradeList:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/e0c07631-db7c-42b8-9c96-69682a1d786f_tradeList.json',
  tradePriceBreakup:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/44003dbf-b8d6-48ca-8a42-2a8e1c6738d0_tradePriceBreakupLanguage.json',
  mySaudaScreen:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/c21d1b39-d206-457f-8300-308618563f3f_mysauda.json',
  saudaDetailScreen:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/a523a783-3231-428a-a981-3f200377f0d7_saudaDetails.json',
  saudaAcknowledgement:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/75b97097-3010-4951-bee5-b1b8813183e9_saudaAcknowledgement.json',
  smstemplateScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/cafde99d-3b3d-4dbc-9c42-3f9a6366100c_smstemplate.json',

  smsLandingScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/f70ea47b-9dec-4065-aeba-32b4cc2320b3_smsLanding.json',

  smsHistoryDetailScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/129fd131-72e0-448d-a65d-75571eb402ec_smsHistoryDetail.json',

  smsFarmerScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/1c8d8e5e-8c7f-4898-b11d-04f8015f0ba5_smsFarmer.json',

  smsAcknowledgementScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/e2eeaf97-e004-4d24-bbd9-0bbec179097b_smsAcknowledgement.json',

  shopPromotionalModalScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/ca3e08fb-2ea0-4613-aabe-a58cf361cd32_shopPromotionalModal.json',
  smsHistoryScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/0073bde1-4056-4584-892f-ba3265ed1fdc_marketingsmsHistory.json',
  pinCodeScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/7748d00c-73d6-404e-8c32-16c8ce71a1aa_pincode.json',
  locationModalScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/2a29867b-f6cc-42bd-8178-2d9048fcce4e_location.json',
  myProfileScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/ce3a852d-c3b0-4ddb-859b-590b73a4ed98_myprofile.json',
  communityLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/f44ba766-66e8-454a-bf80-f0891d7c8cb3_community.json',
  navbarLanguageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/22ff8b90-c5cb-48b1-8d2c-13e1dcf86954_navbar.json',
  editProfileScreenLangageUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/3daa5daf-3da8-428a-ab71-2b5c1528a174_editProfile.json',
  createDispatchUrl:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/b171c9ec-928a-4a2d-a321-60f51403e1d6_createCumDetailDispatch.json',
  documentUpload:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/11/bd070c10-3e86-483b-834b-6efb1a8190cf_documentUpload.json',
  timeAgo:
    'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/11/18/0e2a48fc-c84c-4cf2-83c4-60c2ae403adc_time.json',
  commonErrorState: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/3/630e22fb-806d-4bc2-b524-ed6a81606be1_errorPage.json",
  paymentScreenLangageUrl: "https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/12/4/6285b84f-fc33-4a43-abaa-6493e5ba7551_paymentDialogue.json",
  trackConsent: "https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/10/a83d7038-91b5-40a6-93cb-bb28da16e369_tracking.json",
  buyerSearch:
  'https://d22im4negugtqg.cloudfront.net/fmtapp/asset/2024/12/16/a6d92ed3-d509-472a-a501-2469fb5f434b_searchBuyer.json',
};
