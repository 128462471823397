
import React from 'react';
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import ButtonC from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/button/Button";
import {
	Box,
	Column,
	Row,
} from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";

import { useSelector } from "react-redux";

import PageLoader from "@mono-farmart-web/farmart-web-common/MuiComponents/organisms/pageLoader/PageLoader";
import DeliveryDetails from "../DeliveryDetails";
import TruckDriverDetailCard from "../TruckDriverDetailCard";

import AlertC from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/alert/Alert";
import InAppBrowser from "@mono-farmart-web/farmart-web-common/MuiComponents/organisms/InAppBrowser/InAppBrowser";
import FullScreenDialog from "@mono-farmart-web/farmart-web-common/MuiComponents/organisms/fullScreenDialog/FullScreenDialog";
import { useInterval } from "@mono-farmart-web/farmart-web-common/hooks/general";
import { LangEnums } from "@mono-farmart-web/farmart-web-common/constant/enums/appEnums";
import { getAuthTokenFromStorage } from "@mono-farmart-web/farmart-web-common/modal/auth/auth.model";
import { useNavigate } from "react-router-dom";
import useAskForConsent from "../../hooks/useAskForConsent";
import HeroMessageCover from "../HeroMessageCover";
import MessageWrapper from "../MessageWrapper";
import StepCard from "../StepCard";
// import type { RootState } from "../../../store/store";

import { isSaudabookPWA } from '@mono-farmart-web/farmart-web-common/utils/common';
import { getTrackConsentScreenAssets } from '@mono-farmart-web/farmart-web-common/constant/imageUrls/index';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index';

interface ConsentPendingProps {
	onOpenDialog?: () => void;
	payload?: any;
	loader?: boolean | null;
	language?: any;
	encrypted_trip_id?: string;
	trip_id?: number;
	activeLanguage?: string;
}

let timeoutConsentAsked: any = null;
let timeoutButtonDisabled: any = null;

const timerCount = 0;
const ConsentPending = (props: ConsentPendingProps) => {
  const trackConsentAssets = getTrackConsentScreenAssets();
	const authToken = getAuthTokenFromStorage();
	const activeLanguage = props?.activeLanguage;
	const {
		language,
		trip_id,
		payload: { consent_status, trip_status, dispatchedDate },
	} = props;

	const navigate = useNavigate();
	// const { appState } = useAppState();
	const { askForConsent } = useAskForConsent(language);
	const [isConsentAsked, setIsConsentAsked] = useState(false);
	const [consentAskedStatusMessage, setConsentAskedStatusMessage] =
		useState("");
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [disabledButtonMessage, setDisabledButtonMessage] = useState(
		language?.consentReminderButtonDisabled,
	);
	const [activeButtonMessage, setActiveButtonMessage] = useState(
		language?.consentReminderButtonActive,
	);
	const [notifIcon, setNotifIcon] = useState<string>("info");
	const [notifMessage, setNotifMessage] = useState<string>(
		language?.takeDriverConsentMessage,
	);
	const [openMidVideoModal, setOpenMidVideoModal] = useState<boolean>(false);

	const sendConsentReminder = async () => {
		let payload: any = {
			mobile_number: props?.payload?.vehicle_details?.driver_number,
			driver_name: props?.payload?.vehicle_details?.driver_name,
			sender_name: "some random name for testing", // to be changed after discussion;
		};

    if(!isSaudabookPWA) {
      payload = {
        trip_id: props?.payload?.trip_id,
        truck_id: props?.payload?.truck_id
      }
    }

		const result = await askForConsent(payload);

		if (result?.status) {
			// TRACKING THE CONSENT REMINDER BUTTON EVENT;
			/* The above code is tracking an event using Moengage analytics when a reminder SMS button is
			clicked in a TypeScript React application. It is passing the event type
			`moengageEvents?.OnReminderSMSButtonClick` and the `ipAddress` as parameters to the `trackEvent`
			function. */

			// CHANGING THE MESSAGE BREADCRUMB;
			setNotifIcon("info");
			setNotifMessage(language?.consentReminderSentMessage);

			setIsConsentAsked(result?.status);
			setConsentAskedStatusMessage(language?.consentMessage);

			// DISABLING BUTTON AFTER EVERY HIT FOR 1-MINUTE STRAIGHT;
			setIsButtonDisabled(true);

			timeoutConsentAsked = setTimeout(() => {
				setIsConsentAsked(false);
				setConsentAskedStatusMessage("");
			}, 3000);

			// ENABLES THE CONSENT REMINDER BUTTON AFTER 1 MINUTE;
			timeoutButtonDisabled = setTimeout(() => {
				setIsButtonDisabled(false);
			}, 60000);
		}
	};

	const handleMidVideoModal = (toggleFlag: boolean) => {
		setOpenMidVideoModal(toggleFlag);
	};

	useInterval(() => {
		// CHECKS IF THE CONSENT HAS BEEN GIVEN BY THE DRIVER (EVERY 15 MINUTES) AND THEN TRANSITIONS THE PAGE TO TRACKING SECTION;
		if(isSaudabookPWA) {
      axios
			.get(
				`${import.meta.env.VITE_API_BASE_URL}/v1/trip/fetchStatus?trip_id=${trip_id}`,
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
				},
			)
			.then((res) => {
				const {
					data: { consent_status, trip_status },
				} = res?.data;

				const trip_started = trip_status === 2 && consent_status === 2;

				if (trip_started) {
					setNotifIcon("success");
					setNotifMessage(language?.consentReceivedMessage);

					// AFTER THE CONSENT APPROVED MESSAGE IS SHOWN,
					// THE PAGE TRANSITIONS IN 3 SECONDS;
					setTimeout(() => {
						navigate(`/tripDetail/${props?.encrypted_trip_id}`);
					}, 3000);
				}
			})
			.catch((err) => {
				console.log("Error while getting the trip information:", err);
			});
    }
	}, 10000);

	useEffect(() => {
		return () => {
			clearTimeout(timeoutButtonDisabled);
			clearTimeout(timeoutConsentAsked);
		};
	}, []);

	if (props?.loader) return <PageLoader />;

	return (
		<>
			{/* SUCCESS/FAILRUE MESSAGE COMPONENT */}
			{isConsentAsked && (
				<Box
					padding={4}
					sx={{
						position: "absolute",
						bottom: 0,
						right: 0,
						left: 0,
						background: "transparent",
					}}
				>
					<AlertC
						color="success"
						size="md"
						variant="solid"
						startDecorator={
							<CheckCircleIcon
								sx={{ width: "24px", height: "24px", color: "common.white" }}
							/>
						}
						sx={{
							zIndex: 4000,
							borderRadius: "8px",
						}}
					>
						<Text level="body-xxs" textColor="common.white" fontWeight={500}>
							{consentAskedStatusMessage}
						</Text>
					</AlertC>
				</Box>
			)}
			<HeroMessageCover
				bgColor={"success.plainHoverBg"}
				titleId="tripCreatediText_consentpendingscreen"
				dateStringId=""
				icon={
					<CheckCircleIcon
						id="tickicon_consentpendingscreen"
						sx={{ width: "30px", height: "30px", color: "#136C13" }}
					/>
				}
				title={language?.tripCreated}
				titleColor="success.600"
				dateString={moment(dispatchedDate)?.format("dddd, DD MMMM YYYY h:mm A")}
			/>

			{/* MESSAGE UPDATE SECTION */}
			<MessageWrapper type={notifIcon} message={notifMessage} />

			{/* LOWER SECTION */}
			<Column gap={2} bgcolor={"neutral.softBg"}>
				{/* DRIVER DETAIL/CTA SECTION */}
				<Box
					sx={{
						padding: "0px 16px 24px 16px",
					}}
				>
					<Column gap={6}>
						{/* DRIVER INFO SECTION */}
						<TruckDriverDetailCard
							allowEditDetails={true}
							payload={props?.payload?.vehicle_details}
							onOpenDialog={props?.onOpenDialog}
						/>

						{/* CTA SECTION */}
						<ButtonC
							id="reminder_driver_forConsent_Tabconsentpendingscreen"
							size="md"
							disabled={isButtonDisabled}
							endDecorator={
								<ArrowForwardIcon sx={{ width: "24px", height: "24px" }} />
							}
							sx={{
								fontSize: "primary.solidColor",
								fontFamily: "Noto Sans",
								fontWeight: 600,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								padding: "4px 16px 4px 16px",
								height: "40px",
								borderRadius: "8px",
							}}
							onClick={() => {
                if(!isSaudabookPWA) {
                  trackEvent(Events?.onRemindDriverLocationAccessButtonClick, {});
                }
                sendConsentReminder();
              }}
						>
							{isButtonDisabled ? disabledButtonMessage : activeButtonMessage}
						</ButtonC>
					</Column>
				</Box>

				{/* TUTORIAL */}
				<Column>
					{/* HEADER INSTRUCTIONS */}
					<Box
						sx={{
							padding: "24px 16px 16px 16px",
						}}
					>
						<Column
							gap={0.5}
							sx={{
								textAlign: "center",
							}}
						>
							<Text
								id="How_to_get_consent_text_consentpendingscreen"
								level="title-lg"
								fontWeight={600}
								textColor="text.secondary"
							>
								{language?.howToGetConsentFromDriver}
							</Text>
							<Text
								id="follow_the_steps_given_consentpendingscreen"
								level="body-xs"
								fontWeight={500}
								lineHeight={1.5}
								textColor="text.secondary"
							>
								{
									language?.consentPendingPage
										?.followTheStepsGivenBelowOrWatchVideo
								}
							</Text>
						</Column>
					</Box>

					{/* VIDEO BTN */}
					<Box
						sx={{
							padding: "0px 16px 28px 16px",
						}}
					>
						<Row alignItems={"center"} justifyContent={"center"}>
							{/* <Link href={"https://www.youtube.com"}>

							</Link> */}
							<ButtonC
								id="watch_how_to_take_consent_consentpendingscreen"
								variant="outlined"
								onClick={() => handleMidVideoModal(true)}
								sx={{
									border: "1px solid #F09898",
									color: "danger.outlinedColor",
									fontWeight: 600,
									lineHeight: "14px",
									fontFamily: "Noto Sans",
									borderRadius: "8px",
								}}
								endDecorator={
									<SmartDisplayIcon sx={{ color: "danger.outlinedColor" }} />
								}
							>
								{language?.watchHowToTakeConsent}
							</ButtonC>
						</Row>
					</Box>

					{/* STEPS TUTORIAL */}
					<Box paddingLeft={4} paddingRight={4}>
						<Column gap={4}>
							<StepCard
								stepTitleId="your-driver_receviestext_consentpendingscreen"
								stepDescriptionId="your_driver_will-_consentpendingscreen"
								stepCount={1}
								stepTitle={language?.takeConsentStepOneTitle}
								stepDescription={language?.takeConsentStepOneDescription}
							>
								{/* TUTORIAL IMAGES AND CONTENT */}
								<Box
									sx={{
										background: "#FBFCFE",
									}}
								>
									<Row
										alignItems={"center"}
										justifyContent={"center"}
										sx={{ background: "inherit" }}
									>
										<img
											id="mobile_image-_consentpendingscreen"
											src={trackConsentAssets?.trackingMobile}
											alt={"phone-img-ico"}
											width={126}
											height={255}
										/>
									</Row>
								</Box>
							</StepCard>

							<StepCard
								stepTitleId="ask_driverto_get_consent_consentpendingscreen"
								stepDescriptionId="check_driver_sim_consentpendingscreen"
								stepCount={2}
								stepTitle={language?.takeConsentStepTwoTitle}
								stepDescription={language?.takeConsentStepTwoDescription}
							>
								{/* TUTORIAL IMAGES AND CONTENT */}
								<Box
									sx={{
										background: "#FBFCFE",
									}}
								>
									{/* INNER BOX TO HOLD MULTIPLE CHILDREN */}
									<Box
										sx={{
											background: "inherit",
										}}
									>
										<Column
											gap={11}
											sx={{
												background: "inherit",
											}}
										>
											<Column
												sx={{
													background: "inherit",
												}}
												justifyContent={"center"}
												alignItems={"center"}
												gap={4}
											>
												<img
													id="jio_sim_logo_consentpendingscreen"
													src={trackConsentAssets?.jio}
													alt={"jio-ico"}
													width={98}
													height={98}
												/>
												<Box
													sx={{
														background: "inherit",
													}}
												>
													<Column
														gap={1}
														sx={{ textAlign: "center", background: "inherit" }}
													>
														<Text
															id="if_driver_have_jio_sim_consentpendingscreen"
															level="body-xs"
															textColor="text.secondary"
															fontWeight={500}
														>
															{
																language?.consentPendingPage
																	?.ifDriverHasNoJioCard
															}
														</Text>
														<Text
															level="body-sm"
															textColor="text.secondary"
															fontWeight={400}
														>
															{language?.giveMissedCallTo}{" "}
															<Text
																id="jio_operator_number_sim_consentpendingscreen"
																level="title-sm"
																textColor="text.primary"
																fontWeight={600}
															>
																9321003994
															</Text>
														</Text>
													</Column>
												</Box>
											</Column>

											<Column
												justifyContent={"center"}
												alignItems={"center"}
												gap={4}
												sx={{
													background: "inherit",
												}}
											>
												{/* IMAGES CONTAINER */}
												<Box
													sx={{
														background: "inherit",
													}}
												>
													<Row
														gap={1}
														sx={{
															background: "inherit",
														}}
													>
														<img
															id="airtel+sim_logo_consentpendingscreen"
															src={trackConsentAssets?.airtel}
															alt={"airtel-ico"}
															width={80}
															height={80}
														/>
														<img
															id="bsnl_sim_logo_consentpendingscreen"
															src={trackConsentAssets?.bsnl}
															alt={"bsnl-ico"}
															width={80}
															height={80}
														/>
														<img
															id="vodafone_sim_logo_consentpendingscreen"
															src={trackConsentAssets?.vi}
															alt={"voda-ico"}
															width={80}
															height={80}
														/>
													</Row>
												</Box>

												<Box
													sx={{
														background: "inherit",
													}}
												>
													<Column
														gap={1}
														sx={{
															textAlign: "center",
															background: "inherit",
														}}
													>
														<Text
															id="if_driver_has airbsnlvi_number_consentpendingscreen"
															level="body-xs"
															textColor="text.secondary"
															fontWeight={500}
														>
															{language?.ifNoOtherSim}
														</Text>
														{activeLanguage === LangEnums?.HINDI ? (
															<Text
																id="operator_number_consentpendingscreen"
																level="title-sm"
																textColor="text.primary"
																fontWeight={600}
															>
																7303777719{" "}
																<Text
																	level="body-sm"
																	textColor="text.secondary"
																	fontWeight={400}
																>
																	पर कॉल करें और{" "}
																</Text>
																<Text
																	level="title-sm"
																	textColor="text.primary"
																	fontWeight={600}
																>
																	1 दबायें।
																</Text>
															</Text>
														) : activeLanguage === LangEnums?.ENGLISH ? (
															<Text
																level="body-sm"
																textColor="text.secondary"
																fontWeight={400}
															>
																Call{" "}
																<Text
																	id="operator_number_consentpendingscreen"
																	level="title-sm"
																	textColor="text.primary"
																	fontWeight={600}
																>
																	7303777719
																</Text>{" "}
																and{" "}
																<Text
																	level="title-sm"
																	textColor="text.primary"
																	fontWeight={600}
																>
																	Press 1
																</Text>
															</Text>
														): (
                              <Text
																level="body-sm"
																textColor="text.primary"
																fontWeight={600}
															>
																7303777719{" "}
																<Text
																	id="operator_number_consentpendingscreen"
																	level="title-sm"
																	textColor="text.secondary"
																	fontWeight={400}
																>
																	वर कॉल करा आणि{" "}
																</Text>{" "}
																<Text
																	level="title-sm"
																	textColor="text.primary"
																	fontWeight={600}
																>
																	1 {" "}
                                  <Text
																	id="operator_number_consentpendingscreen"
																	level="title-sm"
																	textColor="text.secondary"
																	fontWeight={400}
																>
																	दाबा
																</Text>
																</Text>
															</Text>
                            )}
													</Column>
												</Box>
											</Column>
										</Column>
									</Box>
								</Box>
							</StepCard>
						</Column>
					</Box>

					{/* Message Box */}
					<Box
						sx={{
							padding: "12px 16px 24px 16px",
						}}
					>
						<Row alignItems={"center"} justifyContent={"center"}>
							<Text
								id="once_driver_number_consentpendingscreen"
								level="title-sm"
								fontWeight={600}
								textAlign={"center"}
								width={"296px"}
								textColor={"text.secondary"}
							>
								{language?.onceDriverCompleteTheseSteps}
							</Text>
						</Row>
					</Box>
				</Column>

				{/* EXTRA DETAILS */}
				<DeliveryDetails
					title={language?.truckRouteDetail}
					language={language}
					deliveredTo={props?.payload?.receiver_detail?.company_name}
					dispatchedLocation={`${props?.payload?.source_address?.district}, ${props?.payload?.source_address?.state}`}
					dispatchedDate={`${moment(
						props?.payload?.source_address?.dispatched_at,
					)?.format("DD MMM YYYY")} | ${moment(
						props?.payload?.source_address?.expected_delivery,
					)?.format("h:mm A")}`}
					deliveryLocation={`${props?.payload?.destination_address?.district}, ${props?.payload?.destination_address?.state}`}
					deliveryDate={`${moment(
						props?.payload?.destination_address?.expected_delivery,
					)?.format("DD MMM YYYY")}`}
					estimatedDistance={`${props?.payload?.estimated_distance} kms`}
				/>
			</Column>

			{/* VIDEO MODAL FOR CONSENT PENDING SCREEN */}
			<FullScreenDialog
				open={openMidVideoModal}
				onClose={() => handleMidVideoModal(false)}
				titleText={"How to get driver consent?"}
			>
				<video
					height={"100%"}
					width={"100%"}
					src={"https://www.youtube.com/embed/_uY9LHa0VEw"}
					autoPlay
					loop
				/>
			</FullScreenDialog>

			<InAppBrowser
				open={openMidVideoModal}
				onClose={() => handleMidVideoModal(false)}
				titleText={"How to get driver consent?"}
			>
				<iframe
					id="iframe_homescreen"
					loading="lazy"
					src={"https://www.youtube.com/embed/_uY9LHa0VEw"}
					title="IFrame"
					width="100%"
					style={{ display: "flex", flex: 1, border: "none" }}
					height="100%"
				/>
			</InAppBrowser>
		</>
	);
};

export default ConsentPending;
