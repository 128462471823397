import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    searchBuyer: 'Search Buyer',
    nobuyerFound: 'No Buyer Found',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    searchBuyer: 'बायर खोजें ',
    nobuyerFound: 'कोई बायर नहीं मिला।',
  },
};

export default (language) => languages[language];
