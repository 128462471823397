import CreateDispatchApi from "../../../apis/MySauda/createDispatch";

import type { CreateDispatchPayload, CreateDispatchResponse } from "../types";

export default async function createDispatch(
	payload: CreateDispatchPayload,
): Promise<CreateDispatchResponse> {
	const body = {
		...payload,
	};
	try {
		const result = await CreateDispatchApi(body);

		if (!result.data.status) {
			throw result;
		}

		return result.data.data;
	} catch (error) {
		if (!error.message) {
			error.message = "Something Went Wrong";
		}

		throw error;
	}
}
