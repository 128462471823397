// LanguageContext.tsx
import React, {
	createContext,
	useState,
	useEffect,
	type ReactNode,
} from "react";
import { setLanguageData } from "../../service/languageService";
import { apiEndPoints } from "../../apis/endpoints";
import GetRequest from "../../utils/apiCaller/getRequest";
import { useDispatch} from "react-redux";
interface LanguageContextType {
	languageData: any;
	languageLoading: boolean;
}

const LanguageContext = createContext<LanguageContextType | undefined>(
	undefined,
);

const fallbackValue = [
	{
		langCode: "HI",
		languageNameGeneral: "hindi",
		languageNameInHindi: "हिंदी",
		languageNameInEnglish: "Hindi",
		languageAlphabet: "आ",
		backendLanguageCode: 1,
	},
];
function transformLanguageData(data) {
	return data.map((item) => ({
		langCode: item.lang_code,
		languageNameGeneral: item.language_name_general,
		languageNameInHindi: item.primary_language_name,
		languageNameInEnglish: item.secondary_language_name,
		languageAlphabet: item.language_alphabet,
		backendLanguageCode: item.id,
	}));
}
export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [languageData, setLanguageDataState] = useState(null);
	const [languageLoading, setLanguageLoading] = useState(true);
	const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
	function localStorageUpdateLanguage(value: any) {
		const matchedLang = value?.find((lang) =>
			activeSystemLang?.includes(lang.lang_code.toLowerCase()),
		);

		const data = matchedLang?.lang_code || fallbackValue[0]?.langCode; // Default to "HI" if no match is found
		const langId = matchedLang?.id || fallbackValue[0]?.backendLanguageCode; // Default to null if no match is found
		const localData = JSON.parse(localStorage.getItem("userLanguage"));

		if (!localData) {
			localStorage.setItem(
				"userLanguage",
				JSON.stringify({
					langCode: data,
					langId: langId,
				}),
			);
		}
	}

	useEffect(() => {
		const fetchLanguageData = async () => {
			try {

				const response = await GetRequest({
					payload: {},
					uri: apiEndPoints?.getLanguage,
				});

				if (response) {
					if (response?.data?.status) {
						setLanguageDataState(transformLanguageData(response?.data?.data));
						setLanguageData(transformLanguageData(response?.data?.data)); // Set language data in the global service
						localStorageUpdateLanguage(response?.data?.data);
					} else {
						setLanguageDataState(fallbackValue);
						setLanguageData(fallbackValue); // Set language data in the global service
					}
				} else {
					setLanguageDataState(fallbackValue);
					setLanguageData(fallbackValue); // Set language data in the global service
				}
			} catch (error) {
				setLanguageDataState(fallbackValue);
				setLanguageData(fallbackValue); // Set language data in the global service
				console.error("Error fetching language data:", error);
			} finally {
				setLanguageLoading(false);
			}
		};
		fetchLanguageData();
	}, []);
	return (
		<LanguageContext.Provider value={{ languageData, languageLoading }}>
			{children}
		</LanguageContext.Provider>
	);
};
export const useLanguage = () => {
	const context = React.useContext(LanguageContext);
	if (!context) {
		throw new Error("useLanguage must be used within a LanguageProvider");
	}
	return context;
};
