// AuthContext.tsx
// biome-ignore lint/style/useImportType: <explanation>
import React from "react";
import { createContext, useContext, type ReactNode } from "react";
import useGetOtp from "./useGetOtp"; // Assuming your custom hook file is named useGetOtp
import useGetSBOtp from "./useGetSBOtp"; // Assuming your custom hook file is named useGetSBOtp
import type { GetOtp } from "./types";
import LoginView from "./TradeLoginPage/TraderLogin";
import { isFarmartWeb } from "../../utils/common";

// Define the context type
type AuthContextType = any;

// Create the context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create the provider component
const FWAuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const authContextValue = useGetOtp(); // Use your custom hook here

	return (
		<AuthContext.Provider value={authContextValue}>
			{children}
		</AuthContext.Provider>
	);
};


// THIS AUTH CONTEXT WOULD BE USED FOR SAUDABOOK APP;
const SBAuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const authContextValue = useGetSBOtp(); // Use your custom hook here

	return (
		<AuthContext.Provider value={authContextValue}>
			{children}
		</AuthContext.Provider>
	);
};

export const AuthProvider = !isFarmartWeb ? SBAuthProvider : FWAuthProvider;

// Custom hook to consume the context
export const useAuth = (): AuthContextType => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};

export { LoginView };
