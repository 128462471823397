import React, { useState, useEffect } from 'react';
import { Alert, Box, Card, Chip, Tooltip } from '@mui/joy';
import {
  FolderRounded,
  PersonOutlineRounded,
  CreateNewFolderRounded,
  ChevronRightRounded,
} from '@mui/icons-material';
import MuiAccordian from '@mono-farmart-web/farmart-web-common/MuiComponents/MuiAccordian';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { RiveComponent } from '@mono-farmart-web/farmart-web-common/utils/riveComponent';
import DocumentStatus from '@mono-farmart-web/farmart-web-common/constant/docStatusEnums';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
import { CalendarTodayRounded } from '@mui/icons-material';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index';
import formatDate from '@mono-farmart-web/farmart-web-common/utils/dateAndTIme';

const UnloadingDocContainer = ({
  language,
  uploadedByFarMart,
  buyerGRNDocument,
  unloadingWeightSlipDocument,
  onClickUploadDocument,
  unloadDispatchTime
}) => {
  const [isClosed, setIsClosed] = useState(false);
  const accordionState = !!unloadingWeightSlipDocument && !!buyerGRNDocument;

  const checkAllApproved =
    buyerGRNDocument?.folder_status_id ===
      DocumentStatus.get('documentApproved') &&
    unloadingWeightSlipDocument?.folder_status_id ===
      DocumentStatus.get('documentApproved');

  useEffect(() => {
    setIsClosed(accordionState);
  }, [accordionState]);

  const folderContainer = (folderDetails) => {
    return folderDetails?.folder_status_id ||
      folderDetails?.docs?.length > 0 ? (
      <Box
        sx={{ backgroundColor: '#C7DFF7', color: '#12467B' }}
        borderRadius={'6px'}
        padding={'9px'}
      >
        <FolderRounded
          sx={{ height: '32px', width: '32px' }}
          htmlColor="#12467B"
        />
      </Box>
    ) : (
      <Box
        sx={{ border: '1px dashed #CDD7E1' }}
        borderRadius={'6px'}
        padding={'9px'}
      >
        <CreateNewFolderRounded sx={{ height: '32px', width: '32px' }} />
      </Box>
    );
  };

  const bannerText = () => {
    if (
      buyerGRNDocument?.folder_status_id ||
      unloadingWeightSlipDocument?.folder_status_id
    ) {
      return (
        <Box display={'flex'} alignItems={'center'}>
          <MuiTypography level={'body-xs'} color={'primary'}>
            {language?.waitTillDocumentsGetApproved}
          </MuiTypography>
        </Box>
      );
    } else {
      return (
        <>
          <MuiTypography level={'body-xs'} color={'primary'}>
            {language?.getPaymentWithinHours}
          </MuiTypography>
          <MuiTypography
            level={'label-sm'}
            color={'primary'}
            id="toreceivepayment_text_truckdetailscreen"
            data-testid="toreceivepayment_text_truckdetailscreen"
          >
            {language?.uploadUnloadingDocumentsToGetPayment}
          </MuiTypography>
        </>
      );
    }
  };

  const unloadingBanner = () => {
    return (
      <Box
        borderRadius={8}
        paddingTop={2}
        paddingBottom={6}
        paddingX={3}
        display={'flex'}
        marginBottom={'-12px'}
        sx={{ backgroundColor: '#B2E0D8' }}
      >
        <Box height={22} width={19} paddingRight={2}>
          <RiveComponent
            src={
              'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/12/10/724d0cea-907f-4bf9-8e6c-55e2c1745b90_ruppee-trade.riv'
            }
            autoplay={true}
            id="goldcoin_icon_truckdetailscreen"
            data-testid="goldcoin_icon_truckdetailscreen"
          />
        </Box>
        <Box>{bannerText()}</Box>
      </Box>
    );
  };

  const cardSubTitle = (folderDetails, text, docObject) => {
    const docObj = JSON.parse(docObject);
    if (docObj?.newDocs?.length || docObj?.newDocs?.length) {
      return (
        <MuiTypography
          level={'label-md'}
          lineHeight={'14px'}
          textColor={'primary.outlinedColor'}
          fontSize={'10px'}
        >
          {language?.documentAdded}
        </MuiTypography>
      );
    }

    if (docObj?.updatedDocs?.length || docObj?.updatedDocs?.length) {
      return (
        <MuiTypography
          level={'label-md'}
          lineHeight={'14px'}
          textColor={'primary.outlinedColor'}
          fontSize={'10px'}
        >
          {language?.documentAdded}
        </MuiTypography>
      );
    }

    if (folderDetails?.folder_status_id) {
      switch (folderDetails?.folder_status_id) {
        case DocumentStatus.get('documentApprovalPending'):
          return (
            <Chip size="sm" sx={{ borderRadius: '6px', marginTop: '4px' }}>
              {language?.approvalPending}
            </Chip>
          );
        case DocumentStatus.get('documentApproved'):
          return (
            <Chip
              size="sm"
              sx={{ borderRadius: '6px', marginTop: '4px' }}
              color={'success'}
            >
              {language?.approved}
            </Chip>
          );
        case DocumentStatus.get('documentRejected'):
          return (
            <Chip
              size="sm"
              sx={{ borderRadius: '6px', marginTop: '4px' }}
              color={'danger'}
            >
              {language?.rejected}
            </Chip>
          );
        default:
      }
    }

    if (folderDetails?.docs) {
      return (
        <MuiTypography
          level={'label-md'}
          lineHeight={'14px'}
          textColor={'primary.outlinedColor'}
          fontSize={'10px'}
        >
          {language?.documentAdded}
        </MuiTypography>
      );
    }

    return (
      <MuiTypography
        level={'label-sm'}
        lineHeight={'14px'}
        textColor={'text.tertiary'}
      >
        {text}
      </MuiTypography>
    );
  };

  return (
    <Box marginTop={4}>
      {!uploadedByFarMart && !checkAllApproved && unloadingBanner()}
      <Box borderRadius={8}>
        <MuiAccordian
          title={language?.unloadingDocuments}
          onClick={() => {
            trackEvent(Events?.onTruckTrackingDocsAccordionClick, {
              'Document Category': 'Unloading Documents',
            });
            setIsClosed((c) => !c);
          }}
          accordionProps={{
            sx: {
              padding: '12px',
              backgroundColor: '#FFF',
              borderRadius: '8px',
            },
            expanded: true,
          }}
          accordionSummaryProps={{
            id: 'unloadingdoucment_text_truckdetailscreen',
            sx: {
              pointerEvents: 'none',
            },
          }}
        >
          {uploadedByFarMart && (
            <Alert
              color="warning"
              variant="outlined"
              sx={{ borderRadius: '8px', marginBottom: '16px' }}
            >
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                {/* <Box> */}
                <PersonOutlineRounded />
                {/* </Box> */}
                <Box paddingLeft={2}>
                  <MuiTypography
                    color="warning"
                    level={'body-xxs'}
                    fontWeight={500}
                    id="youdocareuploaded_text_truckdetailscreen"
                    data-testid="youDocAreUploadedTextTruckDetailScreenTestId"
                  >
                    {language?.yourDocumentsAreUplaoded}
                  </MuiTypography>
                </Box>
              </Box>
            </Alert>
          )}
          {checkAllApproved && (
            <Box
              display={'flex'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              paddingBottom={4}
            >
              <CalendarTodayRounded color={'primary'} />
              <MuiTypography
                level="label-md"
                sx={{ paddingLeft: '4px' }}
              >{`${language?.truckUnloaded} ${formatDate(unloadDispatchTime)}`}</MuiTypography>
            </Box>
          )}
          <Box>
            <Card
              sx={{ marginBottom: '12px' }}
              onClick={() => {
                trackEvent(Events?.onTruckTrackingAddBuyerGRNButtonClick, {});
                onClickUploadDocument(
                  4,
                  buyerGRNDocument?.folder_status
                    ? buyerGRNDocument?.folder_status
                    : '',
                  null,
                  buyerGRNDocument?.folder_status_id,
                );
              }}
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Box
                  display={'flex'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                >
                  {folderContainer(buyerGRNDocument)}
                  <Box paddingLeft={3}>
                    <MuiTypography level={'title-sm'} lineHeight={'19px'}>
                      {language?.buyerGRN}
                    </MuiTypography>
                    {cardSubTitle(
                      buyerGRNDocument,
                      language?.pleaseAddTheGRN,
                      sessionStorage?.getItem('buyerGRN'),
                    )}
                  </Box>
                </Box>
                <Box>
                  <ChevronRightRounded
                    color="primary"
                    sx={{ height: '24px', width: '24px' }}
                  />
                </Box>
              </Box>
            </Card>
            <Card
              onClick={() => {
                trackEvent(
                  Events?.onTruckTrackingAddUnloadingWeightSlipButtonClick,
                  {},
                );
                onClickUploadDocument(
                  5,
                  unloadingWeightSlipDocument?.folder_status
                    ? unloadingWeightSlipDocument?.folder_status
                    : '',
                  null,
                  unloadingWeightSlipDocument?.folder_status_id,
                );
              }}
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Box
                  display={'flex'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                >
                  {folderContainer(unloadingWeightSlipDocument)}
                  <Box paddingLeft={3}>
                    <MuiTypography level={'title-sm'} lineHeight={'19px'}>
                      {language?.unloadingWeightSlip}
                    </MuiTypography>
                    {cardSubTitle(
                      unloadingWeightSlipDocument,
                      'Please add the Unloading Docs',
                      sessionStorage?.getItem('unloadingWeightSlip'),
                    )}
                  </Box>
                </Box>
                <Box>
                  <ChevronRightRounded
                    color="primary"
                    sx={{ height: '24px', width: '24px' }}
                  />
                </Box>
              </Box>
            </Card>
          </Box>
        </MuiAccordian>
      </Box>
    </Box>
  );
};

export default UnloadingDocContainer;
