import React from 'react';
import Styles from './style.module.css';
import Header from './Header/Header';

import { Box } from '@mui/material';
import { PinCodeProps } from '@mono-farmart-web/farmart-web-common/hooks/PinCode/types';
import LocationCard from './LocationList/LocationCard';

export default function PinCodeView(props: PinCodeProps) {
  const {
    onSearchPinCode,
    pinCodeSearchValue,
    loading,
    totalPinCodeCounts,
    pinCodeList,
    onClickLocation,
    onPressBack,
    language,
    pincodeAssets,
    onContactUs,
  } = props;
  return (
    <Box className={Styles.container} bgcolor={'background.body'}>
      <Header
        onSearchPinCode={onSearchPinCode}
        pinCodeSearchValue={pinCodeSearchValue}
        onPressBack={onPressBack}
        language={language}
        onContactUs={onContactUs}
      />

      <LocationCard
        pinCodeList={pinCodeList}
        totalPinCodeCounts={totalPinCodeCounts}
        loading={loading}
        onClickLocation={onClickLocation}
        language={language}
        pinCodeSearchValue={pinCodeSearchValue}
        noLocationImage={pincodeAssets.noLocationImage}
      />
    </Box>
  );
}
