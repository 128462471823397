import axios from "axios";
import {
	getAuthTokenFromStorage,
} from "@mono-farmart-web/farmart-web-common/modal/auth/auth.model";
import { apiEndPoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints";

interface HookProps {
	language?: any;
}

const useAskForConsent = (props: HookProps) => {
  const authToken = getAuthTokenFromStorage();
  const path = apiEndPoints?.askForConsent;
	const askForConsent = async (payload: any) => {
		try {
			const response = await axios.post(
				`${import.meta.env.VITE_API_BASE_URL}${path}`,
				payload,
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
				},
			);

			const { status } = response?.data;

			if (!status) {
				return {
					status: false,
					message: "Unable to send consent reminder!",
				};
			}

			if (status) {
				return {
					status,
					message: props?.language?.consentPendinPage?.consentMessage,
				};
			}
		} catch (err) {
			return {
				status: false,
				message: "Unable to send consent reminder!",
			};
		}
	};

	return {
		askForConsent,
	};
};

export default useAskForConsent;
