import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    brokeragePopUpTitle: 'Brokerage',
    mobileNumberCopytext: 'Mobile number has been copied to clipboard',

    brokeragePopUpSubHeading:
      'The charges to book an order with the buyer via FarMart in ensuring you get the best prices and you are being offered reliable services every time. Brokerage: {(BrokergeUnit)}.',
    popUpOkayButton: 'Okay',
    financePopUpTitle: 'FarMart Fee',
    netPricePopUpSubHeading:
      'Net FOR Price = Gross Buyer Price - Bag Deduction - Unloading Charges - Brokerage - FarMart Fee.',

    netPricePopUpTitle: 'Net FOR Price',
    financePopUpSubHeading:
      "To help you get your payment faster before buyer's makes the payment. This will assist you in maintaining your cash flow faster. FarMart Fee: {(financeCost)}%",
    heading: 'Trade Details',
    lastUpdate: 'Updated',
    buyerDetails: 'Buyer & Delivery Details',
    paymentTerms: 'Payment Terms',
    priceDetails: 'Price Details',
    buyerTerms: 'Pay at Buyer Claim',
    grnTerms: 'Pay at Unloading',
    netPrice: 'Net FOR Price',
    qualityParaMeters: 'Quality Parameters',
    orderFulfilmentDate: 'Order Fulfilment Date',
    fulfilmentNote:
      'Note: From the day of the order booking, the booked quantity should be delivered by {(fulfilmentDate)}.',
    paymentTermsNote:
      'Note: The payment would be made by buyer after {(days)} of unloading',
    // "Note: The payment would be made by buyer after {(days)} of GRN (goods receipt note) creation.",
    grossPrice: 'Gross Buyer Price',
    cashDiscount: 'Cash Discount',
    bagDeduction: 'Bag Deduction',
    unloadingCharges: 'Unloading Charges',
    brokerage: 'Brokerage',
    financingCost: 'FarMart Fee',
    moisture: 'Moisture',
    fireginMatter: 'Foreign Matter',
    damaged: 'Damaged & Discoloured',
    buyerTermToolTip:
      'Get paid, {(paymertDays)} days post unloading. FarMart charges {*brokerage*} brokerage.',
    // "Get paid, {(paymertDays)} post GRN. FarMart will charge brokerage at {*brokerage*}.",
    // grnTermToolTip:
    //   "Get paid on GRN. FarMart will charge brokerage at {(brokerage)} and FarMart Fee at {*financingCost*} of the billed value.",
    grnTermToolTip:
      'Get paid at unloading. FarMart charges {(brokerage)} brokerage + {*financingCost*} fee on billed value.',
    DiscountedGrnTermToolTip:
      'Get paid at unloading. FarMart charges {(brokerage)} brokerage + discounted fee of {*financingCost*} on the billed value.',
    contactToSell: 'Connect to Sell',
    priceExpired: 'Price Expired',
    requestSauda: 'Request Sauda',
    quantityToSell: 'How much you want to sell?',
    enterQuantity: 'Enter Quantity',
    atBuyersTerms: 'Pay at Buyer Claim',
    atGRNTerms: 'Pay at Unloading',
    proceed: 'Proceed',
    minQuantityPreferred: 'Minimum quantity preferred is ',
    choosePaymentTerms: 'Choose your payment terms',
    suadaAlreadyRequested:
      'You have already requested for the Sauda against this buyer price',
    saudaRequested: 'Sauda Requested',
    netPricePopUpSubHeading2:
      'The rate shown above will be available after sending the truck to the buyer. If you want to hire a truck from Farmart, you will have to pay the fare for the truck.',
    priceWarning:
      'NOTE: The rate shown above will be available after sending the truck to the buyer. If you want to hire a truck from Farmart, you will have to pay the fare for the truck.',
    buyerNetPrice: 'Net Buyer Price',
    discountFee: 'Discount on FarMart Fee @ {(variable)}%',
    savingAmount: 'You’re saving {(variable)}% on FarMart Fee',
    amountSavedText: 'You have saved ₹{(variable)} on this Sauda',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    mobileNumberCopytext: 'मोबाइल नंबर को क्लिपबोर्ड पर कॉपी किया गया है।',
    brokeragePopUpTitle: 'ब्रोकरेज',
    brokeragePopUpSubHeading:
      'फारमार्ट के माध्यम से खरीददार के साथ एक सौदा बुक करने के लिए शुल्क, जिससे फारमार्ट यह सुनिश्चित करता है कि आप हर बार सर्वोत्तम मूल्य प्राप्त कर सकें और आपको हमेशा विश्वसनीय सेवाएं प्रदान की जा सके। ब्रोकरेज: {(BrokerageUnit)}',
    popUpOkayButton: 'ठीक है',

    financePopUpTitle: 'फारमार्ट फी',
    financePopUpSubHeading:
      'बायर के भुगतान करने से पहले तेजी से भुगतान प्राप्त करने में आपकी सहायता करेगा, यह आपके कैश फ्लो को तेज बनाए रखने में मदद करेगा। फारमार्ट फी: {(financeCost)}%',

    netPricePopUpTitle: 'नेट FOR मूल्य',
    netPricePopUpSubHeading:
      'नेट FOR मूल्य = ग्रॉस बायर मूल्य - बैग कटौती - अनलोडिंग शुल्क - ब्रोकरेज - फारमार्ट फी।',
    heading: 'व्यापार विवरण',
    lastUpdate: 'अपडेट',
    buyerDetails: 'खरीददार और डिलीवरी विवरण',
    paymentTerms: 'भुगतान शर्तें',
    priceDetails: 'मूल्य विवरण',
    buyerTerms: 'बायर क्लेम पर भुगतान',
    grnTerms: 'अनलोडिंग पर भुगतान',
    netPrice: 'नेट FOR मूल्य',
    qualityParaMeters: 'गुणवत्ता पैरामीटर',
    orderFulfilmentDate: 'आर्डर पूर्ति की तारीख',
    fulfilmentNote:
      'नोट: आर्डर बुकिंग के दिन से बुक की गई मात्रा को {(fulfilmentDate)} तक पहुँचाया जाना चाहिए।',
    paymentTermsNote:
      'नोट : बायर द्वारा गाड़ी अनलोडिंग के {(days)} बाद भुगतान किया जायेगा',
    // "नोट: भुगतान खरीददार द्वारा GRN (गुड्स रिसीप्ट नोट) बनाने के {(days)} बाद होगा।",
    grossPrice: 'ग्रॉस बायर मूल्य',
    cashDiscount: 'कैश डिस्काउंट',
    bagDeduction: 'बैग कटौती',
    unloadingCharges: 'अनलोडिंग शुल्क',
    brokerage: 'ब्रोकरेज',
    financingCost: 'फारमार्ट फी',
    moisture: 'आर्द्रता / नमी',
    fireginMatter: 'विजातीय पदार्थ',
    damaged: 'डैमेज और डिस्कलर',
    buyerTermToolTip:
      'अनलोडिंग के {(paymertDays)} दिन बाद भुगतान प्राप्त करें, फारमार्ट {*brokerage*} के दर से ब्रोकरेज शुल्क लेगा।',
    // "भुगतान खरीददार द्वारा GRN (गुड्स रिसीप्ट नोट) बनाने के {(paymertDays)} बाद होगा। फारमार्ट ब्रोकरेज शुल्क {*brokerage*} के दर से लागत लेगा",
    // grnTermToolTip:
    //   "GRN (गुड्स रिसीप्ट नोट) पर भुगतान के लिए फारमार्ट ब्रोकरेज शुल्क {(brokerage)} और बिल मूल्य का {*financingCost*} फारमार्ट फी लेगा।",
    grnTermToolTip:
      'अनलोडिंग पर भुगतान पायें।  फारमार्ट {(brokerage)} ब्रोकरेज और बिल की गई राशि के {*financingCost*} की दर से फारमार्ट फी लेगा।',
    DiscountedGrnTermToolTip:
      'अनलोडिंग पर भुगतान पायें।  फारमार्ट {(brokerage)} ब्रोकरेज और बिल की गई राशि के {*financingCost*} की दर से डिस्काउंटेड फारमार्ट फी लेगा।',
    contactToSell: 'संपर्क करें',
    priceExpired: 'रेट समाप्त हो चुका है',
    requestSauda: 'सौदा करें',
    quantityToSell: 'आप कितना बेचना चाहते हैं ?',
    enterQuantity: 'मात्रा दर्ज करें',
    atBuyersTerms: 'बायर क्लेम पर भुगतान',
    atGRNTerms: 'अनलोडिंग पर भुगतान',
    proceed: 'आगे बढ़ें',
    minQuantityPreferred: 'न्यूनतम मात्रा आवश्यक है: ',
    choosePaymentTerms: 'भुगतान का तरीका चुनें',
    suadaAlreadyRequested:
      'आपने पहले ही इस खरीददार कीमत  के लिए सौदा अनुरोध किया है।',
    saudaRequested: 'सौदा रिक्वेस्टेड',
    netPricePopUpSubHeading2:
      'ऊपर दिख रहा रेट बायर के यहाँ गाड़ी भेजने पर मिलेगा। अगर आप फारमार्ट से गाड़ी लगाना चाहते हैं तो आपको गाड़ी का किराया देना होगा।',
    priceWarning:
      'ध्यान दें: ऊपर दिख रहा रेट बायर के यहाँ गाड़ी भेजने पर मिलेगा। अगर आप फारमार्ट से गाड़ी लगाना चाहते हैं तो आपको गाड़ी का किराया देना होगा।',
    buyerNetPrice: 'नेट बायर मूल्य',
    discountFee: 'फारमार्ट फी पर @ {(variable)}% की छूट',
    savingAmount: 'आप फारमार्ट फी पर {(variable)}% की बचत कर रहे हैं',
    amountSavedText: 'इस सौदे पर आपको ₹{(variable)} की बचत हुई',
  },
};

export default (language) => languages[language];
