import React from 'react';
import Styles from './style.module.css';
import Header from './Header/Header';
import Actionbutton from './BottomContainer/Actionbutton';
import LanguageSelection from './LanguageSelection/LanguageSelection';
import { OTPInputProps } from '@mono-farmart-web/farmart-web-common/hooks/OtpHook/types';
import InputContainer from './InputContainer/InputContainer';
import { Box } from '@mui/material';

export default function Login(props: OTPInputProps) {
  const {
    handleLanguageSelect,
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    userOtp,
    getUserOtpFromInput,
    setDisableResend,
    disableResend,
    resendOtp,
    userMobileNumber,
    onClickValidateOtpProceed,
    error,
    onPressBack,
    language,
    onContactUs,
    loading,
    otpScreenAssets,
    languageData,
  } = props;
  return (
    <Box className={Styles.container} bgcolor={'background.surface'}>
      <Header
        onLanguageModalToggle={onLanguageModalToggle}
        onPressBack={onPressBack}
        language={language}
        languageSelectionIcon={otpScreenAssets.languageSelection}
        backIcon={otpScreenAssets.backIcon}
      />
      <InputContainer
        userOtp={userOtp}
        getUserOtpFromInput={getUserOtpFromInput}
        setDisableResend={setDisableResend}
        disableResend={disableResend}
        resendOtp={resendOtp}
        userMobileNumber={userMobileNumber}
        error={error}
        onPressBack={onPressBack}
        language={language}
        onContactUs={onContactUs}
        whatsappIcon={otpScreenAssets.whatsappIcon}
      />
      <Actionbutton
        onClickValidateOtpProceed={onClickValidateOtpProceed}
        language={language}
        loading={loading}
      />
      <LanguageSelection
        handleLanguageChange={handleLanguageChange}
        selectedLanguage={selectedLanguage}
        isLanguageModalOpen={isLanguageModalOpen}
        isLanguageSelectedInProgress={isLanguageSelectedInProgress}
        handleLanguageSelect={handleLanguageSelect}
        onLanguageModalToggle={onLanguageModalToggle}
        language={language}
        languageData={languageData}
      />
    </Box>
  );
}
