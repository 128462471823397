import React from "react";
import { Box, Row } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";

import LocalShippingIcon from "@mui/icons-material/LocalShipping";

interface DriverNumberProps {
	data?: any; // TYPE TO BE DEFINED LATER ON;
}

const DriverNumber = (props: DriverNumberProps) => {
	const {
		data: { vehicleNumber },
	} = props;

	return (
		<Box>
			<Row>
				{/* DRIVER NUMBER CONTAINER */}
				<Box
					bgcolor="neutral.softBg"
					sx={{
						px: "12px",
						borderRadius: "4px",
					}}
				>
					<Row gap={1} alignItems={"center"}>
						<LocalShippingIcon
							sx={{ color: "neutral.softColor", width: "28px", height: "28px" }}
						/>
						<Text
							id="trucknumber_trip_cancelledscreen"
							level="title-md"
							textColor="neutral.softColor"
						>
							{vehicleNumber}
						</Text>
					</Row>
				</Box>
			</Row>
		</Box>
	);
};

export default DriverNumber;
