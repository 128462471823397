import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePlatfromData } from "../CrossPlatformActionHandler/platformChecker";
import {
	type SMSLandingScreenProps,
	type MerchantData,
	LanguageContentType,
} from "./types";
import useAuthenticationChecker from "../../utils/authChecker";
import { appLanguages } from "../../constant/appLanguages";
import { helpLineNumber } from "../../constant/HelpLineNumber";
import { smsContactSupportUrl } from "../../constant/smsFeatureUrl";
import getLang from "../../languages/smsLanding";
import { apiEndPoints } from "../../apis/endpoints";
import Events from "../../constant/MoengageEvents/index";
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import bridgeFunctions from "../../utils/bridge";
import { userType } from "../../constant/enums/userType";
import { trackEvent } from "../../utils/moengage";
import { languageUrls } from "../../constant/languageUrls/index";
import useAppLanguage from "../../utils/applanguage";

export default function SMSLandingScreenHook(): SMSLandingScreenProps {
	const bridge = createBridge(); // Initialize the bridge for communication with the native app.
	const navigate = useNavigate();
	const platformData = usePlatfromData(); // Get platform data.
	// Get the active system language from the user's browser settings
	const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
	// Determine the system language code and backend language code based on whether the language is English
	const systemLanguage = activeSystemLang?.includes("en")
		? appLanguages[1].langCode
		: appLanguages[0].langCode;

	// Retrieve user's selected language from local storage, defaulting to system language if not found
	const langId = JSON.parse(localStorage.getItem("userLanguage"));
	// Get the language translation object based on the user's selected language or default system language

	const systemLanguageId = activeSystemLang?.includes("en")
		? appLanguages[1].backendLanguageCode
		: appLanguages[0].backendLanguageCode;
	const selectedLanguage = langId?.langCode ? langId?.langCode : systemLanguage;
	const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();
	const [showShopPromotionModal, setShowShopPromotionModal] = useState(false);
	const [showMediaModal, setShowMediaModal] = useState(false);
	const [totalSMSCredit, setTotalSMSCredit] = useState(null);
	const [noOfDays, setNoOfDays] = useState(null);
	const [noOfSMSSent, setNoOfSMSSent] = useState(null);
	const [monthlyFreeSMS, setMonthlyFreeSMS] = useState(null);
	const [smsTemplateTypes, setSMSTemplateTypes] = useState([]);
	const [promotionalMedia, setPromotionalMedia] = useState([]);
	const [loading, setLoading] = useState(false); // Loader state
	const [isRetailer, setIsRetailer] = useState(false);
	const [selectedSmsData, setSelectedSmsData] = useState();
	const [progressiveModalVisible, setProgressiveModalVisible] = useState(false);
	const [merchantDetail, setMerchantDetail] = useState({
		merchantName: "",
		merchantShopName: "",
	});

	const [progressiveSignupLoading, setProgressiveSignupLoading] =
		useState(false);

	const [merchantData, setMerchantData] = useState<MerchantData | null>(null);

	const { language } = useAppLanguage(
		languageUrls?.smsLandingScreenLangageUrl,
		getLang,
	);

	useEffect(() => {
		// Run both API calls simultaneously
		setLoading(true);
		Promise.all([getSMSDetails(), getSMSTypes(), fetchMyProfile()]).finally(
			() => {
				setLoading(false);
			},
		);
	}, []);

	async function getSMSDetails() {
		const smsDetails = await getSMSStats();
		setTotalSMSCredit(smsDetails?.data?.total_sms_credit_point);
		setNoOfDays(smsDetails?.data?.no_of_days);
		setNoOfSMSSent(smsDetails?.data?.no_of_sms_sent);
		setMonthlyFreeSMS(smsDetails?.data?.total_no_of_monthly_free_sms);
		setPromotionalMedia(smsDetails?.data?.promotional_media);
	}

	async function getSMSStats() {
		try {
			const response = await AuthenticationCheckerAndApiCaller({
				uri: apiEndPoints?.getSMSBalance,
				payload: {},
				requestType: "get",
			});
			if (response?.data?.status) {
				return response?.data;
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function getSMSTypes() {
		try {
			const response = await AuthenticationCheckerAndApiCaller({
				uri: apiEndPoints?.getSMSCategories,
				payload: { language: systemLanguageId },
				requestType: "get",
			});
			if (response?.data?.status) {
				const combinedSMSTypes = [];
				response?.data?.data?.sms_categories.forEach((category) => {
					if (category?.sms_types?.length > 0) {
						combinedSMSTypes.push(...category?.sms_types);
					}
				});
				setSMSTemplateTypes(combinedSMSTypes);
			}
		} catch (err) {
			console.log(err);
		}
	}

	function onClickViewSMSHistory() {
		navigate("/smsHistory");
		trackEvent(Events?.onSmsBalanceButtonClick, {
			SMS_Remaining_Balance: totalSMSCredit,
		});
	}

	function onClickBackSMSLanding() {
		navigate("/");
	}

	async function fetchMyProfile() {
		try {
			const response = await AuthenticationCheckerAndApiCaller({
				uri: apiEndPoints?.getMerchantProfile,
				requestType: "post",
				payload: {},
			});
			if (response?.data?.status) {
				setIsRetailer(
					response?.data?.data?.merchant_occupation_id === userType["retailer"],
				);
				setMerchantData(response?.data?.data);
			}
		} catch (error) {
			console.error(error);
		}
	}

	function onClickInfoSMSLanding() {
		setShowShopPromotionModal(true);
		trackEvent(Events?.onHomeScreenSmsFeatureIButtonClick, {
			from: "Shop_Promtion_Screen",
		});
	}

	function onClickHelpSMSLanding() {
		navigate("/faq/questions");
	}

	function onCloseModal() {
		setShowShopPromotionModal(false);
		trackEvent(Events?.onHomeScreenSmsFeatureIButtonModalClosedButtonClick, {
			from: "Shop_Promtion_Screen",
		});
	}

	function onCloseProgressiveModal() {
		setProgressiveModalVisible(false);
	}

	function onChangeInput(value: string, type: string) {
		setMerchantDetail({ ...merchantDetail, [type]: value });
	}

	function removeEmojis(string) {
		const regex =
			/([#0-9]\u20E3)|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?/g;
		return string.replace(regex, "");
	}

	async function onCompleteUserInfo() {
		setProgressiveSignupLoading(true);
		const updateMercahntprofileParam = {
			business_name: removeEmojis(merchantDetail.merchantShopName),
			merchant_name: removeEmojis(merchantDetail.merchantName),
			occupation_id: merchantData.merchant_occupation_id,
		};
		try {
			const response = await AuthenticationCheckerAndApiCaller({
				uri: apiEndPoints?.updateMerchantName,
				requestType: "put",
				payload: updateMercahntprofileParam,
			});
			if (response?.data?.status) {
				setProgressiveSignupLoading(false);
				setProgressiveModalVisible(false);
				onClickSMSTemplateCard(selectedSmsData, true);
			}
		} catch (error) {
			setProgressiveSignupLoading(false);
		}
	}

	function onClickSMSTemplateCard(templateDetails, checkName) {
		if (!checkName) {
			if (
				!merchantData?.merchant_name &&
				merchantData.merchant_occupation_id === 5
			) {
				setSelectedSmsData(templateDetails);
				return setProgressiveModalVisible(true);
			} else if (!merchantData?.business_name || !merchantData?.merchant_name) {
				setSelectedSmsData(templateDetails);
				return setProgressiveModalVisible(true);
			}
		}

		trackEvent(Events?.onSmsTemplateSelectionClick, {
			SMS_Template_Screen: templateDetails?.form_page_heading,
		});
		navigate(`/smsTemplate/${templateDetails?.sms_type_id}`, {
			state: { templateDetails },
		}); // Pass templateDetails as state
	}

	function onClickPromtionalMediaCard() {
		setShowMediaModal(true);
		trackEvent(Events?.onSmsDemoVideoClick, { action: "Play" });
	}

	function onCloseMediaModal() {
		setShowMediaModal(false);
		trackEvent(Events?.onSmsDemoVideOkayButtonClick, {});
	}

	function onClickContactSupport() {
		trackEvent(Events?.onSmsChatWithUsButtonClick, {});
		let url = "";
		if (selectedLanguage === appLanguages[0]?.langCode) {
			url = smsContactSupportUrl.hindi;
		} else {
			url = smsContactSupportUrl.english;
		}
		if (platformData.platform === "WEB") {
			const win = window?.open(url, "_blank"); // Open WhatsApp link in a new tab if on web.
			win?.focus();
		} else {
			const text = `${language?.whatsAppMessageText}&phone=${helpLineNumber}`;
			const link = `whatsapp://send?text=${text}`;

			// Send request to open WhatsApp link on the native app.
			bridge.sendRequestToNative(
				"OpenUrl",
				{
					link: link,
					show_toast: true,
					toast_message: language?.noWhatsAppAccountFound,
				},
				(response) => {
					bridgeFunctions.PrintLog(response);
				},
			);
		}
	}

	return {
		language,
		loading,
		totalSMSCredit,
		noOfDays,
		noOfSMSSent,
		monthlyFreeSMS,
		promotionalMedia,
		onClickViewSMSHistory,
		smsTemplateTypes,
		showShopPromotionModal,
		onCloseModal,
		onClickBackSMSLanding,
		onClickInfoSMSLanding,
		onClickHelpSMSLanding,
		onClickSMSTemplateCard,
		onClickPromtionalMediaCard,
		showMediaModal,
		onCloseMediaModal,
		onClickContactSupport,
		onChangeInput,
		onCompleteUserInfo,
		onCloseProgressiveModal,
		progressiveModalVisible,
		merchantData,
		merchantDetail,
		isRetailer,
	};
}
