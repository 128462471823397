import getOtpApi from "../../../apis/Trader/login";

import type { GetOtp } from "../types";

export default async function getPages(payload = {}): Promise<GetOtp> {
	const body = {
		...payload,
	};
	try {
		const result = await getOtpApi(body);

		if (!result.data.status) {
			throw result;
		}

		return result.data.data;
	} catch (error) {
		if (!error.message) {
			error.message = "Something Went Wrong";
		}

		throw error;
	}
}
