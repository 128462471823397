import React from 'react';
import Styles from './style.module.css';
import { Box } from '@mui/joy';

interface LogoInterface {
  farmartLogo: string;
}

export default function logoContainer({farmartLogo}:LogoInterface) {
  return (
    <Box className={Styles.loactionLabelContainer}>
      <img
        className={Styles.whatsAppIcon}
        src={farmartLogo}
        alt="Farmart Logo"
        loading="eager"
        height={"100%"}
        width={"100%"}
      />
    </Box>
  );
}
