import React from 'react';
import Styles from './style.module.css';
import { Box, Typography } from '@mui/joy';
import { ArrowBackRounded } from '@mui/icons-material';
import isAndroid from '@mono-farmart-web/farmart-web-common/utils/operatingSystemType';

interface HeaderProps {
  onLanguageModalToggle: () => void;
  language: { [key: string]: any };
  farmartLogo: string;
  languageSelectionIcon: string;
  onClickBack:()=>void;
}
const Header = (props: HeaderProps) => {
  const {
    onLanguageModalToggle,
    language,
    farmartLogo,
    languageSelectionIcon,
    onClickBack
  } = props;
  return (
    <Box className={Styles.mainContainer} bgcolor={'background.surface'}>
      <div className={Styles.locationContainer}>
        {!isAndroid()?<ArrowBackRounded sx={{height:"24px", width:"24px"}} onClick={onClickBack} />:null}
        {/* <div className={Styles.loactionLabelContainer}>
          <img
            className={Styles.whatsAppIcon}
            src={farmartLogo}
            alt="Farmart Logo"
            height={18}
            width={80}
            loading="eager"
          />
        </div> */}
      </div>

      <div
        id="loginScreenChangeLangTestId"
        className={Styles.helpButtonContainer}
        onClick={onLanguageModalToggle}
      >
        <img
          className={Styles.languageIconStyle}
          src={languageSelectionIcon}
          alt="Language Icon"
          height={20}
          width={20}
          loading="eager"
        />
        <Typography
          className={Styles.helpTextStyle}
          level="body-xs"
          color="primary"
        >
          {language?.changeLanguage}
        </Typography>
      </div>
    </Box>
  );
};

export default Header;
