import React from 'react';
import BuyerSearchView from '../../Pages/BuyerSearch';
import BuyerSearchHook from '../../hooks/buyerSearchHook';

export default function RakeList() {
  const {
    onPressBack,
    language,
    onContactUs,
    buyerSearchScreenAssets,
    buyerList,
    searchedValue,
    onSearchBuyer,
    loading,
    onClickRemoveSearch,
    onClickBuyerName,
  } = BuyerSearchHook();
  return (
    <BuyerSearchView
      onPressBack={onPressBack}
      language={language}
      onContactUs={onContactUs}
      buyerSearchScreenAssets={buyerSearchScreenAssets}
      buyerList={buyerList}
      searchedValue={searchedValue}
      onSearchBuyer={onSearchBuyer}
      loading={loading}
      onClickRemoveSearch={onClickRemoveSearch}
      onClickBuyerName={onClickBuyerName}
    />
  );
}
