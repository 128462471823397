import React from 'react';
import { Box, Grid } from '@mui/joy';
import MuiSkeleton from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';

export const bannerSkeleton = () => {
  return (
    <Box
      display="flex" // Use flexbox for alignment
      justifyContent="center" // Center horizontally
      alignItems="center" // Center vertically
      margin={4}
    >
      <MuiSkeleton
        variant="rectangular"
        sx={{
          height: '40px',
          width: '100%', // This will take full width of the Box
          borderRadius: '8px',
        }}
      />
    </Box>
  );
};

export const templateGridSkeleton = () => {
  return (
    <Box paddingX={4} paddingTop={1}>
      <Grid container spacing={2}>
        {Array.from({ length: 11 }).map((_, index) => (
          <Grid xs={4} display={'flex'} justifyContent={'center'}>
            <MuiSkeleton
              sx={{
                display: 'flex',
                height: '92px',
                maxWidth: '312px', // Full width of the Grid item
                borderRadius: '8px',
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
