import React from 'react';
import FeedDetailsViewScreen from '../../Pages/postDetails/postDetails';
import usePostAction from '@mono-farmart-web/farmart-web-common/hooks/community/usePostActions';
import UsePostDetail from '@mono-farmart-web/farmart-web-common/hooks/community/usePostDetails';
import useComment from '@mono-farmart-web/farmart-web-common/hooks/community/useComment';
import useRelevantPost from '@mono-farmart-web/farmart-web-common/hooks/community/useFetchRelevantPosts';

export default function PostDetails() {
  const {
    postDetailsData,
    PostDetailLoading,
    setCurrentPostId,
    currentPostId,
    setPostDetailsData,
    onClickBack,
    onClickDisclaimer,
  } = UsePostDetail();
  const {
    baseCommentList,
    baseCommentLoading,
    loadMoreBaseComment,
    setBaseCommentList,
    onClickViewNestedReply,
    baseCommentCount,
    CreateCommentOnPost,
    commentInputVisible,
    onClickReply,
    setCommentInputVisible,
    setRefCommentId,
    setTaggedMerchantId,
    setBaseCommentPageNo,
    taggedName,
    removeTaggedName,
    focus,
    nameModal,
    onChangeName,
    merchantName,
    onCompleteUserInfo,
    onCloseUpdateUserInoModal,
    userNameUploadLoading,
  } = useComment(currentPostId, setPostDetailsData);
  const {
    relevantPostList,
    loadMoreRelevantPostList,
    relevantPostLoading,
    setRelevantPostList,
  } = useRelevantPost(currentPostId);
  const { onClap, onComment, onShare, onViewPost, language, communityAssets } =
    usePostAction(
      setCommentInputVisible,
      commentInputVisible,
      setRefCommentId,
      setTaggedMerchantId,
      setCurrentPostId,
      setBaseCommentPageNo,
      setBaseCommentList,
      setPostDetailsData,
      setRelevantPostList,
      relevantPostList,
    );
  return (
    <FeedDetailsViewScreen
      onClickBack={onClickBack}
      onClap={onClap}
      onComment={onComment}
      onShare={onShare}
      baseCommentList={baseCommentList}
      CreateCommentOnPost={CreateCommentOnPost}
      postDetailsData={postDetailsData}
      PostDetailLoading={PostDetailLoading}
      onClickViewNestedReply={onClickViewNestedReply}
      baseCommentLoading={baseCommentLoading}
      loadMoreBaseComment={loadMoreBaseComment}
      commentInputVisible={commentInputVisible}
      onClickReply={onClickReply}
      baseCommentCount={baseCommentCount}
      onViewPost={onViewPost}
      setCommentInputVisible={setCommentInputVisible}
      taggedName={taggedName}
      removeTaggedName={removeTaggedName}
      focus={focus}
      relevantPostList={relevantPostList}
      loadMoreRelevantPostList={loadMoreRelevantPostList}
      relevantPostLoading={relevantPostLoading}
      nameModal={nameModal}
      onChangeName={onChangeName}
      merchantName={merchantName}
      onCompleteUserInfo={onCompleteUserInfo}
      onCloseUpdateUserInoModal={onCloseUpdateUserInoModal}
      userNameUploadLoading={userNameUploadLoading}
      onClickDisclaimer={onClickDisclaimer}
      language={language}
      communityAssets={communityAssets}
    />
  );
}
