import React, { Suspense, lazy, useState, useEffect } from 'react';
import Styles from './style.module.css';
import { Box, Tooltip } from '@mui/joy';
import Header from '@mono-farmart-web/farmart-web-common/ComponentV2/Header';
import Input from '@mono-farmart-web/farmart-web-common/ComponentV3/inputWithIconCumTitle';
import type { CreateCumDetailsDispatchProps } from '../../viewmodal/createDispatch/types';
import LocalShippingOutlined from '@mui/icons-material/LocalShippingOutlined';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import Toast from '@mono-farmart-web/farmart-web-common/ComponentV2/Toast';
import ErrorMessageToast from '@mono-farmart-web/farmart-web-common/ComponentV2/ErrorMessageToast';
import Typography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import DocumentStatus from '@mono-farmart-web/farmart-web-common/constant/docStatusEnums';
import saudaTypeEnum from '@mono-farmart-web/farmart-web-common/constant/enums/saudaTypeEnum';
import MuiSkeleton from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';
import { LoginView } from '@mono-farmart-web/farmart-web-common/context/login';
import MuiAccordion from '@mono-farmart-web/farmart-web-common/MuiComponents/MuiAccordian';
import CommonErrorState from '@mono-farmart-web/farmart-web-common/ComponentsV4/CommonErrorState';
import TruckTrackingCard from '@mono-farmart-web/farmart-web-common/ComponentsV4/TruckTrackingCard';
import UnloadingDocContainer from './UnloadingDocContainer';
import tripTrackingStatus from '@mono-farmart-web/farmart-web-common/constant/enums/tripTrackingStatus';
import { data } from 'branch-sdk';
import { getUserProfileFromStorage } from '@mono-farmart-web/farmart-web-common/modal/auth/auth.model';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index';
import { ShoppingBagOutlined } from '@mui/icons-material';
// import ActionContainer from "./ActionContainer";

// Lazy load components
const DocContainer = lazy(() => import('./DocContainer'));
const ActionContainer = lazy(() => import('./ActionContainer'));
const CancelDispatchModal = lazy(() => import('./CancelDispatchModal'));
export default function CreateDispatchView(
	props: CreateCumDetailsDispatchProps,
) {

	const userProfile = getUserProfileFromStorage() || null;
	const userlogedIn =
		JSON.parse(localStorage.getItem('loged')) ||
		userProfile?.merchant_detail_id ||
		userProfile?.merchant_occupation_id;
	const {
		language,
		onClickBackButton,
		dispatchDetails,
		dispatchWeight,
		truckNumber,
		noOfBags,
		onChangeDispatchWeight,
		onChangeTruckNumber,
		onChangeNoOfBags,
		dispatchDetailLoading,
		onClickInput,
		onClickUploadDocument,
		onUploadUnloadingDocs,
		onClickSubmitButton,
		error,
		invoiceDocument,
		weightSlipDocument,
		mandiDoument,
		showCancelDispatchModal,
		toggleCancelDispatchModal,
		onClickCancelDispatch,
		createDispatchToastPopup,
		submitLoading,
		setIsError,
		isError,
		onChangeTruckDriverNumber,
		truckDriverNumber,
		saudaDetailData,
		onClickMandiToolTip,
		mandiToolTipVisible,
		truckErrorMessage,
		tripDetails,
		truckId,
		onClickTripTrackingCard,
		showTourGuide,
		uploadedByFarMart,
		buyerGRNDocument,
		unloadingWeightSlipDocument,
		dispatchId,
		getDispatchDetails,
		onPressHelp,
		createDispatchError,
	} = props;

	const [isClosed, setIsClosed] = useState<boolean>(!!dispatchDetails);
  const [sdd, setSdd] = useState<any>(null);

	const checkUpdatedDispatchDocs = () => {
		// CHECKING INSIDE SESSION STORAGE FOR:
		// 1. weight_slip
		// 2. invoice_docs
		const weightSlipDoc = JSON.parse(
			sessionStorage?.getItem('weight_slip') || '{}',
		);
		const updatedWeightSlipDoc = weightSlipDoc?.['updatedDocs'];

		const invoiceDoc = JSON.parse(
			sessionStorage?.getItem('invoice_docs') || '{}',
		);
		const updatedInvoiceDoc = invoiceDoc?.['updatedDocs'];

    const mandiTax = JSON.parse(
      sessionStorage?.getItem('mandi_tax') || '{}',
    );
    const mandiTaxDoc = mandiTax?.["updatedDocs"];

		if (updatedInvoiceDoc?.length || updatedWeightSlipDoc?.length || mandiTaxDoc?.length) {
			return true;
		}

		return false;
	};

	function disablesubmission(sdd: any) {

		const isUpdatedDispatchDocPresent = checkUpdatedDispatchDocs();

		if (dispatchDetails === null && isUpdatedDispatchDocPresent) {
		  return true;
		}

		if (!!dispatchDetails && dispatchDetails?.is_po_so_mapped) {
		  const weightSlipDoc = sessionStorage?.getItem('buyerGRN');
		  const invoiceDoc = sessionStorage?.getItem('unloadingWeightSlip');

		  if(!buyerGRNDocument?.folder_status_id && !unloadingWeightSlipDocument?.folder_status_id) {
			if (!weightSlipDoc || !invoiceDoc) return true;
		  }

		  if (!weightSlipDoc && !invoiceDoc) return true;

		  return false;
		}

		if (dispatchDetails) {
		  const weightSlipDoc = sessionStorage?.getItem('weight_slip');
		  const invoiceDoc = sessionStorage?.getItem('invoice_docs');
      const mandiTax = sessionStorage?.getItem('mandi_tax');

      if(weightSlipDoc || invoiceDoc || mandiTax) {
        return false;
      }

		  if (!weightSlipDoc && !invoiceDoc) return true;

		  return false;
		}

		if (
		  buyerGRNDocument?.folder_status_id < 3 &&
		  unloadingWeightSlipDocument?.folder_status_id < 3
		) {
		  return true;
		}

		if (
		  (!!buyerGRNDocument?.docs && !!unloadingWeightSlipDocument?.docs) ||
		  dispatchDetails === null
		) {
		  return false;
		}

		return false;
	  }
	function formatUpdatedAt(isoTimestamp) {
		const date = new Date(isoTimestamp);

		const now = new Date();
		const isToday =
			date.getDate() === now.getDate() &&
			date.getMonth() === now.getMonth() &&
			date.getFullYear() === now.getFullYear();

		const options: Intl.DateTimeFormatOptions = {
			hour: '2-digit',
			minute: '2-digit',
			hour12: true,
		};

		const formattedTime = date.toLocaleTimeString("en-IN", options).replace(/am|pm/gi, match => match.toUpperCase());

		if (isToday) {
			return `${language?.updatedToday} ${formattedTime}`;
		}

		const yesterday = new Date(now);
		yesterday.setDate(now.getDate() - 1);
		const isYesterday =
			date.getDate() === yesterday.getDate() &&
			date.getMonth() === yesterday.getMonth() &&
			date.getFullYear() === yesterday.getFullYear();

		if (isYesterday) {
			return `${language?.updatedAt} ${formattedTime}`;
		}

		const formattedDate = date.toLocaleDateString("en-IN", {
			month: 'short',
			day: 'numeric',
		}).replace(/am|pm/gi, match => match.toUpperCase());

		return `${language?.updatedAt} ${formattedDate} ${formattedTime}`;
	}

    function getDispatchDocApprovalState({ weightSlipDocument, mandiDoument, invoiceDocument }) {
      if(weightSlipDocument?.folder_status_id === 2 && invoiceDocument?.folder_status_id === 2) {
        if(!!mandiDoument?.folder_status_id && mandiDoument?.folder_status_id === 2) {
          return true;
        }

        if(mandiDoument?.docs === null) {
          return true;
        }
      }

      return false;
    }

    function getDispatchDocState({ buyerGRNDocument, unloadingWeightSlipDocument }) {
      if(!!buyerGRNDocument?.docs && !!unloadingWeightSlipDocument?.docs) {
        return true;
      }

      return false;
    }

	function tripTrackingDetails(tripDetails) {
		const subTitleText = tripDetails?.updated_at
			? formatUpdatedAt(tripDetails?.updated_at)
			: '';
		let primaryColor = 'warning';
		let secondaryColor = '#FDF0E1';
		let title = tripDetails?.trip_status_message || '';
		let subtitle = subTitleText;
		let titleTestId = '';
		let titleDataTestId = '';
		let subTitleTestId = '';
		let subTitleDataTestId = '';
		let titleAndSubtitleTextColor = '';
    let cardBackgroundColor = '';

    // IF TRIP IN ON_TRACK BUT NO UPDATED_AT PRESENT;
    if(tripTrackingStatus.get('ON_TRACK') === tripDetails?.trip_status_id  && !tripDetails?.updated_at) {
      primaryColor = 'warning';
      secondaryColor = '#FDF0E1';
      title = "Driver Consent Received!";
      subtitle = subTitleText;
      titleTestId = 'driverconsentpending_text_truckdetaiscreen';
      titleDataTestId = 'driverconsentpending_text_truckdetaiscreen';
      subTitleTestId = '';
      subTitleDataTestId = '';
      titleAndSubtitleTextColor="#FFFFFF";
      cardBackgroundColor = '';

      return {
        primaryColor,
        secondaryColor,
        title,
        subtitle,
        titleTestId,
        titleDataTestId,
        subTitleTestId,
        subTitleDataTestId,
        titleAndSubtitleTextColor,
        cardBackgroundColor
      };
    }

		switch (tripDetails?.trip_status_id) {
			case tripTrackingStatus.get('CONSENT_PENDING'):
				primaryColor = 'warning';
				secondaryColor = '#FDF0E1';
				title = language?.driverConsentPending;
				subtitle = subTitleText;
				titleTestId = 'driverconsentpending_text_truckdetaiscreen';
				titleDataTestId = 'driverconsentpending_text_truckdetaiscreen';
				subTitleTestId = '';
				subTitleDataTestId = '';
				titleAndSubtitleTextColor="#FFFFFF";
				cardBackgroundColor=""
				break;
			case tripTrackingStatus.get('ON_TRACK'):
				primaryColor = 'success';
				secondaryColor = '#E8F5E9';
				title = language?.onTrack;
				subtitle = subTitleText;
				titleTestId = 'ontrack_text_truckdetailscreen';
				titleDataTestId = 'ontrack_text_truckdetailscreen';
				subTitleTestId = 'updatetoday_text_truckdetailscreen';
				subTitleDataTestId = 'updatetoday_text_truckdetailscreen';
				titleAndSubtitleTextColor="#FFFFFF";
				cardBackgroundColor=""
				break;
			case tripTrackingStatus.get('CONSENT_REMOVED'):
				primaryColor = 'warning';
				secondaryColor = '#FDF0E1';
				title = language?.driverConsentRemoved;
				subtitle = subTitleText;
				titleTestId = 'driverconsent_text_truckdetailscreen';
				titleDataTestId = 'driverconsent_text_truckdetailscreen';
				subTitleTestId = '';
				subTitleDataTestId = '';
				titleAndSubtitleTextColor="#FFFFFF";
				cardBackgroundColor=""
				break;
			case tripTrackingStatus.get('HALTED'):
				primaryColor = 'danger';
				secondaryColor = '#FCE4E4';
				title = language?.halted;
				subtitle = subTitleText;
				titleTestId = 'halted_text_truckdetailscrene';
				titleDataTestId = 'halted_text_truckdetailscrene';
				subTitleTestId = '';
				subTitleDataTestId = '';
				titleAndSubtitleTextColor="#FFFFFF";
				cardBackgroundColor=""
				break;
			case tripTrackingStatus.get('POTENTIAL_DELAY'):
				primaryColor = 'warning';
				secondaryColor = '#FDF0E1';
				title = language?.potentialDelay;
				subtitle = subTitleText;
				titleTestId = 'potentialdelay_text_truckdetailscreen';
				titleDataTestId = 'potentialdelay_text_truckdetailscreen';
				subTitleTestId = '';
				subTitleDataTestId = '';
				titleAndSubtitleTextColor="#FFFFFF";
				cardBackgroundColor=""
				break;
			case tripTrackingStatus.get('DRIVER_NOT_REACHABLE'):
				primaryColor = 'danger';
				secondaryColor = '#FCE4E4';
				title = language?.driverNotReachable;
				subtitle = subTitleText;
				titleTestId = 'drivernotreachable_text_truckdetailscreen';
				titleDataTestId = 'drivernotreachable_text_truckdetailscreen';
				subTitleTestId = '';
				subTitleDataTestId = '';
				titleAndSubtitleTextColor="#FFFFFF";
				cardBackgroundColor=""
				break;
			case tripTrackingStatus.get('ARRIVED'):
				primaryColor = 'success';
				secondaryColor = '#E8F5E9';
				title = language?.arrived;
				subtitle = subTitleText;
				titleTestId = 'arrived_text_truckdetailscreen';
				titleDataTestId = 'arrived_text_truckdetailscreen';
				subTitleTestId = '';
				subTitleDataTestId = '';
				titleAndSubtitleTextColor="#FFFFFF";
				cardBackgroundColor="cardBackgroundColor"
				break;
			case tripTrackingStatus.get('COMPLETED'):
				primaryColor = 'neutral';
				secondaryColor = '#F0F4F8';
				title = language?.completed;
				subtitle = language?.truckReachedDestination;
				titleTestId = 'completed_text_truckdetailscreen';
				titleDataTestId = 'truckhasreacheddestination_text_truckdetailscreen';
				subTitleTestId = '';
				subTitleDataTestId = '';
				titleAndSubtitleTextColor="#FFFFFF";
				cardBackgroundColor=""
				break;
			case tripTrackingStatus.get('TERMINATED'):
				primaryColor = '#CDD7E1';
				secondaryColor = '#ffffff';
				title = language?.cancelled;
				subtitle = language?.tripCancelled;
				titleTestId = 'cancelled_text_truckdetailscreen';
				titleDataTestId = 'tripcancelled_text_truckdetailscreen';
				titleAndSubtitleTextColor ="#555E68";
				subTitleTestId = '';
				subTitleDataTestId = '';
				cardBackgroundColor="#CDD7E1"
				break;
			default:
				primaryColor = 'warning';
				secondaryColor = '#FDF0E1';
				title = tripDetails?.trip_status_message || '';
				subtitle = subTitleText;
				titleTestId = '';
				titleDataTestId = '';
				subTitleTestId = '';
				subTitleDataTestId = '';
				titleAndSubtitleTextColor="";
				cardBackgroundColor=""
		}

		return {
			primaryColor,
			secondaryColor,
			title,
			subtitle,
			titleTestId,
			titleDataTestId,
			subTitleTestId,
			subTitleDataTestId,
			titleAndSubtitleTextColor,
			cardBackgroundColor
		};
	}

	const {
		primaryColor,
		secondaryColor,
		title,
		subtitle,
		titleTestId,
		titleDataTestId,
		subTitleTestId,
		subTitleDataTestId,
		titleAndSubtitleTextColor,
		cardBackgroundColor
	} = tripTrackingDetails(tripDetails);

	useEffect(() => {
		setIsClosed(!!dispatchDetails);
	}, [!!dispatchDetails]);

	const docStatusForSubmission = disablesubmission(saudaDetailData);
  const isDiaptchDocPendingOrRejected = getDispatchDocApprovalState({
    weightSlipDocument,
    mandiDoument,
    invoiceDocument
  });
  const isUnloadingDocAvailableAndUpproved = getDispatchDocState({
    buyerGRNDocument,
    unloadingWeightSlipDocument
  })

	return (
		<div className={Styles?.mainContainer}>
			<Suspense
				fallback={
					<MuiSkeleton sx={{ height: 100, width: '100%', marginBottom: 8 }} />
				}
			>
				<Header
					title={
						dispatchDetails?.dispatch_id
							? `${language?.truck}`
							: language?.createDispatch
					}
					vehicleNumber={dispatchDetails?.vehicle_number}
					dispatchIdPresent={dispatchDetails?.dispatch_id ? true : false}
					onClickBackIcon={onClickBackButton}
					id="closetab_button_truckdetailscreen"
					titleTestId="truck_text_truckdetailscreen"
					titleDataTestId="truck_text_truckdetailscreen"
				/>
			</Suspense>
			<Suspense
				fallback={
					<MuiSkeleton
						sx={{
							height: 100,
							width: 'calc(100% - 32px)',
							borderRadius: 8,
							marginBottom: 8,
						}}
					/>
				}
			>
				<Toast
					isError={true}
					message={language?.requestFailErrorMessage}
					isOpen={createDispatchToastPopup}
				/>
			</Suspense>
			{dispatchDetailLoading ? (
				<div className={Styles?.loadingContainer}>
					<MuiSkeleton
						sx={{
							height: 100,
							width: 'calc(100% - 32px)',
							borderRadius: 8,
							marginBottom: 8,
						}}
						itemNumber={4}
					/>
				</div>
			) : (!dispatchDetailLoading && createDispatchError) ? (
				<CommonErrorState
					onPressRetry={getDispatchDetails}
					onPressHelp={onPressHelp}
					onPressHelpTestId="createDispatchHelp"
					onPressRetryTestId="createDispatchRetry"
				/>
			) : (
				<>
					<div className={Styles?.restOfBodyContainer}>
						{dispatchDetails?.dispatch_id && tripDetails?.trip_status_id  && (
							<Tooltip
								open={showTourGuide}
								title={language?.truckTrackingAvailableNow}
								variant="solid"
								arrow
								sx={{marginBottom: "8px"}}
							>
								<Box paddingBottom={2}>
									{tripDetails && (
										<TruckTrackingCard
											primaryColor={primaryColor}
											secondaryColor={secondaryColor}
											title={title}
											subtitle={subtitle}
											onClick={() => {
													trackEvent(Events?.onTruckTrackingStatusBoxClick, {
														Status: `${title}`,
                            "PO ID": saudaDetailData?.poID,
                            "Sauda ID": saudaDetailData?.sauda_id,
                            "Trip Id": tripDetails?.trip_id,
													});
													onClickTripTrackingCard();
											}}
											subTitleDataTestId={subTitleDataTestId}
											subTitleTestId={subTitleTestId}
											titleDataTestId={titleDataTestId}
											titleTestId={titleTestId}
											titleAndSubtitleTextColor={titleAndSubtitleTextColor}
											cardBackgroundColor={cardBackgroundColor}
										/>
									)}
								</Box>
							</Tooltip>
						)}
						<MuiAccordion
							title={language?.truckDetails}
							onClick={() => {
								trackEvent(Events?.onTruckTrackingDocsAccordionClick, {
									'Document Category': 'Truck Details',
								});
								setIsClosed((c) => !c);
							}}
							accordionProps={{
								sx: {
									padding: '12px',
									backgroundColor: '#FFF',
									borderRadius: '8px',
								},
								expanded: dispatchDetails ? !isClosed : true,
							}}
							accordionSummaryProps={
								dispatchDetails
									? {
										sx: {
											color: 'var(--text-primary, #171A1C)',
											fontFamily: 'NotoSans',
											fontSize: '16px',
											fontStyle: 'normal',
											fontWeight: '600',
											lineHeight: '150%',
                      pointerEvents:"none"
										},
										// indicator: dispatchDetails ? true : false
									}
									: {
										sx: {
											color: 'var(--text-primary, #171A1C)',
											fontFamily: 'NotoSans',
											fontSize: '16px',
											fontStyle: 'normal',
											fontWeight: '600',
											lineHeight: '150%',
                      pointerEvents:"none"
										},
										id: 'truckdetails_text_truckdetailscreen',
										indicator: false,
									}
							}
						>
							<Input
								placeholder={language?.enterTruckNumber}
								title={language?.truckNumber}
								value={truckNumber}
								onchange={onChangeTruckNumber}
								unit={<LocalShippingOutlined />}
								onClickInput={() => {
									onClickInput('truckNumber');
								}}
								id="truckNumberInput"
								disabled={dispatchDetails?.vehicle_number ? true : false || dispatchDetails?.is_po_so_mapped}
								endDecorator={true}
								containerStyles={Styles?.inputContainer1}
                inputBoxStyles={dispatchId ? Styles?.inputBoxStyles:""}
                inputElementStyles={dispatchId ?Styles?.inputElementStyles:""}
							/>
							<Input
								placeholder={language?.enterDispatchWeight}
								title={language?.dispatchWeight}
								value={dispatchWeight}
								onchange={onChangeDispatchWeight}
								unit={<MuiTypography level="body-md">Kg</MuiTypography>}
								inputType="tel"
								onClickInput={() => {
									onClickInput('dispatchWeight');
								}}
								id="dispatchWeightInput"
								disabled={dispatchDetails?.dispatched_weight ? true : false || dispatchDetails?.is_po_so_mapped}
								endDecorator={true}
                inputBoxStyles={dispatchId ? Styles?.inputBoxStyles:""}
                inputElementStyles={dispatchId ?Styles?.inputElementStyles:""}
							/>
							<Input
								placeholder={language?.enterNoOfBag}
								title={language?.noOfBag}
								value={noOfBags}
								onchange={onChangeNoOfBags}
								unit={<ShoppingBagOutlined />}
								inputType="number"
								onClickInput={() => {
									onClickInput('numberOfBag');
								}}
								id="numberOfBagInput"
								disabled={dispatchDetails?.number_of_bags ? true : false || dispatchDetails?.is_po_so_mapped}
								endDecorator={true}
                inputBoxStyles={dispatchId ? Styles?.inputBoxStyles:""}
                inputElementStyles={dispatchId ?Styles?.inputElementStyles:""}
                onKeyDown={(e) => {
                  if (['+', '-', 'e', '.'].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
							/>
							<Input
								placeholder={language?.mobileNumberPlaceholder}
								title={language?.mobileNumbertitle}
								value={truckDriverNumber}
								onchange={onChangeTruckDriverNumber}
								unit={<Typography level="body-md">+91 - </Typography>}
								inputType="tel"
								onClickInput={() => {
									onClickInput('truckDriverMobileNumber');
								}}
								id="truckDriverMobileNumber"
								disabled={
									dispatchDetails?.truck_driver_mobile_number ? true : false || dispatchDetails?.is_po_so_mapped
								}
								startDecorator={true}
								textLimit={10}
								containerStyles={Styles?.inputContainer}
                inputBoxStyles={dispatchId ? Styles?.inputBoxStyles:""}
                inputElementStyles={dispatchId ?Styles?.inputElementStyles:""}
							/>
						</MuiAccordion>
						{dispatchDetails?.sauda_creation_type === saudaTypeEnum?.offline &&
							(invoiceDocument?.docs === null ||
								invoiceDocument?.docs?.length === 0) &&
							(weightSlipDocument?.docs === null ||
								weightSlipDocument?.docs?.length === 0) &&
							(mandiDoument?.docs === null ||
								mandiDoument?.docs?.length === 0) ? (
							<></>
						) : (
							<Suspense
								fallback={
									<MuiSkeleton
										sx={{
											height: 100,
											width: 'calc(100% - 32px)',
											borderRadius: 8,
											marginBottom: 8,
										}}
									/>
								}
							>
								<DocContainer
									isPoSoMapped={dispatchDetails?.is_po_so_mapped}
									isOpenInitially={!!dispatchDetails}
									language={language}
									onClickUploadDocument={onClickUploadDocument}
									invoiceDocument={invoiceDocument}
									weightSlipDocument={weightSlipDocument}
									mandiDoument={mandiDoument}
									saudaDetailData={saudaDetailData}
									mandiToolTipVisible={mandiToolTipVisible}
									onClickMandiToolTip={onClickMandiToolTip}
									saudaType={dispatchDetails?.sauda_creation_type}
									dispatchId={dispatchDetails?.dispatch_id}
                  truckDispatchTime={dispatchDetails?.truck_dispatch_time}
								/>
							</Suspense>
						)}
						{
              !!dispatchDetails && dispatchDetails?.is_po_so_mapped && isUnloadingDocAvailableAndUpproved ? (
                <UnloadingDocContainer
                    // isClosed={!!dispatchDetails}
                    language={language}
                    uploadedByFarMart={uploadedByFarMart}
                    buyerGRNDocument={buyerGRNDocument}
                    unloadingWeightSlipDocument={unloadingWeightSlipDocument}
                    onClickUploadDocument={onClickUploadDocument}
                    unloadDispatchTime={dispatchDetails?.truck_unloading_time}
                  />
              ) : (
                <>
                  {!!dispatchDetails && dispatchDetails?.is_po_so_mapped && isDiaptchDocPendingOrRejected && (
                    <UnloadingDocContainer
                      // isClosed={!!dispatchDetails}
                      language={language}
                      uploadedByFarMart={uploadedByFarMart}
                      buyerGRNDocument={buyerGRNDocument}
                      unloadingWeightSlipDocument={unloadingWeightSlipDocument}
                      onClickUploadDocument={onClickUploadDocument}
                      unloadDispatchTime={dispatchDetails?.truck_unloading_time}
                    />
                  )}
                </>
              )
            }
					</div>
					<Suspense
						fallback={
							<MuiSkeleton
								sx={{
									height: 100,
									width: 'calc(100% - 32px)',
									borderRadius: 8,
									marginBottom: 8,
								}}
							/>
						}
					>
						<ActionContainer
							language={language}
							onClickSubmitButton={onClickSubmitButton}
							error={error}
							onUploadUnloadingDocs={onUploadUnloadingDocs}
							isCreateDispatch={!!dispatchDetails?.is_po_so_mapped}
							submitLoading={submitLoading}
							actionButtonDisable={docStatusForSubmission}
							saudaType={dispatchDetails?.sauda_creation_type}
							dispatchDetails={dispatchDetails}
						/>
					</Suspense>
					<Suspense
						fallback={
							<MuiSkeleton
								sx={{
									height: 100,
									width: 'calc(100% - 32px)',
									borderRadius: 8,
									marginBottom: 8,
								}}
							/>
						}
					>
						<CancelDispatchModal
							language={language}
							showCancelDispatchModal={showCancelDispatchModal}
							toggleCancelDispatchModal={toggleCancelDispatchModal}
							onClickCancelDispatch={onClickCancelDispatch}
						/>
					</Suspense>
				</>
			)}
			<Suspense
				fallback={
					<MuiSkeleton
						sx={{
							height: 100,
							width: 'calc(100% - 32px)',
							borderRadius: 8,
							marginBottom: 8,
						}}
					/>
				}
			>
				{!userlogedIn && <LoginView />}
			</Suspense>
			<Suspense
				fallback={
					<MuiSkeleton
						sx={{
							height: 100,
							width: 'calc(100% - 32px)',
							borderRadius: 8,
							marginBottom: 8,
						}}
					/>
				}
			>
				<ErrorMessageToast
					message={
						truckErrorMessage
							? language?.truckerrorMessage
							: language?.requestFailErrorMessage
					}
					isError={isError}
					setIsError={setIsError}
					ErrorToastType={true}
				/>
			</Suspense>
		</div>
	);
}
