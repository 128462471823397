async function clearServiceWorkerCache() {
  try {
    if (!('serviceWorker' in navigator)) {
      console.log('Service Workers not supported');
      return;
    }

    const registrations = await navigator.serviceWorker.getRegistrations();

    if (registrations.length) {
      await Promise.all(
        registrations.map(async registration => {
          if (registration.active) {
            await registration.active.postMessage({ type: 'SKIP_WAITING' });
          }
          const success = await registration.unregister();
          return success;
        })
      );

      const cacheNames = await caches.keys();

      const deletionPromises = cacheNames.map(async cacheName => {
        try {
          const success = await caches.delete(cacheName);
          console.log(`Cache deleted: ${cacheName} - ${success}`);
          return success;
        } catch (err) {
          console.error(`Error deleting cache ${cacheName}:`, err);
          return false;
        }
      });

      await Promise.all(deletionPromises);

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key?.includes('sw-')) {
          localStorage.removeItem(key);
        }
      }

      window.location.reload(true);
    } else {
      const cacheNames = await caches.keys();

      if(cacheNames?.length) {
        const deletionPromises = cacheNames.map(async cacheName => {
          try {
            const success = await caches.delete(cacheName);
            console.log(`Cache deleted: ${cacheName} - ${success}`);
            return success;
          } catch (err) {
            console.error(`Error deleting cache ${cacheName}:`, err);
            return false;
          }
        });

        await Promise.all(deletionPromises);
      }
      console.log('No service workers found');
    }
  } catch (error) {
    console.error('Error during cleanup:', error);
    throw error;
  }
}

export default clearServiceWorkerCache;
