import React from 'react';
import UseFaqAnswers from '@mono-farmart-web/farmart-web-common/hooks/FAQAnswers/FAQAnswers';
import FAQAnswers from '../../Pages/FAQAnswers/FAQAnswers';

export default function faqAnswers() {
  const {
    faqAnswer,
    onClickBackFaqAnswerScreen,
    onClickNeedMoreHelp,
    showFaqAlert,
    setShowFaqAlert,
    language,
    faqAssets,
  } = UseFaqAnswers();

  return (
    <FAQAnswers
      faqAnswer={faqAnswer}
      onClickBackFaqAnswerScreen={onClickBackFaqAnswerScreen}
      onClickNeedMoreHelp={onClickNeedMoreHelp}
      showFaqAlert={showFaqAlert}
      setShowFaqAlert={setShowFaqAlert}
      language={language}
      faqAssets={faqAssets}
    />
  );
}
