/**
 * The function `personaCard` is a React component that renders a persona card based on the selected
 * user persona, with different text and image based on the persona type.
 * @param  - 1. `personaImages`: An image URL for the persona card.
 * @returns The `personaCard` component is being returned. It is a functional component in React that
 * displays a persona card based on the props passed to it. The card includes an image, labels for
 * persona type and description, and a button that triggers a function when clicked. The styling of the
 * card is determined by the selected persona key and user selected persona.
 */
/**
 * The `personaCard` component renders a persona card based on the selected user persona, with different
 * text and image based on the persona type.
 * @param {string} personaImages - An image URL for the persona card.
 * @param {Function} onClickPersonaCard - Callback function for handling click on the persona card.
 * @param {string} userSelectedPersona - ID of the selected persona.
 * @param {string} selectedPersonaKey - Key representing the selected persona type.
 * @param {Object} language - Language object containing text for translations.
 * @param {number} index - Index of the persona card.
 * @returns {JSX.Element} - The `personaCard` component renders a persona card with an image, labels
 * for persona type and description, and a button that triggers a function when clicked.
 * The styling of the card is determined by the selected persona key and user selected persona.
 */
import React from 'react';
import Styles from './style.module.css';
import { userType } from '@mono-farmart-web/farmart-web-common/constant/enums/userType';

export default function personaCard({
  personaImages,
  onClickPersonaCard,
  userSelectedPersona,
  selectedPersonaKey,
  language,
  index,
}) {
  return (
    <button
      className={
        userSelectedPersona === selectedPersonaKey
          ? Styles.selectedMainContainer
          : Styles.nonSelectedMainContainer
      }
      onClick={onClickPersonaCard(selectedPersonaKey)}
      id={'UserPersonaSelectPersona' + index}
    >
      <div className={Styles.textContainer}>
        <label className={Styles.paragraph1}>
          {/* Determine persona type label based on selected persona key */}
          {userType['retailer'] == selectedPersonaKey
            ? language?.retailer
            : userType['trader'] == selectedPersonaKey
            ? language?.distributer
            : language?.farmer}
        </label>
        <label className={Styles.paragraph2}>
          {/* Determine persona description based on selected persona key */}
          {userType['retailer'] == selectedPersonaKey
            ? language?.retailerDescp
            : userType['trader'] == selectedPersonaKey
            ? language?.distributerDescp
            : language?.farmerDescp}
        </label>
      </div>
      <img
        className={Styles.imageContainer}
        src={personaImages}
        alt="Persona"
        loading="eager"
      />
    </button>
  );
}
