import React from 'react';
import Styles from './style.module.css';
import Button from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';

interface ActionButtonProps {
  selectedPersona: string;
  loading: boolean;
  onClickProceedPersona: () => void;
  language: { [key: string]: any };
}
export default function Actionbutton(props: ActionButtonProps) {
  const { selectedPersona, loading, onClickProceedPersona, language } = props;
  return selectedPersona ? (
    <div className={Styles?.actionContainer}>
      <div className={Styles.proceedButtonContainer}>
        <Button
          id="loginProceedtestId"
          size="lg"
          onClick={onClickProceedPersona}
          loading={loading}
        >
          {language?.proceed}
        </Button>
      </div>
    </div>
  ) : (
    <></>
  );
}
