/* This code snippet defines a functional component called `NewsfeedContainer` in a TypeScript React
application. Here's a breakdown of what the code is doing: */
import React from 'react';
import styles from './style.module.css';
import { Box } from '@mui/material';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import ButtonV2 from '@mono-farmart-web/farmart-web-common/ComponentV2/ButtonV2';
import { ChevronRightRounded } from '@mui/icons-material';
import { Post as PostType } from '@mono-farmart-web/farmart-web-common/modal/community/types';
import Card from '@mono-farmart-web/farmart-web-common/Components/Card/Card/Card';
import ScreenType from '@mono-farmart-web/farmart-web-common/constant/screenEnums';
import SkeletonCard from '@mono-farmart-web/farmart-web-common/Components/SkeletonLoading/SkeletonLoading';
/**
 * Functional component representing a news feed container.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - posts: Array of post objects.
 *   - onClap: Function to handle clap action.
 *   - onComment: Function to handle comment action.
 *   - onShare: Function to handle share action.
 *   - onViewPost: Function to view a post.
 *   - language: Object containing language-specific text and translations.
 *   - onClickViewMorePost: Function to handle view more posts action.
 *   - isFetchingAllPosts: Boolean indicating whether all posts are being fetched.
 *   - homeScreenLoading: Boolean indicating whether the home screen is loading.
 * @returns JSX elements representing the NewsfeedContainer component.
 */

export default function NewsfeedContainer({
  posts,
  onClap,
  onComment,
  onShare,
  onViewPost,
  language,
  onClickViewMorePost,
  isFetchingAllPosts,
  homeScreenLoading,
  homeScreenAssets,
}) {
  const newsFeedList = posts.slice(0, 3)?.map(function (post: PostType) {
    // doing this because we are mutating the post object
    // to set created_by to UserDeleted if the user is deleted
    // we cant mutate the object directly as it is a readonly object
    // due to redux toolkit, reduxtoolkit uses immer under the hood
    // which makes the state object readonly
    post = { ...post };
    const thumbnail = post.image_thumbnails ? post.image_thumbnails : [];
    post.created_by = post.created_by || {
      name: 'USER DELETED',
      district: '',
      state: '',
      profile_pic_url: '',
      id: '',
      page: false,
      is_verified: false,
    };
    return (
      <Card
        className={styles?.cardStyles}
        backgroundColor={'#F0F4F8'}
        name={post.created_by.name}
        state={post.created_by.state}
        heading={post.heading_text}
        clap={post.news_feed_stats.claps}
        comment={post.news_feed_stats.comment_count}
        share={post.news_feed_stats.share_count}
        profilepic={post.created_by.profile_pic_url}
        postpic={thumbnail}
        key={post.id + 'homeScreenList'}
        id={post.id}
        onClap={onClap(
          post.id,
          ScreenType.get('homeScreen'),
          post.heading_text,
        )}
        onComment={onComment(
          post.id,
          ScreenType.get('homeScreen'),
          post.heading_text,
        )}
        onShare={onShare(
          post.id,
          post.heading_text,
          ScreenType.get('homeScreen'),
          post.heading_text,
        )}
        onViewPost={onViewPost(
          post.id,
          post.heading_text,
          ScreenType.get('homeScreen'),
          post,
        )}
        postedAgo={post.live_from}
        verifiedPost={post.created_by.is_verified}
        postTypographyData={post.formatted_long_description}
        page={post.created_by.page}
        shortDescription={post.short_description}
        isClapped={
          post.news_feed_stats.claps || post.news_feed_stats.user_clap_count > 0
            ? true
            : false
        }
        onClapButtonId="homeScreenPostCardOnClapButtonId"
        onCommentButtonId="homeScreenPostCardOnCommentButtonId"
        onShareButtonId="homeScreenPostCardOnShareButtonId"
        language={language}
        companyProfileIcon={homeScreenAssets.companyProfileIcon}
        whatsappIcon={homeScreenAssets.whatsappIcon}
        clapIcon={homeScreenAssets.clapIcon}
        clapDoneIcon={homeScreenAssets.clapDoneIcon}
      />
    );
  });
  return homeScreenLoading || isFetchingAllPosts ? (
    <SkeletonCard />
  ) : newsFeedList?.length > 0 ? (
    <Box className={styles.mainContainer}>
      <div className={styles?.titleContainer}>
        <Box sx={{ flex: 1 }}>
          <img
            src={homeScreenAssets.startDecorationLine}
            height={6}
            width="100%"
            loading="lazy"
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <MuiTypography level="title-md">{language?.community}</MuiTypography>
        </Box>
        <Box sx={{ flex: 1 }}>
          <img
            src={homeScreenAssets.endDecorationLine}
            height={6}
            width="100%"
            loading="lazy"
          />
        </Box>
      </div>
      <div className={styles?.newsfeedContainer}>{newsFeedList}</div>
      <ButtonV2
        onClick={onClickViewMorePost}
        title={language?.viewAllNewsFeed}
        trailingIcon={
          <ChevronRightRounded
            sx={{ color: '#FCFCFD' }}
            id={'homeScreenViewMorePostsButtonId'}
          />
        }
      />
    </Box>
  ) : (
    <></>
  );
}
