import React from 'react';
import Style from './style.module.css';
import MuiAlert from '@mono-farmart-web/farmart-web-common/ComponentsV3/MuiAlert';
import ErrorRounded from '@mui/icons-material/ErrorRounded';

interface DocumentUploadAlert {
  text: string;
}

const DocumentUploadAlert = ({ text }: DocumentUploadAlert) => {
  return (
    <div className={Style.alertWrapper}>
      <MuiAlert
        size="sm"
        variant="soft"
        color="warning"
        sx={{ borderRadius: '8px' }}
      >
        <div className={Style.alert}>
          <ErrorRounded />
          <label className={Style.alertText}>{text}</label>
        </div>
      </MuiAlert>
    </div>
  );
};

export default DocumentUploadAlert;
