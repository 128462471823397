import React from 'react';
import Style from './style.module.css';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

interface Header {
  HeaderRightSideContent?: React.ReactNode;
  HeaderRightSideContentStyles?: string;
  title: string;
  onClickBackIcon: () => void;
  id?: string;
  backButtonVisible?: boolean;
  titleTestId?: string;
  titleDataTestId?: string;
  vehicleNumber?: string;
  dispatchIdPresent?: boolean;
}

export default function index(props: Header) {
  const {
    HeaderRightSideContent,
    HeaderRightSideContentStyles,
    title,
    onClickBackIcon,
    id,
    backButtonVisible = true,
    titleTestId = '',
    titleDataTestId = '',
    vehicleNumber = "",
    dispatchIdPresent = false
  } = props;

  return (
    <div className={Style.container}>
      <div className={Style.iconCumTitle}>
        {backButtonVisible ? (
          <ArrowBackRoundedIcon
            onClick={onClickBackIcon}
            id={id}
            sx={{ color: '#293756', height: '24px', width: '24px' }}
          />
        ) : (
          <></>
        )}
        <div className={Style.labelContainer}>
          <label
            className={Style.titleTextStyle}
            id={titleTestId}
            data-testid={titleDataTestId}
          >
            {title}
          </label>
          {dispatchIdPresent && <label
            className={Style.titleTextStyle}
            id={titleDataTestId}
            data-testid={titleDataTestId}
          >
            {vehicleNumber}
          </label>}
        </div>

      </div>
      <div className={HeaderRightSideContentStyles}>
        {HeaderRightSideContent}
      </div>
    </div>
  );
}
