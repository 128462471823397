import { useEffect, useRef, useState } from "react";
import type { ModalTypes } from "./types";
import GetIpLocation from "../../modal/Traders/models/GetIpLocation";
import { trackEvent } from "../../utils/moengage";
import Events from "../../constant/MoengageEvents/index";
import { apiEndPoints } from "../../apis/endpoints";
import { useLocation } from "react-router-dom";
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import bridgeFunctions from "../../utils/bridge";
import {
	getUserProfileFromStorage,
	loginAsGuest,
} from "../../modal/auth/auth.model";
import useAuthenticationCheckerHook from "../../utils/authChecker";
import { usePlatfromData } from "../../hooks/CrossPlatformActionHandler/platformChecker";
import PlatForms from "../../constant/platFormEnums";
import { languageUrls } from "../../constant/languageUrls/index";
import useAppLanguage from "../../utils/applanguage";
import getLang from "../../languages/location.js";

export default function useLocationHook(): ModalTypes {
	const location = useLocation();
	const bridge = createBridge();
	const userProfile = getUserProfileFromStorage() || null;
	const userLogedIn =
		userProfile?.merchant_detail_id ||
		userProfile?.merchant_occupation_id ||
		JSON.parse(localStorage.getItem("loged"));

	const { language } = useAppLanguage(
		languageUrls?.locationModalScreenLangageUrl,
		getLang,
	);

	const [isLocationModal, setLocationModal] = useState<boolean>(false);
	const platformData = usePlatfromData();
	const [locationModalLoading, setLocationModalLoading] =
		useState<boolean>(false);
	const token = localStorage.getItem("authToken");
	const [lat, setLat] = useState<number>(null);
	const [locationToastPopup, setLocationToastPopup] = useState(false);
	const { AuthenticationCheckerAndApiCaller } = useAuthenticationCheckerHook();

	function trimDecimalValues(value: number, trimUpto: number) {
		return Number(value.toFixed(trimUpto));
	}

	async function getIpLocationGlobalFun() {
		try {
			/* The line `const IpLocationLatLong = await GetIpLocation();` is calling the `GetIpLocation`
      function asynchronously and storing the result in the `IpLocationLatLong` constant. This
      function is likely responsible for fetching the latitude and longitude coordinates of the
      user's current location based on their IP address. The `await` keyword is used to wait for the
      asynchronous operation to complete before proceeding further in the code. */

			const IpLocationLatLong = await GetIpLocation();

			/* The line `const IpLocationLatLong = await GetIpLocation();` is calling the `GetIpLocation`
      function asynchronously and storing the result in the `IpLocationLatLong` constant. This
      function is likely responsible for fetching the latitude and longitude coordinates of the
      user's current location based on their IP address. The `await` keyword is used to wait for the
      asynchronous operation to complete before proceeding further in the code. */
			const ipAddressResp = await AuthenticationCheckerAndApiCaller({
				payload: {
					lat_long: `${trimDecimalValues(
						IpLocationLatLong?.location?.lat,
						4,
					)}, ${trimDecimalValues(IpLocationLatLong?.location?.lng, 4)}`,
				},
				uri: apiEndPoints.setIPLocationLogs,
				requestType: "post",
			});
			localStorage.setItem(
				"lat",
				JSON.stringify(IpLocationLatLong?.location?.lat),
			);
			localStorage.setItem(
				"long",
				JSON.stringify(IpLocationLatLong?.location?.lng),
			);
			const reverseGeoParams = {
				lat: JSON.stringify(
					trimDecimalValues(IpLocationLatLong?.location?.lat, 4),
				),
				long: JSON.stringify(
					trimDecimalValues(IpLocationLatLong?.location?.lng, 4),
				),
			};
			/* The code snippet `const stateDistrict = await AuthenticationCheckerAndApiCaller({
          payload: reverseGeoParams,
          uri: apiEndPoints?.fetchStateDistrictsReverseGeoCode,
          });` is making an asynchronous request to fetch state and district information based on the
          provided latitude and longitude coordinates. */
			const stateDistrict = await AuthenticationCheckerAndApiCaller({
				payload: reverseGeoParams,
				uri: apiEndPoints?.fetchStateDistrictsReverseGeoCode,
				requestType: "get",
			});

			const locationAttributes = {
				Location: "yes",
				State:
					stateDistrict?.data?.data?.reverse_geocode?.state.toLocaleLowerCase(),
				District:
					stateDistrict?.data?.data?.reverse_geocode?.district.toLocaleLowerCase(),
				userLoggedIn: JSON.parse(userLogedIn) === true ? "Yes" : "No",
				locationFrom: "IP",
				ipAddress: ipAddressResp?.data?.data?.ip_address,
			};
			trackEvent(Events.beforeLocationAccess, locationAttributes);

			localStorage.setItem("ipAddress", ipAddressResp?.data?.data?.ip_address);
			setLat(IpLocationLatLong?.location?.lat);

			localStorage.setItem(
				"stateId",
				JSON.stringify(
					stateDistrict?.data?.data?.reverse_geocode?.master_state_id,
				),
			);
			localStorage.setItem(
				"districtId",
				JSON.stringify(
					stateDistrict?.data?.data?.reverse_geocode?.master_district_id,
				),
			);
			localStorage.setItem(
				"stateName",
				JSON.stringify(
					stateDistrict?.data?.data?.reverse_geocode?.master_state_name,
				),
			);
			localStorage.setItem(
				"districtName",
				JSON.stringify(
					stateDistrict?.data?.data?.reverse_geocode?.master_district_name,
				),
			);
			localStorage.setItem(
				"pinCode",
				JSON.stringify(stateDistrict?.data?.data?.reverse_geocode?.zipcode),
			);
			localStorage.setItem(
				"talukId",
				JSON.stringify(
					stateDistrict?.data?.data?.reverse_geocode?.master_taluk_id,
				),
			);

			const params = {
				key: "userNewLocation",
				asyncData: stateDistrict?.data?.data?.reverse_geocode,
			};

			bridge.sendRequestToNative(
				"setNativeAppAsyncStorageListener",
				params,
				(response: any) => {
					bridgeFunctions.PrintLog(response);
				},
			);

			if (userLogedIn) {
				/* The above TypeScript code is creating an object `submitLocationParams` with properties
     `pincode`, `master_taluk_id`, `master_district_id`, and `master_state_id` extracted from the
     `stateDistrict` object. It then makes a POST request using the `AuthenticationCheckerAndApiCaller` function with the
     payload set to `submitLocationParams` and the URI set to `apiEndPoints.updateMerchantLocation`. */
				const submitLocationParams = {
					pincode: stateDistrict?.data?.data?.reverse_geocode?.zipcode,
					master_taluk_id:
						stateDistrict?.data?.data?.reverse_geocode?.master_taluk_id,
					master_district_id:
						stateDistrict?.data?.data?.reverse_geocode?.master_district_id,
					master_state_id:
						stateDistrict?.data?.data?.reverse_geocode?.master_state_id,
				};

				await AuthenticationCheckerAndApiCaller({
					payload: submitLocationParams,
					uri: apiEndPoints?.updateMerchantLocation,
					requestType: "post",
				});
			}
			setLocationModalLoading(false);
			setLocationModal(false);
		} catch (error) {
			if (error?.code === 401 || error?.code === 426) {
				setLocationModalLoading(false);
				setLocationModal(false);
			}
			console.log(error);
		}
	}

	async function checkUserLocation() {
		try {
			const stateId = localStorage.getItem("stateId");
			const districtId = localStorage.getItem("districtId");
			const districtName = localStorage.getItem("districtName");
			const stateName = localStorage.getItem("stateName");
			const ipAddress = localStorage.getItem("ipAddress");
			const pincode = localStorage?.getItem("pinCode");
			const talukId = localStorage?.getItem("talukId");

			if (stateId && stateName && districtId && districtName) {
				const locationAttributes = {
					Location: "yes",
					State: stateName?.toLocaleLowerCase(),
					District: districtName.toLocaleLowerCase(),
					userLoggedIn: JSON.parse(userLogedIn) === true ? "Yes" : "No",

					locationFrom: "IP",
					ipAddress: ipAddress,
				};
				trackEvent(Events.beforeLocationAccess, locationAttributes);

				if (userLogedIn) {
					/* The above TypeScript code is creating an object `submitLocationParams` with properties
       `pincode`, `master_taluk_id`, `master_district_id`, and `master_state_id` extracted from the
       `stateDistrict` object. It then makes a POST request using the `AuthenticationCheckerAndApiCaller` function with the
       payload set to `submitLocationParams` and the URI set to `apiEndPoints.updateMerchantLocation`. */
					const submitLocationParams = {
						pincode: pincode,
						master_taluk_id: talukId,
						master_district_id: districtId,
						master_state_id: stateId,
					};

					await AuthenticationCheckerAndApiCaller({
						payload: submitLocationParams,
						uri: apiEndPoints?.updateMerchantLocation,
						requestType: "post",
					});
				}
			} else {
				await askForHighAccuracyLocation("");
			}
		} catch (error) {
			if (error?.code === 401 || error?.code === 426) {
			}
			console.log(error);
		}
	}

	const locationFetchRef = useRef(null);

	function initialLocationCheck() {
		if (!locationFetchRef.current) {
			locationFetchRef.current = new Promise((resolve, reject) => {
				checkUserLocation()
					.then((data) => {
						resolve(data);
						locationFetchRef.current = null;
					})
					.catch((err) => {
						reject(err);
						locationFetchRef.current = null;
					});
			});
		}
		return locationFetchRef.current;
	}

	/**
	 * The function clears the last stored location and asks for high accuracy location.
	 */
	function onClickAllowLocationModal(): void {
		askForHighAccuracyLocation("asked");
	}

	/**
	 * The function `askForHighAccuracyLocation` requests high accuracy location data and updates user
	 * location information accordingly.
	 * @param {string} type - The `type` parameter in the `askForHighAccuracyLocation` function is used to
	 * determine the behavior of the function based on the value passed to it. It is a string parameter
	 * that can have different values to control the flow of the function. In the provided code snippet,
	 * the `type`
	 */
	function askForHighAccuracyLocation(type: string) {
		return new Promise((resolve, reject) => {
			setLocationModalLoading(true);
			const options = {
				enableHighAccuracy: true, // Request high accuracy
				timeout: 6000, // Maximum time (in milliseconds) to wait for location data
				maximumAge: 0, // Maximum age (in milliseconds) of a possible cached position
			};
			if (navigator?.geolocation) {
				navigator.geolocation.getCurrentPosition(
					async (position) => {
						try {
							/* The code snippet you provided is creating an object `reverseGeoParams` with `lat` and
             `long` properties that store the latitude and longitude coordinates obtained from the
             `position` object. These coordinates are then used as payload data in a `AuthenticationCheckerAndApiCaller`
             function call to fetch state and district information based on the provided latitude and
             longitude. The `fetchStateDistrictsReverseGeoCode` endpoint from `apiEndPoints` is used
             for this purpose. This process is part of retrieving location details based on the user's
             current geolocation. */
							const reverseGeoParams = {
								lat: JSON.stringify(
									trimDecimalValues(position.coords.latitude, 4),
								),
								long: JSON.stringify(
									trimDecimalValues(position.coords.longitude, 4),
								),
							};
							const stateDistrict = await AuthenticationCheckerAndApiCaller({
								payload: reverseGeoParams,
								uri: apiEndPoints?.fetchStateDistrictsReverseGeoCode,
								requestType: "get",
							});

							/* The code snippet you provided is checking if the user is logged in (`userLogedIn` is
             truthy) and then constructing a `submitLocationParams` object with properties like
             `pincode`, `master_taluk_id`, `master_district_id`, and `master_state_id` based on the
             data obtained from `stateDistrict`. */
							if (userLogedIn) {
								const submitLocationParams = {
									pincode: stateDistrict?.data?.data?.reverse_geocode?.zipcode,
									master_taluk_id:
										stateDistrict?.data?.data?.reverse_geocode?.master_taluk_id,
									master_district_id:
										stateDistrict?.data?.data?.reverse_geocode
											?.master_district_id,
									master_state_id:
										stateDistrict?.data?.data?.reverse_geocode?.master_state_id,
								};

								await AuthenticationCheckerAndApiCaller({
									payload: submitLocationParams,
									uri: apiEndPoints?.updateMerchantLocation,
									requestType: "post",
								});
							}
							/* The code snippet you provided is storing various location-related data in the browser's
         `localStorage`. Here's a breakdown of what each `localStorage.setItem` call is doing: */
							localStorage.setItem(
								"lat",
								JSON.stringify(position.coords.latitude),
							);
							setLat(position.coords.latitude);
							localStorage.setItem(
								"long",
								JSON.stringify(position.coords.longitude),
							);

							localStorage.setItem(
								"stateName",
								JSON.stringify(
									stateDistrict?.data?.data?.reverse_geocode?.master_state_name,
								),
							);
							localStorage.setItem(
								"districtName",
								JSON.stringify(
									stateDistrict?.data?.data?.reverse_geocode
										?.master_district_name,
								),
							);
							localStorage.setItem(
								"stateId",
								JSON.stringify(
									stateDistrict?.data?.data?.reverse_geocode.master_state_id,
								),
							);
							localStorage.setItem(
								"districtId",
								JSON.stringify(
									stateDistrict?.data?.data?.reverse_geocode
										?.master_district_id,
								),
							);
							localStorage.setItem(
								"pinCode",
								JSON.stringify(
									stateDistrict?.data?.data?.reverse_geocode?.zipcode,
								),
							);
							localStorage.setItem(
								"talukId",
								JSON.stringify(
									stateDistrict?.data?.data?.reverse_geocode?.master_taluk_id,
								),
							);

							const params = {
								key: "userNewLocation",
								asyncData: stateDistrict?.data?.data?.reverse_geocode,
							};

							bridge.sendRequestToNative(
								"setNativeAppAsyncStorageListener",
								params,
								(response: any) => {
									bridgeFunctions.PrintLog(response);
								},
							);

							if (type === "asked") {
								setLocationModal(false);
								setLocationModalLoading(false);
							}
							resolve(true);
						} catch (error) {
							if (error?.code === 401 || error?.code === 426) {
								reject(error);
							}
						}
					},
					async (error) => {
						await getIpLocationGlobalFun();
						setLocationModal(false);
						setLocationModalLoading(false);
						handleLocationToastPopup();
						reject(error);
					},

					options, // Pass the options object
				);
			} else {
				getIpLocationGlobalFun()
					.then((data) => {
						resolve(data);
					})
					.catch((err) => {
						reject(err);
					});
				if (type === "asked") {
					setLocationModal(false);
					setLocationModalLoading(false);
				}
			}
		});
	}

	const handleLocationToastPopup = () => {
		setLocationToastPopup(true);
		setTimeout(() => {
			setLocationToastPopup(false);
		}, 5000);
	};

	/* The `useEffect` hook in the code snippet is used to perform side effects in function components in
React. In this specific case, the `useEffect` hook is calling the `initialLocationCheck` function
when the `token` dependency changes. */
	useEffect(() => {
		if (location?.pathname === "/") {
			if (token) {
				initialLocationCheck();
			} else if (platformData.platform === PlatForms?.get("website")) {
				loginAsGuestApiCall();
			}
		}
	}, [token]);

	async function loginAsGuestApiCall() {
		await loginAsGuest();
	}

	return {
		language,
		onClickAllowLocationModal,
		isLocationModal,
		locationModalLoading,
		setLocationModal,
		setLocationModalLoading,
		initialLocationCheck,
		lat,
		locationToastPopup,
	};
}
