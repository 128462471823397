export const languageUrls = {
  homeScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/01ff7762-2be9-4994-a37e-03768ddc161e_homescreen.json',
  loginScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/4eee02f3-c17c-45bf-a8ed-701ac1c438a3_login.json',
  otpScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/8c55d502-af2d-4939-9068-3c23afc1bd6c_otp.json',
  registerScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/7f28698b-f817-4727-b5dd-56da71d17dc5_userRegistration.json',
  addFarmerAcknowledgementScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/53bca036-462d-4c4d-b622-d94a0d2bfce0_acknowledgement.json',
  addFarmerScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/13219d8d-f1d2-4e70-b520-a9fecb17bbc8_addFarmer.json',
  myFarmerScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/082d9f01-0007-440a-98d9-cee8df85617e_myfarmer.json',
  rakeDetailListScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/32aff641-057c-437f-89b8-8e4b6ebe2213_rakeDetailList.json',
  rakeListScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/966190e6-81b1-4c23-98ec-f3b064e3721f_rakeList.json',
  faqAnswers:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/16d44114-620c-4102-b545-0749adbe2550_faqAnswers.json',

  faqQuestions:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/14e82569-0514-4631-800f-49bff57c33fe_faqQuestions.json',
  tradeList:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/ef9736c0-0280-40d4-bd4e-298138592f99_tradeList.json',
  tradePriceBreakup:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/b32d0c39-ea5e-4bf9-bc5d-93a336f9dfe9_tradePriceBreakupLanguage.json',
  mySaudaScreen:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/21ad082c-a0c8-44f5-99f7-f83d26d7560a_mysauda.json',
  saudaDetailScreen:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/2cbf4503-bc7f-40f9-94a4-312e0aa97524_saudaDetails.json',
  saudaAcknowledgement:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/d32485bc-bfe5-4db1-ad91-643460a8efd1_saudaAcknowledgement.json',
  smstemplateScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/9ecae935-2fb4-4223-8105-6f236b595468_smstemplate.json',

  smsLandingScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/ab7d1a22-3ab5-48a0-b4e0-dac9246f6a72_smsLanding.json',

  smsHistoryDetailScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/999b628b-1e81-400c-84b5-beec4d1632b6_smsHistoryDetail.json',

  smsFarmerScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/feb63503-c379-460d-a375-cedb05ba202f_smsFarmer.json',

  smsAcknowledgementScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/f8c746c6-41ae-4be8-80f0-8293acd6f695_smsAcknowledgement.json',

  shopPromotionalModalScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/959ac555-d534-439c-9708-b4433d661c45_shopPromotionalModal.json',
  smsHistoryScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/2c2f54cb-c985-488f-bfdd-6ec3a17b0215_marketingsmshistory.json',
  pinCodeScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/d3b3885d-3f33-470b-86b1-e8d4bc6ad8f2_pincode.json',
  locationModalScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/171aba09-5f4f-42c2-9418-7a5b56a4504a_location.json',
  myProfileScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/fa8d00f2-a223-4d36-80ba-948cfd0e2d19_myprofile.json',
  communityLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/72533df2-86d9-424a-8683-0d9d0db041f0_community.json',
  navbarLanguageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/15c35ad0-d1d1-45db-a5fc-51dd5ca751fb_navbar.json',
  editProfileScreenLangageUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/ca70e3ee-5a97-4e99-860a-2aa69f092533_editProfile.json',
  createDispatchUrl:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/1be75fa4-7138-4e4d-9666-97e63e2ea782_createCumDispatchDetail.json',
  documentUpload:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/22ba07e3-427e-4e1a-a862-feb7f5e2bbfc_documentUpload.json',
  timeAgo:
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/27/17f1d271-ae98-4296-93fc-5bc4817ba47d_time.json',
  commonErrorState: "https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/6/6598a355-d27c-4d24-93ff-8e02c798af93_errorPage.json",
  paymentScreenLangageUrl: "https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/6/f59fac4d-e92f-4bac-bb07-8559bede4875_paymentDialogue.json",
  trackConsent: "https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/6/4ba9f2a5-aaf0-4bf5-9351-6dc91bdc9b2d_tracking.json",
  buyerSearch:
  'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2025/1/6/eec79234-aeeb-4ce8-9d1f-4834272e5df4_searchBuyer.json',
};
