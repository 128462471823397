import { Box } from '@mui/joy';
import React from 'react';
import Modal from '@mono-farmart-web/farmart-web-common/ComponentV2/Modal';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import Styles from './style.module.css';
import { LogOutModalProps } from '@mono-farmart-web/farmart-web-common/hooks/MyProfile/types';

export default function LogOutModal({
  language,
  onClickCloseLogOutModal,
  logOutModalVisible,
  confirmLogout,
}: LogOutModalProps) {
  const logOutUi = (
    <Box
      sx={{
        flexShrink: 0,
        borderRadius: 'var(--radius-xl, 16px) var(--radius-xl, 16px) 0px 0px',
      }}
      bgcolor={'background.body'}
      data-testid={'myProfileLogOutModalVisiblityTestId'}
    >
      <MuiTypography
        textColor={'text.primary'}
        level="body-sm"
        padding={'16px 16px 8px 16px'}
        data-testid={'myProfileLogOutDescriptionTestId'}
      >
        {language?.logOutDescription}
      </MuiTypography>
      <Box
        display={'flex'}
        padding={'var(--5, 20px) var(--4, 16px)'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={'var(--3, 12px)'}
        bgcolor={'background.body'}
      >
        <MuiButton
          className={Styles?.yesButton}
          size="md"
          variant="outlined"
          onClick={confirmLogout}
          id="myProfileLogOutModalConfirmButtonId"
          data-testid={'myProfileLogOutModalConfirmButtonTestId'}
        >
          {language?.yes}
        </MuiButton>
        <MuiButton
          className={Styles?.cancelButton}
          size="md"
          variant="solid"
          onClick={onClickCloseLogOutModal}
          id="myProfileLogOutModalCancelButtonId"
          data-testid={'myProfileLogOutModalCancelButtonTestId'}
        >
          {language?.cancel}
        </MuiButton>
      </Box>
    </Box>
  );
  return (
    <Modal
      isOpen={logOutModalVisible}
      id="myProfileLogOutModalId"
      data-testid={'myProfileLogOutModalTestId'}
      isCloseButtonOption={true}
      onClose={onClickCloseLogOutModal}
      title={language?.confirmLogout}
    >
      {logOutUi}
    </Modal>
  );
}
