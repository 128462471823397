import React from "react";
import {
	Box,
	Column,
} from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import PageLoader from "@mono-farmart-web/farmart-web-common/MuiComponents/organisms/pageLoader/PageLoader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import moment from "moment";
import DeliveryDetails from "../DeliveryDetails";
import HeroMessageCover from "../HeroMessageCover";
import MessageWrapper from "../MessageWrapper";
import TruckDriverDetailCard from "../TruckDriverDetailCard";

interface TripCancelledProps {
	onOpenDialog?: () => void;
	loader?: boolean | null;
	payload?: any; // TYPE TO BE SPECIFIED LATER ON;
	language?: any;
	tripCancelledDueToNoConsent?: boolean;
}

const TripCancelled = (props: TripCancelledProps) => {
	const data = props?.payload;
	const {
		language,
		payload: { dispatchedDate },
	} = props;

	const notifIcon = "disabled";
	const notifMessage = language?.tripCancelledWithin24Hrs;

	if (props?.loader) return <PageLoader />;

	return (
		<Box bgcolor={"neutral.softBg"}>
			<Column gap={2}>
				{/* SUCCESS/FAILRUE MESSAGE COMPONENT */}
				<HeroMessageCover
					titleId="tripcancelled_trip_cancelledscreen"
					dateStringId=""
					icon={
						<DeleteForeverIcon
							id="bin_icon_trip_cancelledscreen"
							sx={{ width: "30px", height: "30px", color: "text.primary" }}
						/>
					}
					title={language?.tripCancelled}
					dateString={moment(dispatchedDate)?.format(
						"dddd, DD MMMM YYYY h:mm A",
					)}
				/>

				{/* EXTRA DETAILS */}
				<DeliveryDetails
					title={language?.truckDriverDetails}
					language={props?.language}
					deliveredTo={data?.receiver_detail?.company_name}
					dispatchedLocation={`${data?.source_address?.district}, ${data?.source_address?.state}`}
					dispatchedDate={`${moment(
						data?.source_address?.dispatched_at,
					)?.format("DD MMM YYYY")} | ${moment(
						data?.source_address?.expected_delivery,
					)?.format("h:mm A")}`}
					deliveryLocation={`${data?.destination_address?.district}, ${data?.destination_address?.state}`}
					deliveryDate={`${moment(
						data?.destination_address?.expected_delivery,
					)?.format("DD MMM YYYY")}`}
					estimatedDistance={`${data?.estimated_distance} kms`}
					showTripCancelMessage={[
						props?.tripCancelledDueToNoConsent,
						<MessageWrapper
							key={1}
							sx={{ padding: "20px 16px 0px 16px" }}
							type={notifIcon}
							message={notifMessage}
						/>,
					]}
				/>

				{/* DRIVER DETAILS */}
				<TruckDriverDetailCard
					allowEditDetails={false}
					title={language?.truckDriverDetails}
					payload={data?.vehicle_details}
					onOpenDialog={props?.onOpenDialog}
				/>
			</Column>
		</Box>
	);
};

export default TripCancelled;
