import React from "react";
import {
	Box,
	Column,
	Row,
} from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";
import DriverNumber from "../DriverNumber";
import ProfileCard from "../ProfileCard";

interface TruckDriverDetailCardProps {
	title?: string;
	onOpenDialog?: () => void;
	payload?: any; // TYPE TO BE DEFINED LATER ON;
	allowEditDetails?: boolean;
}

const TruckDriverDetailCard = (props: TruckDriverDetailCardProps) => {
	const vehicleDetails = props?.payload;

	return (
		<Box>
			<Column>
				{props?.title ? (
					<Box
						sx={{
							padding: "16px 16px 12px 16px",
						}}
					>
						<Row justifyContent={"flex-start"} alignItems={"center"}>
							<Text
								id="truck&driverdetail_trip_cancelledscreen"
								level="title-sm"
								textColor="text.secondary"
								fontWeight={600}
							>
								{props?.title}
							</Text>
						</Row>
					</Box>
				) : null}
				<Box
					sx={{
						padding: props?.title ? "12px 16px 16px 16px" : 0,
					}}
				>
					<Column gap={4}>
						<ProfileCard
							allowEditDetails={props?.allowEditDetails}
							onOpenDialog={props?.onOpenDialog}
							data={{
								driverName: vehicleDetails?.driver_name,
								driverNumber: vehicleDetails?.driver_number,
							}}
						/>
						<DriverNumber
							data={{
								vehicleNumber: vehicleDetails?.vehicle_number,
							}}
						/>
					</Column>
				</Box>
			</Column>
		</Box>
	);
};

export default TruckDriverDetailCard;
