import React, { useState } from 'react';
import Style from './style.module.css';
import Button from '@mui/joy/Button';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import { SaudaDetailResp } from '@mono-farmart-web/farmart-web-common/modal/Traders/types';

interface EmptyStateProps {
  docType?: number;
  language: { [key: string]: any };
  handleFileChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    index?: number,
  ) => void;
  handleUploadButtonClick?: (from: string, index?: number) => void;
  fileInputRef: any;
  saudaDetailData?: SaudaDetailResp;
  invoiceImage: string;
  weightSlipImage: string;
}

const EmptyState = ({
  docType,
  language,
  handleFileChange,
  handleUploadButtonClick,
  fileInputRef,
  saudaDetailData,
  invoiceImage,
  weightSlipImage,
}: EmptyStateProps) => {
  const [id, setId] = useState(docType); // use id to select the type of doc invoice_documents : 1 ,weight_slip_documents : 2 , mandi_documents : 3
  const template = language?.bill_to_text || '';
  const values = {
    deliveryDistrict: `${saudaDetailData?.buyer_district_name}`,
    deliveryState: `${saudaDetailData?.buyer_state_name}`,
    farmartGST: `${saudaDetailData?.farmart_gst_no}`,
  };

  const replacePlaceholders = ({ template, values }) => {
    return template
      .split(/(<[^>]+>)/)
      .map((part, index) =>
        part.startsWith('<') && part.endsWith('>') ? (
          <span key={index}>{values[part.replace(/<|>/g, '')]}</span>
        ) : (
          <span key={index}>{part}</span>
        ),
      );
  };

  const invoice = (
    <div>
      <div className={Style.imageContainer}>
        <img
          src={invoiceImage}
          height={254}
          width={154}
          style={{ maxWidth: 154, maxHeight: 254 }}
          alt="invoice Empty State"
          loading="eager"
        />
      </div>
      <div className={Style.infoContainerBorder}>
        <InfoRoundedIcon sx={{ color: '#32383E' }} />
        <label className={Style.infoText}>
          {replacePlaceholders({ template, values })}
        </label>
      </div>
    </div>
  );
  const weightSlip = (
    <div>
      <div className={Style.imageContainer}>
        <img
          src={weightSlipImage}
          width={227}
          height={250}
          style={{ maxWidth: 227 }}
          alt="WeightSlip Empty State"
          loading="eager"
        />
      </div>
    </div>
  );

  const getSectionUI = () => {
    if (id === 1) {
      return invoice;
    } else if (id == 2) {
      return weightSlip;
    } else {
      return;
    }
  };

  return (
    <div className={Style.uploadContainer}>
      {getSectionUI()}
      <div className={Style.uploadContainerBorder}>
        <input
          type="file"
          accept=".pdf, .jpg, .jpeg, .png"
          multiple
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
        <Button
          sx={{
            backgroundColor: '#B2E0D8',
            color: '#007B65',
            borderRadius: '8px',
          }}
          className={Style.custom}
          onClick={() => {
            handleUploadButtonClick('emptyState');
          }}
          size={'lg'}
          id="onClickUploadButtonEmptyState"
        >
          <UploadRoundedIcon />
          {language?.add_document}
        </Button>
      </div>
      <label className={Style.uploadContainerText}>
        {language?.supportedFormatsText}
      </label>
    </div>
  );
};

export default EmptyState;
