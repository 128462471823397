import React from 'react';
import DocumentUpload from '../../Pages/DocumentUpload/DocumentUpload';
import UseDocumentUpload from '@mono-farmart-web/farmart-web-common/hooks/DocumentUpload/documentUpload';

export default function documentUpload({}) {
  const {
    docType,
    onClickBackDocumentUpload,
    language,
    showDocumentUploadFooter,
    showDocumentUploadAlert,
    doneDocumentUpload,
    handleFileChange,
    handleUploadButtonClick,
    fileInputRef,
    documents,
    preViewDocument,
    selectedDocument,
    showCancelUploadModal,
    toggleCancelUploadModal,
    onCLickCancelUpload,
    removeDocument,
    onPressClosePreview,
    multiFileUpload,
    reUploadInputRef,
    handleReUploadChange,
    handleReUploadButtonClick,
    disableDone,
    deleteDocVisible,
    documentUploadToastPopup,
    reuploadVisible,
    currentPreviewIndex,
    fileLengthAlert,
    setFileLengthAlert,
    initialPageLoading,
    saudaDetailData,
    isError,
    fileSizeExceededToast,
    fileFormatNotSupported,
    setIsError,
    uploadDocAssets,
  } = UseDocumentUpload();
  return (
    <DocumentUpload
      docType={docType}
      onClickBack={onClickBackDocumentUpload}
      language={language}
      showDocumentUploadFooter={showDocumentUploadFooter}
      showDocumentUploadAlert={showDocumentUploadAlert}
      doneDocumentUpload={doneDocumentUpload}
      handleFileChange={handleFileChange}
      handleUploadButtonClick={handleUploadButtonClick}
      fileInputRef={fileInputRef}
      documents={documents}
      preViewDocument={preViewDocument}
      selectedDocument={selectedDocument}
      showCancelUploadModal={showCancelUploadModal}
      toggleCancelUploadModal={toggleCancelUploadModal}
      onCLickCancelUpload={onCLickCancelUpload}
      removeDocument={removeDocument}
      onPressClosePreview={onPressClosePreview}
      multiFileUpload={multiFileUpload}
      reUploadInputRef={reUploadInputRef}
      handleReUploadChange={handleReUploadChange}
      handleReUploadButtonClick={handleReUploadButtonClick}
      disableDone={disableDone}
      deleteDocVisible={deleteDocVisible}
      documentUploadToastPopup={documentUploadToastPopup}
      reuploadVisible={reuploadVisible}
      currentPreviewIndex={currentPreviewIndex}
      fileLengthAlert={fileLengthAlert}
      setFileLengthAlert={setFileLengthAlert}
      initialPageLoading={initialPageLoading}
      isError={isError}
      setIsError={setIsError}
      saudaDetailData={saudaDetailData}
      fileSizeExceededToast={fileSizeExceededToast}
      fileFormatNotSupported={fileFormatNotSupported}
      uploadDocAssets={uploadDocAssets}
    />
  );
}
