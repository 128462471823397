const DocumentType = new Map();

DocumentType.set(1, 'invoice_docs');
DocumentType.set(2, 'weight_slip');
DocumentType.set(3, 'mandi_tax');
DocumentType.set(4, 'buyerGRN');
DocumentType.set(5, 'unloadingWeightSlip')


export default DocumentType;
