const TruckStatus = new Map();

TruckStatus.set('truck_dispatched', 1);
TruckStatus.set('truck_unloaded', 2);
TruckStatus.set('buyer_quality_report', 3);
TruckStatus.set('truck_rejected', 4);
TruckStatus.set('document_approval_pending', 5)

export default TruckStatus;

const TruckStatusEnum = new Map();

TruckStatusEnum.set('TRUCK_DISPATCHED', 1);
TruckStatusEnum.set('TRUCK_UNLOADED', 2);
TruckStatusEnum.set('BUYER_QUALITY_REPORT', 3);
TruckStatusEnum.set('TRUCK_REJECTED', 4);

export {
  TruckStatusEnum
}
