/* This code snippet is a TypeScript React functional component named `AddFarmer`. Here is a breakdown
of what it does: */
import { Box } from '@mui/joy';
import React from 'react';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';

/* The `interface AddFarmerProps` is defining the prop types that the `AddFarmer` functional component
expects to receive. Here is a breakdown of each property: */
interface AddFarmerProps {
  savingLoading: boolean;
  saveFarmer: () => void;
  selectedCount: number;
  language: { [key: string]: string };
}
export default function AddFarmer(props: AddFarmerProps) {
  /* The line `const { saveFarmer, savingLoading, selectedCount, language } = props;` is using object
  destructuring to extract specific properties from the `props` object passed to the `AddFarmer`
  functional component. */
  const { saveFarmer, savingLoading, selectedCount, language } = props;

  return (
    <>
      {selectedCount ? (
        /* This part of the code is rendering a `Box` component from the Material-UI library with specific
   styling properties. Inside the `Box` component, there is a `MuiButton` component being rendered.
   Here is a breakdown of what each property is doing: */
        <Box
          position={'fixed'}
          display={'flex'}
          bottom={0}
          width={'100%'}
          bgcolor={'background.body'}
          borderTop={1}
          borderColor={'neutral.outlinedBorder'}
        >
          <MuiButton
            style={{ margin: '16px 16px' }}
            variant="solid"
            size="lg"
            color="primary"
            onClick={saveFarmer}
            loading={savingLoading}
          >
            {language?.heading} {selectedCount}
          </MuiButton>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}
