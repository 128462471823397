import { usePlatfromData } from '../CrossPlatformActionHandler/platformChecker';
import PlatForms from '../../constant/platFormEnums';
import { createBridge } from '@mono-farmart-web/rpc-bridge';
import bridgeFunctions from '../../utils/bridge/index';

export default function useClearUserLocationEvery24Hours() {
  const platformData = usePlatfromData();
  function clearLocationIfNeed() {
    const bridge = createBridge();
    try {
      const now = new Date();
      const nowString = now.toISOString().split('T')[0]; // Get the date in YYYY-MM-DD format

      const lastClearedDate = localStorage.getItem('lastClearedDate');

      if (lastClearedDate && lastClearedDate === nowString) {
        // If the last cleared date is today, do nothing
        return;
      } else {
        if (platformData?.platform === PlatForms?.get('application')) {
          const params = { key: 'userNewLocation' };
          bridge.sendRequestToNative(
            'removeNativeAppAsyncStorageListener',
            params,
            (response: any) => {
              bridgeFunctions.PrintLog(response);
            },
          );
        } else {
          // Clear the userLocation key
          localStorage.removeItem('lat');
          localStorage.removeItem('long');
          localStorage.removeItem('stateName');
          localStorage.removeItem('districtName');
          localStorage.removeItem('stateId');
          localStorage.removeItem('districtId');
        }
      }
      // Update the last cleared date to today
      localStorage.setItem('lastClearedDate', nowString);
    } catch (error) {
      console.error('Failed to clear userLocation key or update date:', error);
    }
  }
  return { clearLocationIfNeed };
}
