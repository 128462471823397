import React from 'react';
import Styles from './style.module.css';

import Header from './Header/Header';
import SmsBalance from './SmsBalance/SmsBalance';

import HisoryList from './SmsHistoryList/HisoryList';

import { SmsHisoryProps } from '@mono-farmart-web/farmart-web-common/hooks/SmsHistoryHook/types';
import { Box } from '@mui/joy';
import Loading from '@mono-farmart-web/farmart-web-common/ComponentV2/Loading';
import ShopPromotionModal from '@mono-farmart-web/farmart-web-common/ComponentV2/ShopPromotionModal';
import InfiniteScroll from  '@mono-farmart-web/farmart-web-common/Components/InfintyScroll';
import Loader from '@mono-farmart-web/farmart-web-common/ComponentV2/Loading';
import MuiSkeleton from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';

export default function AddFarmerView(props: SmsHisoryProps) {
  const {
    onPressBack,
    language,
    onContactUs,
    smsHistoryData,
    onClickInfo,
    onClickSms,
    smsBalance,
    initialLoading,
    onCloseModal,
    showShopPromotionModal,
    fetchMoreData,
  } = props;

  function renderLoader() {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        paddingTop={3}
      >
        <Loader size={30} color="grey" marginTop={20} />
      </Box>
    );
  }

  return (
    <Box className={Styles.container} bgcolor={'background.body'}>
      <Header
        onContactUs={onContactUs}
        onPressBack={onPressBack}
        language={language}
        onClickInfo={onClickInfo}
      />

      {initialLoading ? (
        <Box>
          <Box
            margin={4}
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            gap={'12px'}
          >
            <MuiSkeleton
              variant="rectangular"
              sx={{
                height: '20px',
                width: '40%', // This will take full width of the Box
                borderRadius: '8px',
              }}
            />
            <MuiSkeleton
              variant="rectangular"
              sx={{
                height: '30px',
                width: '20%', // This will take full width of the Box
                borderRadius: '8px',
              }}
            />
          </Box>
          <Box
            display="flex" // Use flexbox for alignment
            justifyContent="center" // Center horizontally
            alignItems="center" // Center vertically
            margin={4}
            flexDirection="column"
            gap={'12px'}
          >
            <MuiSkeleton
              itemNumber={8}
              variant="rectangular"
              sx={{
                height: '70px',
                width: '100%', // This will take full width of the Box
                borderRadius: '8px',
              }}
            />
          </Box>
        </Box>
      ) : (
        <>
          <SmsBalance language={language} smsBalance={smsBalance} />

          <ShopPromotionModal
            onClose={onCloseModal}
            openModal={showShopPromotionModal}
          />
          <InfiniteScroll
            onScrollToEnd={fetchMoreData}
            loading={false}
            loadingElement={renderLoader()}
          >
            <HisoryList
              smsHistoryData={smsHistoryData}
              onClickSms={onClickSms}
              language={language}
              fetchMoreData={fetchMoreData}
            />
          </InfiniteScroll>
        </>
      )}
    </Box>
  );
}
