import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import type { CSSProperties } from "react";
import { Box, Column, Row } from "../../atoms/layout";
import { Text } from "../../atoms/typography";

interface FormTitleProps {
	title: string;
	description?: string;
	align?: string;
	style?: CSSProperties;
	closeButton?: boolean;
	id?: string;
	onCloseDialog?: () => void;
	titleId?: string;
	descriptionId?: string;
}

const FormTitle = (props: FormTitleProps) => {
	let alignment = "center";
	if (props?.align) {
		if (props?.align === "left") {
			alignment = "flex-start";
		} else if (props?.align === "right") {
			alignment = "flex-end";
		}
	}
	return (
		<Box
			paddingTop={4}
			paddingLeft={4}
			paddingRight={4}
			paddingBottom={2.5}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			position={"relative"}
			sx={{
				...props?.style,
				borderBottom: "1px solid #DDE7EE",
				borderTopLeftRadius: "24px",
				borderTopRightRadius: "24px",
			}}
		>
			{props?.closeButton ? (
				<Box
					position={"absolute"}
					top={"-70px"}
					width={"45px"}
					height={"45px"}
					borderRadius={"50%"}
					bgcolor="common.white"
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					onClick={props?.onCloseDialog}
				>
					<Row alignItems={"center"} justifyContent={"center"}>
						<CloseRoundedIcon sx={{ width: "30px", height: "30px" }} />
					</Row>
				</Box>
			) : null}
			<Column alignItems={alignment} justifyContent={"center"} gap={1}>
				<Text
					level="title-md"
					textColor="text.primary"
					id={props?.id || props?.titleId}
				>
					{props?.title}
				</Text>
				{props?.description ? (
					<Text
						id={props?.descriptionId}
						level="body-xxs"
						textColor="text.secondary"
						width={"328px"}
						fontWeight={500}
						lineHeight={"16px"}
					>
						{props?.description}
					</Text>
				) : null}
			</Column>
		</Box>
	);
};

export default FormTitle;
