import React from "react";
import type { CSSProperties } from "react";
import { Box } from "../../atoms/layout";
import { Text } from "../../atoms/typography";

interface FormHeadingProps {
	text: string;
	id?: string;
	sx?: CSSProperties;
}

const FormHeading = (props: FormHeadingProps) => {
	return (
		<Box
			textAlign={"center"}
			sx={{
				padding: "24px 16px",
				...props?.sx,
			}}
		>
			<Text
				textColor="text.secondary"
				id={props?.id}
				fontWeight={600}
				level="title-sm"
			>
				{props?.text}
			</Text>
		</Box>
	);
};

export default FormHeading;
