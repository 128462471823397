import React from 'react';
import Styles from './style.module.css';

import { AcknowledgementProps } from '@mono-farmart-web/farmart-web-common/hooks/AcknowledgementHooks/types';

import { Box } from '@mui/joy';
import AcknowledgementInfo from './AcknowledgementLabel/AcknowledgementInfo';

/**
 * Renders the AddFarmerView component.
 *
 * @param {AcknowledgementProps} props - The props for the AddFarmerView component.
 * @returns {JSX.Element} The rendered AddFarmerView component.
 */
export default function AddFarmerView(props: AcknowledgementProps) {
  const { onPressBack, language } = props;

  return (
    <Box className={Styles.container} bgcolor={'background.body'}>
      <AcknowledgementInfo language={language} onPressBack={onPressBack} />
    </Box>
  );
}
