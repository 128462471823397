/* This code snippet is a React functional component named `perosna` that renders a modal window
displaying a list of persona cards. Here's a breakdown of what the code is doing: */
import React from 'react';
import Modal from '@mono-farmart-web/farmart-web-common/ComponentV2/Modal';
import Styles from './style.module.css';
import PersonaCard from './PersonaCard';
import OccupationImages from '@mono-farmart-web/farmart-web-common/constant/images';

export default function perosna({
  personaModalVisible, // Flag indicating whether the persona modal is visible
  onClickPersonaCard, // Callback function for handling click on persona cards
  userSelectedPersona, // ID of the selected persona
  onClickProceedPersona, // Callback function for proceeding with the selected persona
  usePerosnaProceedButtonLoading, // Flag indicating loading state of proceed button
  language, // Language object for translations
  onClickpersonaRole, // Callback function for closing the persona modal
}) {
  // Generate persona cards based on OccupationImages data
  const userPersona = (
    <div className={Styles.personaContainer}>
      <div className={Styles.optionTextContainer}>
        <label className={Styles.labelText1}>{language?.whatYouDo}</label>
        <label className={Styles.labelText2}>
          {language?.applicableProfession}
        </label>
      </div>
      <div className={Styles.personaCardListContainer}>
        {Object?.keys(OccupationImages?.occupationImages)?.map(function (
          key,
          index,
        ) {
          return (
            <PersonaCard
              key={index + 'persona'}
              personaImages={OccupationImages?.occupationImages[key]}
              onClickPersonaCard={onClickPersonaCard}
              userSelectedPersona={userSelectedPersona}
              selectedPersonaKey={key}
              language={language}
              index={index}
            />
          );
        })}
      </div>
    </div>
  );

  // Render the modal with userPersona content
  return (
    <Modal
      isOpen={personaModalVisible} // Determine if the modal is open
      onClose={onClickpersonaRole} // Close modal function
      children={userPersona} // Content to be rendered within the modal
      title={language?.chooseRole} // Title of the modal
      isCloseButtonOption={true} // Option to show close button
      id={'homeScreenUserPersonModalId'} // ID for the modal
    />
  );
}
