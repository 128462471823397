import React, { Dispatch, SetStateAction } from 'react';
import type {
  ActiveTrade,
  ExpiredTrade,
  PromotionalMediaList,
} from '@mono-farmart-web/farmart-web-common/modal/Traders/types';
import { BuyerDataProps } from '../../hooks/types';

import type { StateDistrict } from '../../hooks/types';
import ComingSoon from './comingSoon/ComingSoon';
import NoLocation from './NoLocationPermission/NoLocation';
import Styles from './style.module.css';
import Header from './Header/Header';
import TradeFilter from './TradeFilter/TradeFilter';
import LocationModal from './LocationAccess/LocationModal';
import SelectCropModal from './SelectCropModal';
import SelectVarietyModal from './SelectVarietyModal';
import SelectLocationModal from './SelectLocationModal';
import TradeList from './TradeList';
import type {
  Crop,
  CropVariety,
  Location,
} from '@mono-farmart-web/farmart-web-common/modal/Traders/types';
import Toast from '@mono-farmart-web/farmart-web-common/ComponentV2/Toast';
import { Box } from '@mui/joy';
import CommonErrorState from '@mono-farmart-web/farmart-web-common/ComponentsV4/CommonErrorState';
import TradeSkeleton from '@mono-farmart-web/farmart-web-common/ComponentsV4/TradeCardSkeleton';

interface TradeProps {
  serviciableLoading: boolean;
  serviciability: boolean;
  isLocationModal: boolean;
  onClickAllowLocationModal: () => void;
  otpModalVisible: boolean;
  onClickLoginProceed: () => void;
  mobileNumberModalVisible: boolean;
  getMobileNumber: (number: string) => void;
  getUserOtpFromInput: (number: string) => void;
  userOtp: string;
  onClickValidateOtpProceed: (number: number) => void;
  changeMobileNumber: () => void;
  personaModalVisible: boolean;
  onClickPersonaCard: (number: string) => () => void;
  userSelectedPersona: string;
  onClickProceedPersona: () => void;
  resendOtp: () => void;
  buyersList: ActiveTrade[];
  totalRecords: number;
  getMoreTradeListData: () => void;
  error: boolean;
  loading: boolean;
  userMobileNumber: string;
  language: { [key: string]: string };
  locationModalLoading: boolean;
  cropList: Crop[];
  cropVarietyList: CropVariety[];
  locationList: Location[];
  cropListSearchParam: string;
  setCropListSearchParam: React.Dispatch<React.SetStateAction<string>>;
  cropVarietyListCropId: number;
  setCropVarietyListCropId: React.Dispatch<React.SetStateAction<number>>;
  cropVarietyListSearchParam: string;
  setCropVarietyListSearchParam: React.Dispatch<React.SetStateAction<string>>;
  cropVarietyListPage: number;
  setVarietyCropListPage: React.Dispatch<React.SetStateAction<number>>;
  cropVarietyListLimit: number;
  setCropVarietyListLimit: React.Dispatch<React.SetStateAction<number>>;
  buyerLocationListSearchParam: string;
  setBuyerLocationListSearchParam: React.Dispatch<React.SetStateAction<string>>;
  buyerLocationListPage: number;
  setBuyerLocationListPage: React.Dispatch<React.SetStateAction<number>>;
  cropListModal: boolean;
  handleCropListModal: () => void;
  cropVarietyListModal: boolean;
  handleCropVarietyListModal: () => void;
  buyerLocationListModal: boolean;
  handleBuyerLocationListModal: () => void;
  activeVariety: number[];
  activeCrop: Crop;
  handleCropSelection: (cropId: Crop) => void;
  handleVarietySelection: (
    cropVarietyId: number,
    cropVarietyName: string,
  ) => void;
  handleLocationSelection: (locationId: string, districtName: string) => void;
  cropLoading: boolean;
  activeLocations: string[];
  cropVarietyLoading: boolean;
  locationLoading: boolean;
  handleCropSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  locationToastPopup: boolean;
  handleLocationToastPopup: () => void;
  numberCopyToast: boolean;
  handleNumberCopyToast: (
    phoneNumber: number,
    cropName: string,
    buyerName: string,
  ) => void;
  userBlocked: boolean;
  onContactUs: () => void;
  onClickHelp: () => void;
  onClickLocationFilterApply: () => void;
  buyersListLoading: boolean;
  currentLanguageId: string;
  handleCropVarietySearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCLickViewDetail: (
    id: string,
    cropName: string,
    buyerName: string,
    requestSauda: boolean,
  ) => void;
  handleLocationSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loadMoreLocationList: () => void;
  disableResend: boolean;
  setDisableResend: Dispatch<SetStateAction<boolean>>;
  activeTradeList: ActiveTrade[];
  expiredTradeList: ExpiredTrade[];
  onClickMySaudaCard: () => void;
  tradeListPageNumber: number;
  saudaRequestToastPopup: boolean;
  handleSaudaRequestToastPopup: () => void;
  masterStateDistrict: StateDistrict;
  onChangeLocation: () => void;
  isLocationPermission: boolean;
  promotionalMediaList: PromotionalMediaList[];
  tradeScreenAssets: { [key: string]: string };
  onClickRemoveCropFilter: () => void;
  onClickRemoveVarietyFilter: () => void;
  onClickRemoveLocationFilter: () => void;
  locationFilterApplied: boolean;
  isModalErrorVisible: boolean;
  onRefreshCropList: () => void;
  onRefreshCropVarietyList: () => void;
  onRefreshLocationList: () => void;
  isErrorScreenVisible: boolean;
  onRefreshScreen: () => void;
  onClickVarietyFilterApply: () => void;
  variertFilterApplied: boolean;
  reloadTrades: () => Promise<void>;
  searchedBuyerDetail: BuyerDataProps;
  onClickRemoveBuyerSearch: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  onClickBuyerSearch: () => void;
  activePriceCount: number;
  timeLanguage: { [key: string]: string };
}

export default function TraderList(props: TradeProps) {
  const {
    serviciableLoading,
    serviciability,
    isLocationModal,
    onClickAllowLocationModal,
    buyersList,
    totalRecords,
    getMoreTradeListData,
    language,
    locationModalLoading,
    cropList,
    cropVarietyList,
    locationList,
    cropListSearchParam,
    cropVarietyListSearchParam,
    buyerLocationListSearchParam,
    cropListModal,
    handleCropListModal,
    cropVarietyListModal,
    handleCropVarietyListModal,
    buyerLocationListModal,
    handleBuyerLocationListModal,
    activeVariety,
    activeCrop,
    handleCropSelection,
    handleVarietySelection,
    cropLoading,
    cropVarietyLoading,
    activeLocations,
    handleLocationSelection,
    locationLoading,
    handleCropSearch,
    locationToastPopup,
    numberCopyToast,
    handleNumberCopyToast,
    onContactUs,
    onClickHelp,
    onClickLocationFilterApply,
    buyersListLoading,
    currentLanguageId,
    handleCropVarietySearch,
    onCLickViewDetail,
    handleLocationSearch,
    loadMoreLocationList,
    activeTradeList,
    expiredTradeList,
    tradeListPageNumber,
    saudaRequestToastPopup,
    handleSaudaRequestToastPopup,
    masterStateDistrict,
    onChangeLocation,
    isLocationPermission,
    promotionalMediaList,
    tradeScreenAssets,
    onClickRemoveCropFilter,
    onClickRemoveVarietyFilter,
    onClickRemoveLocationFilter,
    locationFilterApplied,
    isModalErrorVisible,
    onRefreshCropList,
    onRefreshCropVarietyList,
    onRefreshLocationList,
    onRefreshScreen,
    isErrorScreenVisible,
    onClickVarietyFilterApply,
    variertFilterApplied,
    searchedBuyerDetail,
    onClickRemoveBuyerSearch,
    onClickBuyerSearch,
    reloadTrades,
    activePriceCount,
    timeLanguage,
  } = props;

  return (
    <div className={Styles.container}>
      <Header
        language={language}
        onClickHelp={onClickHelp}
        masterStateDistrict={masterStateDistrict}
        onChangeLocation={onChangeLocation}
        whatsappIcon={tradeScreenAssets.whatsappIcon}
      />
      <Toast
        isError={true}
        message={language?.locationEnableText}
        isOpen={locationToastPopup}
      />
      <Toast
        isError={false}
        message={language?.mobileNumberCopytext}
        isOpen={numberCopyToast}
      />
      <Toast
        isError={true}
        message={language?.saudaAlreadyRequested}
        isOpen={saudaRequestToastPopup}
      />
      <SelectCropModal
        isOpen={cropListModal}
        handleCropListModal={handleCropListModal}
        cropList={cropList}
        activeCrop={activeCrop}
        handleCropSelection={handleCropSelection}
        cropLoading={cropLoading}
        cropListSearchParam={cropListSearchParam}
        handleCropSearch={handleCropSearch}
        language={language}
        searchIcon={tradeScreenAssets.searchIcon}
        isModalErrorVisible={isModalErrorVisible}
        onRefreshCropList={onRefreshCropList}
        onClickHelp={onClickHelp}
      />
      <SelectVarietyModal
        isOpen={cropVarietyListModal}
        handleCropVarietyListModal={handleCropVarietyListModal}
        cropVarietyList={cropVarietyList}
        activeVariety={activeVariety}
        handleVarietySelection={handleVarietySelection}
        cropVarietyLoading={cropVarietyLoading}
        activeCrop={activeCrop}
        handleCropVarietySearch={handleCropVarietySearch}
        cropVarietyListSearchParam={cropVarietyListSearchParam}
        language={language}
        searchIcon={tradeScreenAssets.searchIcon}
        isModalErrorVisible={isModalErrorVisible}
        onRefreshCropVarietyList={onRefreshCropVarietyList}
        onClickHelp={onClickHelp}
        onClickVarietyFilterApply={onClickVarietyFilterApply}
      />
      <SelectLocationModal
        isOpen={buyerLocationListModal}
        handleBuyerLocationListModal={handleBuyerLocationListModal}
        locationList={locationList}
        handleLocationSelection={handleLocationSelection}
        activeLocations={activeLocations}
        locationLoading={locationLoading}
        onClickLocationFilterApply={onClickLocationFilterApply}
        buyerLocationListSearchParam={buyerLocationListSearchParam}
        handleLocationSearch={handleLocationSearch}
        loadMoreLocationList={loadMoreLocationList}
        language={language}
        searchIcon={tradeScreenAssets.searchIcon}
        activeSvg={tradeScreenAssets.activeSvg}
        inactiveSvg={tradeScreenAssets.inactiveSvg}
        isModalErrorVisible={isModalErrorVisible}
        onRefreshLocationList={onRefreshLocationList}
        onClickHelp={onClickHelp}
      />

      <Box>
        {isLocationPermission ? (
          <Box>
            {isErrorScreenVisible ? (
              <Box>
                <CommonErrorState
                  onPressHelp={onClickHelp}
                  onPressRetry={onRefreshScreen}
                  imageId="alertimage_alerttradescreen"
                  onPressHelpTestId="help_button_alerttradescreen"
                  subHeadingTextId="unabletoprocess_text_alerttradescreen"
                  headingTextId="somethingwentwrong_text_alerttradescreen"
                  onPressRetryTestId="retry_button_alerttradescreen"
                />
              </Box>
            ) : (
              <div>
                {serviciableLoading ? (
                  <TradeSkeleton count={3} />
                ) : !serviciability &&
                  !serviciableLoading &&
                  !buyersListLoading &&
                  totalRecords === 0 ? (
                  <ComingSoon
                    language={language}
                    onContactUs={onContactUs}
                    tradeComingSoonImage={tradeScreenAssets.tradeComingSoon}
                  />
                ) : (
                  <Box>
                    <TradeFilter
                      language={language}
                      handleCropVarietyListModal={handleCropVarietyListModal}
                      handleCropListModal={handleCropListModal}
                      handleBuyerLocationListModal={
                        handleBuyerLocationListModal
                      }
                      activeCrop={activeCrop}
                      activeVariety={activeVariety}
                      activeLocations={activeLocations}
                      onClickRemoveCropFilter={onClickRemoveCropFilter}
                      onClickRemoveVarietyFilter={onClickRemoveVarietyFilter}
                      onClickRemoveLocationFilter={onClickRemoveLocationFilter}
                      locationFilterApplied={locationFilterApplied}
                      variertFilterApplied={variertFilterApplied}
                      searchedBuyerDetail={searchedBuyerDetail}
                      onClickBuyerSearch={onClickBuyerSearch}
                      onClickRemoveBuyerSearch={onClickRemoveBuyerSearch}
                    />

                    <TradeList
                      onCLickViewDetail={onCLickViewDetail}
                      currentLanguageId={currentLanguageId}
                      language={language}
                      buyersList={buyersList}
                      totalRecords={totalRecords}
                      getMoreTradeListData={getMoreTradeListData}
                      buyersListLoading={buyersListLoading}
                      handleNumberCopyToast={handleNumberCopyToast}
                      activeTradeList={activeTradeList}
                      expiredTradeList={expiredTradeList}
                      serviciability={serviciability}
                      tradeListPageNumber={tradeListPageNumber}
                      saudaRequestToastPopup={saudaRequestToastPopup}
                      handleSaudaRequestToastPopup={
                        handleSaudaRequestToastPopup
                      }
                      promotionalMediaList={promotionalMediaList}
                      noBuyerListImage={tradeScreenAssets.noBuyerList}
                      noExpiredListImage={tradeScreenAssets.noExpiredList}
                      discountCardBgSmall={
                        tradeScreenAssets.discountCardBgSmall
                      }
                      endDecorationLine={tradeScreenAssets.endDecorationLine}
                      startDecorationLine={
                        tradeScreenAssets.startDecorationLine
                      }
                      serviciableLoading={serviciableLoading}
                      reloadTrades={reloadTrades}
                      activePriceCount={activePriceCount}
                      timeLanguage={timeLanguage}
                    />
                  </Box>
                )}
              </div>
            )}
          </Box>
        ) : (
          <NoLocation language={language} onChangeLocation={onChangeLocation} />
        )}
      </Box>
      <LocationModal
        language={language}
        onClickAllowLocationModal={onClickAllowLocationModal}
        isLocationModal={isLocationModal}
        locationModalLoading={locationModalLoading}
        locationImage={tradeScreenAssets.locationImage}
      />
    </div>
  );
}
