/* export { default as getCommunityFeed } from "./models/getCommunityFeed"; */
//use above as example

/* export * from "./types"; */
import fetchTraderList from '../../../apis/Trader/fetchTraderList';

import { TradeList } from '../types';

interface PayloadType {
  page_no: number;
  limit: number;
  supplier_state_id: number;
  supplier_district_id: number;
  crop_id?: number | null;
  variety_id?: number | null;
  master_state_district_map?: Array<string> | null;
  buyer_id: number | null;
}

export default async function getTradeList(
  payload: PayloadType,
): Promise<TradeList> {
  const body = {
    ...payload,
  };
  try {
    const result = await fetchTraderList(body);

    if (!result.data.status) {
      throw result;
    }

    return result.data.data;
  } catch (error) {
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    throw error;
  }
}
