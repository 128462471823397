import getConfigByKeys from "../../config/config";
import { configKeys } from "../../config/types";
import handleHttpError from "../../utils/api/httpErrorMapping";
import HttpStatusCode from "../types/httpStatusCodesEnums.type";
import type UnifiedResponse from "../types/unifiedResponse.type";
import { apiEndPoints } from "../endpoints";

const { API_URL } = getConfigByKeys([configKeys.API_URL]);

export default async function guestLogin(payload): Promise<UnifiedResponse> {
	const path = apiEndPoints.guestLogin;
	const response = await fetch(`${API_URL}${path}`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(payload),
	});

	if (response.status !== HttpStatusCode.Ok) {
		throw handleHttpError(response.status, path);
	}

	const data = await response.json();
	return { data, response };
}
