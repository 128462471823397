import React from 'react';
import { Box } from '@mui/material';
import NoLocation from '../NoLocation/NoLocation';
import MuiSkeleton from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import Style from './style.module.css';
import { BuyerDataProps } from '../../../hooks/types';

interface LocationListProps {
  language: { [key: string]: string };
  buyerList: BuyerDataProps[];
  loading: boolean;
  searchedValue: string;
  onClickBuyerName: (buyerDetal: BuyerDataProps) => void;
  buyerSearchScreenAssets: { [key: string]: string };
}

function LoadingContainer() {
  return (
    <Box
      display="flex" // Use flexbox for alignment
      justifyContent="center" // Center horizontally
      flexDirection="column"
      gap={'4px'}
    >
      <MuiSkeleton
        itemNumber={1}
        variant="rectangular"
        sx={{
          height: '36px',
          width: '40%', // This will take full width of the Box
        }}
      />
      <MuiSkeleton
        itemNumber={1}
        variant="rectangular"
        sx={{
          height: '36px',
          width: '60%', // This will take full width of the Box
        }}
      />
    </Box>
  );
}

export default function LocationList(props: LocationListProps) {
  const {
    language,
    loading,
    buyerList,
    searchedValue,
    onClickBuyerName,
    buyerSearchScreenAssets,
  } = props;

  return (
    <Box margin={'8px 16px 0px 16px'}>
      {loading ? (
        <LoadingContainer />
      ) : buyerList?.length === 0 && searchedValue.length > 0 ? (
        <NoLocation
          language={language?.nobuyerFound}
          noLocationImage={buyerSearchScreenAssets?.noSearchedBuyerFound}
          imageId="nobuyerfound_image_searchscreen"
        />
      ) : searchedValue.length === 0 ? (
        <NoLocation
          language={language?.searchBuyer}
          noLocationImage={buyerSearchScreenAssets?.buyerSearch}
          imageId="searchbuyer_image_searchbuyerscreen"
          data-testid="searchbuyer_image_searchbuyerscreen"
        />
      ) : (
        <Box display={'flex'} gap={'4px'} flexDirection={'column'}>
          {buyerList.map((item, index) => (
            <Box
              height={'30px'}
              alignItems={'center'}
              display={'flex'}
              className={Style.buyerCard}
              onClick={() => onClickBuyerName(item)}
              id={`searchresult_${index}_searchscreen`}
              data-testid={`searchresult_${index}_searchscreen`}
            >
              <MuiTypography level="title-sm" textColor={'text.primary'}>
                {item?.buyer_name}
              </MuiTypography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
