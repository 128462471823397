import React from "react";
import type { ReactNode } from "react";
import Alert from "@mui/joy/Alert";
import type { AlertProps } from "@mui/joy/Alert";

interface IAlertWrapperProps extends AlertProps {
  children?: ReactNode;
  // You can define additional props here if needed
}

const AlertC: React.FC<IAlertWrapperProps> = ({ children, ...props }) => {
  return <Alert {...props}>{children}</Alert>;
};

export default AlertC;
