import React from 'react';
import Style from './style.module.css';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import {
  ArrowForwardRounded,
  SyncOutlined,
  CloseRounded,
  VerifiedRounded,
} from '@mui/icons-material';
import { Input, Button } from '@mui/joy';
import ArrowLeft from '@mono-farmart-web/farmart-web-common/Images/ArrowLeft.svg';
import ArrowRight from '@mono-farmart-web/farmart-web-common/Images/ArrowRight.svg';

interface AcknowledgementBrokerCodeProps {
  language: { [key: string]: any }; // Language settings for localization
  showBrokerCodeInputText?: boolean; // Flag to show broker code input text
  brokerCode?: string; // Optional broker code
  handleBrokerCodeInputChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void; // Function to handle broker code input change
  handleOnClickBrokerCodeInput?: () => void; // Function to handle broker code input click
  verifyBrokerCode?: () => void; // Function to verify broker code
  brokerCodeButtonLoading?: boolean; // Optional loading state for broker code button
  brokerCodeState?: number; // Optional state for broker code
  brokerFirmName?: string; // Optional broker firm name
  clearBrokerCodeInput?: () => void; // Function to clear broker code input
}

export default function BrokerCode({
  language,
  showBrokerCodeInputText,
  brokerCode,
  handleBrokerCodeInputChange,
  handleOnClickBrokerCodeInput,
  verifyBrokerCode,
  brokerCodeButtonLoading,
  brokerCodeState,
  brokerFirmName,
  clearBrokerCodeInput,
}: AcknowledgementBrokerCodeProps) {
  // Function to replace variables in a language string with actual values
  function replaceVariablesInString(
    valuesToReplace: { [key: string]: string },
    languageString: string,
  ) {
    let replacedString = languageString;

    Object.keys(valuesToReplace).forEach((variable) => {
      const regex = new RegExp(`\\{\\(${variable}\\)\\}`, 'g');
      replacedString = replacedString.replace(regex, valuesToReplace[variable]);
    });
    return replacedString;
  }

  // Function to get the text for broker code input based on its state
  function brokerCodeInputText() {
    switch (brokerCodeState) {
      case 0:
        return { text: '' };
      case 1:
        return {
          text: language?.unverifiedBrokerCode,
        };
      case 2:
        return {
          text: language?.invalidBrokerCode,
        };
      case 3:
        return {
          text: replaceVariablesInString(
            { variable1: brokerFirmName },
            language?.verifiedBrokerCode,
          ),
        };
      default:
        return { text: '' };
    }
  }

  // Function to render the appropriate icon at the end of the broker code input
  const renderEndDecorator = () => {
    if (brokerCodeState === 3) {
      return (
        <VerifiedRounded
          data-testid={'verifiedBrokerCodeIconId'}
          color="success"
        />
      );
    } else if (
      (brokerCodeState === 2 || brokerCodeState === 1) &&
      showBrokerCodeInputText
    ) {
      return (
        <div
          id="onClickCloseInputId"
          className={Style.brokerCodeInputCloseIcon}
        >
          <CloseRounded
            data-testid="onClickCloseInputId"
            onClick={() => clearBrokerCodeInput()}
          />
        </div>
      );
    }
  };

  // Function to get the color for broker code input based on its state
  function brokerCodeInputColor() {
    switch (brokerCodeState) {
      case 0:
        return 'neutral';
      case 1:
        return 'danger';
      case 2:
        return 'danger';
      case 3:
        return 'success';
      default:
        return 'neutral';
    }
  }

  return (
    <div className={Style.brokerCodeContainer}>
        <div className={Style.brokerCodeHeadingContainer}>
          <img
            src={ArrowLeft as unknown as string}
            className={Style.arrowLeftImage}
            alt="arrowLeft"
            loading="eager"
          />
          <MuiTypography level="body-xxs" className={Style.brokerCodeHeading}>
            {language?.doYouHaveBrokerCode}
          </MuiTypography>
          <img
            src={ArrowRight as unknown as string}
            className={Style.arrowRightImage}
            alt="arrowRight"
            loading="eager"
          />
        </div>
        {/* Broker code input section */}
        <div className={Style.brokerCodeInputContainer}>
          <Input
            placeholder={language?.enterBrokerCode}
            color={showBrokerCodeInputText ? brokerCodeInputColor() : 'neutral'}
            size="lg"
            className={Style.brokerCodeInput}
            endDecorator={renderEndDecorator()}
            value={brokerCode}
            onChange={handleBrokerCodeInputChange}
            onClick={handleOnClickBrokerCodeInput}
            sx={{
              input: {
                '&:focus': {
                  border: 'none',
                },
                //placeholder text style
                '&::placeholder': {
                  opacity: 0.64,
                  color: '#9FA6AD',
                },
              },
            }}
          />
          <Button
            sx={{ borderRadius: '8px' }}
            disabled={!brokerCode?.length}
            onClick={verifyBrokerCode}
            id="onClickVerifyBrokerCodeButtonId"
            data-testid="onClickVerifyBrokerCodeButtonId"
          >
            {brokerCodeButtonLoading ? ( //changing button icon on the basis of loding state
              <SyncOutlined />
            ) : (
              <ArrowForwardRounded />
            )}
          </Button>
        </div>
        {showBrokerCodeInputText && (
          <div className={Style.brokerCodeInputStatusText}>
            <MuiTypography
              level="body-xxs"
              color={brokerCodeState === 3 ? 'success' : 'danger'}
            >
              {brokerCodeInputText().text}
            </MuiTypography>
          </div>
        )}
      </div>
  );
}
