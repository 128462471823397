const formatDate = (isoDate) => {
  if (!isoDate) {
    return '';
  }

  const date = new Date(isoDate);

  // Format each part of the date
  const dayOfWeek = new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(date);
  const day = date.getDate();
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  const time = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(date);

  // Construct the desired format
  return `${dayOfWeek} ${month} ${day}, ${time}`;
};

export default formatDate;
