import React from 'react';
import Styles from './style.module.css';
import Modal from '@mono-farmart-web/farmart-web-common/ComponentV2/Modal';
import Loading from '@mono-farmart-web/farmart-web-common/ComponentV2/Loading';
import { Crop } from '@mono-farmart-web/farmart-web-common/modal/Traders/types';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';

import CommonErrorState from '@mono-farmart-web/farmart-web-common/ComponentsV4/CommonErrorState';
import { Box } from '@mui/joy';
interface PayloadType {
  isOpen: boolean;
  handleCropListModal: () => void;
  cropList: Crop[];
  activeCrop: Crop;
  handleCropSelection: (cropId: Crop) => void;
  cropLoading: boolean;
  cropListSearchParam: string;
  handleCropSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  language: { [key: string]: string };
  searchIcon: string;
  isModalErrorVisible: boolean;
  onRefreshCropList: () => void;
  onClickHelp: () => void;
}

const SelectCropModal = ({
  isOpen,
  handleCropListModal,
  cropList,
  activeCrop,
  handleCropSelection,
  cropLoading,
  cropListSearchParam,
  handleCropSearch,
  language,
  searchIcon,
  isModalErrorVisible,
  onRefreshCropList,
  onClickHelp,
}: PayloadType) => {
  const UiModal = (
    <div>
      {isModalErrorVisible ? (
        <Box margin={'8px 0px'}>
          <CommonErrorState
            onPressRetry={onRefreshCropList}
            onPressHelp={onClickHelp}
            type="modal"
            imageId="alertimage_alertmodal_tradescreen"
            subHeadingTextId="unabletoprocess_text_alertmodal_tradescreen"
            headingTextId="somethingwentwrong_text_alertmodal_tradescreen"
            onPressRetryTestId="retry_button_alertmodal_tradescreen"
          />
        </Box>
      ) : (
        <div className={Styles.wrapper}>
          <div className={Styles.searchWrapper}>
            <div className={Styles.searchField}>
              <div className={Styles.iconWrapper}>
                <img
                  src={searchIcon}
                  height={24}
                  width={25}
                  loading="eager"
                  alt="searchIcon"
                />
              </div>
              <input
                type="text"
                placeholder={language?.cropModalSearchPlaceholder}
                value={cropListSearchParam}
                onChange={handleCropSearch}
                className={Styles.searchInput}
              />
            </div>
          </div>
          <div className={Styles.optionWrapper}>
            {cropLoading ? (
              <div
                className={Styles.loadingContainer}
                data-testid="loadingSelectCropModalTradeList"
              >
                <Loading size={20} color="grey" loaderWidth={3} />
              </div>
            ) : (
              cropList?.length > 0 &&
              cropList.map((item, index) => (
                <label key={index} className={Styles.listItem}>
                  <div
                    className={Styles.itemName}
                    onClick={() => handleCropSelection(item)}
                  >
                    <div
                      className={
                        item?.id === activeCrop?.id
                          ? Styles.activeCrop
                          : Styles.inactiveCrop
                      }
                    >
                      {item?.crop_name}
                    </div>
                    <div>
                      {item?.id === activeCrop?.id ? (
                        <RadioButtonCheckedRoundedIcon
                          htmlColor="#008B74"
                          sx={{ height: 28, width: 28 }}
                        />
                      ) : (
                        <RadioButtonUncheckedRoundedIcon
                          htmlColor="#C2C7CF"
                          sx={{ height: 28, width: 28 }}
                        />
                      )}
                    </div>
                  </div>
                  <div></div>
                </label>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Modal
      onClose={handleCropListModal}
      isCloseButtonOption={true}
      title={language?.cropModalHeading}
      isOpen={isOpen}
      children={UiModal}
    />
  );
};

export default SelectCropModal;
