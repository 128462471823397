import React from 'react';
import { Box } from '@mui/joy';
import { ArrowForwardRounded, HelpRounded } from '@mui/icons-material';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';

// Define the interface for the props
interface SupportContainerProps {
  onClickContactSupport: () => void;
  language: { [key: string]: any };
}

export default function SupportContainer({
  onClickContactSupport,
  language,
}: SupportContainerProps) {
  return (
    <Box
      marginTop={5}
      marginX={4}
      marginBottom={18}
      onClick={onClickContactSupport}
      id={'smsLandingOnClickContactSupportId'}
      data-testid={'smsLandingOnClickContactSupportId'}
    >
      <MuiTypography
        level={'title-sm'}
        fontWeight={600}
        style={{ color: '#555E68' }}
      >
        {language?.needSupport}
      </MuiTypography>
      <Box
        borderRadius={8}
        marginTop={2}
        display={'flex'}
        justifyContent={'space-between'}
        justifySelf={'center'}
        alignItems={'center'}
        padding={2}
        sx={{
          backgroundColor: '#F0F4F8',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <HelpRounded data-testid="help-icon" />
          <Box paddingLeft={2}>
            <MuiTypography
              level={'title-sm'}
              fontWeight={600}
              paddingBottom={0.5}
            >
              {language?.contactUs}
            </MuiTypography>
            <MuiTypography level={'label-sm'} fontWeight={400} lineHeight={1.5}>
              {language?.reachOutForHelp}
            </MuiTypography>
          </Box>
        </Box>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <ArrowForwardRounded data-testid="arrow-icon" />
        </Box>
      </Box>
    </Box>
  );
}
