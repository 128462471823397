import React from 'react';
import Style from './style.module.css';
import DocumentTypeEnum from '@mono-farmart-web/farmart-web-common/constant/enums/docType';
import UnloadingDocumentEnums from '@mono-farmart-web/farmart-web-common/constant/enums/unloadingDocumentEnums';
import NavigateBack from '@mono-farmart-web/farmart-web-common/assets/svgComponet/navigateBack';

interface DocumentUploadHeaderProps {
  docType: number;
  onClickBack: () => void;
  language: { [key: string]: any };
}

const DocumentUploadHeader = ({
  docType,
  onClickBack,
  language,
}: DocumentUploadHeaderProps) => {
  return (
    <div className={Style.header}>
      <div onClick={onClickBack} id="DocumentUploadBackButton">
        <NavigateBack />
      </div>
      <div className={Style.headerText}>
        {DocumentTypeEnum.get(docType) ? language[DocumentTypeEnum.get(docType)] : UnloadingDocumentEnums.get(docType) ? language[UnloadingDocumentEnums.get(docType)] : ""}
      </div>
    </div>
  );
};

export default DocumentUploadHeader;
