import React from 'react';
import { Box } from '@mui/joy';
import MuiSkeleton from '../../MuiComponents/Skeleton';


export const filterSkeleton = () => {
  return (
    <Box display={'flex'} paddingY={3}>
      <MuiSkeleton
        itemNumber={6}
        sx={{
          height: '32px',
          width: '100px',
          borderRadius: '16px',
          marginRight: '8px',
        }}
      />
    </Box>
  );
};

export const postSkeleton = () => {
  return (
    <Box
      padding={2}
      borderRadius={8}
      sx={{ position: 'relative', zIndex: 1 }}
      marginBottom={20}
    >
      <Box borderColor={'red'}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'stretch'}>
          <MuiSkeleton
            sx={{ height: '36px', width: '36px', borderRadius: '8px' }}
          />
          <Box marginLeft={2}>
            <MuiSkeleton
              sx={{ height: '12px', width: '80px', borderRadius: '8px' }}
            />
            <MuiSkeleton
              sx={{
                height: '12px',
                width: '80px',
                marginTop: '8px',
                borderRadius: '8px',
              }}
            />
          </Box>
        </Box>
        <Box marginTop={2}>
          <MuiSkeleton
            sx={{ height: '12px', width: '100%', borderRadius: '8px' }}
          />
          <MuiSkeleton
            sx={{
              height: '12px',
              width: '90%',
              marginTop: '8px',
              borderRadius: '8px',
            }}
          />
        </Box>
      </Box>
      <MuiSkeleton sx={{ height: '148px', marginTop: '24px' }} />
      <MuiSkeleton sx={{ height: '1px', width: '100%', marginTop: '12px' }} />
      <Box
        marginTop={2}
        padding={1}
        display={'flex'}
        justifyContent={'space-between'}
      >
        {Array.from({ length: 3 }).map((_, index) => {
          return (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <MuiSkeleton
                key={index} // It's important to add a key prop for each element
                variant="circular"
                sx={{ height: '20px', width: '20px' }}
              />
              <MuiSkeleton
                sx={{
                  height: '12px',
                  width: '32px',
                  marginLeft: '8px',
                  borderRadius: '8px',
                }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
