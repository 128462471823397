import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import type { PageList } from "../../modal/community/communityModel";
import type { RootState } from "../../store";
import { usePlatfromData } from "../../hooks/CrossPlatformActionHandler/platformChecker";
import { trackEvent } from "../../utils/moengage";
import Events from "../../constant/MoengageEvents/index";

import {
	setPages,
	setCurrentPageNumber as setPageNo,
	setSelectedPageId as setSelectedPage,
	resetPostList,
	setCurrentPostPageNumber,
} from "../../modal/community/slice/community.slice";
import useAuthenticationChecker from "../../utils/authChecker";
import { apiEndPoints } from "../../apis/endpoints";

interface UsePages {
	pages: PageList;
	isFetchingAllPages: boolean;
	onClickPage: (id: number, name?: string) => () => void;
	selectedPage: number;
	fetchMorePages: () => void;
}

export default function usePages(): UsePages {
	const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();
	const dispatch = useDispatch();
	const platformData = usePlatfromData();

	const isFirstTimeScreenOpen = useRef(false);

	const startLoadTime = new Date().getTime();

	const { pages, pageNo, selectedPage } = useSelector((state: RootState) => {
		return {
			pages: state.community.pages,
			pageNo: state.community.pages.currentPageNumber,
			selectedPage: state.community.pages.selectedPageId,
		};
	});

	const [isFetchingAllPages, setIsFetchingAllPages] = useState(false);
	const tokenExpiredCount = 0;

	useEffect(() => {
		if (!isFirstTimeScreenOpen.current) {
			isFirstTimeScreenOpen.current = true;
			if (pages.pages.length) {
				return;
			}
		}

		fetchAllPages();
	}, [pageNo]);

	async function fetchAllPages() {
		setIsFetchingAllPages(true);

		try {
			const pageResult = await AuthenticationCheckerAndApiCaller({
				uri: apiEndPoints?.fetchPages,
				payload: {
					page_no: pageNo,
					limit: 10,
				},
				requestType: "get",
			});

			pageResult?.data?.data && dispatch(setPages(pageResult?.data?.data));
			setIsFetchingAllPages(false);
		} catch (error) {
			// Handle other errors
			console.log(error);
			setIsFetchingAllPages(false);
			// somethign went wrong
		} finally {
			setIsFetchingAllPages(false);
		}
	}

	function onClickPage(id: number, name?: string): () => void {
		return () => {
			trackEvent(Events?.onCommunityCategoryPageButtonClick, {
				From: "COMMUNITY_HOME_SCREEN",
				Page_name: name,
			});

			if (selectedPage !== id) {
				dispatch(resetPostList({ count: 0, rows: [] }));
			}
			dispatch(setSelectedPage(id));
			dispatch(setCurrentPostPageNumber(1));
		};
	}

	function fetchMorePages() {
		pages.count > pages.pages.length && dispatch(setPageNo(pageNo + 1));
	}

	return {
		pages: pages,
		isFetchingAllPages,
		onClickPage,
		selectedPage,
		fetchMorePages,
	};
}
