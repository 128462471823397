import React from 'react';
import Styles from './style.module.css';
import { TradePriceBreakupData } from '@mono-farmart-web/farmart-web-common/modal/Traders/types';
import ButtonV2 from '@mono-farmart-web/farmart-web-common/ComponentV2/ButtonV2';
import RequestSaudaModal from '../RequestSaudaModal';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
interface TradeDetailProps {
  PriceBreakup: TradePriceBreakupData;
  numberCopyToast: boolean;
  handleNumberCopyToast: () => void;
  language?: { [key: string]: any };
  onClickRequestSauda: () => void;
  showRequestSauda: boolean;
  toggleRequestSaudaModal: () => void;
  quantity: number;
  handleQuantityChange: () => void;
  paymentTerms: string;
  paymentTermsType: string;
  handlePaymentTerms: () => void;
  showPaymentTermsModal: boolean;
  proceedDisabled: boolean;
  grnInfo: () => void;
  buyersInfo: () => void;
  onClosePaymentTermsModal: () => void;
  closePaymentTermsModal: () => void;
  handleBrokeragePopup: () => void;
  handleFinancingPopup: () => void;
  handleNetPopup: () => void;
  saudaRequestToastPopup: boolean;
  handleSaudaRequestToastPopup: () => void;
  proceedSaudaRequest: () => void;
  isFocusedSaudaRequestInput: boolean;
  handleFocusSaudaRequestInput: () => void;
  handleBlurSaudaRequestInput: () => void;
  onKeyDownSaudaRequestInput: () => void;
  discountFeeSmiley: string;
  discountCardBgBig: string;
  magicIcon: string;
}

const BottomButton = (props: TradeDetailProps) => {
  const {
    handleNumberCopyToast,
    language,
    PriceBreakup,
    onClickRequestSauda,
    showRequestSauda,
    toggleRequestSaudaModal,
    quantity,
    handleQuantityChange,
    paymentTerms,
    paymentTermsType,
    handlePaymentTerms,
    showPaymentTermsModal,
    proceedDisabled,
    grnInfo,
    buyersInfo,
    onClosePaymentTermsModal,
    closePaymentTermsModal,
    handleBrokeragePopup,
    handleFinancingPopup,
    handleNetPopup,
    saudaRequestToastPopup,
    handleSaudaRequestToastPopup,
    proceedSaudaRequest,
    isFocusedSaudaRequestInput,
    handleFocusSaudaRequestInput,
    handleBlurSaudaRequestInput,
    onKeyDownSaudaRequestInput,
    discountCardBgBig,
    discountFeeSmiley,
    magicIcon,
  } = props;
  const disabled = PriceBreakup?.is_expire === 1 ? true : false;

  const callIcon = disabled ? (
    <CallRoundedIcon htmlColor="#C2C7CF" sx={{ height: 20, width: 20 }} />
  ) : (
    <CallRoundedIcon htmlColor="#008B74" sx={{ height: 20, width: 20 }} />
  );

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.btn}>
        <div className={Styles.left}>
          <ButtonV2
            type={'secondary'}
            leadingIcon={callIcon}
            onClick={handleNumberCopyToast}
            disabled={disabled}
          />
        </div>
        <div className={Styles.right}>
          <ButtonV2
            title={language?.requestSauda}
            onClick={onClickRequestSauda}
            disabled={disabled}
          />
        </div>
        <RequestSaudaModal
          showRequestModal={showRequestSauda}
          priceBreakUp={PriceBreakup}
          onClose={toggleRequestSaudaModal}
          onClickRequestSauda={onClickRequestSauda}
          toggleRequestSaudaModal={toggleRequestSaudaModal}
          quantity={quantity}
          paymentTerms={paymentTerms}
          paymentTermsType={paymentTermsType}
          showPaymentTermsModal={showPaymentTermsModal}
          handleQuantityChange={handleQuantityChange}
          handlePaymentTerms={handlePaymentTerms}
          proceedDisabled={proceedDisabled}
          grnInfo={grnInfo}
          buyersInfo={buyersInfo}
          onClosePaymentTermsModal={onClosePaymentTermsModal}
          handleBrokeragePopup={handleBrokeragePopup}
          handleFinancingPopup={handleFinancingPopup}
          handleNetPopup={handleNetPopup}
          saudaRequestToastPopup={saudaRequestToastPopup}
          handleSaudaRequestToastPopup={handleSaudaRequestToastPopup}
          language={language}
          proceedSaudaRequest={proceedSaudaRequest}
          closePaymentTermsModal={closePaymentTermsModal}
          isFocusedSaudaRequestInput={isFocusedSaudaRequestInput}
          handleFocusSaudaRequestInput={handleFocusSaudaRequestInput}
          handleBlurSaudaRequestInput={handleBlurSaudaRequestInput}
          onKeyDownSaudaRequestInput={onKeyDownSaudaRequestInput}
          discountCardBgBig={discountCardBgBig}
          discountFeeSmiley={discountFeeSmiley}
          magicIcon={magicIcon}
        />
      </div>
    </div>
  );
};

export default BottomButton;
