import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    errorStateHeading: "ओह… सर्वर में कुछ समस्या है!",
    errorStateSubHeading: "इस समय हम आपका अनुरोध प्रोसेस करने में असमर्थ हैं, कृपया कुछ समय बाद फिर से प्रयास करें।",
    errorStatePrimaryButton: "पुनः प्रयास करें",
    errorStateSecondaryButton: "सहायता"
  },
  [AppLanguages.appLanguagesIndex.english]: {
    errorStateHeading: "Oops... Something went wrong!",
    errorStateSubHeading: "We’re unable to process your request at this moment please try again after sometime.",
    errorStatePrimaryButton: "Retry",
    errorStateSecondaryButton: "Help"
  },
};

export default (language) => languages[language];
