import {
	useEffect,
	useState,
	useRef,
	type Dispatch,
	type SetStateAction,
} from "react";
import type { PostList } from "../../modal/community/types";
import useAuthenticationChecker from "../../utils/authChecker";
import { apiEndPoints } from "../../apis/endpoints";

interface RelevantPost {
	relevantPostList: PostList;
	loadMoreRelevantPostList: () => void;
	relevantPostLoading: boolean;
	setRelevantPostList: Dispatch<SetStateAction<PostList>>;
}

export default function useRelevantPostList(
	currentPostId: number,
): RelevantPost {
	const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();
	const relevantPostPageNo = useRef<number>(1);
	const [relevantPostList, setRelevantPostList] = useState<PostList>({
		count: 0,
		rows: [],
	});
	const [relevantPostLoading, setRelevantPostLoading] =
		useState<boolean>(false);

	async function fetchRelevantList() {
		setRelevantPostLoading(true);
		const postsResult = await AuthenticationCheckerAndApiCaller({
			payload: {
				news_feed_post_id: currentPostId,
				page_no: relevantPostPageNo.current,
				limit: 3,
			},
			uri: apiEndPoints?.fetchRevelantPosts,
			requestType: "get",
		});
		setRelevantPostList((prevPost) => ({
			count: postsResult?.data?.data?.count,
			rows: [...prevPost.rows, ...postsResult?.data?.data?.rows],
		}));
		increasePageCount();
		setRelevantPostLoading(false);
	}

	function loadMoreRelevantPostList() {
		if (relevantPostList.count > relevantPostList.rows.length) {
			fetchRelevantList();
		}
	}

	function increasePageCount() {
		relevantPostPageNo.current = relevantPostPageNo.current + 1;
	}

	useEffect(() => {
		if (relevantPostList.count !== 0) {
			setRelevantPostList({
				count: 0,
				rows: [],
			});
			relevantPostPageNo.current = 1;
		}
		fetchRelevantList();
	}, [currentPostId]);

	return {
		relevantPostList,
		loadMoreRelevantPostList,
		relevantPostLoading,
		setRelevantPostList,
	};
}
