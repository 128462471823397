import { useCallback, useEffect, useState } from "react";
import { GetTripDetails } from "@mono-farmart-web/farmart-web-common/apis/saudabook-pwa/takeconsent";

const useFetchTripDetails = (trip_id: string, truck_id?: string | number) => {
	const [tripDetails, setTripDetails] = useState<any | null>(null);
	const [error, setError] = useState<any | null>(null);
	const [isLoading, setIsLoading] = useState<boolean | null>(true);

	const fetchTripDetails = useCallback(async () => {
		setIsLoading(true);

		try {
			const tripDetailData = await GetTripDetails({ trip_id, truck_id });
			setTripDetails(tripDetailData);
		} catch (error) {
			console.log("Error - while fetching the trip detail", error);
			setError("Unable to fetch the trip detail. Some error occured");
		} finally {
			setIsLoading(false);
		}
	}, [trip_id]);

	useEffect(() => {
		fetchTripDetails();
	}, [fetchTripDetails]);

	return {
		tripDetails,
		error,
		isLoading,
	};
};

export default useFetchTripDetails;
