import React from 'react';
import { Box, Button } from '@mui/joy';
import MyFarmerEmptyState from '@mono-farmart-web/farmart-web-common/Images/MyFarmerEmptyState.svg';
import Typography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import AddFilled from '@mui/icons-material/Add'; // Importing an icon component

interface EmptyStateProps {
  language: { [key: string]: string }; // Interface for the language object
  onClickAddFarmer: () => void; // Function to handle the onClick event for adding a farmer
}

const EmptyStateContent = (props: EmptyStateProps) => {
  const { language, onClickAddFarmer } = props; // Destructuring the props object
  return (
    <Box
      marginTop={'124px'}
      alignSelf={'center'}
      justifyContent={'center'}
      alignItems={'center'}
      data-testid="emptyStateContainer"
      textAlign={'center'}
    >
      <img
        src={MyFarmerEmptyState as unknown as string} // Casting MyFarmerEmptyState to string
        alt="myFarmerEmptyState"
        style={{
          textAlign: 'center',
        }}
        height={167}
        width={167}
        data-testid="myFarmerEmptyStateImageTestId"
        loading="eager"
      />
      <Box margin={'12px 28px 0px 28px'} data-testid="emptyStateTextContainer">
        <Typography
          level="body-md"
          textColor={'text.primary'}
          textAlign={'center'}
          data-testid="emptyStateText"
        >
          {language?.emptyAlert}
        </Typography>
      </Box>
      <Box justifyContent={'center'} display={'flex'} paddingTop={'12px'}>
        <Button
          onClick={onClickAddFarmer} // Assigning the onClickAddFarmer function to the onClick event
          size={'sm'}
          variant="soft"
          sx={{
            borderRadius: 8,
          }}
          startDecorator={
            <AddFilled
              style={{
                color: '#007B65',
              }}
            />
          }
          data-testid="addFarmerButton"
        >
          {language?.addNewFarmerButton}
        </Button>
      </Box>
    </Box>
  );
};

export default EmptyStateContent;
