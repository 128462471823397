import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import {
	type BaseComment,
	type ReplyComment,
	PostActionControllerEnums,
	type PostDetailsById,
} from "../../modal/community/communityModel";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { increamentCommentCountPost } from "../../modal/community/slice/community.slice";

import AnalyticEvents from "../../constant/analyticEvents";
import { getUserProfileFromStorage } from "../../modal/auth/auth.model";
import { usePlatfromData } from "../../hooks/CrossPlatformActionHandler/platformChecker";
import { trackEvent } from "../../utils/moengage";
import Events from "../../constant/MoengageEvents/index";
import useAuthenticationChecker from "../../utils/authChecker";
import { apiEndPoints } from "../../apis/endpoints";
import bridgeFunctions from "../../utils/bridge";
import { createBridge } from "@mono-farmart-web/rpc-bridge";
interface UseComment {
	baseCommentList: BaseComment[];
	baseCommentLoading: boolean;
	loadMoreBaseComment: () => void;
	setBaseCommentList: Dispatch<React.SetStateAction<BaseComment[]>>;
	onClickViewNestedReply: (id: number, index: number) => () => void;
	baseCommentCount: number;
	CreateCommentOnPost: (text: string) => void;
	commentInputVisible: boolean;
	onClickReply: (replyParameter: ReplyComment) => () => void;
	setCommentInputVisible: Dispatch<React.SetStateAction<boolean>>;

	setRefCommentId: Dispatch<React.SetStateAction<number>>;
	setTaggedMerchantId: Dispatch<React.SetStateAction<number>>;
	setBaseCommentPageNo?: Dispatch<React.SetStateAction<number>>;
	taggedName: string;
	merchantName: string;
	removeTaggedName: () => void;
	onCompleteUserInfo: () => void;

	focus: boolean;
	nameModal: boolean;
	onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onCloseUpdateUserInoModal: () => void;
	userNameUploadLoading: boolean;
}

export default function useComment(
	currentPostId?: number,
	setPostDetailsData?: Dispatch<SetStateAction<PostDetailsById>>,
): UseComment {
	const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();
	const platformData = usePlatfromData();
	const bridge = createBridge();
	const postId = +useParams()?.id;
	const dispatch = useDispatch();
	//create comment hooks start
	const inputVisibleViaParam = useLocation()?.state?.navigateViaComment;
	const [commentInputVisible, setCommentInputVisible] = useState<boolean>(
		inputVisibleViaParam ? inputVisibleViaParam : false,
	);
	const [refCommentId, setRefCommentId] = useState<number>(null);
	const [taggedMerchantId, setTaggedMerchantId] = useState<number>(null);
	const [commentIndex, setCommentIndex] = useState<number>(null);
	const [focus, setFocus] = useState<boolean>(false);
	//create comment hooks end

	//Base comment Hooks
	const [baseCommentList, setBaseCommentList] = useState<BaseComment[]>([]);
	const [baseCommentCount, setBaseCommentCount] = useState<number>(0);
	const [baseCommentLoading, setBaseCommentLoading] = useState<boolean>(false);
	const [baseCommentPageNo, setBaseCommentPageNo] = useState<number>(1);

	// Progressive signup hooks

	const [nameModal, setNameModal] = useState<boolean>(false);
	const [currentComment, setCurrentComment] = useState<string>("");
	const [merchantName, setMerchantName] = useState<string>("");

	let profile = getUserProfileFromStorage();
	const [userNameUploadLoading, setUserNameUploadLoading] =
		useState<boolean>(false);
	//

	const tokenExpiredCount = 0;
	const tokenExpiredCountNestedComment = 0;

	//Base comment Hooks end here

	//Nested comment Hooks
	const [taggedName, setTaggedName] = useState<string>("");
	//Nested comment Hooks end here

	//Base comment logic start
	async function getBaseCommentList() {
		setBaseCommentLoading(true);
		const baseCommentResult = await AuthenticationCheckerAndApiCaller({
			payload: {
				page_no: baseCommentPageNo,
				limit: 3,
				news_feed_id: currentPostId || postId,
			},
			uri: apiEndPoints?.fetchBaseComments,
			requestType: "get",
		});
		if (baseCommentResult?.data?.status) {
			setBaseCommentList([
				...baseCommentList,
				...baseCommentResult?.data?.data?.comments,
			]);
			setBaseCommentCount(baseCommentResult?.data?.data?.count);
		}
		setBaseCommentLoading(false);
	}

	function loadMoreBaseComment() {
		if (baseCommentCount > baseCommentList.length) {
			setBaseCommentPageNo(baseCommentPageNo + 1);
			trackEvent(Events?.onCommentMoreRepliesClick, {
				From: "Post_Detail_Screen",
				Post_id: currentPostId || postId,
				User_id: profile?.merchant_detail_id,
				Reply_count: baseCommentCount,
			});
		}
	}

	useEffect(() => {
		getBaseCommentList();
	}, [baseCommentPageNo, currentPostId]);
	//Base comment logic end

	//Nested comment logic start
	async function getNestedCommentReplyList(id: number, index: number) {
		const baseCommentListPrev = [...baseCommentList];
		baseCommentListPrev[index].nestead_loading = true;
		setBaseCommentList(baseCommentListPrev);
		const CommentReplyResult = await AuthenticationCheckerAndApiCaller({
			payload: {
				page_no: 1,
				limit: 1045,
				news_feed_id: currentPostId || postId,
				ref_comment_id: id,
			},
			uri: apiEndPoints?.fetchCommentReply,
			requestType: "get",
		});
		const baseCommentListPrevTemp = [...baseCommentList];
		baseCommentListPrevTemp[index].nested_replies =
			CommentReplyResult?.data?.data?.replies;
		setBaseCommentList(baseCommentListPrevTemp);
	}

	function onClickViewNestedReply(id: number, index: number) {
		return () => {
			getNestedCommentReplyList(id, index);
		};
	}
	//Nested comment logic end

	function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
		const value = event.target.value;
		setMerchantName(value);
	}

	// Progressive signup logic
	function onCloseUpdateUserInoModal() {
		setNameModal(false);
	}

	async function onCompleteUserInfo() {
		setUserNameUploadLoading(true);
		const result = await AuthenticationCheckerAndApiCaller({
			payload: {
				merchant_name: merchantName,
			},
			uri: apiEndPoints?.updateMerchantName,
			requestType: "put",
		});
		if (result) {
			localStorage.setItem(
				"userProfile",
				JSON.stringify({ ...profile, merchant_name: merchantName }),
			);
			profile = getUserProfileFromStorage();
			await CreateCommentOnPost(currentComment);
			bridge.sendRequestToNative(
				"updateMerchantProfileInReduxAndAsync",
				{ merchantName: merchantName },
				(response: any) => {
					bridgeFunctions.PrintLog(response);
				},
			);
		}
		setMerchantName("");
		setUserNameUploadLoading(false);
		setNameModal(false);
	}
	//

	//create comment logic start
	async function CreateCommentOnPost(value: string) {
		if (!profile.merchant_name) {
			setCurrentComment(value);
			setCommentInputVisible(false);

			return setNameModal(true);
		}

		const result = await AuthenticationCheckerAndApiCaller({
			payload: {
				news_feed_id: currentPostId || postId,
				comment: value,
				ref_comment_id: refCommentId,
				tagged_merchant_id: taggedMerchantId,
			},
			uri: apiEndPoints?.postComments,
			requestType: "post",
		});
		trackEvent(Events?.onCommentSubmitButtonClick, {
			From: "Post_Detail_Screen",
			Post_id: currentPostId || postId,
			User_id: profile?.merchant_detail_id,
			Type_of_comment: "",
		});
		if (refCommentId) {
			getNestedCommentReplyList(refCommentId, commentIndex);
		} else {
			setBaseCommentList((prevPosts) => [result?.data?.data, ...prevPosts]);
		}
		actionOnComment();
		setCommentInputVisible(false);
	}

	async function actionOnComment() {
		await AuthenticationCheckerAndApiCaller({
			payload: {
				action: PostActionControllerEnums.COMMENT,
				postId: currentPostId || postId,
			},
			uri: apiEndPoints?.newsFeedAction,
			requestType: "post",
		});
		dispatch(increamentCommentCountPost(postId));
		setPostDetailsData((prevState) => ({
			...prevState,
			comment_count: prevState?.comment_count + 1,
		}));
	}

	function onClickReply(replyParameter: ReplyComment) {
		return () => {
			setRefCommentId(replyParameter.ref_comment_id);
			setTaggedMerchantId(replyParameter.tagged_merchant_id);
			setCommentInputVisible(true);
			setCommentIndex(replyParameter.comment_index);
			setTaggedName(replyParameter.tagged_name);
			setFocus(!focus);
			trackEvent(Events?.onCommentReplyClick, {
				From: "Post_Detail_Screen",
				Post_id: currentPostId || postId,
				User_id: profile?.merchant_detail_id,
			});
		};
	}

	function removeTaggedName() {
		setTaggedName("");
	}

	//create comment logic ends

	return {
		baseCommentList,
		baseCommentLoading,
		loadMoreBaseComment,
		setBaseCommentList,
		onClickViewNestedReply,
		baseCommentCount,
		CreateCommentOnPost,
		commentInputVisible,
		onClickReply,
		setCommentInputVisible,
		setRefCommentId,
		setTaggedMerchantId,
		setBaseCommentPageNo,
		taggedName,
		removeTaggedName,
		focus,
		nameModal,
		onChangeName,
		merchantName,
		onCompleteUserInfo,
		onCloseUpdateUserInoModal,
		userNameUploadLoading,
	};
}
