import { useState, useEffect, useCallback } from "react"; // React hooks for state and lifecycle management
import getLang from "../../languages/smFarmer.js"; // Language translation function
import { useNavigate, useLocation, useParams } from "react-router-dom"; // React Router hooks for navigation and URL parameters
import { apiEndPoints } from "../../apis/endpoints"; // API endpoints constants
import useAuthenticationChecker from "../../utils/authChecker"; // Custom hook for authentication
import type { SmsFarmerProps } from "./types.js"; // TypeScript types for SMS Farmer properties
import debounce from "../../utils/debounce/debounce"; // Debounce utility function
import { trackEvent } from "../../utils/moengage/index"; // Tracking functions for analytics
import Events from "../../constant/MoengageEvents/index"; // Tracking events constants
import { DEFAULT_LANGUAGE } from "../../constant/language";
import { getLanguageService } from "./../../utils/getLanguageService";
import { languageUrls } from "../../constant/languageUrls/index";

export default function MyFarmerHook(): SmsFarmerProps {
	const [languageFile, setLanguageFile] = useState({});

	const { state } = useLocation(); // Get location state
	const [smsBalance, setSmsBalance] = useState(0); // State for SMS balance
	const [saving, setSaving] = useState(false); // State for saving status
	const { smsId } = useParams(); // Get SMS ID from URL params
	const language = languageFile ?? getLang(DEFAULT_LANGUAGE); // Getting language file based on language ID

	const [selectAllView, setSelectAllView] = useState(false); // State for select all view
	const [contactsIndex, setContactsIndex] = useState({}); // State for contacts index
	const [initialFarmersCount, setInitialFarmersCount] = useState(0); // State for initial farmers count

	const [currentPage, setCurrentPage] = useState(1); // State for current page
	const [hasMore, setHasMore] = useState(0); // State for more data availability

	const navigate = useNavigate(); // Navigation hook
	const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker(); // Custom authentication hook

	const [farmers, setFarmers] = useState([]); // State for farmers list
	const [searchParam, setSearchParam] = useState(""); // State for search parameter
	const [searchLoading, setSearchLoading] = useState(false); // State for search loading
	const [initialLoading, setInitialLoading] = useState(true); // State for initial loading
	const [firstLaunch, setFirstLaunch] = useState(false); // State for first launch
	const [isErrorPopup, setIsErrorPopup] = useState(false);

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false); // State for delete modal visibility

	useEffect(() => {
		getLanguageJson();
	}, []);

	async function getLanguageJson() {
		try {
			const url = languageUrls?.smsFarmerScreenLangageUrl;
			const result = await getLanguageService(url);
			setLanguageFile(result);
		} catch (error) {
			// console.log("object");
		} finally {
			// console.log("object");
		}
	}

	/**
	 * Toggles the visibility of the delete modal.
	 * @param {string} type - The type of action triggering the toggle.
	 */
	function toggleDeleteModal(type: string) {
		if (!isDeleteModalVisible) {
			trackEvent(Events.onMyFarmersDeleteButtonClick, {}); // Track delete button click event
		} else {
			if (type === "Cancel") {
				trackEvent(Events.onDeleteFarmerCancelButtonClick, {}); // Track cancel button click event
			} else {
				trackEvent(Events.onDeleteFarmerCrossButtonClick, {}); // Track cross button click event
			}
		}
		setIsDeleteModalVisible(!isDeleteModalVisible); // Toggle delete modal visibility
	}

	/**
	 * Fetches the SMS balance from the API.
	 */
	async function getSmsBalance() {
		try {
			const smsBalanceResponse = await AuthenticationCheckerAndApiCaller({
				payload: {}, // Empty payload
				uri: apiEndPoints.smsBalance, // API endpoint for SMS balance
				requestType: "get", // HTTP GET request
			});

			if (smsBalanceResponse?.data?.status) {
				setSmsBalance(smsBalanceResponse.data.data?.total_sms_credit_point); // Set SMS balance
			}
		} catch (error) {
			handleErrorToastPopup();
			console.log(error); // Log error
		}
	}

	useEffect(() => {
		getSmsBalance(); // Fetch SMS balance on component mount
	}, []);

	useEffect(() => {
		if (currentPage !== 1 && searchParam === "") {
			getMyFarmers(searchParam, false); // Fetch farmers without search parameter
		} else if (currentPage === 1) {
			getMyFarmers(searchParam, true); // Fetch farmers with search parameter
		} else if (currentPage !== 1) {
			getMyFarmers(searchParam, false); // Fetch farmers without search parameter
		}
	}, [currentPage, searchParam]);

	/**
	 * Fetches the list of farmers from the API.
	 * @param {string} search_param - The search parameter for filtering farmers.
	 * @param {boolean} isInitialLoad - Flag indicating if it's the initial load.
	 */
	async function getMyFarmers(search_param: string, isInitialLoad: boolean) {
		if (isInitialLoad && firstLaunch) {
			setInitialLoading(true); // Set initial loading state to true
		}
		setFirstLaunch(false); // Set first launch state to false
		try {
			const fetchMyFarmersParams = {
				page_no: currentPage, // Current page number
				limit: 30, // Number of items per page
				sort_fashion: "asc", // Sort order
				search_param: search_param, // Search parameter
			};
			const farmersResp = await AuthenticationCheckerAndApiCaller({
				payload: fetchMyFarmersParams, // Request payload
				uri: apiEndPoints.fetchFarmerOfUser, // API endpoint
				requestType: "get", // HTTP method
			});

			if (farmersResp?.data?.status) {
				const response = farmersResp?.data;
				const farmersKey = {};

				// Filter farmers and update farmersKey
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const farmers = response.data.rows.filter((data) => {
					farmersKey[data.mobile_number] = selectAllView;
				});

				if (searchParam !== "" && currentPage === 1) {
					setContactsIndex(farmersKey);
				} else {
					setContactsIndex({ ...contactsIndex, ...farmersKey }); // Update contacts index
				}
				if (isInitialLoad) {
					if (initialFarmersCount === 0 && response?.data?.count) {
						setInitialFarmersCount(response.data?.count); // Set initial farmers count
					}
					setFarmers(response?.data.rows); // Set farmers list
				} else {
					setFarmers((prevFarmers) => [...prevFarmers, ...response.data.rows]); // Append to farmers list
				}
				setHasMore(response?.data?.count); // Set has more data flag
			}
		} catch (error) {
			handleErrorToastPopup();

			console.log("Error in fetching My Farmers", error); // Log error
		} finally {
			if (isInitialLoad) {
				setInitialLoading(false); // Set initial loading state to false
			} else {
				setSearchLoading(false); // Set search loading state to false
			}
		}
	}

	/**
	 * Selects or deselects all farmers.
	 * @param {boolean} status - The selection status to apply to all farmers.
	 */

	console.log(contactsIndex, "====contant");
	function selectAll(status: boolean) {
		const index = { ...contactsIndex };
		console.log(contactsIndex, index, "====");
		for (const keys in contactsIndex) {
			index[keys] = status; // Update selection status for each contact
		}
		setContactsIndex(index); // Set updated contacts index
		setSelectAllView(status); // Set select all view status
	}

	/**
	 * Loads more farmers by incrementing the current page.
	 */
	function loadMoreFarmers() {
		if (hasMore !== farmers.length && searchParam === "") {
			setCurrentPage((prevPage) => prevPage + 1); // Increment current page
		}
	}

	/**
	 * Navigates back to the previous page or home if no previous state.
	 */
	function onPressBack() {
		if (state?.from) {
			navigate("/"); // Navigate to home
		} else {
			navigate(-1); // Navigate back
		}
	}

	/**
	 * Navigates to the contact us page.
	 */
	function onContactUs() {
		navigate("/faq/questions"); // Navigate to FAQ page
	}

	/**
	 * Navigates to the add farmer screen.
	 */
	function onClickAddFarmer() {
		navigate("/addFarmerScreen", {
			state: { fromScreen: "shopPromotion" },
		}); // Navigate to add farmer screen
	}

	/**
	 * Toggles the selection of a farmer.
	 * @param {number} mobile_number - The mobile number of the farmer to toggle.
	 */
	const toggleFarmerSelection = (mobile_number: number) => {
		setContactsIndex({
			...contactsIndex,
			[mobile_number]: !contactsIndex[mobile_number], // Toggle selection status
		});
		setSelectAllView(false); // Disable select all view
	};

	const debouncedSetSearchParam = useCallback(
		debounce((value: string) => {
			setSearchParam(value); // Set search parameter with debounce
		}, 300),
		[],
	);

	let selectedCount = selectAllView
		? hasMore
		: Object.values(contactsIndex).filter((item) => {
				return item === true; // Count selected farmers
			}).length;

	selectedCount = selectedCount * state?.smsCost; // Calculate selected count cost

	const selectedFarmerCount = selectAllView
		? hasMore
		: Object.values(contactsIndex).filter((item) => {
				return item === true; // Count selected farmers
			}).length;

	/**
	 * Saves the selected farmers by sending an SMS.
	 */
	async function saveFarmer() {
		try {
			const farmers_numbers = [];
			setSaving(true); // Set saving state to true
			for (const key in contactsIndex) {
				if (contactsIndex[key]) {
					farmers_numbers.push(+key); // Add selected farmer numbers
				}
			}

			const requestBody = {
				mobile_numbers: farmers_numbers, // Selected farmer numbers
				sms_type_id: smsId, // SMS type ID
				variables: state.inputValues, // Input values
				send_to_all: searchParam === "" ? selectAllView : false, // Send to all flag
			};

			const sendBuldSmsResp = await AuthenticationCheckerAndApiCaller({
				payload: requestBody, // Request payload
				uri: apiEndPoints.sendSms, // API endpoint for sending SMS
				requestType: "post", // HTTP POST request
			});

			if (sendBuldSmsResp.data.status) {
				navigate("/smsSuccess", {
					state: {
						smsSent: selectedFarmerCount, // Number of SMS sent
						smsBalance: smsBalance - selectedCount, // Updated SMS balance
						smsId: smsId,
					},
				});
				setSaving(false); // Set saving state to false
			}
			setSaving(false); // Set saving state to false
		} catch (error) {
			handleErrorToastPopup();
			setSaving(false); // Set saving state to false
		}
	}

	const handleErrorToastPopup = () => {
		setIsErrorPopup(true);

		setTimeout(() => {
			setIsErrorPopup(false);
		}, 5000);
	};

	return {
		onPressBack,
		language,
		onContactUs,
		farmers,
		searchParam,
		setSearchParam,
		toggleFarmerSelection,
		debouncedSetSearchParam,
		initialLoading,
		searchLoading,
		onClickAddFarmer,
		toggleDeleteModal,
		isDeleteModalVisible,
		isErrorPopup,
		currentPage,
		hasMore,
		loadMoreFarmers,
		setCurrentPage,
		contactsIndex,
		selectAll,
		selectAllView,
		initialFarmersCount,
		selectedCount,
		smsBalance,
		saveFarmer,
		saving,
		selectedFarmerCount,
	};
}
