const language = {
  second: 'सेकंड',
  minute: 'मिनट',
  hour: 'घंटे',
  day: 'दिन',
  month: 'महीने',
  year: 'वर्ष',
  ago: 'पहले',
  now: 'अभी',
};

function convertToIST(utcTimestamp: string): Date {
  const utcDate = new Date(utcTimestamp);
  const istOffset = 5.5 * 60 * 60 * 1000; // IST offset is 5 hours 30 minutes
  const istDate = new Date(utcDate.getTime() + istOffset);
  return istDate;
}

function getTimeAgoFormatted(timeDifference: number, unit: string): string {
  return timeDifference < 1
    ? language?.now
    : `${timeDifference} ${unit} ${language?.ago}`;
}

interface TimeProps {
  utcTimestamp: string;
}

export default function convertAndFormatTimeAgo(props: TimeProps): string {
  const { utcTimestamp } = props;
  const istDate = convertToIST(utcTimestamp);
  const istNow = convertToIST(new Date().toISOString()); // Get IST current time

  const timeDiffInSeconds = Math.floor(
    (istNow.getTime() - istDate.getTime()) / 1000,
  );

  if (timeDiffInSeconds < 60) {
    return getTimeAgoFormatted(timeDiffInSeconds, `${language?.second}`);
  }

  const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60);
  if (timeDiffInMinutes < 60) {
    return getTimeAgoFormatted(timeDiffInMinutes, `${language?.minute}`);
  }

  const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);
  if (timeDiffInHours < 24) {
    return getTimeAgoFormatted(timeDiffInHours, `${language?.hour}`);
  }

  const timeDiffInDays = Math.floor(timeDiffInHours / 24);
  if (timeDiffInDays < 30) {
    return getTimeAgoFormatted(timeDiffInDays, `${language?.day}`);
  }

  const timeDiffInMonths = Math.floor(timeDiffInDays / 30);
  if (timeDiffInMonths < 12) {
    return getTimeAgoFormatted(timeDiffInMonths, `${language?.month}`);
  }

  const timeDiffInYears = Math.floor(timeDiffInMonths / 12);
  return getTimeAgoFormatted(timeDiffInYears, `${language?.year}`);
}
