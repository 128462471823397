import { Box } from '@mui/joy'; // MUI Box component for layout
import React from 'react'; // React library
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography'; // Custom Typography component
import { farmerCardData } from '@mono-farmart-web/farmart-web-common/hooks/SmsHistoryHookDetailHook/types'; // TypeScript type for farmer card data

// Interface for MessageCard component props
interface MessageCardProp {
  smsHistoryData:
    | {
        message: string; // SMS message content
        farmers: farmerCardData[]; // List of farmers
        sms_type: number; // Type of SMS
        status: number; // Status of the SMS
        comment: string; // Comment on the SMS
      }
    | undefined; // SMS history data can be undefined
  language: { [key: string]: string }; // Language translations
}

// MessageCard component definition
export default function MessageCard(props: MessageCardProp) {
  const { smsHistoryData, language } = props; // Destructure props

  return (
    <Box margin={'16px'} display={'flex'} flexDirection={'column'} gap={'8px'}>
      <MuiTypography level="title-sm" textColor={'text.secondary'}>
        {language?.messageSentTitle} {/* Display message sent title */}
      </MuiTypography>
      <Box
        borderRadius={'4px'} // Border radius for rounded corners
        display={'flex'} // Flexbox layout
        padding={'12px'} // Padding for the box
        bgcolor={'background.level1'} // Background color
      >
        <MuiTypography level="body-xxs" textColor={'text.primary'}>
          {smsHistoryData.message} {/* Display SMS message content */}
        </MuiTypography>
      </Box>
    </Box>
  );
}
