type FormatPriceParams = {
  prefix?: string | any; // Allow other types but default to string
  value?: number | string | unknown; // Allow other types but default to number or string
  suffix?: string | any; // Allow other types but default to string
  gapBetweenPrefixValueSuffix?: boolean;
  gapBetweenValueSuffix?: boolean;
  gapBetweenPrefixValue?: boolean;
};

export default function formatPrice({
  prefix = '',
  value,
  suffix = '',
  gapBetweenPrefixValueSuffix = false,
  gapBetweenValueSuffix = false,
  gapBetweenPrefixValue = false,
}: FormatPriceParams): string {
  try {
    // Ensure prefix and suffix are strings
    const validatedPrefix = typeof prefix === 'string' ? prefix : '';
    const validatedSuffix = typeof suffix === 'string' ? suffix : '';

    // Ensure value is a valid number or default to 0
    const numericValue =
      typeof value === 'number' ||
      (typeof value === 'string' && !isNaN(Number.parseFloat(value)))
        ? Number.parseFloat(`${value}`)
        : 0;

    // Format the number to two decimal places with commas for thousands
    const formattedValue = numericValue.toLocaleString('en-IN', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    if (gapBetweenPrefixValueSuffix) {
      return `${validatedPrefix} ${formattedValue} ${validatedSuffix}`;
    } else if (gapBetweenPrefixValue) {
      return `${validatedPrefix} ${formattedValue}${validatedSuffix}`;
    } else if (gapBetweenValueSuffix) {
      return `${validatedPrefix}${formattedValue} ${validatedSuffix}`;
    } else {
      // Return the concatenated string
      return `${validatedPrefix}${formattedValue}${validatedSuffix}`;
    }
  } catch (error) {
    console.error('Error formatting price:', error.message);
    return 'Invalid Price';
  }
}
