import React from 'react';
import Styles from './style.module.css';
import Header from './Header/Header';
import Actionbutton from './BottomContainer/Actionbutton';
import LanguageSelection from './LanguageSelection/LanguageSelection';
import UserRegister from './UserPersona';
import { UserRegistrationHooks } from '@mono-farmart-web/farmart-web-common/hooks/UserRegistrationhooks/types';
import { Box } from '@mui/joy';

export default function UserRegistraion(props: UserRegistrationHooks) {
  const {
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    handleLanguageSelect,
    selectedPersona,
    onClickPersonaCard,
    language,
    loading,
    onClickProceedPersona,
    userRegistrationAssets,
    languageData,
  } = props;
  return (
    <Box className={Styles.container} bgcolor={'background.surface'}>
      <Header
        onLanguageModalToggle={onLanguageModalToggle}
        language={language}
        farmartLogo={userRegistrationAssets.farmartLogo}
        languageSelectIcon={userRegistrationAssets.languageSelection}
      />
      <UserRegister
        onClickPersonaCard={onClickPersonaCard}
        selectedPersona={selectedPersona}
        language={language}
      />

      <Actionbutton
        selectedPersona={selectedPersona}
        loading={loading}
        onClickProceedPersona={onClickProceedPersona}
        language={language}
      />
      <LanguageSelection
        handleLanguageChange={handleLanguageChange}
        selectedLanguage={selectedLanguage}
        isLanguageModalOpen={isLanguageModalOpen}
        isLanguageSelectedInProgress={isLanguageSelectedInProgress}
        handleLanguageSelect={handleLanguageSelect}
        onLanguageModalToggle={onLanguageModalToggle}
        language={language}
        languageData={languageData}
      />
    </Box>
  );
}
