import React from 'react';
import MyFarmerView from '../../Pages/SmsFarmers/SmsFarmersView';

import MyFarmersHook from '@mono-farmart-web/farmart-web-common/hooks/SmsFarmersHook/smsFarmersHook';

export default function otpScreen() {
  const {
    onPressBack,
    language,
    onContactUs,
    farmers,
    searchParam,
    setSearchParam,
    toggleFarmerSelection,
    debouncedSetSearchParam,
    initialLoading,
    searchLoading,
    onClickAddFarmer,
    isDeleteModalVisible,
    toggleDeleteModal,
    isErrorPopup,
    currentPage,
    hasMore,
    loadMoreFarmers,
    setCurrentPage,
    contactsIndex,
    selectAll,
    selectAllView,
    initialFarmersCount,
    selectedCount,
    smsBalance,
    saveFarmer,
    saving,
    selectedFarmerCount,
  } = MyFarmersHook();
  return (
    <MyFarmerView
      onPressBack={onPressBack}
      language={language}
      onContactUs={onContactUs}
      farmers={farmers}
      searchParam={searchParam}
      setSearchParam={setSearchParam}
      toggleFarmerSelection={toggleFarmerSelection}
      debouncedSetSearchParam={debouncedSetSearchParam}
      initialLoading={initialLoading}
      searchLoading={searchLoading}
      onClickAddFarmer={onClickAddFarmer}
      toggleDeleteModal={toggleDeleteModal}
      isDeleteModalVisible={isDeleteModalVisible}
      isErrorPopup={isErrorPopup}
      currentPage={currentPage}
      hasMore={hasMore}
      loadMoreFarmers={loadMoreFarmers}
      setCurrentPage={setCurrentPage}
      contactsIndex={contactsIndex}
      selectAll={selectAll}
      selectAllView={selectAllView}
      initialFarmersCount={initialFarmersCount}
      selectedCount={selectedCount}
      smsBalance={smsBalance}
      saveFarmer={saveFarmer}
      saving={saving}
      selectedFarmerCount={selectedFarmerCount}
    />
  );
}
