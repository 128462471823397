import { useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import useAuth from "@mono-farmart-web/farmart-web-common/hooks/auth/useAuth";
import applicationRoutes from "./routes/router";
import branch from "branch-sdk";
import {
	useMoengage,
	addUniqueId,
} from "@mono-farmart-web/farmart-web-common/utils/moengage";
import { getUserProfileFromStorage } from "@mono-farmart-web/farmart-web-common/modal/auth/auth.model";
import * as Sentry from "@sentry/react";
import BottomNavigationBar from "@mono-farmart-web/farmart-web-common/utils/bottomNavBar";
import useAuthenticationChecker from "@mono-farmart-web/farmart-web-common/utils/authChecker";
import { apiEndPoints } from "@mono-farmart-web/farmart-web-common/apis/endpoints";
import { userType } from "@mono-farmart-web/farmart-web-common/constant/enums/userType";
import type { RootState } from "@mono-farmart-web/farmart-web-common/store";
import { setUserPersona } from "@mono-farmart-web/farmart-web-common/redux/slices/personaSlice";
import MuiSkeleton from "@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton";
import { Box } from "@mui/joy";
import correctUrl from "@mono-farmart-web/farmart-web-common/utils/urlCorrection";

function App() {
	/* The code snippet you provided is setting up various hooks, state variables, and side effects
  within the `App` component of a React application. Here's a breakdown of what each part of the
  code is doing: */
	const { checkingIsLoggedIn, isLoggedIn } = useAuth();
	const location = useLocation();
	const dispatch = useDispatch();
	const addId = "CS2M99BHDFVE4TH87HIUXKP4";
	useMoengage(addId, +import.meta.env.VITE_MOENGAGE_DEBUG_MODE);
	const user = getUserProfileFromStorage();
	const loggedIn = JSON.parse(localStorage.getItem("loged"));
	const [isWebView, setIsWebView] = useState(false);
	const [modalVisible, setShowModalVisible] = useState(false);
	const [isNavBarVisibleForPath, setIsNavBarVisibleForPath] = useState(true);
	const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();
	const [isRetailer, setIsRetailer] = useState(false);

	const userSelectedPersona = useSelector(
		(state: RootState) => state.persona.userSelectedPersona,
	);

	useEffect(() => {
		branch.init("key_live_pk6sb0nWjlo8i4IEpdwSvepfErlDZ0yB");
	}, []);

	useEffect(() => {
		if (loggedIn) {
			fetchMerchantProfile();
		}
	}, [userSelectedPersona]);

	useEffect(() => {
		if (
			JSON.parse(localStorage?.getItem("profileSelected")) ===
				userType["retailer"] ||
			JSON.parse(localStorage?.getItem("profileSelected")) === null
		) {
			setIsRetailer(true);
		} else {
			setIsRetailer(false);
		}
	}, [JSON.parse(localStorage?.getItem("profileSelected"))]);

	useEffect(() => {
		user?.mobile_number && addUniqueId(user?.mobile_number);
	}, [user?.mobile_number]);

	useEffect(() => {
		// Logic to detect if web view is open in a mobile app
		const userAgent = navigator?.userAgent?.toLowerCase();
		setIsWebView(userAgent?.includes("wv"));

		return () => {
			setIsWebView(false);
		};
	}, []);

	const handlePersonaChange = (newPersona: string) => {
		dispatch(setUserPersona(newPersona));
	};

	async function fetchMerchantProfile() {
		try {
			const queryParameter = {};
			const response = await AuthenticationCheckerAndApiCaller({
				uri: apiEndPoints?.getMerchantProfile,
				requestType: "post",
				payload: queryParameter,
			});
			if (response?.data?.status) {
				setIsRetailer(
					response?.data?.data?.merchant_occupation_id === userType["retailer"],
				);
				handlePersonaChange(response?.data?.data?.merchant_occupation_id);
			}
		} catch (error) {
			console.error(error);
		}
	}

	/* The `useEffect` hook you provided is responsible for updating the state variable
  `isNavBarVisibleForPath` based on the current `location.pathname` value. Here's a breakdown of
  what it does: */
	useEffect(() => {
    const path=correctUrl(location?.pathname);
		if (
			path === "/" ||
			path === "/community" ||
			path === "/trades" ||
			path === "/trades/mySauda" ||
			(path === "/shopPromotion" && isRetailer)
		) {
			setIsNavBarVisibleForPath(true);
		} else {
			setIsNavBarVisibleForPath(false);
		}
	}, [location?.pathname, isRetailer]);

	/**
	 * The above function uses a MutationObserver to track changes in the DOM and show or hide a modal
	 * component based on the presence of an element with the id "modalComponentVisible".
	 * @param mutationsList - The `mutationsList` parameter in the `callback` function of the `useEffect`
	 * hook is an array of `MutationRecord` objects. Each `MutationRecord` represents a single mutation
	 * that occurred on the observed element or its descendants. It contains information about the type
	 * of mutation, the target element
	 * @param observer - The `observer` parameter in the `callback` function is typically an instance of
	 * `MutationObserver` which is used to observe changes in the DOM and trigger a callback function
	 * when a specific type of mutation occurs. In this case, the `callback` function is observing
	 * mutations related to child elements being added
	 */
	useEffect(() => {
		const callback = (mutationsList, observer) => {
			for (const mutation of mutationsList) {
				if (mutation?.type === "childList") {
					if (document?.getElementById("modalComponentVisible")) {
						setShowModalVisible(true);
					} else {
						setShowModalVisible(false);
					}
				}
			}
		};
		const config = { childList: true, subtree: true };
		// Select the target node
		const targetNode = document?.body;
		// Create an observer instance linked to the callback function
		const observer = new MutationObserver(callback);
		// Start observing the target node for configured mutations
		observer.observe(targetNode, config);
		return () => {
			observer.disconnect();
		};
	}, []);

	// Conditional rendering for the bottom navigation bar
	const renderBottomNav =
		isLoggedIn &&
		!checkingIsLoggedIn &&
		!isWebView &&
		!modalVisible &&
		isNavBarVisibleForPath;

	function getResponsiveStyles() {
		return {
			"@media (max-width: 480px)": {
				width: "100vw",
			},

			"@media (min-width: 481px) and (max-width: 767px)": {
				width: "100vw",
			},

			"@media (min-width: 768px) and (max-width: 1024px)": {
				width: "100vw",
			},

			"@media (min-width: 1025px) and (max-width: 1280px)": {
				width: "30vw",
				margin: "0vw 35vw",
			},

			"@media (min-width: 1281px)": {
				width: "30vw",
				margin: "0vw 35vw",
			},
		};
	}

	return (
		/* This part of the code snippet within the `App` component is responsible for rendering the main
    content of the application along with handling routes and conditionally rendering the bottom
    navigation bar based on certain criteria. */
		<Suspense
			fallback={
				<Box
					sx={{
						height: "100%",
						gap: 8,
						display: "flex",
						flexDirection: "column",
						boxSizing: "border-box",
						...getResponsiveStyles(),
						padding: 8,
					}}
				>
					<MuiSkeleton
						sx={{
							height: 100,
							width: "100%",
							borderRadius: 8,
						}}
						itemNumber={8}
					/>
				</Box>
			}
		>
			<div className="app-container">
				{/* handling routes */}
				<Routes>
					{applicationRoutes.map((route) => (
						<Route
							key={route.path}
							path={route.path}
							Component={route.component}
						>
							{route.routes &&
								route.routes.map((subRoute) => (
									<Route
										key={subRoute.path}
										path={subRoute.path}
										Component={subRoute.component}
									/>
								))}
						</Route>
					))}
				</Routes>
				{/* Render bottom navigation bar if user is logged in*/}
				{renderBottomNav && <BottomNavigationBar isRetailer={isRetailer} />}
			</div>
		</Suspense>
	);
}

export default Sentry.withProfiler(App);
