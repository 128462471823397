/**
 * The `RakePrimary` function in TypeScript React renders a component displaying information about a
 * rake, including its availability status and details, with support for loading states and dynamic
 * language content.
 * @param language - The `language` parameter in the `RakePrimary` component seems to be an object
 * containing language translations for different text elements used in the component. These
 * translations are accessed using keys like `jan`, `feb`, `march`, etc.
 * @param date - It looks like you have provided a code snippet for a React component called
 * `RakePrimary`. This component seems to be responsible for displaying information related to a rake,
 * including its availability and details.
 * @returns The `RakePrimary` component is being returned, which contains JSX elements to display
 * information about a rake. The component includes conditional rendering based on loading states and
 * data availability, as well as functions to format dates and replace placeholders in a template
 * string. The component structure includes various MUI components such as `Box`, `Chip`,
 * `MuiTypography`, `SkeletonLoading`, `MuiButton`, and icons
 */
import React from 'react';
import { Box } from '@mui/material';
import { Chip } from '@mui/joy';
import styles from './style.module.css';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import InsightsRounded from '@mui/icons-material/InsightsRounded';
import AddLocationAltOutLined from '@mui/icons-material/AddLocationAltOutlined';
import TrainOutlined from '@mui/icons-material/TrainOutlined';
import SkeletonLoading from '@mono-farmart-web/farmart-web-common/ComponentsV4/SkeletonLoading';
function rakeDate(language, date) {
  const months = {
    '01': language?.jan,
    '02': language?.feb,
    '03': language?.march,
    '04': language?.april,
    '05': language?.may,
    '06': language?.june,
    '07': language?.july,
    '08': language?.august,
    '09': language?.september,
    10: language?.october,
    11: language?.november,
    12: language?.december,
  };
  const rakeDate = {
    startDate: '',
    startMonth: '',
    startYear: '',
  };
  rakeDate.startDate = date.substring(0, 2);
  rakeDate.startMonth = months[date.substring(3, 5)];
  rakeDate.startYear = date.substring(6, 10);
  return rakeDate;
}
const replacePlaceholders = ({ template, values }) => {
  return template.split(/(<[^>]+>)/).map((part, index) =>
    part.startsWith('<') && part.endsWith('>') ? (
      <span key={index} style={{ fontWeight: 'bold' }}>
        {values[part.replace(/<|>/g, '')]}
      </span>
    ) : (
      <span key={index}>{part}</span>
    ),
  );
};

/**
 * The `RakePrimary` function in TypeScript React renders a component displaying information about a
 * rake, including its availability status and details, with support for loading states and dynamic
 * language content.
 * @param {Object} props - The props object containing the following properties:
 *   - item: The rake item object containing information about the rake.
 *   - language: An object containing language translations for different text elements used in the component.
 *   - onCLickViewRake: Function triggered when the button to view rake details is clicked.
 *   - rakeData: An array containing data related to the rake.
 *   - stateName: The name of the state where the rake is located.
 *   - districtName: The name of the district where the rake is located.
 *   - rakeLoading: Boolean indicating whether rake data is loading.
 *   - homeScreenLoading: Boolean indicating whether the home screen is loading.
 * @returns {JSX.Element} The `RakePrimary` component is being returned, which contains JSX elements to display
 * information about a rake. The component includes conditional rendering based on loading states and
 * data availability, as well as functions to format dates and replace placeholders in a template
 * string. The component structure includes various MUI components such as `Box`, `Chip`,
 * `MuiTypography`, `SkeletonLoading`, `MuiButton`, and icons.
 */

export default function RakePrimary({
  item,
  language,
  onCLickViewRake,
  rakeData,
  stateName,
  districtName,
  rakeLoading,
  homeScreenLoading,
}) {
  let dates;
  if (rakeData && rakeData?.length > 0) {
    dates = rakeDate(language, rakeData[0]?.demand_date_info);
  }
  const template = language?.rakeUnavailable;
  const values = {
    districtName: districtName,
  };

  return (
    <Box className={styles.mainContainer} bgcolor={'background.surface'}>
      {homeScreenLoading || rakeLoading ? (
        <SkeletonLoading className={styles?.loading} />
      ) : (
        <>
          <Box className={styles.infoContainer}>
            <Box className={styles.contentContainer}>
              <Box>
                <MuiTypography level={'title-md'}>
                  {language?.rake}
                </MuiTypography>
                <MuiTypography
                  level="body-xxs"
                  style={{ marginTop: 4 }}
                  textColor={'text.tertiary'}
                >
                  {language?.rakePrimarySubHeading}
                </MuiTypography>
                <Chip
                  style={{
                    borderRadius: 4,
                    gap: 6,
                    marginTop: 6,
                  }}
                  color="neutral"
                  size="sm"
                  startDecorator={<InsightsRounded />}
                >
                  {language?.rakeFomoPrimary}
                </Chip>
              </Box>
            </Box>
            <Box>
              <img
                className={styles.iconImageStyle}
                src={item.features[0]?.icon}
                alt="itemFeatureIcon"
                loading="eager"
              />
            </Box>
          </Box>
          {!districtName ? (
            <Box className={styles.cardContainer}>
              <Box className={styles.noLocationContent}>
                <AddLocationAltOutLined
                  color={'warning'}
                  sx={{ fontSize: 18 }}
                />
                <MuiTypography
                  level={'body-xxs'}
                  color={'warning'}
                  className={styles.cardText}
                >
                  {language?.rakeLocationUnavailableText}
                </MuiTypography>
              </Box>
            </Box>
          ) : rakeData && rakeData?.length > 0 ? (
            <Box className={styles.cardContainer}>
              <Box className={styles.rakePresentContent}>
                <Box display={'block'}>
                  <MuiTypography
                    level={'label-md'}
                    noWrap={true}
                    textColor={'#9FA6AD'}
                    display={'block'}
                  >
                    {rakeData[0]?.from_station}
                  </MuiTypography>
                </Box>
                <Box className={styles.rakeDetailsContainer}>
                  <Box>
                    <MuiTypography level={'body-xs'} color={'neutral'}>
                      {districtName}
                    </MuiTypography>
                    <MuiTypography level={'body-xs'} sx={{ color: '#171A1C' }}>
                      {`${dates?.startDate}-${dates?.startMonth}-${dates?.startYear}`}
                    </MuiTypography>
                  </Box>
                  <Box>
                    <Chip
                      size={'md'}
                      color={
                        rakeData[0]?.is_matured === 0 ? 'warning' : 'success'
                      }
                      variant={'solid'}
                      sx={{ borderRadius: 4 }}
                    >
                      {rakeData[0]?.is_matured === 0
                        ? language?.rakeStatus
                        : language?.rakeReached}
                    </Chip>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box className={styles.cardContainer}>
              <Box className={styles.noRakeContent}>
                <TrainOutlined sx={{ fontSize: 18, color: '#C41C1C' }} />
                <MuiTypography
                  level={'body-xxs'}
                  color={'danger'}
                  className={styles.cardText}
                >
                  {replacePlaceholders({ template, values })}
                </MuiTypography>
              </Box>
            </Box>
          )}
          <MuiButton
            size="md"
            color="primary"
            variant="solid"
            id={'rakePrimaryCardButtonId'}
            onClick={onCLickViewRake}
          >
            {language?.rakePrimaryButton}
          </MuiButton>
        </>
      )}
    </Box>
  );
}
