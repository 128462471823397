import { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { apiEndPoints } from "../../apis/endpoints";
import useAuthenticationChecker from "../../utils/authChecker";
import type { MyFarmerProps } from "./types.js";
import debounce from "../../utils/debounce/debounce";
import { trackEvent } from "../../utils/moengage/index"; // Importing tracking functions
import Events from "../../constant/MoengageEvents/index"; // Importing constant events
import { getLanguageService } from "./../../utils/getLanguageService";

import { languageUrls } from "../../constant/languageUrls/index";

export default function MyFarmerHook(): MyFarmerProps {
	const [language, setLanguageFile] = useState({});

	useEffect(() => {
		getLanguageJson();
	}, []);

	async function getLanguageJson() {
		try {
			const url = languageUrls?.myFarmerScreenLangageUrl;
			const result = await getLanguageService(url);
			setLanguageFile(result);
		} catch (error) {
		} finally {
		}
	}

	const { state } = useLocation();


	const [isErrorPopup, setIsErrorPopup] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [hasMore, setHasMore] = useState(0);

	const navigate = useNavigate();

	/* The line `const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();` is
  destructuring a function called `AuthenticationCheckerAndApiCaller` from the custom hook
  `useAuthenticationChecker`. */
	const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();
	/* The code snippet you provided is initializing multiple state variables using the `useState` hook in
a React functional component. Here's a breakdown of what each state variable is used for: */

	const [farmers, setFarmers] = useState([]);
	const [searchParam, setSearchParam] = useState("");
	const [selectedFarmers, setSelectedFarmers] = useState<number[]>([]);
	const [searchLoading, setSearchLoading] = useState(false); // New state for search loading
	const [initialLoading, setInitialLoading] = useState(true);
	const [firstLaunch, setFirstLaunch] = useState(false);

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

	/**
	 * The function `toggleDeleteModal` toggles the visibility of a delete modal.
	 */
	function toggleDeleteModal(type: string) {
		if (!isDeleteModalVisible) {
			trackEvent(Events.onMyFarmersDeleteButtonClick, {});
		} else {
			if (type === "Cancel") {
				trackEvent(Events.onDeleteFarmerCancelButtonClick, {});
			} else {
				trackEvent(Events.onDeleteFarmerCrossButtonClick, {});
			}
		}
		setIsDeleteModalVisible(!isDeleteModalVisible);
	}

	useEffect(() => {
		if (currentPage !== 1 && searchParam === "") {
			getMyFarmers(searchParam, false);
		} else if (currentPage === 1) {
			getMyFarmers(searchParam, true);
		} else if (currentPage !== 1) {
			getMyFarmers(searchParam, false);
		}
	}, [currentPage, searchParam]);

	/**
	 * The function `getMyFarmers` fetches a list of farmers based on a search parameter and handles
	 * loading states accordingly.
	 * @param {string} search_param - The `search_param` parameter in the `getMyFarmers` function is a
	 * string that is used to search for farmers based on a specific criteria or keyword. This parameter is
	 * passed to the function to filter the list of farmers that will be fetched from the API based on the
	 * search criteria provided.
	 * @param {boolean} isInitialLoad - The `isInitialLoad` parameter is a boolean flag that indicates
	 * whether the function call is for the initial load or not. If `isInitialLoad` is `true`, it means
	 * that the function is being called for the initial load, and if it is `false`, it means that the
	 * function
	 */

	async function getMyFarmers(search_param: string, isInitialLoad: boolean) {
		if (isInitialLoad && firstLaunch) {
			setInitialLoading(true);
		}
		setFirstLaunch(false);
		try {
			const fetchMyFarmersParams = {
				page_no: currentPage,
				limit: 30,
				sort_fashion: "asc",
				search_param: search_param,
			};
			const farmersResp = await AuthenticationCheckerAndApiCaller({
				payload: fetchMyFarmersParams,
				uri: apiEndPoints.fetchFarmerOfUser,
				requestType: "get",
			});

			if (farmersResp?.data?.status) {
				const response = farmersResp?.data;
				if (isInitialLoad) {
					setFarmers(response?.data.rows);
				} else {
					setFarmers((prevFarmers) => [...prevFarmers, ...response.data.rows]);
				}
				setHasMore(response?.data?.count);
			}
		} catch (error) {
			console.log("Error in fetching My Farmers", error);
		} finally {
			if (isInitialLoad) {
				setInitialLoading(false);
			} else {
				setSearchLoading(false);
			}
		}
	}

	function loadMoreFarmers() {
		if (hasMore !== farmers.length && searchParam === "") {
			setCurrentPage((prevPage) => prevPage + 1);
		}
	}

	// Function to handle back button press
	function onPressBack() {
		if (state?.returnPath) {
      navigate(state?.returnPath)
    } else if (state?.from) {
			navigate("/");
		} else {
			navigate(-1);
		}
	}

	/**
	 * The function `onContactUs` navigates to the "/faq/questions" page.
	 */
	function onContactUs() {
		navigate("/faq/questions");
	}

	/**
	 * The function onClickAddFarmer navigates to the "/addFarmerScreen" when called.
	 */
	function onClickAddFarmer() {
		navigate("/addFarmerScreen");
	}

	/**
	 * The function `onPressDelete` handles the deletion of selected farmers, updates the farmer list,
	 * resets selected farmers, and closes the delete modal.
	 */
	async function onPressDelete() {
		// setLoading(true); // Start loading
		try {
			const deleteFarmersParams = {
				farmer_ids: selectedFarmers,
			};
			setFirstLaunch(false);
			const deleteFarmersResp = await AuthenticationCheckerAndApiCaller({
				payload: deleteFarmersParams,
				uri: apiEndPoints.deleteFarmers,
				requestType: "delete",
			});
			if (deleteFarmersResp?.data?.status) {
				setFarmers([]);
				trackEvent(Events.onDeleteFarmerButtonClick, {});
				setCurrentPage(() => {
					return 1;
				});

				getMyFarmers(searchParam, false); //get Farmer list with current Search Params
				setSelectedFarmers([]); // Reset the selected farmers
				setIsDeleteModalVisible(false);
			}
		} catch (error) {
			handleErrorToastPopup();
			console.log("Error in deleting farmers", error);
		} finally {
			// setLoading(false); // End loading
		}
	}

	/**
	 * The function `toggleFarmerSelection` toggles the selection of a farmer by adding or removing their
	 * ID from the list of selected farmers.
	 * @param {number} farmerId - The `farmerId` parameter in the `toggleFarmerSelection` function is a
	 * number that represents the unique identifier of a farmer. This function is used to toggle the
	 * selection of a farmer by adding or removing their `farmerId` from the `selectedFarmers` array.
	 */
	const toggleFarmerSelection = (farmerId: number) => {
		setSelectedFarmers((prevState) =>
			prevState.includes(farmerId)
				? prevState.filter((id) => id !== farmerId)
				: [...prevState, farmerId],
		);
	};

	/* The code snippet you provided is creating a debounced version of the `setSearchParam` function using
the `debounce` utility function. Here's what each part of the code is doing: */
	const debouncedSetSearchParam = useCallback(
		debounce((value: string) => {
			setSearchParam(value);
		}, 300),
		[],
	);

	const handleErrorToastPopup = () => {
		setIsErrorPopup(true);

		setTimeout(() => {
			setIsErrorPopup(false);
		}, 5000);
	};
	// Returning necessary functions and states
	return {
		onPressBack,
		language,
		onContactUs,
		farmers,
		searchParam,
		setSearchParam,
		toggleFarmerSelection,
		selectedFarmers,
		debouncedSetSearchParam,
		initialLoading,
		searchLoading,
		onPressDelete,
		onClickAddFarmer,
		toggleDeleteModal,
		isDeleteModalVisible,
		isErrorPopup,
		currentPage,
		hasMore,
		loadMoreFarmers,
		setCurrentPage,
	};
}
