import React from 'react';
import SmsAcknowledgementView from '../../Pages/SmsAcknowledgementScreen/SmsAcknowledgementView';

import SmsAcknowledgementHooks from '@mono-farmart-web/farmart-web-common/hooks/SmsAcknowledgementHooks/smsAcknowledgementHooks';

export default function otpScreen() {
  const {
    onPressSendNewSms,
    language,
    onContactUs,
    smsBalance,
    smsSent,
    smsId,
  } = SmsAcknowledgementHooks();
  return (
    <SmsAcknowledgementView
      onPressSendNewSms={onPressSendNewSms}
      language={language}
      onContactUs={onContactUs}
      smsBalance={smsBalance}
      smsSent={smsSent}
      smsId={smsId}
    />
  );
}
