import axios from "axios";
import { getAuthTokenFromStorage } from "../../../modal/auth/auth.model";
import { apiEndPoints } from "../../endpoints";
import { isSaudabookPWA } from "@mono-farmart-web/farmart-web-common/utils/common";

interface IGetTripDetailsProps {
	trip_id: string;
  truck_id?: string | number
}

const path = apiEndPoints?.fetchTripDetails;

async function GetTripDetails(props: IGetTripDetailsProps) {
    const authToken = getAuthTokenFromStorage();
    const url = isSaudabookPWA ? `${import.meta.env.VITE_API_BASE_URL}${path}?trip_id=${props?.trip_id}` : `${import.meta.env.VITE_API_BASE_URL}${path}?trip_id=${props?.trip_id}&truck_id=${props?.truck_id}`
	try {
		const res = await axios.get(
			url,
			{
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			},
		);
		const { status, data, message } = res?.data;

		if (status !== true) {
			throw new Error(message);
		}
		return data;
	} catch (error: any) {
		return error?.response?.data;
	}
	// The return value is *not* serialized
	// You can return Date, Map, Set, etc.
}

export default GetTripDetails;
