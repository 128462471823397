import React from 'react';
import { Box } from '@mui/joy';
import Typography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { FaqAnswerDetail } from '@mono-farmart-web/farmart-web-common/hooks/FAQAnswers/types';
import Styles from './style.module.css';

//Interface for AnswerDetailProps
interface AnswerDetailProps {
  faqAnswer: FaqAnswerDetail;
}

// Functional component definition with destructured props
const AnswerDetail = ({ faqAnswer }: AnswerDetailProps) => {
  return (
    <Box className={Styles.answerDetailContainer}>
      <Box className={Styles.questionContainer}>
        <Typography level="title-md">{faqAnswer.question} ?</Typography>
      </Box>
      <Box className={Styles.stepsContainer}>
        {faqAnswer?.steps?.map((step, index) => (
          <Box key={index} className={Styles.listItem}>
            <Typography level={'body-xxs'}>{`STEP ${index + 1}`}</Typography>
            <Typography
              className={Styles.stepText}
              level={'title-sm'}
              sx={{ color: '#555e68' }}
            >
              {step}
            </Typography>
            {index === faqAnswer?.steps?.length - 1 && (
              <Box className={Styles.bottomSpace} /> // Applying bottomSpace style from CSS module for last list item
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AnswerDetail; // Exporting the AnswerDetail component as the default export
