import React from 'react';
import HomeScreen from '../../Pages/HomeScreen/HomeScreen';
import HomeScreenHook from '@mono-farmart-web/farmart-web-common/hooks/HomeScreen/homeScreenList';
import usePostActions from '@mono-farmart-web/farmart-web-common/hooks/community/usePostActions';
import useFetchCommunity from '@mono-farmart-web/farmart-web-common/hooks/community/useFetchCommunity';

export default function HomeScreenMain() {
  const {
    personaModalVisible,
    onClickPersonaCard,
    userSelectedPersona,
    onClickProceedPersona,
    usePerosnaProceedButtonLoading,
    isDownloadAppModalOpen,
    closeDownloadAppModal,
    language,
    onClickpersonaRole,
    onClickDownloadAppNow,
    onChangeLocation,
    homeScreenData,
    weatherData,
    districtName,
    stateName,
    subscribedCropsAdvisoryList,
    totalCropSubscirbedCount,
    totalCredit,
    onCLickViewRake,
    primaryFeatureModalType,
    onClickViewCropAdvisoryPrimary,
    onClickBahiKhataPrimaryButton,
    onClickShopPromotionPrimaryButton,
    onClickSecondaryFeatureCard,
    onClickViewMorePost,
    rakeData,
    totalSmsLeft,
    homeScreenLoading,
    weatherLoading,
    rakeLoading,
    subscribedCropAdvisoryLoading,
    bahiKhataLoading,
    smsLoading,
    userLogedIn,
    shareOnWhatsApp,
    quizImage,
    onClickQuiz,
    addFarmerVisible,
    onClickAddFarmer,
    onClickMyProfile,
    onClickSmsHistory,
    onClickSubCropAdvisory,
    onClickFaq,
    disableQuizClick,
    onClickCloseQuizAlertModal,
    quizAlert,
    onClickViewSubscribedCropsList,
    refreshPage,
    uniqueKey,
    homeScreenAssets,
  } = HomeScreenHook();
  const { isFetchingAllPosts, posts } = useFetchCommunity(
    null,
    homeScreenData.some((item) => item.category_enum_id === 3),
  );
  const { onClap, onComment, onShare, onViewPost } = usePostActions();
  return (
    <HomeScreen
      personaModalVisible={personaModalVisible}
      onClickPersonaCard={onClickPersonaCard}
      userSelectedPersona={userSelectedPersona}
      onClickProceedPersona={onClickProceedPersona}
      usePerosnaProceedButtonLoading={usePerosnaProceedButtonLoading}
      language={language}
      isDownloadAppModalOpen={isDownloadAppModalOpen}
      closeDownloadAppModal={closeDownloadAppModal}
      onClickpersonaRole={onClickpersonaRole}
      onClickDownloadAppNow={onClickDownloadAppNow}
      onChangeLocation={onChangeLocation}
      homeScreenData={homeScreenData}
      posts={posts?.rows}
      onClap={onClap}
      onComment={onComment}
      onShare={onShare}
      onViewPost={onViewPost}
      weatherData={weatherData}
      districtName={districtName}
      stateName={stateName}
      subscribedCropsAdvisoryList={subscribedCropsAdvisoryList}
      totalCropSubscirbedCount={totalCropSubscirbedCount}
      totalCredit={totalCredit}
      onCLickViewRake={onCLickViewRake}
      primaryFeatureModalType={primaryFeatureModalType}
      onClickViewCropAdvisoryPrimary={onClickViewCropAdvisoryPrimary}
      onClickBahiKhataPrimaryButton={onClickBahiKhataPrimaryButton}
      onClickShopPromotionPrimaryButton={onClickShopPromotionPrimaryButton}
      onClickSecondaryFeatureCard={onClickSecondaryFeatureCard}
      onClickViewMorePost={onClickViewMorePost}
      rakeData={rakeData}
      totalSmsLeft={totalSmsLeft}
      isFetchingAllPosts={isFetchingAllPosts}
      homeScreenLoading={homeScreenLoading}
      weatherLoading={weatherLoading}
      rakeLoading={rakeLoading}
      subscribedCropAdvisoryLoading={subscribedCropAdvisoryLoading}
      bahiKhataLoading={bahiKhataLoading}
      smsLoading={smsLoading}
      userLogedIn={userLogedIn}
      shareOnWhatsApp={shareOnWhatsApp}
      quizImage={quizImage}
      onClickQuiz={onClickQuiz}
      addFarmerVisible={addFarmerVisible}
      onClickAddFarmer={onClickAddFarmer}
      onClickMyProfile={onClickMyProfile}
      onClickSmsHistory={onClickSmsHistory}
      onClickSubCropAdvisory={onClickSubCropAdvisory}
      onClickFaq={onClickFaq}
      disableQuizClick={disableQuizClick}
      onClickCloseQuizAlertModal={onClickCloseQuizAlertModal}
      quizAlert={quizAlert}
      onClickViewSubscribedCropsList={onClickViewSubscribedCropsList}
      refreshPage={refreshPage}
      uniqueKey={uniqueKey}
      homeScreenAssets={homeScreenAssets}
    />
  );
}
