import React from 'react';
import Tooltip from '@mono-farmart-web/farmart-web-common/Components/ToolTip';
import Styles from './style.module.css';

const DisclaimerContainer = ({ onClickDisclaimer, id }) => {
  return (
    <div className={Styles.disclaimerContainer}>
      <Tooltip
        contentTitle="Disclaimer"
        contentDetails="इस पोस्ट की सामग्री इस ऐप पर तीसरे पक्ष के उपयोगकर्ता द्वारा बनाई गई है और यह फार्मर्ट का प्रतिनिधित्व नहीं करती है।"
        analyticEvents={onClickDisclaimer}
        id={id}
      >
        <label className={Styles.disclaimerText}>Disclaimer</label>
      </Tooltip>
    </div>
  );
};

export default DisclaimerContainer;
