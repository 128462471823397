import { apiEndPoints } from "../../apis/endpoints";
import GetRequest from '../apiCaller/getRequest';
import GetIpLocation from '../../modal/Traders/models/GetIpLocation';

function trimDecimalValues(value: number, trimUpto: number) {
  return Number(value.toFixed(trimUpto));
}

export default function useFeatureLocation() {
  async function initialLoactionCheck() {
    try {
      const stateId = localStorage.getItem('stateId');
      const districtId = localStorage.getItem('districtId');
      const districtName = localStorage.getItem('districtName');
      const stateName = localStorage.getItem('stateName');
      if (stateId && stateName && districtId && districtName) {
        return {
          master_state_id: JSON.parse(stateId),
          master_state_name: JSON.parse(stateName),
          master_district_id: JSON.parse(districtId),
          master_district_name: JSON.parse(districtName),
        };
      } else {
        const merchantlocationData = await GetRequest({
          uri: apiEndPoints.updateMerchantLocation,
          payload: {},
        });

        if (merchantlocationData?.data?.status) {
          if (merchantlocationData?.data?.data?.master_district_id) {
            return merchantlocationData?.data?.data;
          } else {
            const IpLocationLatLong = await GetIpLocation();

            if (
              IpLocationLatLong?.location?.lat &&
              IpLocationLatLong?.location?.lng
            ) {
              const reverseGeoParams = {
                lat: JSON.stringify(
                  trimDecimalValues(IpLocationLatLong?.location?.lat, 4),
                ),
                long: JSON.stringify(
                  trimDecimalValues(IpLocationLatLong?.location?.lng, 4),
                ),
              };

              const fetchLocationFromLatLongResp = await GetRequest({
                payload: reverseGeoParams,
                uri: apiEndPoints?.fetchStateDistrictsReverseGeoCode,
              });

              if (fetchLocationFromLatLongResp?.data?.data?.reverse_geocode) {
                return fetchLocationFromLatLongResp.data?.data?.reverse_geocode;
              }
            }
          }
        }
      }
    } catch (err) {
      // Handle error as needed
    }
  }

  return { initialLoactionCheck };
}
