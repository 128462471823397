import React from 'react';
import { useDocumentContext } from '../DocumentContext';
import './NavBar.css';
import NavigateBack from '../../../assets/svgComponet/navigateBack';
import DeleteIcon from '../../../assets/svgComponet/DeleteIcon';

import MoreVert from '@mui/icons-material/MoreVert';

const Navbar = ({
  removeDocument,
  onPressClosePreview,
  deleteVisible,
  label,
  downloadVisible,
  onCloseDownloadModal,
  navBarBackIconTestId = '',
  navBarBackIcondataTestId = '',
  navBarLabelTestId = '',
  navBarLabelDataTestId = '',
  navBarMenuTestId = '',
  navBarMenuDataTestId = '',
}) => {
  return (
    <div className="navbar">
      <div
        onClick={onPressClosePreview}
        className="navbar-left"
        id={navBarBackIconTestId}
        data-testid={navBarBackIcondataTestId}
      >
        <NavigateBack />
        <label
          id={navBarLabelTestId}
          data-testid={navBarLabelDataTestId}
          className="label"
        >
          {label}
        </label>
      </div>
      <div className="navbar-right">
        <span style={{ marginLeft: 'auto' }}>
          {deleteVisible && (
            <div onClick={removeDocument} style={{ display: 'flex' }}>
              <DeleteIcon />
            </div>
          )}
          {downloadVisible && (
            <div
              id={navBarMenuTestId}
              data-testid={navBarMenuDataTestId}
              onClick={onCloseDownloadModal}
              style={{ display: 'flex' }}
            >
              <MoreVert />
            </div>
          )}
        </span>
      </div>
    </div>
  );
};

export default Navbar;
