import React from 'react';
import { Box, Button, IconButton } from '@mui/joy';
import { InfoRounded } from '@mui/icons-material';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';

interface HeaderProps {
  language: { [key: string]: any };
  onClickBack?: () => void;
  onClickInfo?: () => void;
  onClickHelp?: () => void;
  isRetailer?: boolean;
}

export default function Header({
  language,
  onClickInfo,
  onClickHelp,
}: HeaderProps) {
  return (
    <Box
      position={'sticky'}
      top={0}
      zIndex={1000}
      bgcolor={'#FFFFFF'}
      paddingRight={4}
      paddingLeft={4}
      paddingTop={3}
      paddingBottom={3}
      borderBottom={1}
      borderColor={'#CDD7E1'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        height={24}
      >
        <MuiTypography level={'title-md'}>
          {language?.headerTitle}
        </MuiTypography>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <Box
          border={1}
          borderColor={'#CDD7E1'}
          height={30}
          width={30}
          borderRadius={8}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          marginRight={2}
        >
          <IconButton
            onClick={onClickInfo}
            id={'onClickInfoButtonSMSLandingId'}
            data-testid={'onClickInfoButtonSMSLandingId'}
          >
            <InfoRounded style={{ color: '#32383E', height: 20, width: 20 }} />
          </IconButton>
        </Box>
        <Button
          size={'sm'}
          variant="soft"
          color="warning"
          style={{ borderRadius: 8, height: 32 }}
          onClick={onClickHelp}
          id={'smsLandingHeaderOnClickHelpId'}
          data-testid={'smsLandingHeaderOnClickHelpId'}
        >
          {language?.help}
        </Button>
      </Box>
    </Box>
  );
}
