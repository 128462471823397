import React, { Suspense } from 'react';

import Styles from './style.module.css';

import type { SaudaDetailHooksTypes } from '../../hooks/types';
import Toast from '@mono-farmart-web/farmart-web-common/ComponentV2/Toast';
import DownloadSnackbar from './DownloadSnackbar';
import saudaStatusEnums from '@mono-farmart-web/farmart-web-common/constant/saudaStatusEnum';
import ErrorMessageToast from '@mono-farmart-web/farmart-web-common/ComponentV2/ErrorMessageToast';
import {
  LoginView,
  useAuth,
} from '@mono-farmart-web/farmart-web-common/context/login';
import DocumentViewer from '@mono-farmart-web/farmart-web-common/ComponentsV3/UploadDocument/viewer/DocumentViewer';
import { DocumentProvider } from '@mono-farmart-web/farmart-web-common/ComponentsV3/UploadDocument/DocumentContext';
import { Box } from '@mui/joy';
import MuiSkeleton from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';
import CommonErrorState from '@mono-farmart-web/farmart-web-common/ComponentsV4/CommonErrorState';
import { getUserProfileFromStorage } from '@mono-farmart-web/farmart-web-common/modal/auth/auth.model';

// Lazy load components
const Header = React.lazy(() => import('./Header'));
const SaudaStatusCard = React.lazy(() => import('./SaudaStatusCard'));
const BuyerInfo = React.lazy(() => import('./BuyerInfo'));
const QualityParameters = React.lazy(() => import('./QualityParameters'));
const OrderFulfilment = React.lazy(() => import('./OrderFulfilment'));
const HelpSection = React.lazy(() => import('./HelpSection'));
const OrderDetails = React.lazy(() => import('./OrderDetails'));
const BottomButton = React.lazy(() => import('./BottomButton'));

const TruckDetailModal = React.lazy(() => import('./TruckDetailModal'));
const RejectPOModal = React.lazy(() => import('./RejectPOModal'));
const AcceptPOModal = React.lazy(() => import('./AcceptPOModal'));
const TruckDetails = React.lazy(() => import('./TruckDetails'));
const CancelSaudaModal = React.lazy(() => import('./CancelSaudaModal'));
const PaymentTermsModal = React.lazy(() => import('./PaymentTermsModal'));

const BuyerDetails = (props: SaudaDetailHooksTypes) => {
  const {
    onClickBack,
    onClickHelp,
    onCallNow,
    numberCopyToast,
    isTruckModalVisible,
    saudaDetailData,
    onClickViewPo,
    truckDetailData,
    onCopyUtr,
    utrCopyToast,
    saudaPageLoading,
    saudaTruckLoading,
    isPaymentTermInfoVisible,
    onClickPaymentTermInfo,
    onDownloadPdf,
    downloadingPdf,
    showCancelSaudaModal,
    toggleCancelSaudaModal,
    showAcceptPOModal,
    toggleAcceptPO,
    acceptPODisabled,
    onClickTermsAndCondition,
    showRejectPOModal,
    toggleRejectPOModal,
    updatepoStatus,
    updatePoLoading,
    language,
    brokeragePopup,
    handleBrokeragePopup,
    financingPopup,
    handleFinancingPopup,
    netPopup,
    handleNetPopup,
    onClickPaymentModalGotItButton,
    isError,
    setIsError,
    onClickReUploadTruckDetailsCard,
    onClickTruckNumberTruckDetailsCard,
    onClickCreateDispatch,
    onPressClosePreview,
    preViewDocument,
    downloadPdfModal,
    toggleDownloadPdfModal,
    selectedDocument,
    saudaDetailsAssets,
    onPressHelpErrorState,
    onPressRetryErrorState,
    showTourGuide,
    onClickTourGuide,
    truckDetailModalError,
    onPressRetryModalErrorState,
    triggerEventForPoCard,
    triggerEvent
  } = props;
  const userProfile = getUserProfileFromStorage() || null;
  const userlogedIn =
    JSON.parse(localStorage.getItem('loged')) ||
    userProfile?.merchant_detail_id ||
    userProfile?.merchant_occupation_id;
  return (
    <>
      {selectedDocument ? (
        <DocumentProvider
          initialDocuments={[
            {
              uri: selectedDocument,
              fileName: '',
            },
          ]}
          //onPressClosePreview={onPressClosePreview}
          label={language?.poReciept}
          deleteVisible={false}
          downloadVisible={true}
          onDownload={onDownloadPdf}
          downloadModal={downloadPdfModal}
          onCloseDownloadModal={toggleDownloadPdfModal}
          onPressClosePreview={onPressClosePreview}
          navBarBackIconTestId="backarrowtab_POreciept_saudadetailscreen"
          navBarBackIcondataTestId="backArowTabPoRecieptSaudaDetailScreen"
          navBarLabelTestId="poreceipt_text_porecieptscreen"
          navBarLabelDataTestId="poReceiptTextPoRecieptScreenTestId"
          navBarMenuTestId="threedot_poreceiptscreen"
          navBarMenuDataTestId="threeDotPoRecieptScreenTestId"
          modalIconDataTestId="closeTabPoRecieptModalTestId"
          modalIconTestId="closetab_poreciept_modal"
          modalTitelTestId="poreceipt_text_modal"
          modalTitleDataTestId="poReceiptTextTestId"
          modalTitle={language?.poReciept}
        >
          <DocumentViewer />
        </DocumentProvider>
      ) : (
        <div className={Styles.container}>
          {isError ? (
            <CommonErrorState
              onPressHelp={onPressHelpErrorState}
              onPressRetry={onPressRetryErrorState}
            />
          ) : (
            <>
              <Suspense
                fallback={
                  <MuiSkeleton
                    sx={{
                      height: 60,
                      width: '100%',
                      marginBottom: 1,
                    }}
                  />
                }
              >
                <Header
                  onClickBack={onClickBack}
                  saudaDetailData={saudaDetailData}
                  language={language}
                  saudaPageLoading={saudaPageLoading}
                />
              </Suspense>
              <Toast
                isError={false}
                message={language?.mobileNumberCopytext}
                isOpen={numberCopyToast}
              />

              <Toast
                isError={false}
                message={language?.utrCopyPopup}
                isOpen={utrCopyToast}
              />
              <DownloadSnackbar
                language={language}
                downloadingPdf={downloadingPdf}
              />

              {saudaPageLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop={'53px'}
                  flexDirection={'column'}
                  gap={3}
                >
                  <MuiSkeleton
                    sx={{ width: '100%', height: 130 }}
                    itemNumber={5}
                  />
                </Box>
              ) : (
                <div className={Styles.restOfContainer}>
                  <Suspense
                    fallback={
                      <MuiSkeleton
                        sx={{
                          height: 130,
                          width: '100%',

                          marginBottom: 4,
                        }}
                      />
                    }
                  >
                    <SaudaStatusCard
                      saudaDetailData={saudaDetailData}
                      toggleAcceptPO={toggleAcceptPO}
                      toggleRejectPOModal={toggleRejectPOModal}
                      language={language}
                      onClickCreateDispatch={onClickCreateDispatch}
                      saudaDetailImage={saudaDetailsAssets.saudaDetailsImage}
                      onTimeIcon={saudaDetailsAssets.onTimeIcon}
                    />
                  </Suspense>
                  <Suspense
                    fallback={
                      <MuiSkeleton
                        sx={{
                          height: 130,
                          width: '100%',

                          marginBottom: 4,
                        }}
                      />
                    }
                  >
                    <OrderDetails
                      onDownloadPdf={onDownloadPdf}
                      saudaDetailData={saudaDetailData}
                      onClickPaymentTermInfo={onClickPaymentTermInfo}
                      showTourGuide={showTourGuide}
                      onClickTourGuide={onClickTourGuide}
                      language={language}
                      preViewDocument={preViewDocument}
                      triggerEvent={triggerEvent}
                    />
                  </Suspense>
                  <Suspense
                    fallback={
                      <MuiSkeleton
                        sx={{
                          height: 130,
                          width: '100%',
                          marginBottom: 4,
                        }}
                      />
                    }
                  >
                    <TruckDetails
                      saudaDetailData={saudaDetailData}
                      onClickViewPo={onClickViewPo}
                      language={language}
                      triggerEventForPoCard={triggerEventForPoCard}
                      onClickReUploadTruckDetailsCard={
                        onClickReUploadTruckDetailsCard
                      }
                      onClickTruckNumberTruckDetailsCard={
                        onClickTruckNumberTruckDetailsCard
                      }
                      onClickCreateDispatch={onClickCreateDispatch}
                    />
                  </Suspense>
                  <Suspense
                    fallback={
                      <MuiSkeleton
                        sx={{
                          height: 130,
                          width: '100%',
                          marginBottom: 4,
                        }}
                      />
                    }
                  >
                    <BuyerInfo
                      saudaDetailData={saudaDetailData}
                      language={language}
                    />
                  </Suspense>
                  <Suspense
                    fallback={
                      <MuiSkeleton
                        sx={{
                          height: 130,
                          width: '100%',
                          marginBottom: 4,
                        }}
                      />
                    }
                  >
                    <OrderFulfilment
                      saudaDetailData={saudaDetailData}
                      language={language}
                    />
                  </Suspense>
                  <Suspense
                    fallback={
                      <MuiSkeleton
                        sx={{
                          height: 130,
                          width: '100%',
                          marginBottom: 4,
                        }}
                      />
                    }
                  >
                    <QualityParameters
                      saudaDetailData={saudaDetailData}
                      language={language}
                    />
                  </Suspense>
                  <Suspense
                    fallback={
                      <MuiSkeleton
                        sx={{
                          height: 130,
                          width: '100%',
                          marginBottom: 4,
                        }}
                      />
                    }
                  >
                    <HelpSection
                      onClickHelp={onClickHelp}
                      onCallNow={onCallNow}
                      saudaDetailData={saudaDetailData}
                      language={language}
                      whatsappIcon={saudaDetailsAssets.whatsappIcon}
                    />
                  </Suspense>
                  {saudaDetailData?.sauda_status_id ===
                    saudaStatusEnums.get('saudaInReview') && (
                    <BottomButton
                      toggleCancelSaudaModal={toggleCancelSaudaModal}
                      language={language}
                    />
                  )}
                  <Suspense
                    fallback={
                      <MuiSkeleton
                        sx={{
                          height: 130,
                          width: '100%',
                          marginBottom: 4,
                        }}
                      />
                    }
                  >
                    <TruckDetailModal
                      preViewDocument={preViewDocument}
                      onCopyUtr={onCopyUtr}
                      truckDetailData={truckDetailData}
                      onClickViewPo={onClickViewPo}
                      saudaDetailData={saudaDetailData}
                      isTruckModalVisible={isTruckModalVisible}
                      saudaTruckLoading={saudaTruckLoading}
                      language={language}
                      onClickPaymentModalGotItButton={
                        onClickPaymentModalGotItButton
                      }
                      onTimeIcon={saudaDetailsAssets.onTimeIcon}
                      magicIcon={saudaDetailsAssets.magicIcon}
                      truckDetailModalError={truckDetailModalError}
                      onPressRetryModalErrorState={onPressRetryModalErrorState}
                      onPressHelpErrorState={onPressHelpErrorState}
                    />
                  </Suspense>
                  <Suspense
                    fallback={
                      <MuiSkeleton
                        sx={{
                          height: 130,
                          width: '100%',
                          marginBottom: 4,
                        }}
                      />
                    }
                  >
                    <PaymentTermsModal
                      brokeragePopup={brokeragePopup}
                      handleBrokeragePopup={handleBrokeragePopup}
                      financingPopup={financingPopup}
                      handleFinancingPopup={handleFinancingPopup}
                      netPopup={netPopup}
                      handleNetPopup={handleNetPopup}
                      saudaDetailData={saudaDetailData}
                      language={language}
                      isPaymentTermInfoVisible={isPaymentTermInfoVisible}
                      onClickPaymentTermInfo={onClickPaymentTermInfo}
                      magicIcon={saudaDetailsAssets.magicIcon}
                      onDownloadPdf={onDownloadPdf}
                      downloadingPdf={downloadingPdf}
                    />
                  </Suspense>
                  <Suspense
                    fallback={
                      <MuiSkeleton
                        sx={{
                          height: 130,
                          width: '100%',
                          marginBottom: 4,
                        }}
                      />
                    }
                  >
                    <AcceptPOModal
                      showAcceptPOModal={showAcceptPOModal}
                      toggleAcceptPO={toggleAcceptPO}
                      language={language}
                      acceptPODisabled={acceptPODisabled}
                      onClickTermsAndCondition={onClickTermsAndCondition}
                      saudaDetailData={saudaDetailData}
                      onDownloadPdf={onDownloadPdf}
                      updatepoStatus={updatepoStatus}
                      updatePoLoading={updatePoLoading}
                      activeCheckboxIcon={saudaDetailsAssets.activeSvg}
                      inactiveCheckboxIcon={saudaDetailsAssets.inactiveSvg}
                    />
                  </Suspense>
                  <Suspense
                    fallback={
                      <MuiSkeleton
                        sx={{
                          height: 130,
                          width: '100%',
                          marginBottom: 4,
                        }}
                      />
                    }
                  >
                    <RejectPOModal
                      saudaDetailData={saudaDetailData}
                      language={language}
                      showRejectPOModal={showRejectPOModal}
                      toggleRejectPOModal={toggleRejectPOModal}
                      onDownloadPdf={onDownloadPdf}
                      onClickPaymentTermInfo={onClickPaymentTermInfo}
                      updatepoStatus={updatepoStatus}
                      updatePoLoading={updatePoLoading}
                    />
                  </Suspense>
                  <Suspense
                    fallback={
                      <MuiSkeleton
                        sx={{
                          height: 130,
                          width: '100%',
                          marginBottom: 4,
                        }}
                      />
                    }
                  >
                    <CancelSaudaModal
                      saudaDetailData={saudaDetailData}
                      language={language}
                      updatepoStatus={updatepoStatus}
                      updatePoLoading={updatePoLoading}
                      showCancelSaudaModal={showCancelSaudaModal}
                      toggleCancelSaudaModal={toggleCancelSaudaModal}
                    />
                  </Suspense>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {/* <ErrorMessageToast
			message={language?.errorMessage}
			isError={isError}
			setIsError={setIsError}
			ErrorToastType={true}
		  /> */}
      {!userlogedIn && <LoginView />}
    </>
  );
};

export default BuyerDetails;
