// biome-ignore lint/style/useImportType: <explanation>
import React from "react";
import AccordionGroup, {
	type AccordionGroupProps,
} from "@mui/joy/AccordionGroup";
import Accordion, { type AccordionProps } from "@mui/joy/Accordion";
import AccordionDetails, {
	type AccordionDetailsProps,
} from "@mui/joy/AccordionDetails";
import AccordionSummary, {
	type AccordionSummaryProps,
} from "@mui/joy/AccordionSummary";

interface MuiAccordionProps {
	title: string;
	children: React.ReactNode;
	accordionGroupProps?: AccordionGroupProps;
	accordionProps?: AccordionProps;
	accordionDetailsProps?: AccordionDetailsProps;
	accordionSummaryProps?: AccordionSummaryProps;
	onClick?: () => void;
}

export default function MuiAccordion(props: MuiAccordionProps) {
	const {
		title,
		children,
		accordionGroupProps,
		accordionProps,
		accordionDetailsProps,
		accordionSummaryProps,
		onClick=() => {},
	} = props;
	return (
		<AccordionGroup disableDivider {...accordionGroupProps} onClick={onClick}>
			<Accordion {...accordionProps}>
				<AccordionSummary {...accordionSummaryProps}>{title}</AccordionSummary>
				<AccordionDetails {...accordionDetailsProps} onClick={(event) => event?.stopPropagation()}>
					{children}
				</AccordionDetails>
			</Accordion>
		</AccordionGroup>
	);
}
