import React from 'react';
import PaymentScreenView from '../../Pages/PaymentScreen';
import usePaymentScreen from '../../hooks/usePaymentHooks';

export default function PaymentScreenMain() {
  const {
    language,
    paymentAssets,
    paymentData,
    onViewSaudaDetails,
    onClickBack,
    error,
    loading,
  } = usePaymentScreen();
  return (
    <PaymentScreenView
      language={language}
      paymentAssets={paymentAssets}
      paymentData={paymentData}
      onViewSaudaDetails={onViewSaudaDetails}
      onClickBack={onClickBack}
      error={error}
      loading={loading}
    />
  );
}
