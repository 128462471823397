export default function IndianFormatedDate(dateStr: string) {
  const date = new Date(dateStr);
  if (isNaN(date.getTime())) {
    // Invalid date
    return '';
  }

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formattedDate = date.toLocaleString('en-IN', options);
  return formattedDate.replace(',', '');
}
