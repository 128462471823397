const tripTrackingStatus = new Map();

tripTrackingStatus.set('CONSENT_PENDING', 1); //STAGE 6
tripTrackingStatus.set('ON_TRACK', 2); //STAGE 3         //ToDo: if updated_at: null then Driver Consent Received//STAGE-1: TRACKING INITIATED
tripTrackingStatus.set('CONSENT_REMOVED', 3); //STAGE 5
tripTrackingStatus.set('HALTED', 4); //STAGE 4
tripTrackingStatus.set('POTENTIAL_DELAY', 5); //STAGE 7
tripTrackingStatus.set('DRIVER_NOT_REACHABLE', 6); //STAGE 8
tripTrackingStatus.set('ARRIVED', 7); //STAGE-9
tripTrackingStatus.set('COMPLETED', 8); //STAGE-10
tripTrackingStatus.set('TERMINATED', 9); //STAGE-11

//STAGE 2: CONSENT RECEIVED

export default tripTrackingStatus;
