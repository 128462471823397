import React from 'react';
import replaceVariableInString from '@mono-farmart-web/farmart-web-common/utils/replacevariableInLanguage';
import Style from './style.module.css';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';

interface AcknowldegeDiscountCardProps {
  quantity: number; // Quantity of the crop
  netPrice: number; // Net price of the crop
  paymentTerms: string; // Payment terms for the transaction
  priceBreakup: any; // Detailed price breakup information
  language: { [key: string]: any }; // Language settings for localization
}

const AMOUNT_MULTIPLIER = 10;

// Component to display discount card for the user
export default function DiscountCard({
  quantity,
  netPrice,
  paymentTerms,
  priceBreakup,
  language,
}: AcknowldegeDiscountCardProps) {
  const netDiscount: any =
    paymentTerms === 'grns-term'
      ? priceBreakup?.price_breakup_grn?.net_discount * Number(quantity)
      : priceBreakup?.price_breakup_buyer?.net_discount * Number(quantity);
  return paymentTerms === 'grns-term' &&
    priceBreakup?.price_breakup_grn?.discounted_fee !== null &&
    priceBreakup?.price_breakup_grn?.discounted_fee !== 0 ? (
    <div className={Style.discountCardContainer}>
      <div className={Style.saudaDetailContainer}>
        <div className={Style.saudaDetailIcon}>
          <DescriptionRoundedIcon />
        </div>

        <div className={Style.saudaDetailContent}>
          <label className={Style.saudavalueText}>
            {replaceVariableInString(
              (quantity * netPrice).toFixed(2).toString(),
              language?.totalSaudaValue,
            )}
          </label>
          <div className={Style.discountChip}>
            <label className={Style.discountChipText}>
              {replaceVariableInString(
                (netDiscount.toFixed(2) * AMOUNT_MULTIPLIER)?.toString(),
                language?.amountSaved,
              )}
            </label>
          </div>
        </div>
      </div>
      <div className={Style.infoContainer}>
        <div className={Style.saudaDetailInfoIcon}>
          <InfoOutlined fontSize="small" sx={{color:'#535F77'}}/>
        </div>
        <div>
          <label className={Style.saudaPriceText}>
            {language?.saudaPriceAndQuantity}
          </label>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
