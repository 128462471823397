import React from "react";
import TakeConsentView from "./viewmodel/TakeConsent";
import  TripDetailView from "./viewmodel/TripDetail";
import { useSearchParams } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// TAKE CONSENT COMPONENT;
const TakeConsent = () => {
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const {
      language,
      activeLanguage,
      truck_id,
      trip_id,
      initial=false,
      saudaId,
      dispatchId,
      saudaDetailData
    } = location.state || {};

    return (
        <TakeConsentView
          trip_id={trip_id}
          isInitialTrip={initial === "true"}
          language={language}
          activeLanguage={activeLanguage}
          truck_id={truck_id}
          saudaId={saudaId}
          dispatchId={dispatchId}
          saudaDetailData={saudaDetailData} />
    )
}

// TRIP DETAIL COMPONENT;
const TripDetail = () => {
    const params = useParams();

    const { tripId } = params;
    return (
        <TripDetailView tripId={tripId} />
    )

}

const TripConsentRoutes = [
  {
    path: '/trades/takeconsent',
    component: TakeConsent,
    exact: true,
  },
  {
    path: '/trades/tripDetail/:tripId',
    component: TripDetail,
    exact: true,
  }
];

export {
  TakeConsent,
  TripDetail,
  TripConsentRoutes
};




