import React from 'react';
import Style from './style.module.css';
import { Box } from '@mui/joy';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import Popup from '@mono-farmart-web/farmart-web-common/Components/Popup';
import popEnums from '@mono-farmart-web/farmart-web-common/constant/enums/popEnums';
import { PopDataInterface } from '@mono-farmart-web/farmart-web-common/hooks/SaudaAcknowledgement/types';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import formatPrice from '@mono-farmart-web/farmart-web-common/utils/formatPrice'

export interface PayAtLoadingCardProps {
  priceBreakup: any; // Detailed price breakup information
  quantityUnit: string; // Unit of the quantity (e.g., kg, tons)
  cropName: string; // Name of the crop
  buyerName: string; // Name of the buyer
  buyerAddress: string; // Address of the buyer
  quantity: number; // Quantity of the crop
  language: { [key: string]: any }; // Language settings for localization
  onClickPopUpDisable: (id?: number) => void; // Function to handle popup disable
  popupVisible: boolean; // Flag to show popup
  popupData: PopDataInterface; // PopDataInterface
  paymentTerms: string; // Payment terms for the transaction
}

// Component to display pay at loading card for the user
export default function PayAtLoadingCard({
  priceBreakup,
  quantityUnit,
  cropName,
  buyerName,
  buyerAddress,
  quantity,
  language,
  onClickPopUpDisable,
  popupVisible,
  popupData,
  paymentTerms,
}: PayAtLoadingCardProps) {
  const renderTextWithColors = ({ text, replaceText }) => {
    if (!text) {
      return;
    }
    const splitText = text?.split(/{|}/);
    return splitText.map((item, index) => {
      if (item.startsWith('(') && item.endsWith(')')) {
        return (
          <span key={'payAtLoadingRenderTextColor' + index}>{replaceText}</span>
        );
      }
      return <>{item}</>;
    });
  };
  return (
    <Box className={Style?.container}>
      <Box className={Style?.buyerContainer}>
        <Box className={Style?.buyerNameContainer}>
          <label className={Style?.buyerNameTextStyle}>{buyerName}</label>
        </Box>
        <Box className={Style?.buyerLocation}>
          <Box className={Style?.buyerLocationTextContainer}>
            <label className={Style?.stateDistrict}>{buyerAddress}</label>
          </Box>
          {/*  <Box className={Style?.buyerLocationTextContainer}>
                        <label className={Style?.stateDistrict}>BIHAR</label>
                    </Box> */}
        </Box>
      </Box>
      <Box className={Style?.payAtLoadingContainer}>
        <Box className={Style?.cropNamePriceContainer}>
          <MuiTypography level="title-sm">{cropName}</MuiTypography>
          <MuiTypography level="title-sm">
            {quantity} {quantityUnit}
          </MuiTypography>
        </Box>
        <Box className={Style?.payAtLoadingDetailContainer}>
          <Box className={Style?.payAtLoadingTitleContainer}>
            <MuiTypography level="title-sm">
              {paymentTerms === 'buyers-term'
                ? language?.payAtBuyersTerms
                : language?.payAtUnloading}
            </MuiTypography>
            <Box className={Style?.boldDivider}></Box>
          </Box>
          <Box className={Style?.payAtLoadingItemContainer}>
            <Box className={Style?.priceBreakdownContainer}>
              <Box className={Style?.netPriceDetailContainer}>
                <label className={Style?.priceBreakdownTitile}>
                  {language?.grossBuyerPrice}
                </label>
                <Box className={Style?.netPriceRateContainer}>
                  <label className={Style?.priceBreakdownRateText}>
                    {formatPrice({
                      prefix: priceBreakup?.gross_price_unit_prefix,
                      suffix: priceBreakup?.gross_price_unit_suffix,
                      value: priceBreakup?.gross_price,
                    })}
                  </label>
                </Box>
              </Box>
              <Box className={Style?.netPriceDetailContainer}>
                <label className={Style?.priceBreakdownTitile}>
                  {language?.cashDiscount}
                </label>
                <Box className={Style?.netPriceRateContainer}>
                  <label className={Style?.priceBreakdownRateText}>
                    {priceBreakup?.cash_discount} %
                  </label>
                </Box>
              </Box>
              <Box className={Style?.netPriceDetailContainer}>
                <label className={Style?.priceBreakdownTitile}>
                  {language?.bagDeduction}
                </label>
                <Box className={Style?.netPriceRateContainer}>
                  <label className={Style?.priceBreakdownRateText}>
                    {priceBreakup?.bag_deduction}{' '}
                    {priceBreakup?.bag_deduction_unit}
                  </label>
                </Box>
              </Box>
              <Box className={Style?.netPriceDetailContainer}>
                <label className={Style?.priceBreakdownTitile}>
                  {language?.unloadingCharges}
                </label>
                <Box className={Style?.netPriceRateContainer}>
                  <label className={Style?.priceBreakdownRateText}>
                    {formatPrice({
                      prefix: priceBreakup?.unloading_price_unit_prefix,
                      suffix: priceBreakup?.unloading_price_unit_suffix,
                      value: priceBreakup?.unloading_price,
                    })}
                  </label>
                </Box>
              </Box>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="2"
                viewBox="0 0 100% 2"
                fill="none"
              >
                <path
                  d="M1 1H512"
                  stroke="#DFE1E5"
                  strokeLinecap="round"
                  strokeDasharray="4 4"
                />
              </svg>
              <Box className={Style?.netPriceDetailContainer}>
                <label className={Style?.priceBreakdownTitile}>
                  {language?.netbuyerPrice}
                </label>
                <Box className={Style?.netPriceRateContainer}>
                  <label className={Style?.priceBreakdownRateText}>
                    {formatPrice({
                      prefix: priceBreakup?.net_buyer_price_unit_prefix,
                      suffix: priceBreakup?.net_buyer_price_unit_suffix,
                      value: priceBreakup?.net_buyer_price,
                    })}
                  </label>
                </Box>
              </Box>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="2"
                viewBox="0 0 100% 2"
                fill="none"
              >
                <path
                  d="M1 1H512"
                  stroke="#DFE1E5"
                  strokeLinecap="round"
                  strokeDasharray="4 4"
                />
              </svg>
              <Box className={Style?.netPriceDetailContainer}>
                <Box className={Style?.priceBrakdownWithIcon}>
                  <label className={Style?.priceBreakdownTitile}>
                    {language?.brokerage}
                  </label>
                  <InfoOutlined
                    fontSize="small"
                    onClick={() => {
                      onClickPopUpDisable(popEnums.get('brokerage'));
                    }}
                    id="brokeragePopUpIconSaudaAcknolwdegement"
                    sx={{color:'#616C82'}}
                  />
                </Box>
                <Box className={Style?.netPriceRateContainer}>
                  <label className={Style?.priceBreakdownRateText}>
                    {formatPrice({
                      prefix: priceBreakup?.brokerage_price_unit_prefix,
                      suffix: priceBreakup?.brokerage_price_unit_suffix,
                      value: priceBreakup?.brokerage_price,
                    })}
                  </label>
                </Box>
              </Box>
              <Box className={Style?.netPriceDetailContainer}>
                <Box className={Style?.priceBrakdownWithIcon}>
                  <label className={Style?.priceBreakdownTitile}>
                    {language?.farMartFee}
                  </label>
                  <InfoOutlined
                    fontSize="small"
                    onClick={() => {
                      onClickPopUpDisable(popEnums.get('farmartFee'));
                    }}
                    id="farmartFeePopUpIconSaudaAcknolwdegement"
                    sx={{color:'#616C82'}}
                  />
                </Box>
                <Box className={Style?.netPriceRateContainer}>
                  <label className={Style?.priceBreakdownRateText}>
                    {priceBreakup?.farmart_fee} {priceBreakup?.farmart_fee_unit}
                  </label>
                </Box>
              </Box>
              {priceBreakup?.discounted_fee &&
                priceBreakup?.discounted_fee !== 0 &&
                priceBreakup?.discounted_fee !== null && (
                  <Box className={Style?.netPriceDetailContainer}>
                    <label className={Style?.priceBreakdownTitileMarked}>
                      {renderTextWithColors({
                        text: language?.discountedFarMartFee,
                        replaceText: priceBreakup?.discount_in_percent,
                      })}
                    </label>
                    <Box className={Style?.netPriceRateContainer}>
                      <label className={Style?.discountedFee}>
                        {priceBreakup?.farmart_fee}
                      </label>
                      <label className={Style?.priceBreakdownTitileMarked}>
                        {priceBreakup?.discounted_fee}{' '}
                        {priceBreakup?.discounted_fee_unit}
                      </label>
                    </Box>
                  </Box>
                )}
              <Box className={Style?.netPriceContainer}>
                <Box className={Style?.divider}></Box>
                <Box className={Style?.netPriceDetailContainer}>
                  <Box className={Style?.netPriceNameContainer}>
                    <MuiTypography level="body-xs" sx={{color:"#616C82"}}>
                      {language?.netForPrice}
                    </MuiTypography>
                    <InfoOutlined
                      fontSize="small"
                      onClick={() => {
                        onClickPopUpDisable(popEnums.get('netForPrice'));
                      }}
                      id="netForPricePopUpIconSaudaAcknolwdegement"
                      sx={{color:'#616C82'}}
                    />
                  </Box>
                  <Box className={Style?.netPriceNameContainer}>
                    <label className={Style?.netPriceRateTitleContainer}>
                      {formatPrice({
                        prefix: priceBreakup?.net_price_unit_prefix,
                        suffix: priceBreakup?.net_price_unit_suffix,
                        value: priceBreakup?.net_price,
                      })}
                    </label>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Popup
        show={popupVisible}
        onClose={onClickPopUpDisable}
        data={popupData}
      />
    </Box>
  );
}
