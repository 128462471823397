import React from 'react';
import Styles from './style.module.css';
import PersonaCard from './PersonaCard';
import OccupationImages from '@mono-farmart-web/farmart-web-common/constant/images';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';

interface UserPersonaListInterface {
  onClickPersonaCard: (key: string) => () => void;
  selectedPersona: string;
  language: { [key: string]: any };
}

export default function UserPersona(props: UserPersonaListInterface) {
  const { onClickPersonaCard, selectedPersona, language } = props;
  return (
    <div className={Styles.personaContainer}>
      <div className={Styles.optionTextContainer}>
        <MuiTypography level="title-md">{language?.whatYouDo}</MuiTypography>
        <MuiTypography level="body-sm" textColor={'#555E68'}>
          {language?.applicableProfession}
        </MuiTypography>
      </div>
      <div className={Styles.personaCardListContainer}>
        {Object?.keys(OccupationImages?.occupationImages)?.map(function (
          key,
          index,
        ) {
          return (
            <PersonaCard
              key={index + 'persona'}
              personaImages={OccupationImages?.occupationImages[key]}
              index={index}
              onClickPersonaCard={onClickPersonaCard}
              selectedPersonaKey={key}
              selectedPersona={selectedPersona}
              language={language}
            />
          );
        })}
      </div>
    </div>
  );
}
