import React, { memo } from 'react';
import Styles from './style.module.css';
import { Box } from '@mui/joy';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import MuiSkeleton from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';
import { createdBy } from '@mono-farmart-web/farmart-web-common/modal/community/types';
import PostTimeCalculation from '@mono-farmart-web/farmart-web-common/constant/postTimeCalculation';

interface PostHeaderProps {
  onClickBack: () => void;
  createdBy: createdBy;
  liveFrom: string;
  PostDetailLoading: boolean;
  profileLoadingOnClickBackId?: string;
  profileContainerOnClickBackId?: string;
  userProfileIcon: string;
}

const Header = (props: PostHeaderProps) => {
  const {
    onClickBack,
    createdBy,
    liveFrom,
    PostDetailLoading,
    profileLoadingOnClickBackId = '',
    profileContainerOnClickBackId = '',
    userProfileIcon,
  } = props;
  return (
    <div className={Styles.mainContainer}>
      {PostDetailLoading ? (
        <ProfileLoading
          onClickBack={onClickBack}
          profileLoadingOnClickBackId={profileLoadingOnClickBackId}
        />
      ) : (
        <ProfileContainer
          createdBy={createdBy}
          liveFrom={liveFrom}
          onClickBack={onClickBack}
          profileContainerOnClickBackId={profileContainerOnClickBackId}
          userProfileIcon={userProfileIcon}
        />
      )}
    </div>
  );
};

export default memo(Header);

const ProfileContainer = ({
  createdBy,
  liveFrom,
  onClickBack,
  profileContainerOnClickBackId,
  userProfileIcon,
}) => {
  return (
    <div className={Styles.profileContainer}>
      <div
        className={Styles.backIcon}
        onClick={onClickBack}
        id={profileContainerOnClickBackId}
      >
        <ArrowBackRoundedIcon sx={{ fill: '#293756' }} />
      </div>
      <div className={Styles.profileDetailsContainer}>
        <div className={Styles.profileImage}>
          <img
            className={`${createdBy?.page ? Styles.page : Styles.user} `}
            src={
              createdBy?.profile_pic_url
                ? createdBy?.profile_pic_url
                : userProfileIcon
            }
            alt="profilePic"
            loading="eager"
          />
        </div>
        <div className={Styles.profileDetailsSubContainer}>
          <div className={Styles.profileNameCumPostTimeContainer}>
            <label className={Styles.nameTextStyle}>{createdBy?.name}</label>
          </div>
          <label className={Styles.postTimeTextStyle}>
            {PostTimeCalculation({ utcTimestamp: liveFrom })}
          </label>
        </div>
      </div>
    </div>
  );
};

const ProfileLoading = ({ onClickBack, profileLoadingOnClickBackId }) => {
  return (
    <div className={Styles.profileContainer}>
      <div
        id={profileLoadingOnClickBackId}
        className={Styles.backIcon}
        onClick={onClickBack}
      >
        <ArrowBackRoundedIcon sx={{ fill: '#293756' }} />
      </div>
      <Box display={'flex'}>
        <MuiSkeleton
          sx={{ height: '36px', width: '36px', borderRadius: '8px' }}
        />
        <Box paddingLeft={2}>
          <MuiSkeleton
            sx={{
              height: '12px',
              borderRadius: '8px',
              width: '150px',
              marginBottom: '10px',
            }}
          />
          <MuiSkeleton
            sx={{ height: '12px', borderRadius: '8px', width: '70px' }}
          />
        </Box>
      </Box>
    </div>
  );
};
