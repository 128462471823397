/**
 * The `OtpSubmitHook` function handles OTP validation, language selection, navigation, API calls, and
 * tracking events in a React component.
 * @returns The `OtpSubmitHook` function is returning an object containing various functions and states
 * that are used in the OTP submission process. Here is a summary of what is being returned:
 */
import { useState, useEffect } from "react";
import type { OTPInputProps, LanguageParams } from "./types.js"; // Importing necessary types
import getLang from "../../languages/otp.js"; // Importing language translation function
import { addUniqueId, trackEvent } from "../../utils/moengage/index"; // Importing tracking functions
import Events from "../../constant/MoengageEvents/index"; // Importing constant events

import encryptAsAES from "../../utils/encryption/aes"; // Importing encryption function
import CreateTextForEncryption from "../../utils/mobileEncryption"; // Importing function to create text for encryption
import { useNavigate, useLocation } from "react-router-dom"; // Importing hooks for navigation
import { usePlatfromData } from "../CrossPlatformActionHandler/platformChecker"; // Importing hook to get platform data
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import bridgeFunctions from "../../utils/bridge";
import PlatForm from "../../constant/platFormEnums";
import PostRequest from "../../utils/apiCaller/postRequest";
import { apiEndPoints } from "../../apis/endpoints";
import CountryCode from "../../constant/CountryCodeEnums";
import { helpLineNumber } from "../../constant/HelpLineNumber";
import { getLoginScreenAssets } from "../../constant/imageUrls/index";
import { fetchLanguage } from "./../../utils/languageApi/fetchLanguage";
import { languageUrls } from "../../constant/languageUrls/index";
import { DEFAULT_LANGUAGE } from "../../constant/language";
import { useLanguage } from "../../context/language/index";

export default function OtpSubmitHook(): OTPInputProps {
	const bridge = createBridge();
	const { languageData } = useLanguage();

	const params = useLocation()?.state; // Getting location state
	const navigate = useNavigate(); // Navigation function
	const platformData = usePlatfromData(); // Getting platform data

	const [languageFile, setLanguageFile] = useState([]);

	useEffect(() => {
		getLanguageJson();
	}, []);

	async function getLanguageJson() {
		try {
			const url = languageUrls.otpScreenLangageUrl;
			const result = await fetchLanguage(url);
			setLanguageFile(result);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
	const systemLanguage = activeSystemLang?.includes("en")
		? languageData && languageData[1].langCode
		: languageData && languageData[0].langCode;
	const systemLanguageId = activeSystemLang?.includes("en")
		? languageData && languageData[1].backendLanguageCode
		: languageData && languageData[0].backendLanguageCode;

	const startLoadTime = new Date().getTime(); // Start time for tracking

	const langId = JSON.parse(localStorage.getItem("userLanguage")); // Getting user's selected language
	const [userBlocked, setUserBlocked] = useState<boolean>(false); // State to track if user is blocked
	const [userMobileNumber, setUserMobileNumber] = useState<string>(
		params?.userMobileNumber, // State to store user's mobile number
	);
	const [error, setError] = useState<boolean>(false); // State to track error status
	const [loading, setLoading] = useState<boolean>(false); // State to track loading status
	const [userOtp, setUserOtp] = useState<string>(""); // State to store user's entered OTP
	const [disableResend, setDisableResend] = useState<boolean>(true); // State to disable resend button

	const language = languageFile
		? languageFile[langId?.langCode ? langId?.langCode : systemLanguage]
		: getLang(DEFAULT_LANGUAGE);

	const otpScreenAssets = getLoginScreenAssets(langId?.langCode);

	useEffect(() => {
		const intervalId = setInterval(() => {
			// Call your function here

			(window as any).listenMessagesFromApp = (request: any) => {
				const parsed = JSON.parse(request);
				if (parsed?.otp) {
					const newStr = parsed?.otp.join("");
					setUserOtp(newStr);
					validateOtp(newStr);
				}
			};
		}, 1000);

		// Cleanup function to clear the interval when the component unmounts
		return () => clearInterval(intervalId);
	}, []);

	// Storing current language or defaulting to Hindi
	const currentLanguage = langId || {
		langCode: systemLanguage,
		langId: systemLanguageId,
	};
	const [isLanguageModalOpen, toggleLanguageModal] = useState<boolean>(false); // State to toggle language modal
	const [isLanguageSelectedInProgress, setLanguageSelectedInProgeress] =
		useState<boolean>(false); // State to track if language selection is in progress

	const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage); // State to store selected language

	useEffect(() => {
		getOtpFromNative();
	}, [userOtp]);

	const getOtpFromNative = () => {
		bridge.sendRequestToNative("startOtpListener", null, (response: any) => {
			bridgeFunctions.PrintLog(response);
		});
	};

	// Function to handle language selection in language modal
	function handleLanguageSelect(languageItem: LanguageParams) {
		setSelectedLanguage(languageItem);
	}

	// Function to handle language change
	function handleLanguageChange() {
		if (localStorage.getItem("userLanguage") !== selectedLanguage) {
			setLanguageSelectedInProgeress(!isLanguageSelectedInProgress);
			localStorage.setItem("userLanguage", JSON.stringify(selectedLanguage));
			toggleLanguageModal(!isLanguageModalOpen);
			setLanguageSelectedInProgeress(false);
		} else {
			toggleLanguageModal(!isLanguageModalOpen);
		}
	}

	// Function to toggle language modal
	function onLanguageModalToggle() {
		setSelectedLanguage(
			langId || {
				langCode: systemLanguage,
				langId: systemLanguageId,
			},
		);
		toggleLanguageModal(!isLanguageModalOpen);
	}

	// Function to validate OTP. It will navigate to Profile selection screen for new users or navigate to home screen for old users.
	/**
	 * The function `validateOtp` handles the validation of OTP, sets authentication token in local
	 * storage, and performs additional actions based on the response.
	 * @returns The `validateOtp` function returns either `true` or `false` based on the success or failure
	 * of the OTP validation process. If the OTP validation is successful and a token is received, it
	 * returns `false`. If there is an error during the process, it returns `true`.
	 */
	async function validateOtp(autoReadOtp) {
		setLoading(true);
		try {
			const otpValidateresponse = await PostRequest({
				payload: {
					mobile_number: userMobileNumber,
					otp: autoReadOtp ? autoReadOtp : userOtp,
					role_id: 6,
				},
				uri: apiEndPoints.validateOTP,
			});

			if (typeof otpValidateresponse?.data?.data?.token !== "string") {
				return setError(true);
			}

			setError(false);
			addUniqueId(userMobileNumber);
			localStorage.setItem("authToken", otpValidateresponse?.data?.data?.token);

			/*It is making an asynchronous POST request to fetch the merchant profile of user. */
			const merchantProfile = await PostRequest({
				payload: {},
				uri: apiEndPoints?.fetchMerchantProfile,
			});

			if (!merchantProfile?.data?.data?.merchant_occupation_id) {
				trackEvent(Events?.onSGNOTPSubmittedButtonClick, {
					time: new Date().getTime() - startLoadTime,
					otp_submission: "manual",
					api_faluire: false,
					is_new_user: true,
					is_otp: true,
				});
				navigate("/userRegistration", {
					state: {
						token: otpValidateresponse?.data?.data?.token,
					},
				});
			} else {
				trackEvent(Events?.onSGNOTPSubmittedButtonClick, {
					time: new Date().getTime() - startLoadTime,
					otp_submission: "manual",
					api_faluire: false,
					is_new_user: false,
					is_otp: true,
				});

				/* The `bridge.sendRequestToNative` function is sending a request to the native side of the
     application with the following parameters:
     - Method: "SetToken"
     - Data Object:
       - `token`: The authentication token received from the OTP validation response.
       - `profile`: The merchant profile data obtained from the asynchronous POST request to fetch
     the merchant profile of the user.
     - Callback Function: It is a callback function that will be executed once a response is
     received from the native side. In this case, the callback function is
     `bridgeFunctions.PrintLog(response)` which is responsible for logging the response received
     from the native side. */
				if (platformData?.platform === PlatForm?.get("application")) {
					bridge.sendRequestToNative(
						"SetToken",
						{
							token: otpValidateresponse?.data?.data?.token,
							profile: merchantProfile.data.data,
						},
						(response) => {
							bridgeFunctions.PrintLog(response);
						},
					);
					bridge.sendRequestToNative(
						"setAppLanguage",
						{
							langCode: selectedLanguage?.langCode,
						},
						(response) => {
							bridgeFunctions.PrintLog(response);
						},
					);
				} else {
					localStorage.setItem(
						"profileSelected",
						merchantProfile?.data?.data?.merchant_occupation_id,
					);
					localStorage.setItem("loged", "true");
					navigate("/");
				}
			}
		} catch (error) {
			setLoading(false);
			trackEvent(Events?.onSGNOTPSubmittedButtonClick, {
				time: new Date().getTime() - startLoadTime,
				api_faluire: true,
				is_otp: false,
			});
			return setError(true);
		} finally {
			setLoading(false);
		}
	}

	// Function to get OTP
	async function getOtp() {
		setLoading(true);
		const encryptedText = await encryptAsAES(
			CreateTextForEncryption(userMobileNumber),
		);
		try {
			const getOtpData = await PostRequest({
				payload: {
					mobile_number: encryptedText,
					country_code: CountryCode.get("india"),
					message_id: "",
				},
				uri: apiEndPoints.getOTP,
			});
			if (getOtpData?.data?.status) {
				setError(false);
				trackEvent(Events.SGN_OTP_SUBMITTED, { api_faluire: true });
			}
		} catch (error) {
			if (error?.code === 404) {
				setUserBlocked(true);
			}
			trackEvent(Events.SGN_OTP_SUBMITTED, { api_faluire: false });
			setLoading(false);
			return setError(true);
		} finally {
			setLoading(false);
		}
	}

	// Function to handle back button press
	function onPressBack(from: string) {
		return () => {
			if (from === "backButton") {
				trackEvent(Events?.onSGNBackButtonClick, {
					FROM: "Back Button",
				});
			} else {
				trackEvent(Events?.onSGNBackButtonClick, {
					FROM: "Change Number",
				});
			}
			navigate("/login", {
				state: {
					userMobileNumber: userMobileNumber,
				},
			});
		};
	}

	// Function to get user's OTP from input
	const getUserOtpFromInput = (newOtp: string) => {
		setUserOtp(newOtp);
		if (error) {
			return setError(false);
		}
	};

	// Function to resend OTP
	const resendOtp = () => {
		trackEvent(Events.onOTPResendButtonClick, {});
		getOtp();
	};

	// Function to proceed on OTP validation
	function onClickValidateOtpProceed() {
		if (userOtp?.length < 6) {
			return setError(true);
		}
		validateOtp("");
	}

	// Function to handle contact us action
	function onContactUs() {
		const url = `https://wa.me/${helpLineNumber}?text=नमस्कार जी, मुझे आपसे कुछ सहायता चाहिये | मैने अभी FarMart एप डाउनलोड किया है ।`;
		if (platformData.platform === PlatForm.get("website")) {
			const win = window?.open(url, "_blank");
			win?.focus();
		} else {
			const text = `नमस्कार जी, मुझे आपसे कुछ सहायता चाहिये | मैने अभी FarMart एप डाउनलोड किया है । &phone=${helpLineNumber}`;
			const link = `whatsapp://send?text=${text}`;
			bridge.sendRequestToNative(
				"OpenUrl",
				{
					link: link,
				},
				(response) => {
					bridgeFunctions.PrintLog(response);
				},
			);
		}
	}

	useEffect(() => {
		setSelectedLanguage(
			langId || {
				langCode: systemLanguage,
				langId: systemLanguageId,
			},
		);
	}, [localStorage.getItem("userLanguage")]);

	// Returning necessary functions and states
	return {
		handleLanguageSelect,
		onLanguageModalToggle,
		handleLanguageChange,
		selectedLanguage,
		isLanguageModalOpen,
		isLanguageSelectedInProgress,
		userOtp,
		getUserOtpFromInput,
		disableResend,
		setDisableResend,
		resendOtp,
		userMobileNumber,
		onClickValidateOtpProceed,
		error,
		onPressBack,
		language,
		onContactUs,
		loading,
		otpScreenAssets,
		languageData,
	};
}
