/* This code snippet is a React functional component named `BahiKhataPrimary`. It is a part of a
TypeScript React application and it renders a UI component. Here's a breakdown of what the code is
doing: */
import React from 'react';
import { Box } from '@mui/material';
import { Chip } from '@mui/joy'; // Importing Chip component from MUI library
import styles from './style.module.css'; // Importing CSS styles for the component
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton'; // Importing custom MuiButton component
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography'; // Importing custom MuiTypography component
import InsightsRounded from '@mui/icons-material/InsightsRounded'; // Importing InsightsRounded icon from MUI library
import SkeletonLoading from '@mono-farmart-web/farmart-web-common/ComponentsV4/SkeletonLoading'; // Importing SkeletonLoading component for loading state

// Defining functional component BahiKhataPrimary and destructuring props
export default function BahiKhataPrimary({
  item, // Object containing data related to component's features
  language, // Object containing language translations or text strings
  totalCredit, // Object containing total credits information
  onClickBahiKhataPrimaryButton, // Function to be called on primary button click
  bahiKhataLoading, // Boolean indicating whether BahiKhata data is loading
  userLogedIn, // Boolean indicating whether user is logged in
  homeScreenLoading, // Boolean indicating whether home screen data is loading
}) {
  return (
    <Box className={styles.mainContainer}>
      {/* Main container */}
      {homeScreenLoading || bahiKhataLoading ? ( // Conditional rendering for loading state
        <SkeletonLoading className={styles?.loading} /> // Display SkeletonLoading component if home screen or BahiKhata data is loading
      ) : (
        <>
          {/* Fragment for multiple children */}
          <Box className={styles.infoContainer}>
            {/* Container for information */}
            <Box className={styles.contentContainer}>
              {/* Container for content */}
              <Box>
                <Box className={styles.cardTitleContainer}>
                  {/* Container for card title */}
                  <MuiTypography level={'title-md'}>
                    {language?.bahiKhata}
                  </MuiTypography>
                  {/* Displaying BahiKhata title */}
                </Box>
                <MuiTypography
                  level="body-xxs"
                  style={{ marginTop: 4 }}
                  textColor={'text.tertiary'}
                >
                  {language?.bahiKhataPrimarySubHeading}
                  {/* Displaying BahiKhata subheading */}
                </MuiTypography>
                <Chip // Displaying Chip component for additional information
                  style={{
                    borderRadius: 4,
                    gap: 6,
                    marginTop: 6,
                  }}
                  color="neutral"
                  size="sm"
                  startDecorator={<InsightsRounded />} // Icon for Chip
                >
                  {language?.bahiKhataFomoPrimary}
                  {/* Displaying text on Chip */}
                </Chip>
              </Box>
            </Box>
            <Box>
              <img
                className={styles.iconImageStyle}
                src={item.features[0]?.icon} // Rendering icon image
                alt="itemFeatureIcon"
                loading="eager"
              />
            </Box>
          </Box>
          {userLogedIn && ( // Conditional rendering based on user login status
            <Box className={styles.transactionContainer}>
              {/* Container for transaction details */}
              <Box className={styles.debitContainer}>
                {/* Container for debit details */}
                <div
                  className={styles.debitText}
                >{`${language?.bahiKhataCreditText} ₹${totalCredit?.amount_to_give}`}</div>
                {/* Displaying credit amount */}
              </Box>
              <Box className={styles.creditContainer}>
                {/* Container for credit details */}
                <div
                  className={styles.creditText}
                >{`${language?.bahiKhataDebitText} ₹${totalCredit?.amount_to_take}`}</div>
                {/* Displaying debit amount */}
              </Box>
            </Box>
          )}
          {/* Primary button for BahiKhata */}
          <MuiButton
            size="md"
            color="primary"
            variant="solid"
            id={'bahiKhataPrimaryCardButtonId'}
            onClick={onClickBahiKhataPrimaryButton} // Handling click event
          >
            {language?.bahiKhataPrimaryButton} {/* Displaying button text */}
          </MuiButton>
        </>
      )}
    </Box>
  );
}
