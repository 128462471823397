import React from "react";
import { Box, Row } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";
import { Dropdown, Menu, MenuItem } from "@mui/joy";
import MenuButton from "@mui/joy/MenuButton/MenuButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { isSaudabookPWA } from "@mono-farmart-web/farmart-web-common/utils/common";

interface ComponentHeaderProps {
	bgColor?: string;
	isTripCancelled?: boolean;
	onOptionOneOpen: () => void;
	onOptionTwoOpen: () => void;
	language?: any;
	tripCancelled?: boolean;
  navigateObj?: any;
}

const ComponentHeader = (props: ComponentHeaderProps) => {
	const navigate = useNavigate();
	const { language, navigateObj } = props;
	return (
		<>
			<Box
				paddingLeft={1}
				paddingRight={1}
				bgcolor={props?.bgColor ? props?.bgColor : "common.white"}
			>
				<Row justifyContent={"space-between"} alignItems={"center"}>
					<Row
						padding={2}
						width={"40px"}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<ArrowBackIcon
							onClick={() => {
								if(isSaudabookPWA) {
                  navigate(
                    props?.tripCancelled
                      ? "/triplist/outgoing/3"
                      : "/triplist/outgoing/1",
                  );
                } else {
                  navigate(`/trades/mySauda/createDispatch/${navigateObj?.dispatchId}`, {
                    state: {
                      ...navigateObj,
                    }
                  })
                }
							}}
							id="back_button_consent_pendingscreen"
							sx={{ width: "25px", height: "25px", color: "text.primary" }}
						/>
					</Row>

					<Row
						padding={2}
						width={"40px"}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<Dropdown>
							<MenuButton
								sx={{
									border: "none",
									py: "6px",
									background: "background.surface",
									radius: "8px",
									":active": {
										background: "transparent",
									},
								}}
							>
								{
                  isSaudabookPWA && (
                    <MoreVertIcon
                      id="three_dot_consent_pendingscreen"
                      sx={{ width: "25px", height: "25px", color: "text.primary" }}
                    />
                  )
                }
							</MenuButton>
							<Menu>
								<MenuItem
									sx={{
										padding: "4px 12px",
									}}
									onClick={() => props?.onOptionOneOpen()}
								>
									<Text
										id="need_help_consent_pendingscreen"
										level="title-md"
										textColor="neutral.plainColor"
										startDecorator={
											<HelpOutlineOutlinedIcon
												id="questionmark_consentpendingscreen"
												sx={{
													color: "text.icon",
												}}
											/>
										}
									>
										{language?.needHelp}
									</Text>
								</MenuItem>
								{!props?.isTripCancelled ? (
									<MenuItem
										sx={{
											padding: "4px 12px",
										}}
										onClick={() => props?.onOptionTwoOpen()}
									>
										<Text
											id="canceltab_consentpendingscreen"
											level="title-md"
											textColor="danger.500"
											startDecorator={
												<DeleteRoundedIcon
													id="canceltab_icon_consentpendingscreen"
													sx={{
														color: "danger.500",
													}}
												/>
											}
										>
											{language?.cancelTrip}
										</Text>
									</MenuItem>
								) : null}
							</Menu>
						</Dropdown>
					</Row>
				</Row>
			</Box>
		</>
	);
};

export default ComponentHeader;
