import React from 'react';
import SaudaAcknowledgement from '../../Pages/SaudaAcknowledgement/SaudaAcknowledgement';
import UseSaudaAcknowledgement from '@mono-farmart-web/farmart-web-common/hooks/SaudaAcknowledgement/saudaAcknowledgement';

export default function saudaAcknowledgement({}) {
  const {
    quantity,
    quantityUnit,
    cropName,
    paymentTerms,
    buyerName,
    buyerAddress,
    priceBreakup,
    netPrice,
    netPriceUnit,
    confirmSaudaRequest,
    confirmSaudaLoading,
    language,
    onClickHelpSaudaAcknowledgement,
    onClickBackSaudaAcknowledgement,
    districtName,
    stateName,
    isError,
    setIsError,
    brokerCode,
    brokerFirmName,
    brokerCodeButtonLoading,
    brokerCodeVerified,
    brokerCodeState,
    handleBrokerCodeInputChange,
    verifyBrokerCode,
    showBrokerCodeInputText,
    clearBrokerCodeInput,
    showInvalidBrokeCodeAlert,
    setShowInvalidBrokerCodeAlert,
    handleOnClickBrokerCodeInput,
    onClickPopUpDisable,
    popupVisible,
    popupData,
  } = UseSaudaAcknowledgement();
  return (
    <SaudaAcknowledgement
      quantity={quantity}
      quantityUnit={quantityUnit}
      cropName={cropName}
      paymentTerms={paymentTerms}
      buyerName={buyerName}
      buyerAddress={buyerAddress}
      priceBreakup={priceBreakup}
      netPrice={netPrice}
      netPriceUnit={netPriceUnit}
      confirmSaudaRequest={confirmSaudaRequest}
      confirmSaudaLoading={confirmSaudaLoading}
      language={language}
      onClickHelpSaudaAcknowledgement={onClickHelpSaudaAcknowledgement}
      onClickBackSaudaAcknowledgement={onClickBackSaudaAcknowledgement}
      districtName={districtName}
      stateName={stateName}
      isError={isError}
      setIsError={setIsError}
      brokerCode={brokerCode}
      brokerFirmName={brokerFirmName}
      brokerCodeButtonLoading={brokerCodeButtonLoading}
      brokerCodeVerified={brokerCodeVerified}
      brokerCodeState={brokerCodeState}
      handleBrokerCodeInputChange={handleBrokerCodeInputChange}
      verifyBrokerCode={verifyBrokerCode}
      showBrokerCodeInputText={showBrokerCodeInputText}
      clearBrokerCodeInput={clearBrokerCodeInput}
      showInvalidBrokeCodeAlert={showInvalidBrokeCodeAlert}
      setShowInvalidBrokerCodeAlert={setShowInvalidBrokerCodeAlert}
      handleOnClickBrokerCodeInput={handleOnClickBrokerCodeInput}
      onClickPopUpDisable={onClickPopUpDisable}
      popupVisible={popupVisible}
      popupData={popupData}
    />
  );
}
