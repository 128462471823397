import React from 'react';
import styles from './style.module.css';
import Modal from '@mono-farmart-web/farmart-web-common/ComponentV2/Modal';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';

export default function QuizAlert({
  language,
  onClickCloseQuizAlertModal,
  quizAlert,
}) {
  const quizAlertUi = (
    <div className={styles?.mainContainer}>
      <MuiTypography level="title-md">
        {language?.gkQuizMessageText}
      </MuiTypography>
      <MuiTypography level="body-xxs">
        {language?.gkQuizSubMessageText}
      </MuiTypography>
    </div>
  );
  return (
    <Modal
      title={language?.gkQuizHeaderText}
      isCloseButtonOption={true}
      children={quizAlertUi}
      isOpen={quizAlert}
      onClose={onClickCloseQuizAlertModal}
      id="quizAlert"
    />
  );
}
