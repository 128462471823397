import type React from "react";
import {
	Box,
	Column,
	Row,
} from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";
import { useRef, useState } from "react";
import "./style.css";

interface FormInputProps {
	type?: string;
	protected: boolean;
	icon?: React.ReactNode;
	title: string;
	name?: string;
	value?: string | undefined;
	placeholder?: string;
	fieldDetailText?: string;
	onDetailSave: (event: React.ChangeEvent<HTMLInputElement>) => void;
	titleId?: string;
	inputId?: string;
	fieldDetailTextId?: string;
}

const FormInput = (props: FormInputProps) => {
	const inputRef = useRef<any>(null);
	const [isEditable, setIsEditable] = useState<boolean>(!props?.protected);
	const [value, setValue] = useState<string | undefined>(props?.value);

	const handleEditMode = (toggleValue: boolean) => {
		setIsEditable(toggleValue);
	};

	return (
		<Box
			sx={{
				py: "12px",
				px: "16px",
			}}
		>
			<Column gap={1}>
				<Text
					id={props?.titleId}
					level="body-sm"
					textColor="text.primary"
					fontWeight={500}
				>
					{props?.title}
				</Text>
				<Box>
					<Column gap={"6px"}>
						<Row
							sx={{
								py: "8px",
								px: "12px",
								height: "42px",
								borderRadius: "8px",
								border: "1px solid #CDD7E1",
								boxShadow: "0px 1px 2px 0px #BBBBBB1F",
							}}
							gap={2}
						>
							{props?.name === "driver_number" ? (
								<Text level="body-md" textColor="text.secondary">
									+91
								</Text>
							) : null}
							{/* INPUT SECTION */}
							<input
								id={props?.inputId}
								ref={inputRef}
								type={props?.type}
								style={{
									width: "100%",
									background: "#fff",
									border: "none",
									outline: "none",
									fontSize: "16px",
									fontWeight: 400,
								}}
								value={value}
								className="input-style"
								placeholder={props?.placeholder}
								name={props?.name}
								onChange={(event) => {
									if (props?.type === "number") {
										setValue(event?.target?.value?.slice(0, 10));
									} else {
										setValue(event?.target?.value);
									}
									props.onDetailSave(event);
								}}
								disabled={!isEditable}
							/>

							{/* ICON SECTION */}
							{props?.icon ? (
								<Row
									onClick={() => {
										props?.protected ? handleEditMode(true) : null;
									}}
								>
									{props?.icon}
								</Row>
							) : null}
						</Row>

						{props?.fieldDetailText ? (
							<Row>
								<Text
									id={props?.fieldDetailTextId}
									level="body-sm"
									textColor="text.secondary"
									fontWeight={400}
									width={"328px"}
								>
									{props?.fieldDetailText}
								</Text>
							</Row>
						) : null}
					</Column>
				</Box>
			</Column>
		</Box>
	);
};

export default FormInput;
