import updateUserLocation from "../../../apis/Trader/updateUserLocation";

import type { UpdateUserLocationResp } from "../types";

interface PayloadType {
	feature_id: number; //mandatory
	state_id: number; //mandatory
	district_id: number;
}
export default async function postUserLocation(
	payload: PayloadType,
): Promise<UpdateUserLocationResp> {
	const body = {
		...payload,
	};
	try {
		const result = await updateUserLocation(body);

		if (!result.data.status) {
			throw result;
		}

		return result.data.data;
	} catch (error) {
		console.log("Error:::", error);
		if (!error.message) {
			error.message = "Something Went Wrong";
		}

		throw error;
	}
}
