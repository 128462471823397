import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    heading: "भुगतान किया गया!",
    subHeading: "🎉आपके सौदे का भुगतान आपको भेज दिया गया है 🎉",
    truckNo: "ट्रक:",
    amountPaid: "भुगतान की गई राशि",
    utr: "UTR:",
    disclaimer: "*राशि आपके बैंक खाते में 2-3 कार्य दिवसों में प्रदर्शित होगी!",
    trustPartner: "आपका विश्वसनीय साथी FarMart",
    buttonText: "सौदे का विवरण देखें"
  },
  [AppLanguages.appLanguagesIndex.english]: {
    heading: "Payment Sent!",
    subHeading: "🎉 Your payment for suada is sent to you 🎉",
    truckNo: "Truck:",
    amountPaid: "Amount Paid",
    utr: "UTR: ",
    disclaimer: "*Amount will reflect in your bank account within 2-3 working days!",
    trustPartner: "Your trusted partner FarMart",
    buttonText: "View Sauda Details"
  },
};

export default (language) => languages[language];
