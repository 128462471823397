
import reuploadUnloadingDocApi from "../../../apis/MySauda/reuploadUnloadingDocApi";

export default async function ReuploadUnloadingDocs(
  payload: any,
): Promise<any> {
  const body = {
    ...payload,
  };
  try {
    const result = await reuploadUnloadingDocApi(body);

    if (!result.data.status) {
      throw result;
    }

    return result.data.data;
  } catch (error) {
    if (!error.message) {
      error.message = "Something Went Wrong";
    }

    throw error;
  }
}
