export default function jsonParse<T>(jsonString: string | undefined): T | null {
  if (!jsonString || jsonString === 'undefined') {
    console.error('Invalid input: JSON string is undefined or empty');
    return null;
  }

  try {
    return JSON.parse(jsonString) as T;
  } catch (error) {
    console.log("Error: while parsing JSON:::", error);
    console.error('Failed to parse JSON:', error);
    return null;
  }
}
