import React from 'react';
import Styles from './style.module.css';
import MobileNumberInput from '@mono-farmart-web/farmart-web-common/ComponentV2/MobileNumberInput';
interface LoginInputProps {
  getMobileNumber?: (number: string) => void;
  userBlocked?: boolean;
  userMobileNumber?: string;
  error?: boolean;
  language: { [key: string]: any };
}
export default function Login(props: LoginInputProps) {
  const { error, getMobileNumber, userBlocked, userMobileNumber, language } =
    props;
  return (
    <div className={Styles.mainContainer}>
      <MobileNumberInput
        headingTitle={language?.loginHeading}
        onChange={getMobileNumber}
        error={error}
        placeholderText={language?.numerIntputTitle}
        errorMessage={language?.errorMobileNumber}
        userBlocked={userBlocked}
        id="MobileNumberInput"
        defaultMobileNumber={userMobileNumber}
        blockUserMessage={language?.blockUserMessage}
      />
    </div>
  );
}
