// biome-ignore lint/style/useImportType: <explanation>
import React from "react";
import {
	Box,
	Column,
} from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";

interface HeroMessageCoverProps {
	bgColor?: string;
	icon: React.ReactNode;
	title: string;
	titleColor?: string;
	dateString: string;
	titleId?: string;
	dateStringId?: string;
}

const HeroMessageCover = (props: HeroMessageCoverProps) => {
	return (
		<Box
			paddingLeft={4}
			paddingRight={4}
			paddingTop={3}
			paddingBottom={3}
			bgcolor={props?.bgColor ? props?.bgColor : "common.white"}
		>
			<Column alignItems={"center"} gap={2}>
				{/* TOP SECTION */}
				<Box bgcolor={"inherit"}>
					<Column gap={1}>
						{/* SUCCESS ICON */}
						<Box
							sx={{
								textAlign: "center",
							}}
							bgcolor={"inherit"}
						>
							{props?.icon}
						</Box>

						{/* MESSAGE */}
						<Text
							id={props?.titleId}
							level="h3"
							textColor={props?.titleColor ? props?.titleColor : "text.primary"}
						>
							{props?.title}
						</Text>
					</Column>
				</Box>

				{/* BOTTOM SECTION */}
				<Text
					id={props?.dateStringId}
					level="body-sm"
					textColor="text.secondary"
				>
					{props?.dateString}
				</Text>
			</Column>
		</Box>
	);
};

export default HeroMessageCover;
