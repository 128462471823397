import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type { AcknowledgementProps } from "./types.js";
import { trackEvent } from "../../utils/moengage/index"; // Importing tracking functions
import Events from "../../constant/MoengageEvents/index"; // Importing constant events
import { getLanguageService } from "./../../utils/getLanguageService";

import { languageUrls } from "../../constant/languageUrls/index";

export default function AddFarmerHook(): AcknowledgementProps {
	const { state } = useLocation();

	const [language, setLanguageFile] = useState({});

	useEffect(() => {
		getLanguageJson();
	}, []);

	async function getLanguageJson() {
		try {
			const url = languageUrls?.addFarmerAcknowledgementScreenLangageUrl;
			const result = await getLanguageService(url);
			setLanguageFile(result);
		} catch (error) {
			console.log("object");
		} finally {
			console.log("object");
		}
	}

	const navigate = useNavigate();

	// Function to handle back button press
	/**
	 * The onPressBack function navigates to the "/myFarmers" route with state information indicating it
	 * was triggered from the "addFarmer" action.
	 */
	function onPressBack() {
		trackEvent(Events.onViewMyFarmersButtonClick, {});
		if (state?.fromScreen) {
			navigate(`/${state?.fromScreen}`);
		} else {
			navigate("/myFarmers", {
				state: {
					from: "addFarmer",
				},
			});
		}
	}

	/**
	 * The function `onContactUs` navigates to the "/faq/questions" page.
	 */
	/**
	 * The `onContactUs` function navigates to the "/faq/questions" page.
	 */
	function onContactUs() {
		navigate("/faq/questions");
	}

	// Returning necessary functions and states
	return {
		onPressBack,
		language,
		onContactUs,
	};
}
