/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useCallback } from 'react';
import { unVerifiedRoutes } from '../../constant/unVerifiedRoutes';
import {
  getUserProfileFromStorage,
  loginAsGuest,
} from '../../modal/auth/auth.model';
import GetRequest from '../apiCaller/getRequest';
import PostRequest from '../apiCaller/postRequest';
import PutRequest from '../apiCaller/putRequest';
import DeleteRequest from '../apiCaller/deleteRequest';
import { useNavigate } from 'react-router-dom';
import HttpStatusCode from '../../apis/types/httpStatusCodesEnums.type';
import { createBridge } from '@mono-farmart-web/rpc-bridge';
import bridgeFunctions from '../bridge';
import { useAuth } from '@mono-farmart-web/farmart-web-common/context/login';
import { isSaudabookPWA } from '../common';
interface Parameter {
  payload: any;
  uri: string;
  requestType: 'get' | 'post' | 'put' | 'delete';
  loginMethod?: 'modal' | 'page';
}
interface UnifiedResponse {
  response?: Response;
  data: {
    status: number;
    message: string;
    data: any;
  };
}
const useAuthenticationCheckerHook = () => {
  const navigate = useNavigate();
  const isRedirected = useRef(false);
  const retryCount = useRef(0);
  const guestLoginCalled = useRef(false);
  const authProps = useAuth();
  const unAuthorizedCode = [
    HttpStatusCode?.Unauthorized,
    HttpStatusCode?.TokenExpired,
    HttpStatusCode?.Forbidden,
    /* HttpStatusCode?.UpgradeRequired, */
  ];
  const apiRequestTypeChecker = async (
    uri: string,
    payload: any,
    requestType: string,
  ): Promise<UnifiedResponse> => {
    switch (requestType?.toLowerCase()) {
      case 'get':
        return GetRequest({ uri, payload });
      case 'post':
        return PostRequest({ uri, payload });
      case 'put':
        return PutRequest({ uri, payload });
      case 'delete':
        return DeleteRequest({ uri, payload });
      default:
        throw new Error(`Unsupported request type: ${requestType}`);
    }
  };
  const retryRequest = async (
    uri: string,
    payload: any,
    requestType: string,
  ): Promise<UnifiedResponse> => {
    try {
      const response = await apiRequestTypeChecker(uri, payload, requestType);
      if (response?.data?.status) {
        return response;
      }
    } catch (error) {
      if (unAuthorizedCode?.includes(error?.code)) {
        if (!guestLoginCalled.current) {
          await loginAsGuest();
          guestLoginCalled.current = true;
        }
        retryCount.current++;
        if (retryCount.current < 3) {
          return retryRequest(uri, payload, requestType);
        } else {
          navigateToRootIfNotRedirected();
        }
      } else {
        navigateToRootIfNotRedirected();
        throw error;
      }
    }
  };
  const navigateToRootIfNotRedirected = () => {
    localStorage.setItem('refreshPage', 'true');
    if (!isRedirected.current) {
      isRedirected.current = true;
      navigate('/', {
        replace: true,
      });
    }
  };
  function isAndroid() {
    try {
      return typeof (window as any)?.ReactNativeWebView !== 'undefined';
    } catch (e) {
      return false;
    }
  }

  const AuthenticationCheckerAndApiCaller = useCallback(
    async (props: Parameter): Promise<UnifiedResponse> => {
      const bridge = createBridge();
      const { payload, uri, requestType, loginMethod } = props;
      const token = localStorage.getItem('authToken');
      const userProfile = getUserProfileFromStorage() || null;
      const merchantLogin =
        JSON.parse(localStorage.getItem('loged')) ||
        userProfile?.merchant_detail_id ||
        userProfile?.merchant_occupation_id || (isSaudabookPWA && !!token);
      try {
        if (isAndroid()) {
          //if user's platform is App
          if (token) {
            //if user have token
            try {
              return await apiRequestTypeChecker(uri, payload, requestType);
            } catch (error) {
              if (unAuthorizedCode?.includes(error?.code)) {
                //logout logic for app
                bridge.sendRequestToNative(
                  'logOutFromApp',
                  null,
                  (response) => {
                    bridgeFunctions.PrintLog(response);
                  },
                );
              } else {
                throw error;
              }
            }
          } else {
            //if user have no token
            // logout logic for app
            bridge.sendRequestToNative('logOutFromApp', null, (response) => {
              bridgeFunctions.PrintLog(response);
            });
          }
        } else {
          //if user's platform is Web
          if (merchantLogin) {
            if (unVerifiedRoutes?.guestLogin?.includes(uri)) {
              return await retryRequest(uri, payload, requestType);
            } else {
              try {
                return await apiRequestTypeChecker(uri, payload, requestType);
              } catch (error) {
                if (unAuthorizedCode?.includes(error?.code)) {
                  localStorage?.clear();
                  sessionStorage?.clear();
                  //add web logic if user is not loged in like authProps.setMobileNumberModalVisible(true); for modal login or navigate to login url
                  if (!isRedirected.current) {
                    isRedirected.current = true;
                    if (loginMethod === 'modal') {
                      authProps.setMobileNumberModalVisible(true);
                    } else {
                      navigate('/login',{replace:true});
                    }
                  }
                } else {
                  throw error;
                }
              }
            }
          } else {
            if (unVerifiedRoutes?.guestLogin?.includes(uri)) {
              if (token) {
                return await retryRequest(uri, payload, requestType);
              } else {
                await loginAsGuest();
                return await retryRequest(uri, payload, requestType);
              }
            } else {
              if (merchantLogin) {
                //if merchant is loged in web as merchant
                try {
                  return await apiRequestTypeChecker(uri, payload, requestType);
                } catch (error) {
                  if (unAuthorizedCode?.includes(error?.code)) {
                    localStorage?.clear();
                    sessionStorage?.clear();
                    //add web logic if user is not loged in like authProps.setMobileNumberModalVisible(true); for modal login or navigate to login url
                    if (!isRedirected.current) {
                      isRedirected.current = true;
                      if (loginMethod === 'modal') {
                        authProps.setMobileNumberModalVisible(true);
                      } else {
                        navigate('/login',{replace:true});
                      }
                    }
                  } else {
                    throw error;
                  }
                }
              } else {
                //add web logic if user is not loged in like authProps.setMobileNumberModalVisible(true); for modal login or navigate to login url
                if (!isRedirected.current) {
                  localStorage?.clear();
                  sessionStorage?.clear();
                  if (loginMethod === 'modal') {
                    authProps.setMobileNumberModalVisible(true);
                  } else {
                    navigate('/login',{replace:true});
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        console.error('An error occurred:', error);
        // Handle error appropriately, e.g., show a user-friendly message or retry logic
        throw error;
      }
    },
    [],
  );
  return { AuthenticationCheckerAndApiCaller };
};
export default useAuthenticationCheckerHook;
