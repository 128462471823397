import React from 'react';
import Styles from './style.module.css';
import Modal from '@mono-farmart-web/farmart-web-common/ComponentV2/Modal';
import { Location } from '@mono-farmart-web/farmart-web-common/modal/Traders/types';
import InfiniteScroll from '@mono-farmart-web/farmart-web-common/Components/InfintyScroll';
import Loading from '@mono-farmart-web/farmart-web-common/ComponentV2/Loading';
import CommonErrorState from '@mono-farmart-web/farmart-web-common/ComponentsV4/CommonErrorState';
import { Box, Checkbox } from '@mui/joy';

interface PayloadType {
  isOpen: boolean;
  handleBuyerLocationListModal: () => void;
  locationList: Location[];
  activeLocations: string[];
  handleLocationSelection: (locationId: string, districtName: string) => void;
  locationLoading: boolean;
  onClickLocationFilterApply: () => void;
  buyerLocationListSearchParam: string;
  handleLocationSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loadMoreLocationList: () => void;
  language: { [key: string]: string };
  searchIcon: string;
  activeSvg: string;
  inactiveSvg: string;
  isModalErrorVisible: boolean;
  onRefreshLocationList: () => void;
  onClickHelp: () => void;
}

const SelectLocationModal = ({
  isOpen,
  handleBuyerLocationListModal,
  locationList,
  handleLocationSelection,
  activeLocations,
  locationLoading,
  onClickLocationFilterApply,
  buyerLocationListSearchParam,
  handleLocationSearch,
  loadMoreLocationList,
  language,
  searchIcon,
  activeSvg,
  inactiveSvg,
  isModalErrorVisible,
  onRefreshLocationList,
  onClickHelp,
}: PayloadType) => {
  const locationListing = locationList?.length > 0 && (
    <div className={Styles.optionWrapper}>
      {locationList.map((item, index) => {
        return (
          <div
            key={index + 'locationList'}
            className={
              activeLocations.includes(item?.key_concat)
                ? Styles.ActiveItemContainer
                : Styles.InactiveItemContainer
            }
            onClick={() =>
              handleLocationSelection(item.key_concat, item.district_name)
            }
          >
            <div className={Styles?.titleStyle}>{item?.value_concat}</div>

            <Checkbox
              variant={
                activeLocations.includes(item?.key_concat)
                  ? 'solid'
                  : 'outlined'
              }
              color={
                activeLocations.includes(item?.key_concat)
                  ? 'primary'
                  : 'neutral'
              }
              size="md"
              checked={activeLocations.includes(item?.key_concat)}
              onClick={() =>
                handleLocationSelection(item.key_concat, item.district_name)
              }
            ></Checkbox>
          </div>
        );
      })}
    </div>
  );

  const UiModal = (
    <div>
      {isModalErrorVisible ? (
        <Box margin={'8px 0px'}>
          <CommonErrorState
            onPressRetry={onRefreshLocationList}
            onPressHelp={onClickHelp}
            type="modal"
            imageId="alertimage_alertmodal_tradescreen"
            subHeadingTextId="unabletoprocess_text_alertmodal_tradescreen"
            headingTextId="somethingwentwrong_text_alertmodal_tradescreen"
            onPressRetryTestId="retry_button_alertmodal_tradescreen"
          />
        </Box>
      ) : (
        <div className={Styles.wrapper}>
          <div className={Styles.searchWrapper}>
            <div className={Styles.searchField}>
              <div className={Styles.iconWrapper}>
                <img
                  src={searchIcon}
                  height={24}
                  width={25}
                  loading="eager"
                  alt="searchIcon"
                />
              </div>
              <input
                type="text"
                placeholder={language?.selectLocationSearchPlaceholder}
                onChange={handleLocationSearch}
                value={buyerLocationListSearchParam}
                className={Styles.searchInput}
              />
            </div>
          </div>
          <div className={Styles.infinityScroll}>
            <InfiniteScroll
              children={locationListing}
              vertical={true}
              onScrollToEnd={loadMoreLocationList}
              loading={locationLoading}
              loadingElement={
                <div className={Styles.loadingContainer}>
                  <Loading size={20} color="grey" loaderWidth={3} />
                </div>
              }
            />
          </div>
          <div className={Styles.buttonWrapperContainer}>
            <div
              onClick={onClickLocationFilterApply}
              className={Styles.button_container}
            >
              {language?.apply}
            </div>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Modal
      onClose={handleBuyerLocationListModal}
      isCloseButtonOption={true}
      title={language?.selectLocationHeading}
      isOpen={isOpen}
      children={UiModal}
    />
  );
};

export default SelectLocationModal;
