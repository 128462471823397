import React from 'react';
import { Snackbar } from '@mui/joy';
import { DownloadRounded } from "@mui/icons-material"

interface DownloadSnackbarProps {
    downloadingPdf: boolean;
    language: { [key: string]: string }
}
const DownloadSnackbar = ({ downloadingPdf, language }) => {
    //ToDo: Add language for downloading
    return (
        <Snackbar size="md" open={downloadingPdf} autoHideDuration={3000} onClose={() => { console.log("Close Download Snackbar") }} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{
                paddingTop: 3,
                paddingBottom: 3,
                paddingLeft: 2,
                paddingRight: 2,
                zIndex: 80000,
                borderRadius: '8px',
                marginBottom: '32px',
                marginTop: "48px",
                minWidth: "150px",
                display: 'flex',
                justifyContent: "center"
            }}
            variant="solid"
            color={"neutral"}
            startDecorator={< DownloadRounded />}> {"Downloading!..."}
        </Snackbar >
    )
}

export default DownloadSnackbar