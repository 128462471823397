import React from 'react';
import Styles from './style.module.css';
import { TradePriceBreakupData } from '@mono-farmart-web/farmart-web-common/modal/Traders/types';
import ChecklistRoundedIcon from '@mui/icons-material/ChecklistRounded';
interface TradeDetailProps {
  PriceBreakup: TradePriceBreakupData;
  language?: { [key: string]: any };
}
const QC = (props: TradeDetailProps) => {
  const { PriceBreakup, language } = props;

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.titleBar}>
        <div className={Styles.iconWrapper} data-testid="listIconDataTestId">
          <ChecklistRoundedIcon
            sx={{ color: '#0E1E40', height: 20, width: 20 }}
          />
        </div>
        <div className={Styles.headingText}>{language?.qualityParaMeters}</div>
      </div>
      <div className={Styles.detailsWrapper}>
        {PriceBreakup?.qc_requirement_details &&
        PriceBreakup.qc_requirement_details.length > 0 ? (
          PriceBreakup?.qc_requirement_details?.map((item, index) => (
            <div key={index} className={Styles.listItem}>
              <div className={Styles.name}>{item?.qc_name}</div>
              <div className={Styles.value}>{item?.value}%</div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default QC;
