import React from 'react';
import Styles from './style.module.css';
import Modal from '@mono-farmart-web/farmart-web-common/Components/BottomSheet';

interface ReportProps {
  isOpen: boolean;
  onClose: () => void;
  handleInstallClick: () => void;
  addToHomeImage: string;
}

function modalView(props: ReportProps) {
  const { handleInstallClick, addToHomeImage } = props;
  return (
    <div
      id="pwaInstallButtonId"
      onClick={handleInstallClick}
      className={Styles.modalContainer}
    >
      <img
        className={Styles.modalImage}
        src={addToHomeImage}
        alt="addToHomeScreenModal"
        loading="eager"
      />
      <button className={Styles.addToHome}>ADD TO HOME</button>
    </div>
  );
}

const AddToHome = (props: ReportProps) => {
  const { isOpen, onClose } = props;
  return (
    isOpen && (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        children={modalView(props)}
        title={'FarMart समुदाय, होम स्क्रीन पर जोड़ें'}
      />
    )
  );
};

export default AddToHome;
