import React from 'react';
import { CloseRounded } from '@mui/icons-material';
import { Box } from '@mui/joy';
import RiveComponent from '@mono-farmart-web/farmart-web-common/utils/riveComponent';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { LocalShippingOutlined } from '@mui/icons-material';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentsV3/MuiButton';
import FormatPrice from '@mono-farmart-web/farmart-web-common/utils/formatPrice';
import IndianFormatedDate from '@mono-farmart-web/farmart-web-common/utils/IndianFormatedDate';
import CommonErrorState from '@mono-farmart-web/farmart-web-common/ComponentsV4/CommonErrorState';
import MuiSkeleton from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';
import styles from './style.module.css';

export default function PaymentScreen({
  language,
  paymentAssets,
  paymentData,
  onViewSaudaDetails,
  onClickBack,
  error,
  loading,
}) {
  return (
    <Box
      className={
        error
          ? `${styles?.errorContainer} ${styles?.container}`
          : styles?.container
      }
    >
      {error && !loading ? (
        <Box
          sx={{
            bgcolor: '#ffffff',
            boxSizing: 'border-box',
            height: '100%',
            width: '100%',
          }}
        >
          <CommonErrorState
            customPrimaryButtonTitle={language?.buttonText}
            customSecondaryButtonTitle={language?.backToHome || 'BACK TO HOME'}
            onClickCustomPrimaryButton={onViewSaudaDetails}
            onClickCustomSecondaryButton={onClickBack}
            customPrimaryButtonTestId={
              'truckPaymentErrorScreenViewSaudaDetails'
            }
            customSecondaryButtonTestId={'truckPaymentScreenBackToHome'}
          />
        </Box>
      ) : loading ? (
        <Box
          sx={{
            height: '100vh',
            gap: '16px',
            boxSizing: 'border-box',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#FFFFFF',
          }}
        >
          <MuiSkeleton
            itemNumber={6}
            sx={{ height: '120px', width: '100%', borderRadius: '8px' }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            height: '100vh',
            width: '100%',
          }}
        >
          <CloseRounded
            sx={{
              color: '#FFFFFF',
              height: '24px',
              width: '24px',
            }}
            onClick={onClickBack}
            id="truckPaymentScreenBackButton"
          />
          <Box
            sx={{
              display: 'flex',
              alignSelf: 'center',
              flexDirection: 'column',
              width: '100%',
              borderRadius: ' var(--radius-lg, 12px)',
              background: '#FFFFFF',
              boxSizing: 'border-box',
              marginTop: '50px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: 0.85,
                background: 'var(--primary-700, #007B65)',
                flexDirection: 'column',
                boxSizing: 'border-box',
                backgroundImage: `url(${paymentAssets?.background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '70px',
                  height: '70px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                  marginTop: '28px',
                }}
              >
                <RiveComponent
                  src={paymentAssets?.moneyRiv}
                  autoplay={true}
                  id={'glodcoin_icon_notificationscreen'}
                  data-testid="paymentScreenGoldCoinTestId"
                />
              </Box>
              <MuiTypography
                level="title-lg"
                sx={{ color: 'white', marginTop: '16px' }}
                id="paymentsent_text_notificationscreen"
                data-testid="paymentScreenPaymentSentTestId"
              >
                {language?.heading}
              </MuiTypography>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ opacity: 0.8 }}
                textAlign={'center'}
                margin={'2px 8px 20px 8px'}
              >
                <MuiTypography
                  level="body-xs"
                  sx={{
                    color: '#FFFFFF',
                    margin: '0px 6px',
                    fontFamily: 'NotoSans',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '16px',
                  }}
                  id="yoursaudapaymentsentyou_text_notificationsceen"
                  data-testid="paymentScreenYourSaudaPaymentSentTestId"
                >
                  {language?.subHeading}
                </MuiTypography>
              </Box>
            </Box>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                padding:
                  'var(--5, 20px) var(--7, 32px) var(--2, 20px) var(--7, 32px)',
                flexDirection: 'column',
                alignItems: 'center',
                alignSelf: 'center',
                width: '100%',
                boxSizing: 'border-box',
                borderBottomLeftRadius: ' var(--radius-lg, 12px)',
                borderBottomRightRadius: ' var(--radius-lg, 12px)',
              }}
            >
              <Box
                display={'flex'}
                padding={'var(--1, 4px) var(--2, 8px)'}
                alignItems={'center'}
                gap={'var(--1, 4px)'}
                borderRadius={'var(--radius-3xl, 24px)'}
                bgcolor={'var(--background-level1, #F0F4F8)'}
              >
                <LocalShippingOutlined fontSize="medium" />
                <MuiTypography
                  sx={{
                    color: 'var(--text-secondary, #555E68)',
                    fontFamily: 'NotoSans',
                    fontSize: '10px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '14px',
                  }}
                  id="trucknumber_chip_notifcationscreen"
                  data-testid="paymentScreenTruckNumberTestId"
                >
                  {language?.truckNo} {paymentData?.vehicle_number}
                </MuiTypography>
              </Box>
              <Box
                display={'flex'}
                padding={'var(--3, 12px)'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                alignSelf={'stretch'}
                borderRadius={'var(--radius-md, 8px)'}
                bgcolor={'var(--background-level1, #F0F4F8)'}
                marginTop={'20px'}
              >
                <MuiTypography
                  level="title-sm"
                  sx={{ color: '#171A1C' }}
                  id="amount_number_notificationscreen"
                  data-testid="paymentScreenAmountTestId"
                >
                  {FormatPrice({
                    prefix: paymentData?.amount_unit,
                    suffix: '',
                    value: paymentData?.amount,
                  })}
                </MuiTypography>
                <MuiTypography
                  sx={{
                    color: 'var(--text-secondary, #555E68)',
                    fontFamily: 'NotoSans',
                    fontSize: '10px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '14px',
                    marginTop: '2px',
                  }}
                  id="amountpaid_text_notificationscreen"
                  data-testid="paymentScreenAmountPaidTestId"
                >
                  {language.amountPaid}
                </MuiTypography>
                <Box
                  sx={{
                    border: '1px solid var(--divider, #636B744D)',
                    width: '100%',
                    opacity: 0.6,
                    borderStyle: 'dashed',
                    margin: '8px 0px',
                  }}
                />
                <MuiTypography
                  sx={{
                    marginTop: '8px',
                    color: 'var(--text-secondary, #171A1C)',
                    fontFamily: 'NotoSans',
                    fontSize: '10px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '14px',
                  }}
                  id="utrnumber_text_notificationscreen"
                  data-testid="paymentScreenUtrNumberTestId"
                >
                  {language?.utr} {paymentData?.utr}
                </MuiTypography>
                <MuiTypography
                  sx={{
                    marginTop: '8px',
                    color: 'var(--text-secondary, #9FA6AD)',
                    fontFamily: 'NotoSans',
                    fontSize: '10px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '14px',
                  }}
                  id="date_number_notification"
                  data-testid="paymentScreenDateTestId"
                >
                  {IndianFormatedDate(paymentData?.paid_on)}
                </MuiTypography>
              </Box>
              <MuiTypography
                sx={{
                  color: 'var(--text-secondary, #9FA6AD)',
                  fontFamily: 'NotoSans',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '14px',
                  marginTop: '8px',
                  textAlign: 'center',
                }}
                id="amountwillreflect_text_notifictionscreen"
                data-testid="paymentScreenAmountWillReflectTestId"
              >
                {language?.disclaimer}
              </MuiTypography>
              <Box
                display={'flex'}
                padding={'var(--05, 2px) var(--3, 12px)'}
                alignItems={'center'}
                gap={'var(--1, 4px)'}
                borderRadius={'var(--Corner-Full, 1000px)'}
                border={'1px solid #1CC505'}
                bgcolor={'var(--success-soft-Bg, #E3FBE3)'}
                justifyContent={'center'}
                marginTop={'32px'}
              >
                <img
                  src={paymentAssets?.trustLogo}
                  alt="Trusted Logo"
                  loading="eager"
                  style={{
                    height: '19px',
                    width: '19px',
                    objectFit: 'contain',
                  }}
                  id="yourtrustedpartnerfarmart_chip_notificationscreen"
                  data-testid="paymentScreenYourTrustedPartnerTestId"
                />
                <MuiTypography
                  sx={{
                    marginLeft: '4px',
                    color: 'var(--success-plain-Color, #1F7A1F)',
                    fontFamily: 'NotoSans',
                    fontSize: '10px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '14px',
                  }}
                  id="yourtrustedpartnerfarmart_chip_notificationscreen"
                  data-testid="paymentScreenYourTrustedPartnerTestId"
                >
                  {language?.trustPartner}
                </MuiTypography>
              </Box>
              <MuiButton
                size="md"
                color="primary"
                sx={{ marginTop: '16px', borderRadius: '8px' }}
                onClick={onViewSaudaDetails}
                id="viewsaudadetails_button_notificationscreen"
                data-testid="paymentScreenViewSaudaDetailstestId"
              >
                {language?.buttonText}
              </MuiButton>
            </Box>
          </Box>
          <img
            src={paymentAssets?.farmartLogo}
            alt="Farmart Logo"
            loading="eager"
            style={{
              height: '18px',
              width: '80px',
              objectFit: 'contain',
              alignSelf: 'center',
              position: 'absolute',
              bottom: '16px',
            }}
          />
        </Box>
      )}
      {!loading && !error && <RiveComponent
        src={paymentAssets?.confetti}
        autoplay={true}
        styles={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          alignSelf: 'center',
          boxSizing: 'border-box',
          pointerEvents: 'none',
        }}
      />}
    </Box>
  );
}
