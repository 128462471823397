import React, { useRef, useState } from 'react';
import CircularProgress from '@mui/joy/CircularProgress';
interface PullToRefreshProps {
  onRefresh: () => Promise<void>;
  children: React.ReactNode;
  otherLoading?: boolean;
}

const PullToRefresh: React.FC<PullToRefreshProps> = ({
  onRefresh,
  children,
  otherLoading = false,
}) => {
  const startY = useRef<number | null>(null);
  const [pullDistance, setPullDistance] = useState(0); // Tracks the drag distance
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const threshold = 100; // Minimum pull distance to trigger refresh

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    if (e.currentTarget.scrollTop === 0 && rect.top >= 0) {
      startY.current = e.touches[0].clientY;
    }
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (startY.current !== null) {
      const currentY = e.touches[0].clientY;
      const diffY = currentY - startY.current;

      if (diffY > 0) {
        setPullDistance(Math.min(diffY, threshold * 2)); // Limit max pull distance for effect
      }
    }
  };

  const handleTouchEnd = async () => {
    if (pullDistance > threshold) {
      setLoading(true);
      await onRefresh();
      setLoading(false);
    }
    setPullDistance(0); // Reset pull distance
    startY.current = null;
  };
  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{
        overflow: 'auto',
        height: '100%',
        position: 'relative',
        width: '100%',
        transform: `translateY(${pullDistance > 0 ? pullDistance / 2 : 0}px)`,
        transition: pullDistance === 0 ? 'transform 0.3s ease' : 'none',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!loading && !otherLoading ? (
        <></>
      ) : (
        <CircularProgress
          sx={{
            marginTop: '24px',
            marginBottom: '32px',
            alignSelf: 'center',
          }}
          size="sm"
          color="primary"
        />
      )}
      {children}
    </div>
  );
};

export default PullToRefresh;
