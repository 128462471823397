import { useEffect, useState } from "react";
import type { FAQQuestionsProps, LanguageContentType } from "./types";
import type { FaqAnswerDetail } from "../FAQAnswers/types";
import { useNavigate } from "react-router-dom";
import { usePlatfromData } from "../CrossPlatformActionHandler/platformChecker";
import GetRequest from "../../utils/apiCaller/getRequest";
import { apiEndPoints } from "../../apis/endpoints";
import { helpLineNumber } from "../../constant/HelpLineNumber";
import { faqWhatsappUrl } from "../../constant/whatsappUrl";
import getLang from "../../languages/faqQuestions";
import { DEFAULT_LANGUAGE, DEFAULT_LANGUAGE_ID } from "../../constant/language";
import Events from "../../constant/MoengageEvents/index";
import { trackEvent } from "../../utils/moengage";
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import bridgeFunctions from "../../utils/bridge";
import { getFaqAssets } from "../../constant/imageUrls";
import { getLanguageService } from "./../../utils/getLanguageService";
import { languageUrls } from "../../constant/languageUrls/index";

export default function UseFaqQuestions(): FAQQuestionsProps {
	const bridge = createBridge(); // Initialize the bridge for communication with the native app.
	const navigate = useNavigate(); // Hook for navigation.
	const platformData = usePlatfromData(); // Get platform data.
	const [faqQuestionList, setFaqQuestionList] = useState([]); // State for storing FAQ questions.
	const [loadingQuestion, setLoadingQuestion] = useState(false); // State for loading status.
	const [showFaqAlert, setShowFaqAlert] = useState(false); // State for showing FAQ alert.

	const [languageFile, setLanguageFile] = useState<LanguageContentType>(null); // Language file state

	useEffect(() => {
		getLanguageJson();
	}, []);

	async function getLanguageJson() {
		try {
			const url = languageUrls?.faqQuestions;
			const result = await getLanguageService(url);
			setLanguageFile(result);
		} catch (error) {
		} finally {
		}
	}

	const langId = JSON.parse(localStorage.getItem("userLanguage")); // Retrieve selected language from local storage.
	const language = languageFile ?? getLang(DEFAULT_LANGUAGE); // Getting language file based on language ID

	const faqAssets = getFaqAssets(langId?.langCode); // Get FAQ assets based on the selected language?.

	useEffect(() => {
		// Attach a listener to handle messages from the native app.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(window as any).listenMessagesFromApp = (request: any) => {
			bridge.listenMessagesFromNative(request); // Pass the request to the bridge.
		};
	}, []);

	useEffect(() => {
		getFaqQuestion(); // Fetch FAQ questions on component mount.
	}, []);

	/**
	 * Fetch FAQ questions from the API.
	 */
	async function getFaqQuestion() {
		setLoadingQuestion(true); // Set loading state to true.
		try {
			const faqQuestionPayload = {
				language_id: langId?.langId || DEFAULT_LANGUAGE_ID, // Selected language id in number.
			};
			const response = await GetRequest({
				payload: faqQuestionPayload,
				uri: apiEndPoints?.getFAQQuestion,
			});
			if (response?.data?.status) {
				setFaqQuestionList(response?.data?.data?.faq); // Set FAQ questions list.
			} else {
				console.log("No Data"); // Log no data message.
			}
		} catch (error) {
			// Handle error (you may want to log or display the error).
		} finally {
			setLoadingQuestion(false); // Set loading state to false.
		}
	}

	/**
	 * Navigate back to the previous screen.
	 */
	function onClickBackFaqScreen() {
		navigate(-1); // Navigate back to the previous screen.
	}

	/**
	 * Handle click on an FAQ question item.
	 * @param {FaqAnswerDetail} faqAnswer - The selected FAQ answer.
	 */
	function onClickFaqQuestionItem(faqAnswer: FaqAnswerDetail) {
		const clickedFAQProps = {
			id: faqAnswer?.id,
		};
		trackEvent(Events?.onFaqButtonClick, clickedFAQProps); // Track event for FAQ button click.
		navigate(`/faq/answer/${faqAnswer?.id}`, {
			state: { faqAnswer: faqAnswer }, // Navigate to the FAQ answer screen with the selected answer.
		});
	}

	/**
	 * Handle click on the "Need More Help" button.
	 */
	async function onClickNeedMoreHelp() {
		const url = `${faqWhatsappUrl}`;
		if (platformData.platform === "WEB") {
			const win = window?.open(url, "_blank"); // Open WhatsApp link in a new tab if on web.
			win?.focus();
		} else {
			const text = `${language?.whatsAppMessageText}&phone=${helpLineNumber}`;
			const link = `whatsapp://send?text=${text}`;

			// Send request to open WhatsApp link on the native app.
			bridge.sendRequestToNative(
				"OpenUrl",
				{
					link: link,
					show_toast: true,
					toast_message: language?.noWhatsAppAccountFound,
				},
				(response) => {
					bridgeFunctions.PrintLog(response);
				},
			);
		}
		trackEvent(Events?.onHelpWhatsappButtonClick, {}); // Track event for Help WhatsApp button click.
	}

	return {
		faqQuestionList,
		onClickBackFaqScreen,
		loadingQuestion,
		onClickFaqQuestionItem,
		onClickNeedMoreHelp,
		showFaqAlert,
		setShowFaqAlert,
		language,
		faqAssets,
	};
}
