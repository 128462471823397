import { useState, useEffect } from 'react';
import type { RakeListProps, NearByRakeProps } from './types.js'; // Importing necessary types
import getLang from '../../languages/rakeList.js'; // Importing language translation function
import { useNavigate, useLocation } from 'react-router-dom';
import { apiEndPoints } from '../../apis/endpoints';
import FeatureLocation from '../../utils/FeatureLocation/FeatureLcation';
import FeatureLocationId from '../../constant/enums/FeatureLocationId';
import { appLanguages } from '../../constant/appLanguages';
import { trackEvent } from '../../utils/moengage/index'; // Importing tracking functions
import Events from '../../constant/MoengageEvents/index'; // Importing constant events
/* The line `import GetIpLocation from "../../modal/Traders/models/GetIpLocation";` is importing a
function named `GetIpLocation` from the file located at "../../modal/Traders/models/GetIpLocation".
This function is likely responsible for fetching the IP location details of the user, which involves
retrieving the latitude and longitude coordinates based on the user's IP address. The function may
make use of external services or APIs to determine the geographical location of the user based on
their IP address. */
import GetIpLocation from '../../modal/Traders/models/GetIpLocation';
/* The line `import useAuthenticationChecker from "../../utils/authChecker";` is importing a custom
hook named `useAuthenticationChecker` from the file located at "../../utils/authChecker". This
custom hook likely contains functionality related to authentication checking and API calling, which
is used within the `RakeListHook` function to handle authentication-related tasks and make API
calls. */
import useAuthenticationChecker from '../../utils/authChecker';
import { getLanguageService } from './../../utils/getLanguageService';
import { languageUrls } from '../../constant/languageUrls/index';
import { DEFAULT_LANGUAGE } from '../../constant/language';
import useAppLanguage from '../../utils/applanguage/index.js';

export default function RakeListHook(): RakeListProps {
  /**
   * The code snippet initializes various state variables and functions related to language settings,
   * loading states, page navigation, and location modals.
   */
  const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();
  const { pathname, state } = useLocation();
  const { language } = useAppLanguage(
    languageUrls?.rakeListScreenLangageUrl,
    getLang,
  );

  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  const systemLanguage = activeSystemLang?.includes('en')
    ? appLanguages[1].langCode
    : appLanguages[0].langCode;

  const langCode = langId?.langCode || systemLanguage;
  const [rakeLoading, setRakeLoading] = useState(true);
  const [loadMoreRakeLoading, setLoadMoreRakeLoading] = useState(false);
  const [nearbyRakePage, setNearbyRakePage] = useState(1);
  const [nearRakeData, setNearRakeData] = useState<NearByRakeProps[]>([]);
  const [currentRakeCount, setCurretRakeCount] = useState(0);
  const [nearRakeCount, setNearRakeCount] = useState(0);
  const navigate = useNavigate();
  const startScreenTime = new Date().getTime();
  const [locationModalLoading, setLocationModalLoading] =
    useState<boolean>(false);
  const [isLocationModal, setLocationModal] = useState<boolean>(false);
  function onClickChangeModal() {
    setLocationModal(!isLocationModal);
  }
  /* The code snippet you provided is initializing two state variables using the `useState` hook in a
  React functional component named `RakeListHook`. Here's what each line is doing: */
  const [currentLocation, setCurrentLocation] = useState<NearByRakeProps>(
    state?.changedLocation || {},
  );
  const locationChanged = state?.locationChanged;
  // Function to handle back button press
  function onPressBack() {
    navigate('/');
  }
  /**
   * The function `onContactUs` navigates to the "/faq/questions" page.
   */
  function onContactUs() {
    navigate('/faq/questions');
  }
  /**
   * The function onClickChangeLocation navigates to the '/manualLocation' path with the previous path
   * stored in the state object.
   */
  function onClickChangeLocation() {
    navigate(`/manualLocation`, {
      state: { prevPath: pathname },
    });
  }
  /**
   * The function `onClickRakeCard` navigates to the "/rakeDetail" page with the selected location or
   * current location state.
   * @param selectedLocation - The `selectedLocation` parameter is the location that has been selected
   * by the user. It could be a specific address, coordinates, or any other location identifier.
   * @param rakeCount - The `rakeCount` parameter in the `onClickRakeCard` function likely represents
   * the number of rakes associated with the selected location or card. It seems to be used to determine
   * the behavior or display of the rake card when clicked.
   * @param cardtype - The `cardtype` parameter in the `onClickRakeCard` function is likely used to
   * specify the type of card being clicked or interacted with. It could be used to differentiate
   * between different types of cards or to determine the behavior or action to be taken when a specific
   * type of card is clicked
   * @returns A function is being returned that navigates to the "/rakeDetail" page with the state
   * parameter set to either the selectedLocation or the currentLocation if selectedLocation is falsy.
   */
  function onClickRakeCard(selectedLocation, rakeCount, cardtype) {
    return () => {
      trackEvent(Events?.onRakeLocationCardClick, {
        Primary_dis_rake_visible: currentRakeCount < 0 ? true : false,
        rake_count: rakeCount,
        card_type: cardtype,
        From: 'Rake Card Clicked',
      });
      navigate('/rakeDetail', {
        state: selectedLocation || currentLocation,
      });
    };
  }
  async function fetchNearByRake({ rakeLocation }) {
    try {
      const nearRakeRequestBody = {
        master_state_id: rakeLocation?.master_state_id,
        master_district_id: rakeLocation?.master_district_id,
        limit: 6,
        page_no: nearbyRakePage,
      };
      const nearRakeStatusResp = await AuthenticationCheckerAndApiCaller({
        payload: nearRakeRequestBody,
        uri: apiEndPoints.getNearByRake,
        requestType: 'get',
      });
      return nearRakeStatusResp;
    } catch (error) {
      console.log(error);
    }
  }
  async function checkLocation() {
    if (!locationChanged) {
      const { initialLoactionCheck } = FeatureLocation();
      const rakeLocation = await initialLoactionCheck();
      setCurrentLocation(rakeLocation);
    }
  }
  useEffect(() => {
    checkLocation();
  }, []);
  async function fetchRakeStatus() {
    setRakeLoading(true);
    const rakeLocation = currentLocation;
    if (currentLocation?.master_district_id) {
      const nearRakeResp = await fetchNearByRake({ rakeLocation });
      if (nearRakeResp?.data?.status) {
        trackEvent(Events?.onRakeFeatureLaunch, {
          'is rake available in primary district':
            nearRakeResp?.data?.data?.current_rake_count === 0 ? false : true,
          'Loading time': (new Date().getTime() - startScreenTime) / 1000,
          'Number of primary card loaded': 1,
          'Number of secondary card loaded': nearRakeResp?.data?.data?.count,
        });
        setNearbyRakePage(2);
        setNearRakeData(nearRakeResp?.data?.data?.rows);
        setNearRakeCount(nearRakeResp?.data?.data?.count);
        setCurretRakeCount(nearRakeResp?.data?.data?.current_rake_count);
      }
      setRakeLoading(false);
      const updateFeatrueLocationParams = {
        feature_id: FeatureLocationId?.rake, //mandatory
        state_id: rakeLocation?.master_state_id, //mandatory ,
        district_id: rakeLocation?.master_district_id, //mandatory ,
      };
      AuthenticationCheckerAndApiCaller({
        payload: updateFeatrueLocationParams,
        uri: apiEndPoints.updateUserFeatureLocation,
        requestType: 'post',
      });
    }
    setRakeLoading(false);
  }
  async function onLoadMoreNearRake() {
    setLoadMoreRakeLoading(true);
    trackEvent(Events?.MORE_CARD_EXPLORED, {});
    const nearRakeResp = await fetchNearByRake({
      rakeLocation: currentLocation,
    });
    if (nearRakeResp?.data?.status && nearRakeResp?.data?.data?.rows !== 0) {
      setNearRakeData([...nearRakeData, ...nearRakeResp.data.data.rows]);
      setNearbyRakePage(nearbyRakePage + 1);
    }
    trackEvent(Events?.onRakeViewinNearbyDistrictButtonClick, {
      From: 'More Rake Explored',
    });
    setLoadMoreRakeLoading(false);
  }
  useEffect(() => {
    fetchRakeStatus();
  }, [currentLocation?.master_district_id]);
  function trimDecimalValues(value: number, trimUpto: number) {
    return Number(value.toFixed(trimUpto));
  }
  function onClickGetGpsLocation() {
    setLocationModalLoading(true);
    const options = {
      enableHighAccuracy: true, // Request high accuracy
      timeout: 6000, // Maximum time (in milliseconds) to wait for location data
      maximumAge: 0, // Maximum age (in milliseconds) of a possible cached position
    };
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const reverseGeoParams = {
              lat: JSON.stringify(
                trimDecimalValues(position.coords.latitude, 4),
              ),
              long: JSON.stringify(
                trimDecimalValues(position.coords.longitude, 4),
              ),
            };
            const stateDistrict = await AuthenticationCheckerAndApiCaller({
              payload: reverseGeoParams,
              uri: apiEndPoints?.fetchStateDistrictsReverseGeoCode,
              requestType: 'get',
            });
            setCurrentLocation(stateDistrict?.data?.data?.reverse_geocode);
            setLocationModal(false);
            setLocationModalLoading(false);
          } catch (error) {
            console.log(error);
          }
        },
        (error) => {
          console.log(error);
          getIpLocationGlobalFun();
          setLocationModal(false);
          setLocationModalLoading(false);
        },
        options, // Pass the options object
      );
    } else {
      getIpLocationGlobalFun();
      setLocationModal(false);
      setLocationModalLoading(false);
    }
  }
  async function getIpLocationGlobalFun() {
    try {
      const IpLocationLatLong = await GetIpLocation();
      const reverseGeoParams = {
        lat: JSON.stringify(
          trimDecimalValues(IpLocationLatLong?.location?.lat, 4),
        ),
        long: JSON.stringify(
          trimDecimalValues(IpLocationLatLong?.location?.lng, 4),
        ),
      };
      const stateDistrict = await AuthenticationCheckerAndApiCaller({
        payload: reverseGeoParams,
        uri: apiEndPoints?.fetchStateDistrictsReverseGeoCode,
        requestType: 'get',
      });
      setCurrentLocation(stateDistrict?.data?.data?.reverse_geocode);
      setLocationModalLoading(false);
      setLocationModal(false);
    } catch (error) {
      if (error?.code === 401 || error?.code === 426) {
        setLocationModalLoading(false);
        setLocationModal(false);
      }
      console.log(error);
    }
  }
  // Returning necessary functions and states
  return {
    onPressBack,
    language,
    onContactUs,
    onLoadMoreNearRake,
    rakeLoading,
    loadMoreRakeLoading,
    currentRakeCount,
    nearRakeCount,
    nearRakeData,
    onClickChangeLocation,
    langCode,
    currentLocation,
    onClickChangeModal,
    locationModalLoading,
    onClickGetGpsLocation,
    isLocationModal,
    onClickRakeCard,
  };
}
