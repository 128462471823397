import React from 'react';
import { Box, Card } from '@mui/joy';
import { LocalShippingRounded, ChevronRightRounded } from '@mui/icons-material';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';

const TruckTrackingCard = ({
  primaryColor,
  secondaryColor,
  title,
  subtitle,
  onClick,
  titleTestId = '',
  titleDataTestId = '',
  subTitleTestId = '',
  subTitleDataTestId = '',
  titleAndSubtitleTextColor = '#FFFFFF',
  cardBackgroundColor=""
}) => {
  return (
    <Card
      color={primaryColor}
      variant={'solid'}
      size="lg"
      sx={{ padding: '8px', backgroundColor: cardBackgroundColor }}
      onClick={onClick}
      id="truckstatus_button_truckdetailscreen"
      data-testid="truckstatus_button_truckdetailscreen"
    >
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Box
            borderRadius={4}
            padding={1}
            display={'flex'}
            sx={{ backgroundColor: secondaryColor }}
          >
            <LocalShippingRounded
              color={primaryColor}
              sx={{ height: '30px', width: '30px' }}
              id="truckstatus_button_truckdetailscreen"
              data-testid="truckstatus_button_truckdetailscreen"
            />
          </Box>
          <Box
            marginX={3}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'flex-start'}
          >
            <MuiTypography
              level="body-xs"
              lineHeight={'20px'}
              sx={{ color: titleAndSubtitleTextColor }}
              id={titleTestId}
              data-testid={titleDataTestId}
            >
              {title}
            </MuiTypography>
            <MuiTypography
              lineHeight={'14px'}
              sx={{ color: titleAndSubtitleTextColor }}
              level="label-sm"
              display={'flex'}
              justifyContent={'flex-start'}
              id={subTitleTestId}
              data-testid={subTitleDataTestId}
            >
              {subtitle}
            </MuiTypography>
          </Box>
        </Box>

        <Box display={'flex'} alignItems={'center'}>
          <ChevronRightRounded sx={{ height: '24px', width: '24px' }} />
        </Box>
      </Box>
    </Card>
  );
};

export default TruckTrackingCard;
