/* The line `import React, { useState, useEffect } from "react";` is importing the `useState` and
`useEffect` hooks from the React library. These hooks are essential for managing state and side
effects in functional components in React. */

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
	type HomeScreenHookProps,
	type HomeScreenSectionData,
	type RakeData,
	LanguageContentType,
} from "./types"; //Home screen interfaces imported from here
import { useNavigate } from "react-router-dom"; /* The line `import { useNavigate } from "react-router-dom";` is importing the `useNavigate` hook from
the React Router DOM library. This hook allows you to navigate programmatically in a React
component. It provides a function that can be used to navigate to different routes within your
application without the need for a `<Link>` component. */
import { trackEvent } from "../../utils/moengage"; //Moengage function to capture events
import Events from "../../constant/MoengageEvents/index"; //Moengage events names imported from here
import getLang from "../../languages/homeScreen.js"; //Languages for home screen stored here
import { usePlatfromData } from "../CrossPlatformActionHandler/platformChecker"; //Platform checker use to check whether user is on web or app platform
import { apiEndPoints } from "../../apis/endpoints"; //All api's end points imported from here
import CTUserTypeEnums from "../../constant/CTUserTypeEnums"; /* The line `import CTUserTypeEnums from "../../constant/CTUserTypeEnums";` is importing a module named
`CTUserTypeEnums` from a file located at "../../constant/CTUserTypeEnums". This import statement
allows access to the constants or enums related to user types defined in the `CTUserTypeEnums`
module within the current file. These user type enums are likely used for identifying different
types of users or roles within the application. */

import { userType } from "../../constant/enums/userType";
import downloadAppModalType from "../../constant/enums/downloadAppModalType";
import { useLocation } from "../../context/location";
import { getQuizImage } from "../../constant/quizImages";
import { createBridge } from "@mono-farmart-web/rpc-bridge";
import bridgeFunctions from "../../utils/bridge";
import useClearUserLocationEvery24Hours from "./locationExpiry";
import PlatForms from "../../constant/platFormEnums";
/* The above code is importing various enums, constants, and functions from different files and
directories. It also includes a custom hook `useClearUserLocationEvery24Hours` from the local
directory. Additionally, it seems to be setting up a bridge for RPC communication and using some
utility functions related to the bridge. */
import useAuthenticationChecker from "../../utils/authChecker";
import webAppCommonFeaturesEnums from "../../constant/enums/webAppCommonFeaturesEnums";
import webAppCommonTabFeaturesEnum from "../../constant/enums/webAppCommonTabFeaturesEnum";
import { PlayStoreUrl } from "../../constant/PlayStoreUrl";
import jsonParse from "../../utils/jsonParser";
import { setUserPersona } from "../../redux/slices/personaSlice";
import { getHomeScreenAssets } from "../../constant/imageUrls/index";
import { languageUrls } from "../../constant/languageUrls/index";
import useAppLanguage from "../../utils/applanguage";

export default function HomeScreenList(): HomeScreenHookProps {
	/* home screen common hooks start*/
	const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();
	const platform = usePlatfromData();
	const locationData = useLocation();
	const navigate = useNavigate();
	const bridge = createBridge();
	const { language } = useAppLanguage(
		languageUrls?.homeScreenLangageUrl,
		getLang,
	);

	const startLoadTime = new Date().getTime();
	const userProfileString = localStorage.getItem("userProfile") || null;
	const userProfile = JSON.parse(userProfileString);
	const refreshPageFromStorage = localStorage.getItem("refreshPage");
	const userLogedIn =
		userProfile?.merchant_detail_id ||
		userProfile?.merchant_occupation_id ||
		JSON.parse(localStorage.getItem("loged"));
	const token = localStorage.getItem("authToken");

	const appRoutesData = JSON.parse(localStorage.getItem("appRoutesData"));

	const langId = JSON.parse(localStorage.getItem("userLanguage"));

	const addFarmerVisible =
		userProfile?.merchant_occupation_id !== userType["farmer"];
	const helpButtonVisible = PlatForms?.get("application");
	const [homeScreenLoading, setHomeScreenLoading] = useState<boolean>(true);
	const [homeScreenData, setHomeScreenData] = useState<HomeScreenSectionData[]>(
		[],
	);
	const [refreshPage, setRefreshPage] = useState<boolean>(false);
	const [uniqueKey, setUniqueKey] = useState<number>(1);
	const { clearLocationIfNeed } = useClearUserLocationEvery24Hours();
	/* home screen common hooks end */
	/* download app modal hook start */
	const [isDownloadAppModalOpen, setDownloadAppModalOpen] = useState(false);
	/* download app modal hook end */
	/* primary feature hook start */
	const [primaryFeatureModalType, setPrimaryFeatureModalType] =
		useState<number>(null);
	/* primary feature hook end */
	/* occupation change hooks starts here  */
	const merchantOccupationId = userProfile?.merchant_occupation_id;
	const [userSelectedPersona, setUserSelectedPersona] = useState<string>(
		userLogedIn && merchantOccupationId
			? merchantOccupationId
			: JSON.parse(localStorage?.getItem("profileSelected"))
				? JSON.parse(localStorage?.getItem("profileSelected"))
				: userType["retailer"],
	);
	const [usePerosnaProceedButtonLoading, setUsePerosnaProceedButtonLoading] =
		useState<boolean>(false);
	const [personaModalVisible, setPersonaModalVisible] =
		useState<boolean>(false);
	/* occupation change hooks end here  */
	/* bahi khata hook start */
	const [totalCredit, setTotalCredit] = useState();
	const [bahiKhataLoading, setBahiKhataLoading] = useState<boolean>(false);
	/* bahi khata hook end */
	/* location hook start */
	const [lat, setLat] = useState(null);
	const [long, setLong] = useState(null);
	const [stateId, setStateId] = useState(null);
	const [districtId, setDistrictId] = useState(null);
	const [districtName, setDistrictName] = useState(null);
	const [stateName, setStateName] = useState(null);
	const dispatch = useDispatch();

	const handlePersonaChange = (newPersona: string) => {
		dispatch(setUserPersona(newPersona));
	};
	/* location hook end */

	useEffect(() => {
		handlePersonaChange(userSelectedPersona);
	}, [userSelectedPersona]);

	/* weather hooks start here */
	const [weatherLoading, setWeatherLoading] = useState(true);
	const [weatherData, setWeatherData] = useState([]);
	/* weather hooks ends here */

	/* rake hooks start here */
	const [rakeLoading, setRakeLoading] = useState(true);
	const [rakeData, setRakeData] = useState<RakeData[]>([]);
	/* rake hooks ends here */

	/* sms hooks start here */
	const [totalSmsLeft, setSmsLeft] = useState<string>("");
	const [smsLoading, setSmsLoading] = useState<boolean>(false);
	/* sms hooks end here */

	/* crop advisory hooks start here */
	const [subscribedCropsAdvisoryList, setSubscribedCropsAdvisoryList] =
		useState([]);
	const [subscribedCropAdvisoryLoading, setSubscribedCropAdvisoryLoading] =
		useState(false);
	const [totalCropSubscirbedCount, setTotalCropSubscirbedCount] = useState(0);
	/* crop advisory hooks ends here */

	/* quiz hook starts */
	const quizImage = getQuizImage(langId?.langCode);
	const [quizData, setQuizData] = useState(null);
	const [disableQuizClick, setDisableQuizClick] = useState<boolean>(false);
	const [quizAlert, setQuizAlert] = useState<boolean>(false);
	const homeScreenAssets = getHomeScreenAssets(langId?.langCode);

	/* quiz hook ends */

	/* ======================================================================================== */

	/* home screen logic start */

	function isAndroid() {
		try {
			return typeof (window as any)?.ReactNativeWebView !== "undefined";
		} catch (e) {
			return false;
		}
	}

	function trimDecimalValues(value: number, trimUpto: number) {
		if (value) {
			return Number(value.toFixed(trimUpto));
		} else {
			return;
		}
	}

	async function fetchHomeScreenData() {
		try {
			const response = await AuthenticationCheckerAndApiCaller({
				uri: apiEndPoints?.homeScreenSectionData,
				payload: {
					merchant_occupation_id:
						merchantOccupationId || Number.parseInt(userSelectedPersona),
				},
				requestType: "get",
			});
			if (response?.data?.status) {
				setHomeScreenData(response?.data?.data);
				setHomeScreenLoading(false);
			}
		} catch (error) {
			console.log(error);
			setHomeScreenLoading(false);
		}
	}

	function onClickBack() {
		navigate(-1);
	}

	function nativeAppNavigation(params: any) {
		bridge.sendRequestToNative(
			"nativeAppNavigationListner",
			params,
			(response: any) => {
				bridgeFunctions.PrintLog(response);
			},
		);
	}

	function onClickFaq() {
		trackEvent(Events?.onHomeScreenHelpButtonClick, {});
		navigate("/faq/questions");
	}

	/* home screen logic end */

	/* location logic start */
	function onChangeLocation() {
		locationData?.setLocationModal(true);
		locationData?.setLocationModalLoading(false);
	}
	/* location logic end */

	/* bahi khata logic start */
	async function fetchTotalCredit() {
		setBahiKhataLoading(true);
		if (userLogedIn) {
			try {
				const totalCreditRes = await AuthenticationCheckerAndApiCaller({
					payload: {},
					uri: apiEndPoints.fetchBahiKhata,
					requestType: "post",
				});
				if (totalCreditRes.data?.status) {
					setTotalCredit(totalCreditRes?.data?.data);
					setBahiKhataLoading(false);
				}
				setBahiKhataLoading(false);
			} catch (err) {
				setBahiKhataLoading(false);
				console.log(err);
			}
		} else {
			setBahiKhataLoading(false);
		}
	}
	/* bahi khata logic end */

	/* download app modal logic start */
	function trackEventDownloadAppModal(feature: number) {
		switch (feature) {
			case 1:
				trackEvent(Events?.onWebRakeBottomSheetDownloadAppButtonClick, {});
				break;
			case 2:
				trackEvent(Events?.onWebBahiKhataBottomSheetDownloadAppButtonClick, {});
				break;
			case 3:
				trackEvent(
					Events?.onWebDistributorBottomSheetDownloadAppButtonClick,
					{},
				);
				break;
			case 4:
				trackEvent(Events?.onWebSMSBottomSheetDownloadAppButtonClick, {});
				break;
			case 5:
				trackEvent(
					Events?.onWebCropAdvisoryBottomSheetDownloadAppButtonClick,
					{},
				);
				break;
			case 11:
				trackEvent(Events?.onWebQuizBottomSheetDownloadAppButtonClick, {});
				break;
			default:
				break;
		}
	}

	function trackEventCloseAppModal(feature: number) {
		switch (feature) {
			case 1:
				trackEvent(Events?.onWebRakeBottomSheetNotNowButtonClicked, {});
				break;
			case 2:
				trackEvent(Events?.onWebBahiKhataBottomSheetNotNowButtonClick, {});
				break;
			case 3:
				trackEvent(Events?.onWebDistributorBottomSheetNotNowButtonClick, {});
				break;
			case 4:
				trackEvent(Events?.onWebSMSBottomSheetNotNowButtonClick, {});
				break;
			case 5:
				trackEvent(Events?.onWebCropAdvisoryBottomSheetNotNowButtonClick, {});
				break;
			case 11:
				trackEvent(Events?.onWebQuizBottomSheetNotNowButtonClick, {});
				break;
			default:
				break;
		}
	}

	function onClickDownloadAppNow(feature: number) {
		trackEventDownloadAppModal(feature);
		window.location.href = PlayStoreUrl;
	}

	function closeDownloadAppModal(feature: number) {
		trackEventCloseAppModal(feature);
		setDownloadAppModalOpen(false);
	}
	/* download app modal logic end */

	/* secondary logic start here */

	function triggerSecondaryFeatureMoengageEvents(itemScreenName: string) {
		if (itemScreenName === "SMS_LIST_SCREEN") {
			trackEvent(Events?.onHomeScreenSmsFeatureButtonClick, {
				"Feature Type": "Secondary",
			});
		} else if (itemScreenName === "BAHI_KHATA") {
			trackEvent(Events?.onBahiKhataButtonClicked, {
				"Feature Type": "Secondary",
			});
		}
	}

	function navigateToAppWebCommonFeature(enumId?: string) {
		const response = webAppCommonFeaturesEnums.get(enumId);
		return response;
	}

	function navigateToAppWebCommonTabFeature(enumId?: string) {
		const response = webAppCommonTabFeaturesEnum.get(enumId);
		return response;
	}

	function onClickSecondaryFeatureCard(
		enumId?: string,
		itemName?: string,
		itemScreenName?: string,
	) {
		const tabWebApp = navigateToAppWebCommonTabFeature(enumId);
		const response = navigateToAppWebCommonFeature(enumId);
		if (response) {
			if (platform?.platform === "APP") {
				if (tabWebApp) {
					nativeAppNavigation({
						featureId: downloadAppModalType.get(enumId),
						merchantOccupationId,
					});
					triggerSecondaryFeatureMoengageEvents(itemScreenName);
				} else {
					navigate(`${response}`);
				}
			} else {
				navigate(`${response}`);
			}
		} else {
			if (platform?.platform === "APP") {
				nativeAppNavigation({
					featureId: downloadAppModalType.get(enumId),
					merchantOccupationId,
				});
				triggerSecondaryFeatureMoengageEvents(itemScreenName);
			} else {
				if (downloadAppModalType?.get(enumId)) {
					setPrimaryFeatureModalType(downloadAppModalType.get(enumId));
					setDownloadAppModalOpen(true);
				} else {
					//Modal Design not available for that feature
				}
			}
		}
	}
	/* secondary logic end here */

	/* primary logic start here */
	const primaryFeature = homeScreenData.filter(
		({ category_enum_id }) => category_enum_id === 1,
	);

	function conditionalRender() {
		switch (primaryFeature[0]?.features[0]?.id) {
			/*  case 1:
        getLeftSMS();
        break; */
			case 3:
				fetchTotalCredit();
				break;
			case 4:
				fetchSubscribedCropsList();
				break;
			default:
				break;
		}
	}

	function onClickQuiz() {
		if (platform?.platform === "APP") {
			if (quizData) {
				if (!quizData.participated && quizData.running) {
					trackEvent(Events?.onQuizBannerClick, {
						action_name: "play_quiz",
					});
					nativeAppNavigation({
						featureId: downloadAppModalType.get("quiz"),
						merchantOccupationId,
					});
				} else if (quizData.participated && !quizData.running) {
					trackEvent(Events?.onQuizBannerClick, {
						action_name: "quiz_result",
					});
					nativeAppNavigation({
						featureId: downloadAppModalType.get("quizResult"),
						merchantOccupationId,
					});
				} else if (!quizData.participated && !quizData.running) {
					trackEvent(Events?.onQuizBannerClick, {
						action_name: "winners_list",
					});
					nativeAppNavigation({
						featureId: downloadAppModalType.get("quizWinnerList"),
						merchantOccupationId,
					});
				} else if (quizData.participated && quizData.running) {
					setQuizAlert(true);
				}
			} else {
				setDisableQuizClick(true);
			}
		} else {
			setPrimaryFeatureModalType(downloadAppModalType.get("quiz"));
			setDownloadAppModalOpen(true);
		}
	}

	function onClickViewCropAdvisoryPrimary() {
		if (platform?.platform === "APP") {
			nativeAppNavigation({
				featureId: downloadAppModalType.get("cropAdvisoryList"),
				merchantOccupationId,
			});
		} else {
			setPrimaryFeatureModalType(downloadAppModalType.get("cropAdvisoryList"));
			setDownloadAppModalOpen(true);
		}
	}

	function onClickSubCropAdvisory(data) {
		return () => {
			if (platform?.platform === "APP") {
				nativeAppNavigation({
					featureId: downloadAppModalType.get("subScribedCropAdvisorySteps"),
					merchantOccupationId,
					subscribedCropData: data,
				});
			} else {
				setPrimaryFeatureModalType(
					downloadAppModalType.get("cropAdvisoryList"),
				);
				setDownloadAppModalOpen(true);
			}
		};
	}

	function onCLickViewRake() {
		trackEvent(Events?.onRakeFeatureClick, {
			"Feature source": "Primary",
			"location exists": stateId !== null ? true : false,
		});
		navigate("/rake");
	}

	function onClickBahiKhataPrimaryButton() {
		trackEvent(Events?.onBahiKhataButtonClicked, {
			"Feature source": "primary",
		});
		if (platform?.platform === "APP") {
			nativeAppNavigation({
				featureId: downloadAppModalType.get("bahiKhata"),
				merchantOccupationId,
			});
		} else {
			setPrimaryFeatureModalType(downloadAppModalType.get("bahiKhata"));
			setDownloadAppModalOpen(true);
		}
	}

	function onClickShopPromotionPrimaryButton() {
		trackEvent(Events?.onHomeScreenSmsFeatureButtonClick, {
			"Feature Type": "Primary",
		});
		if (platform?.platform === PlatForms.get("website")) {
			navigate("/shopPromotion");
		} else {
			nativeAppNavigation({
				featureId: downloadAppModalType.get("smsFeature"),
				merchantOccupationId,
			});
			navigate("/shopPromotion");
		}
	}

	function onClickSmsHistory() {
		navigate("/smsHistory");
	}

	/* primary logic end here */

	/* occupation change logic start here */
	async function onClickProceedPersona(perosnaId: string) {
		setUsePerosnaProceedButtonLoading(true);
		try {
			const updatePersonaResponse = await AuthenticationCheckerAndApiCaller({
				payload: {
					occupation_id: Number.parseInt(perosnaId),
				},
				uri: apiEndPoints?.updateUserPersona,
				requestType: "put",
			});
			if (updatePersonaResponse.data.status) {
				trackEvent(Events.SGN_PROFILE_SUBMITTED, {
					user_type: CTUserTypeEnums.get(userSelectedPersona),
				});
				setPersonaModalVisible(false);
			}
		} catch (error) {
			console.log(error);
			setUsePerosnaProceedButtonLoading(false);
		} finally {
			setUsePerosnaProceedButtonLoading(false);
		}
	}

	function onClickpersonaRole() {
		setPersonaModalVisible(!personaModalVisible);
		if (personaModalVisible) {
			trackEvent(Events?.onWebProfileSelectionCloseButtonClick, {});
		} else {
			trackEvent(Events?.onWebHomeScreenChangeProfileClick, {});
		}
		localStorage.setItem("profileSelected", userSelectedPersona);
	}

	function onClickPersonaCard(perosnaId: string) {
		return () => {
			trackEvent(Events?.onWebProfileSelectionClick, {
				"Profile Name Selected": CTUserTypeEnums.get(
					Number.parseInt(perosnaId),
				),
			});
			setUserSelectedPersona(perosnaId);
			setPersonaModalVisible(!personaModalVisible);
			localStorage.setItem("profileSelected", perosnaId);
			// onClickProceedPersona(perosnaId);
		};
	}
	/* occupation chnage logic ends here */

	/* news feed logic starts here */
	function onClickViewMorePost() {
		if (platform?.platform === "APP") {
			nativeAppNavigation({
				featureId: downloadAppModalType.get("newsFeed"),
				merchantOccupationId,
			});
		} else {
			navigate("/community");
		}
	}
	/* news feed logic ends here */

	/* crop advisory logic start here */
	async function fetchSubscribedCropsList() {
		setSubscribedCropAdvisoryLoading(true);
		if (userLogedIn) {
			const cropsDataNotSubscribed = await fetchCropAdvisory();
			if (cropsDataNotSubscribed.data?.status) {
				if (cropsDataNotSubscribed?.data?.data?.crops) {
					setSubscribedCropsAdvisoryList(
						cropsDataNotSubscribed?.data?.data?.crops,
					);
					setTotalCropSubscirbedCount(cropsDataNotSubscribed.data?.data?.count);
				}
				setSubscribedCropAdvisoryLoading(false);
			}
		}

		setSubscribedCropAdvisoryLoading(false);
	}

	async function fetchCropAdvisory() {
		const fetchCropAdvisoryListParam = {
			page_no: 1,
			limit: 10,
			subscribed: true,
		};

		try {
			const fetchCropAdvisoryListResp = await AuthenticationCheckerAndApiCaller(
				{
					payload: fetchCropAdvisoryListParam,
					uri: apiEndPoints?.fetchCropAdvisoryList,
					requestType: "get",
				},
			);
			return fetchCropAdvisoryListResp;
		} catch (err) {}
	}

	function onClickViewSubscribedCropsList() {
		if (platform?.platform === "APP") {
			nativeAppNavigation({
				featureId: downloadAppModalType.get("subscribedCropAdvisoryList"),
				merchantOccupationId,
			});
		} else {
			setPrimaryFeatureModalType(
				downloadAppModalType.get("subscribedCropAdvisoryList"),
			);
			setDownloadAppModalOpen(true);
		}
	}

	/* crop advisory logic end here */

	/* rake status logic start here */
	async function fetchRakeStatus() {
		setRakeLoading(true);
		const rakeStatusResp = await getRakeStatus();
		if (rakeStatusResp?.data?.status) {
			setRakeData(rakeStatusResp.data.data?.rows);
			setRakeLoading(false);
		}
		setRakeLoading(false);
	}

	async function getRakeStatus() {
		try {
			const requestBody = {
				master_state_id: jsonParse(localStorage.getItem("stateId")),
				master_district_id: jsonParse(localStorage.getItem("districtId")),
			};
			const rakeStatusResp = await AuthenticationCheckerAndApiCaller({
				uri: apiEndPoints?.getRakeStatus,
				payload: requestBody,
				requestType: "get",
			});
			return rakeStatusResp;
		} catch (err) {}
	}
	/* rake status logic ends here */

	/* weather logic start here */
	async function getWeatherData() {
		try {
			if (jsonParse(localStorage.getItem("lat"))) {
				const weatherRequestBody = {
					latitude: trimDecimalValues(
						jsonParse(localStorage.getItem("lat")),
						4,
					),
					longitude: trimDecimalValues(
						jsonParse(localStorage.getItem("long")),
						4,
					),
				};
				const weatherDataResp = await AuthenticationCheckerAndApiCaller({
					payload: weatherRequestBody,
					uri: apiEndPoints?.weatherDetail,
					requestType: "get",
				});

				return weatherDataResp;
			} else {
			}
			return;
		} catch (err) {}
	}

	async function fetchWeatherDetail() {
		setWeatherLoading(true);
		const getWeatherDataResp = await getWeatherData();

		if (getWeatherDataResp?.data?.status) {
			setWeatherData(getWeatherDataResp?.data?.data?.weekly_weather_detail[0]);
			setWeatherLoading(false);
		}
		setWeatherLoading(false);
	}

	async function shareOnWhatsApp(title: string, temp: string, moTitle: string) {
		trackEvent(Events?.onWeatherCardShareButtonClick, {
			state_name: stateName?.toLowerCase(),
			district_name: districtName?.toLowerCase(),
			weather_statement: moTitle?.toLowerCase(),
			jobs_to_be_done: CTUserTypeEnums.get(merchantOccupationId),
		});
		const app_url = "https://farmart.app.link/FarMart_Weather";
		const weatherShareImage =
			"https://d2n0idf0n5xz1f.cloudfront.net/image/development/1697184214084";
		const today = new Date();
		const yyyy = today.getFullYear();
		let mm = today.getMonth() + 1; // Months start at 0!
		let dd = today.getDate();

		if (dd < 10) {
			dd = 0 + dd;
		}
		if (mm < 10) {
			mm = 0 + mm;
		}
		const formattedToday = dd + "/" + mm + "/" + yyyy;
		const message = `${language?.weatherGreeting} 🙏\n\n${formattedToday}\n\n*${title}*\n\n${language?.weatherWhatsappLink}${app_url}`;
		const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
			message,
		)}`;
		if (platform?.platform === "APP") {
			bridge.sendRequestToNative(
				"shareViaRnFetchBlob",
				{
					link: message,
					alertMessage: language?.whatsappInstall,
					image: weatherShareImage,
				},
				(response: any) => {
					bridgeFunctions.PrintLog(response);
				},
			);
		} else {
			window.open(whatsappUrl, "_blank");
		}
	}
	/* weather logic end here */

	/* Add farmer logic start */
	function onClickAddFarmer() {
		trackEvent(Events?.onAddFarmerButtonClick, {
			feature_name: "shop_promotion",
			action_name: "add_farmer_button",
			action_time: (new Date().getTime() - startLoadTime) / 1000,
		});

		if (userLogedIn) {
			navigate("/addFarmerScreen");
		} else {
			navigate("/login");
		}
	}
	/* Add farmer logic end */

	/* myProfile logic start here */
	function onClickMyProfile() {
		trackEvent(Events?.onMyProfileButtonClick, {});
		if (token) {
			navigate("/myProfile");
		} else {
			navigate("/login", {
				replace: true,
			});
		}
	}
	/* myProfile logic end here */

	/* quiz logic start */
	async function getQuizData() {
		const recentQuizDetails = await AuthenticationCheckerAndApiCaller({
			uri: apiEndPoints?.recentQuizDetails,
			payload: {},
			requestType: "get",
		});
		if (recentQuizDetails?.data?.status) {
			setQuizData(recentQuizDetails?.data?.data?.quiz);
		}
	}
	function onClickCloseQuizAlertModal() {
		setQuizAlert(false);
	}
	/* quiz logic end */

	/*All useEffect list start */

	useEffect(() => {
		setLat(jsonParse(localStorage.getItem("lat")));
		setLong(jsonParse(localStorage.getItem("long")));
		setStateId(jsonParse(localStorage.getItem("stateId")));
		setDistrictId(jsonParse(localStorage.getItem("districtId")));
		setDistrictName(jsonParse(localStorage.getItem("districtName")));
		setStateName(jsonParse(localStorage.getItem("stateName")));
	}, [locationData?.lat]);

	useEffect(() => {
		clearLocationIfNeed();
	}, []);

	useEffect(() => {
		if (
			!isAndroid() &&
			!userLogedIn &&
			!localStorage.getItem("profileSelected")
		) {
			const timer = setTimeout(() => {
				setPersonaModalVisible(true);
				localStorage.setItem("profileSelected", userSelectedPersona);
			}, 5000);

			// Cleanup function to clear the timeout if userSwitchProfile changes
			return () => clearTimeout(timer);
		}
	}, [userLogedIn, token, userSelectedPersona]);

	useEffect(() => {
		if (token) {
			fetchHomeScreenData();
		}
	}, [userSelectedPersona, token, userProfile?.merchant_detail_id]);

	useEffect(() => {
		conditionalRender();
	}, [primaryFeature[0]?.features[0]?.id]);

	useEffect(() => {
		if (primaryFeature[0]?.features[0]?.id === 5) {
			const districtId = localStorage.getItem("districtId");
			districtId && jsonParse(districtId) && fetchRakeStatus();
		}
	}, [primaryFeature[0]?.features[0]?.id, localStorage?.getItem("districtId")]);

	useEffect(() => {
		if (
			homeScreenData?.length > 0 &&
			homeScreenData.some((item) => item.category_enum_id === 4)
		) {
			fetchWeatherDetail();
		}
	}, [jsonParse(localStorage.getItem("lat")), homeScreenData]);

	useEffect(() => {
		if (userLogedIn) {
			getQuizData();
		}
	}, []);

	useEffect(() => {
		window.addEventListener("popstate", onClickBack);

		return () => {
			window.removeEventListener("popstate", onClickBack);
		};
	}, []);

	useEffect(() => {
		if (token) {
			locationData?.initialLocationCheck();
		}
	}, [token]);

	useEffect(() => {
		if (refreshPageFromStorage === "true" && !isAndroid()) {
			setRefreshPage(true);
			setUniqueKey((prevKey) => prevKey + 1);
		}

		return () => {
			localStorage.removeItem("refreshPage");
		};
	}, [() => {}]);

	/*All useEffect list start */

	return {
		language,
		usePerosnaProceedButtonLoading,
		onClickPersonaCard,
		onClickProceedPersona,
		personaModalVisible,
		userSelectedPersona,
		isDownloadAppModalOpen,
		closeDownloadAppModal,
		onClickpersonaRole,
		onClickDownloadAppNow,
		onChangeLocation,
		homeScreenData,
		weatherData,
		districtName,
		stateName,
		subscribedCropsAdvisoryList,
		totalCropSubscirbedCount,
		totalCredit,
		primaryFeatureModalType,
		onCLickViewRake,
		onClickViewCropAdvisoryPrimary,
		onClickBahiKhataPrimaryButton,
		onClickShopPromotionPrimaryButton,
		onClickSecondaryFeatureCard,
		onClickViewMorePost,
		onClickQuiz,
		rakeData,
		totalSmsLeft,
		homeScreenLoading,
		weatherLoading,
		rakeLoading,
		subscribedCropAdvisoryLoading,
		bahiKhataLoading,
		smsLoading,
		userLogedIn,
		shareOnWhatsApp,
		quizImage,
		addFarmerVisible,
		onClickAddFarmer,
		onClickMyProfile,
		onClickSmsHistory,
		onClickSubCropAdvisory,
		helpButtonVisible,
		onClickFaq,
		disableQuizClick,
		onClickCloseQuizAlertModal,
		quizAlert,
		onClickViewSubscribedCropsList,
		refreshPage,
		uniqueKey,
		homeScreenAssets,
	};
}
