import React from 'react';
import { Box, IconButton } from '@mui/joy';
import { ArrowBackRounded } from '@mui/icons-material';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';

interface HeaderProps {
  //   language: { [key: string]: any };
  headerTitle: string;
  onClickBack?: () => void;
}

export default function Header({ headerTitle, onClickBack }: HeaderProps) {
  return (
    <Box
      position={'sticky'}
      top={0}
      zIndex={1000}
      bgcolor={'#FFFFFF'}
      padding={4}
      borderBottom={1}
      borderColor={'#CDD7E1'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        height={24}
      >
        <IconButton onClick={onClickBack} id={'onClickBackSMSTemplateId'}>
          <ArrowBackRounded
            style={{ color: '#171A1C', paddingRight: 12 }}
            data-testid={'arrowRoundedIconBackButtonTestId'}
          />
        </IconButton>
        <MuiTypography level={'title-md'}>{headerTitle}</MuiTypography>
      </Box>
    </Box>
  );
}
