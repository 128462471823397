import React from "react";
import { Row } from "../../atoms/layout";
import { Text } from "../../atoms/typography";
import CloseIcon from "@mui/icons-material/Close";
import { Modal, ModalDialog } from "@mui/joy";
import type { ModalProps } from "@mui/joy";

import { memo } from "react";

const closeIconStyles = { fontSize: 24, marginRight: 4 };

interface Props extends ModalProps {
	onClose: () => void;
	titleText: string;
}

function FullScreenModal(props: Props) {
	const { children, open, onClose, titleText } = props;

	return (
		<Modal
			open={open}
			onClose={onClose}
			sx={{
				position: "absolute",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 9000,
			}}
		>
			<ModalDialog layout="fullscreen" size="lg">
				<Row alignItems="center">
					<CloseIcon
						id="close_tab_how_to_create_trip"
						onClick={onClose}
						sx={closeIconStyles}
					/>
					<Text
						id="how_to_create_trip_homepage"
						level="title-md"
						textColor="text.primary"
					>
						{titleText}
					</Text>
				</Row>
				{children}
			</ModalDialog>
		</Modal>
	);
}

export default memo(FullScreenModal);
