import { Box, Chip } from '@mui/joy';
import React from 'react';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import smsTemplateType from '@mono-farmart-web/farmart-web-common/constant/enums/smsTemplateType';
import { RiveComponent } from '@mono-farmart-web/farmart-web-common/utils/riveComponent';
import SmsAcknowledgementRiveUrl from '@mono-farmart-web/farmart-web-common/constant/smsAcknowledgementRiveUrl';

interface AckProps {
  language: { [key: string]: string };
  smsBalance: number;
  smsSent: number;
  smsId: string;
}
export default function AcknowledgementInfo(props: AckProps) {
  const { language, smsBalance, smsSent, smsId } = props;

  const replacePlaceholders = ({ template, values }) => {
    return template
      .split(/(<[^>]+>)/)
      .map((part, index) =>
        part.startsWith('<') && part.endsWith('>') ? (
          <span key={index}>{values[part.replace(/<|>/g, '')]}</span>
        ) : (
          <span key={index}>{part}</span>
        ),
      );
  };

  const template =
    Number(smsId) === smsTemplateType.get('customSmsId')
      ? language?.pendingSubHeadingtext
      : language?.congratsSubHeadingText;
  const values = {
    farmerCount: smsSent,
  };
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignSelf={'stretch'}
      justifyContent={'center'}
      alignItems={'center'}
      margin={'0px 16px'}
      flex={1}
    >
      <Box display={'flex'} height={120} width={120} marginBottom={'16px'}>
        <RiveComponent
          src={
            Number(smsId) === smsTemplateType.get('customSmsId')
              ? SmsAcknowledgementRiveUrl.get('smsPending')
              : SmsAcknowledgementRiveUrl.get('smsSent')
          }
          autoplay={true}
        />
      </Box>
      <Box>
        <MuiTypography level="title-lg" textAlign={'center'}>
          {Number(smsId) === smsTemplateType.get('customSmsId')
            ? language?.pendingHeadingtext
            : language?.congratsHeadingText}
        </MuiTypography>
        <MuiTypography textAlign={'center'} level="body-xxs" marginTop={'4px'}>
          {language?.pendingSubHeadingtext &&
            replacePlaceholders({ template, values })}
        </MuiTypography>
      </Box>
      <Box marginTop={'12px'}>
        <Chip sx={{ borderRadius: 4 }} variant="soft" color="success">
          {language?.smsLeft} {smsBalance}
        </Chip>
      </Box>
    </Box>
  );
}
