import React, { Suspense } from 'react';
import Styles from './style.module.css';
import { MyFarmerProps } from '@mono-farmart-web/farmart-web-common/hooks/MyFarmersHook/types';
import InfiniteScroll from  '@mono-farmart-web/farmart-web-common/Components/InfintyScroll';
import { Box } from '@mui/joy';
import DeletModal from './DeleteModal/DeletModal';
import Toast from '@mono-farmart-web/farmart-web-common/ComponentV2/Toast';
import AddFarmer from './AddFarmer/index';
import MuiSkeleton from '@mono-farmart-web/farmart-web-common/MuiComponents/Skeleton';
import Header from './Header/Header';

const SearchBar = React.lazy(() => import('./SearchBar/SearchBar'));
const ContactList = React.lazy(() => import('./ContactList/ContactList'));
const EmptyStateContent = React.lazy(
  () => import('./EmptyStateContent/EmptyStateContent'),
);

export default function MyFarmerView(props: MyFarmerProps) {
  const {
    onPressBack,
    language,
    onContactUs,
    farmers,
    searchParam,
    setSearchParam,
    toggleFarmerSelection,
    selectedFarmers,
    debouncedSetSearchParam,
    initialLoading,
    searchLoading,
    onPressDelete,
    onClickAddFarmer,
    toggleDeleteModal,
    isDeleteModalVisible,
    isErrorPopup,
    loadMoreFarmers,
    setCurrentPage,
  } = props;

  const showEmptyState =
    !initialLoading && farmers.length === 0 && searchParam === '';

  function renderLoader() {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingTop={3}
        margin={'0px 16px'}
        flexDirection={'column'}
        gap={4}
        zIndex={1}
      >
        <MuiSkeleton sx={{ width: '100%', height: 42, borderRadius: 8 }} />
        <MuiSkeleton
          sx={{ width: '100%', height: 70, borderRadius: 8 }}
          itemNumber={10}
        />
      </Box>
    );
  }

  return (
    <Box className={Styles.container} bgcolor={'background.body'}>
      <Header
        onContactUs={onContactUs}
        onPressBack={onPressBack}
        language={language}
        selectedFarmers={selectedFarmers}
        toggleDeleteModal={toggleDeleteModal}
      />
      {initialLoading ? (
        // Show full-screen loader during initial load
        renderLoader()
      ) : (
        <>
          {showEmptyState ? (
            // Show empty state content if no farmers and no search params after initial load
            <Suspense fallback={renderLoader()}>
              <EmptyStateContent
                language={language}
                onClickAddFarmer={onClickAddFarmer}
              />
            </Suspense>
          ) : (
            <>
              <Suspense fallback={<></>}>
                <SearchBar
                  language={language}
                  searchParam={searchParam}
                  setSearchParam={setSearchParam}
                  debouncedSetSearchParam={debouncedSetSearchParam}
                  setCurrentPage={setCurrentPage}
                />
              </Suspense>
              <AddFarmer
                language={language}
                onClickAddFarmer={onClickAddFarmer}
              />

              {searchLoading ? (
                // Show loader in place of list during search
                renderLoader()
              ) : (
                <InfiniteScroll
                  onScrollToEnd={loadMoreFarmers}
                  loading={searchLoading}
                  loadingElement={renderLoader()}
                >
                  <Suspense fallback={renderLoader()}>
                    <ContactList
                      language={language}
                      farmers={farmers}
                      toggleFarmerSelection={toggleFarmerSelection}
                      selectedFarmers={selectedFarmers}
                    />
                  </Suspense>
                </InfiniteScroll>
              )}
            </>
          )}
        </>
      )}
      <DeletModal
        onPressDelete={onPressDelete}
        language={language}
        toggleDeleteModal={toggleDeleteModal}
        isDeleteModalVisible={isDeleteModalVisible}
      />
      <Toast
        isError={true}
        message={language?.apiError}
        isOpen={isErrorPopup}
      />
    </Box>
  );
}
