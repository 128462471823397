import React from 'react';
import Style from './style.module.css';
import { Alert, Box } from "@mui/joy";
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import { InfoOutlined } from "@mui/icons-material";
import FileListCard from '../FileListCard';
import Loading from '@mono-farmart-web/farmart-web-common/ComponentV2/Loading';
import DocumentType from '@mono-farmart-web/farmart-web-common/constant/enums/docType';
import DocumentStatus from '@mono-farmart-web/farmart-web-common/constant/docStatusEnums';

interface FileListProps {
  language: { [key: string]: any };
  handleFileChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    index?: number,
  ) => void;
  handleUploadButtonClick?: (from: string, index?: number) => void;
  fileInputRef: any;
  documents: any;
  preViewDocument: (documents: any, index?: number) => void;
  multiFileUpload: boolean;
  reUploadInputRef: any;
  handleReUploadChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleReUploadButtonClick: (
    from?: string,
    index?: number,
    file?: any,
  ) => void;
  initialPageLoading?: boolean;
  saudaType: number;
  docType?: number
}

const FileList = ({
  language,
  handleFileChange,
  handleUploadButtonClick,
  fileInputRef,
  documents,
  preViewDocument,
  multiFileUpload,
  reUploadInputRef,
  handleReUploadChange,
  handleReUploadButtonClick,
  initialPageLoading,
  saudaType,
  docType
}: FileListProps) => {

  const alertText = () => {
    if (DocumentType.get(docType) === "buyerGRN" || DocumentType.get(docType) === "unloadingWeightSlip") {
      return language?.unloadingMaxFileUpload
    }
    else {
      return language?.maxFilesToUploadPerDocument
    }
  }

  const areAllStatusApproved  = documents.every(doc => doc.status_id === DocumentStatus.get('documentApproved'));

  return (
    <div className={Style.fileList} style={{ paddingBottom: '130px' }}>
      {!areAllStatusApproved && <Alert color={"warning"} variant={"outlined"} size={"sm"} sx={{ borderRadius: "8px", marginBottom: "20px" }} >
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} >
          <InfoOutlined color={"warning"} />
          <MuiTypography fontWeight={500} fontSize={"12px"} paddingLeft={2} color='warning'>{alertText()}</MuiTypography>
        </Box>
      </Alert>}
      <label className={Style.fileListTitle}>{language?.listOfFiles}</label>
      {documents.map((file, index) => (
        <div className={Style.fileCardWrapper} key={index + 'fileListCard'}>
          <FileListCard
            preViewDocument={preViewDocument}
            file={file}
            handleFileChange={handleFileChange}
            handleUploadButtonClick={handleUploadButtonClick}
            fileInputRef={fileInputRef}
            key={index}
            language={language}
            multiFileUpload={multiFileUpload}
            index={index}
            reUploadInputRef={reUploadInputRef}
            handleReUploadChange={handleReUploadChange}
            handleReUploadButtonClick={handleReUploadButtonClick}
            saudaType={saudaType}
          />
        </div>
      ))}

      {initialPageLoading && (
        <div className={Style.loadingContainer}>
          <Loading size={30} marginTop={20} color="grey" />
        </div>
      )}
    </div>
  );
};

export default FileList;
