// biome-ignore lint/style/useImportType: <explanation>
import React from "react";
import {
	Box,
	Column,
	Row,
} from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";

interface StepCardProps {
	stepCount: number;
	stepTitle: string;
	stepDescription: string;
	children?: React.ReactNode;
	stepTitleId?: string;
	stepDescriptionId?: string;
}

const StepCard = (props: StepCardProps) => {
	const { stepCount, stepTitle, stepDescription, children } = props;

	return (
		<Box
			paddingBottom={6}
			bgcolor={"neutral.200"}
			sx={{
				border: "1px solid #DDE7EE",
				borderRadius: "8px",
				background: "#FBFCFE",
			}}
		>
			<Column gap={6}>
				{/* CONTENT */}
				<Box bgcolor={"inherit"}>
					<Column>
						{/* TITLE */}
						<Box
							bgcolor={"inherit"}
							sx={{
								padding: "12px 12px 8px 12px",
							}}
						>
							<Row gap={3} alignItems={"center"}>
								{/* STEP COUNT */}
								<Row
									alignItems={"center"}
									justifyContent={"center"}
									sx={{
										borderRadius: "50%",
										width: "24px",
										height: "24px",
										background: "#000",
									}}
								>
									<Text level="title-sm" textColor="common.white">
										{props?.stepCount}
									</Text>
								</Row>

								{/* STEP TITLE */}
								<Text
									id={props?.stepTitleId}
									level="title-sm"
									textColor="text.primary"
								>
									{props?.stepTitle}
								</Text>
							</Row>
						</Box>

						{/* DESCRIPTION */}
						<Box
							sx={{
								px: "16px",
								background: "#FBFCFE",
							}}
						>
							<Row
								sx={{
									background: "#FBFCFE",
								}}
							>
								<Text
									id={props?.stepDescriptionId}
									level="body-xs"
									fontWeight={500}
									textColor="text.secondary"
								>
									{props?.stepDescription}
								</Text>
							</Row>
						</Box>
					</Column>
				</Box>
				{/* IMAGE */}
				{children}
			</Column>
		</Box>
	);
};

export default StepCard;
