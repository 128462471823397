import type React from "react";
import type { ReactNode } from "react";

import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage";
// import { useFetchUserAPI } from "../../../../hooks/general";
import IconButton from "@mui/joy/IconButton";
import type { IconButtonProps } from "@mui/joy/IconButton";
interface IconButtonWrapperProps extends IconButtonProps {
	children?: ReactNode;
	openDialog?: any;
	event?: string;
	onClick?: () => void;
}

const IconButtonC: React.FC<IconButtonWrapperProps> = ({
	children,
	onClick = () => {},
	event,
	...props
}) => {
	// const { ipAddress, isIpLoading } = useFetchUserAPI();
	function onClickButton() {
		if (event) {
			trackEvent(event, {
				// ipAddress,
			});
		}
		onClick();
	}

	return (
		<IconButton onClick={onClickButton} {...props}>
			{children}
		</IconButton>
	);
};

export default IconButtonC;
