import React from 'react';
import { Box } from '@mui/material';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';

interface NoLocationProp {
  language: string;
  noLocationImage: string;
  imageId: string;
}

export default function LocationList(props: NoLocationProp) {
  const { language, noLocationImage, imageId } = props;
  return (
    <Box
      display={'flex'}
      flex={1}
      alignItems={'center'}
      paddingTop={'180px'}
      flexDirection="column"
      gap={'16px'}
    >
      <img
        height={140}
        width={140}
        src={noLocationImage}
        alt="No location"
        style={{ maxWidth: 140, maxHeight: 120 }}
        loading="eager"
        id={imageId}
        data-testid={imageId}
      />
      <MuiTypography level="title-sm" textColor={'text.tertiary'}>
        {language}
      </MuiTypography>
    </Box>
  );
}
