/* This code snippet is a React functional component named `ShopPromotionPrimary` that represents a UI
component for displaying shop promotion information. Here's a breakdown of what the code is doing: */
import React from 'react';
import { Box } from '@mui/material';
import { Chip } from '@mui/joy';
import styles from './style.module.css';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentV3/MuiButton';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';
import InsightsRounded from '@mui/icons-material/InsightsRounded';
import SkeletonLoading from '@mono-farmart-web/farmart-web-common/ComponentsV4/SkeletonLoading';

export default function ShopPromotionPrimary({
  language, // Language object containing text for translations
  onClickShopPromotionPrimaryButton, // Callback function for handling button click

  item, // Data containing shop promotion information
  smsLoading, // Flag indicating whether SMS data is loading
  userLogedIn, // Flag indicating whether the user is logged in
  homeScreenLoading, // Flag indicating whether home screen data is loading
  onClickSmsHistory,
}) {
  return (
    <Box className={styles.mainContainer} bgcolor={'background.surface'}>
      {homeScreenLoading || smsLoading ? ( // Show skeleton loading if data is loading
        <SkeletonLoading className={styles.loading} />
      ) : (
        <>
          {' '}
          {/* Render shop promotion information */}
          <Box className={styles.infoContainer}>
            <Box className={styles.contentContainer}>
              <Box>
                <Box className={styles.cardTitleContainer}>
                  {/* Render title */}
                  <MuiTypography level={'title-md'}>
                    {language?.smsPrimaryHeading}
                  </MuiTypography>
                  {userLogedIn && (
                    // Render chip with SMS balance if user is logged in
                    <Chip
                      style={{ borderRadius: 4, marginLeft: 8 }}
                      variant="outlined"
                      id={'shopPromotionCardPrimaryLabelId'}
                      onClick={onClickSmsHistory}
                    >
                      {`${language?.smsBalance} ${item.features[0]?.total_sms_credit_point}`}
                    </Chip>
                  )}
                </Box>
                {/* Render subheading */}
                <MuiTypography
                  level="body-xxs"
                  style={{ marginTop: 4 }}
                  textColor={'text.tertiary'}
                >
                  {language?.smsPrimarySubHeading}
                </MuiTypography>
                {/* Render chip with SMS FOMO message */}
                <Chip
                  style={{
                    borderRadius: 4,
                    gap: 6,
                    marginTop: 6,
                  }}
                  color="neutral"
                  size="sm"
                  startDecorator={<InsightsRounded />}
                >
                  {language?.smsFomoPrimary}
                </Chip>
              </Box>
            </Box>
            <Box>
              {/* Render image icon */}
              <img
                className={styles.iconImageStyle}
                src={item.features[0]?.icon}
                alt="itemFeatureIcon"
                loading="eager"
              />
            </Box>
          </Box>
          {/* Render button for shop promotion */}
          <MuiButton
            size="md"
            color="primary"
            variant="solid"
            id={'shopPromotionPrimaryCardButtonId'}
            onClick={onClickShopPromotionPrimaryButton}
          >
            {language?.smsPrimaryButton}
          </MuiButton>
        </>
      )}
    </Box>
  );
}
