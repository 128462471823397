
import uploadUnloadingDocApi from "../../../apis/MySauda/uploadUnloadingDocApi";

export default async function UploadUnloadingDocs(
	payload: any,
): Promise<any> {
	const body = {
		...payload,
	};
	try {
		const result = await uploadUnloadingDocApi(body);

		if (!result.data.status) {
			throw result;
		}

		return result.data.data;
	} catch (error) {
		if (!error.message) {
			error.message = "Something Went Wrong";
		}

		throw error;
	}
}
