import React from "react";
import { Box, Column, Row } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LineIcon from "../../assets/line_icon.png";
import RoundIcon from "../../assets/round_icon.png";
import { isSaudabookPWA } from "@mono-farmart-web/farmart-web-common/utils/common";
import { getTrackConsentScreenAssets } from '@mono-farmart-web/farmart-web-common/constant/imageUrls/index';

interface DeliveryDetailProps {
	title?: string;
	deliveredTo: string;
	dispatchedLocation: string;
	dispatchedDate: string;
	deliveryLocation: string;
	deliveryDate: string;
	estimatedDistance: string;
	language?: any;
	showTripCancelMessage?: any;
}

const DeliveryDetails = (props: DeliveryDetailProps) => {
  const trackConsentAssets = getTrackConsentScreenAssets();
	const { language, showTripCancelMessage } = props;
	return (
		<Box>
			<Column>
				{showTripCancelMessage?.[0] ? showTripCancelMessage?.[1] : null}
				{/* DELIVER TO COLUMN */}
				<Box padding={4}>
					<Row gap={3}>
						{/* DRIVER IMG ICO */}
						<Row alignItems={"center"} justifyContent={"center"}>
							<img
								id="truckicon_trip_cancelledscreen"
								src={trackConsentAssets?.truckIcon}
								alt="driver-img-ico"
								width={40}
								height={40}
							/>
						</Row>

						<Box flex={1}>
							<Column gap={1}>
								<Text
									id="delivery_to_trip_cancelledscreen"
									level="body-sm"
									textColor="text.secondary"
								>
									{language?.deliverTo}
								</Text>
								<Text
									id="partyname_trip_cancelledscreen"
									level="title-md"
									textColor="text.primary"
								>
									{props?.deliveredTo}
								</Text>
							</Column>
						</Box>
					</Row>
				</Box>

				{/* TRUCK ROUTE DETAILS */}
				<Box>
					{/* MAIN CONTAINER */}
					<Column>
						{/* HEADER */}
						<Box
							sx={{
								padding: "16px 16px 12px 16px",
							}}
						>
							<Row justifyContent={"flex-start"} alignItems={"center"}>
								<Text
									id="truck_route_trip_cancelledscreen"
									level="title-sm"
									textColor="text.secondary"
								>
									{props?.title}
								</Text>
							</Row>
						</Box>

						{/* EXTRA DETAILS */}
						<Box
							sx={{
								padding: "13px 16px 24px 16px",
							}}
						>
							<Row gap={2}>
								<Box>
									<Column justifyContent={"flex-start"}>
										<Column gap={1} alignItems={"center"}>
											<img
												id="droplocation_trip_cancelledscreen"
												src={RoundIcon}
												alt={"round-ico"}
												width={20}
												height={20}
											/>
											<img
												src={LineIcon}
												alt={"round-ico"}
												width={1.5}
												height={40}
											/>
											<LocationOnIcon
												id="destinationicon_trip_cancelledscreen"
												sx={{
													color: "danger.500",
													width: "25px",
													height: "25px",
												}}
											/>
										</Column>
									</Column>
								</Box>

								<Box>
									<Column gap={7}>
										<Box>
											<Column gap={1}>
												<Text
													id="Dispatched_text_trip_cancelledscreen"
													level="body-sm"
													textColor="text.secondary"
												>
													{language?.dispatchedFrom}{" "}
													<Text
														id="DispatchedLocation_trip_cancelledscreen"
														level="title-sm"
														textColor="text.primary"
													>
														{props?.dispatchedLocation}
													</Text>
												</Text>
												<Text
													id="dispatched_date_trip_cancelledscreen"
													level="body-xs"
													textColor="text.secondary"
												>
													{props?.dispatchedDate}
												</Text>
											</Column>
										</Box>

										<Box>
											<Column gap={2}>
												<Box>
													<Column gap={1}>
														<Text
															id="destination_text_trip_cancelledscreen"
															level="body-sm"
															textColor="text.secondary"
														>
															{language?.destinationAt}{" "}
															<Text
																id="destinationiLocation_trip_cancelledscreen"
																level="title-sm"
																textColor="text.primary"
															>
																{props?.deliveryLocation}
															</Text>
														</Text>
														<Text
															id="Estimated_delivery_trip_cancelledscreen"
															level="body-xs"
															textColor="text.secondary"
														>
															{language?.expectedDelivery} -{" "}
															<Text
																id="estomated_delivery_date_trip_cancelledscreen"
																level="body-xs"
																textColor="text.secondary"
															>
																{props?.deliveryDate}
															</Text>
														</Text>
													</Column>
												</Box>

                      {
                        isSaudabookPWA && (
                          <Row justifyContent={"flex-start"}>
                            <Row
                              sx={{
                                px: "6px",
                                py: "6px",
                                border: "1px solid #CDD7E1",
                                borderRadius: "4px",
                                width: "auto",
                              }}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                            >
                              <Text
                                id="extimated_distance_trip_cancelledscreen"
                                level="label-sm"
                                textColor="neutral.plainColor"
                              >
                                {language?.estimatedDistance}{" "}
                                {props?.estimatedDistance?.replace(
                                  "kms",
                                  language?.Kms,
                                )}
                              </Text>
                            </Row>
                          </Row>
                        )
                      }
											</Column>
										</Box>
									</Column>
								</Box>
							</Row>
						</Box>
					</Column>
				</Box>
			</Column>
		</Box>
	);
};

export default DeliveryDetails;
