import React from 'react';
import SaudaDetails from '../../Pages/SaudaDetails/SaudaDetails';
import SaudaDetailsHooks from '../../hooks/SaudaDetailsHooks';

function SaudaDetailsView() {
  const {
    onClickBack,
    onClickHelp,
    onCallNow,
    numberCopyToast,
    isTruckModalVisible,
    saudaDetailData,
    onClickViewPo,
    truckDetailData,
    onCopyUtr,
    utrCopyToast,
    saudaPageLoading,
    saudaTruckLoading,
    isPaymentTermInfoVisible,
    onClickPaymentTermInfo,
    onDownloadPdf,
    downloadingPdf,
    showCancelSaudaModal,
    toggleCancelSaudaModal,
    showAcceptPOModal,
    toggleAcceptPO,
    acceptPODisabled,
    onClickTermsAndCondition,
    showRejectPOModal,
    toggleRejectPOModal,
    updatepoStatus,
    updatePoLoading,
    language,
    brokeragePopup,
    handleBrokeragePopup,
    financingPopup,
    handleFinancingPopup,
    netPopup,
    handleNetPopup,
    onClickPaymentModalGotItButton,
    isError,
    setIsError,
    onClickReUploadTruckDetailsCard,
    onClickTruckNumberTruckDetailsCard,
    onClickCreateDispatch,
    downloadPdfModal,
    toggleDownloadPdfModal,
    preViewDocument,
    selectedDocument,
    onPressClosePreview,
    saudaDetailsAssets,
    onPressHelpErrorState,
    onPressRetryErrorState,
    showTourGuide,
    onClickTourGuide,
    truckDetailModalError,
    onPressRetryModalErrorState,
    triggerEventForPoCard,
    triggerEvent
  } = SaudaDetailsHooks();

  return (
    <SaudaDetails
      onClickBack={onClickBack}
      onClickHelp={onClickHelp}
      onCallNow={onCallNow}
      numberCopyToast={numberCopyToast}
      isTruckModalVisible={isTruckModalVisible}
      saudaDetailData={saudaDetailData}
      triggerEvent={triggerEvent}
      onClickViewPo={onClickViewPo}
      truckDetailData={truckDetailData}
      onCopyUtr={onCopyUtr}
      utrCopyToast={utrCopyToast}
      triggerEventForPoCard={triggerEventForPoCard}
      saudaPageLoading={saudaPageLoading}
      saudaTruckLoading={saudaTruckLoading}
      isPaymentTermInfoVisible={isPaymentTermInfoVisible}
      onClickPaymentTermInfo={onClickPaymentTermInfo}
      onDownloadPdf={onDownloadPdf}
      downloadingPdf={downloadingPdf}
      showCancelSaudaModal={showCancelSaudaModal}
      toggleCancelSaudaModal={toggleCancelSaudaModal}
      showAcceptPOModal={showAcceptPOModal}
      toggleAcceptPO={toggleAcceptPO}
      acceptPODisabled={acceptPODisabled}
      onClickTermsAndCondition={onClickTermsAndCondition}
      showRejectPOModal={showRejectPOModal}
      toggleRejectPOModal={toggleRejectPOModal}
      updatepoStatus={updatepoStatus}
      updatePoLoading={updatePoLoading}
      language={language}
      brokeragePopup={brokeragePopup}
      handleBrokeragePopup={handleBrokeragePopup}
      financingPopup={financingPopup}
      handleFinancingPopup={handleFinancingPopup}
      netPopup={netPopup}
      handleNetPopup={handleNetPopup}
      onClickPaymentModalGotItButton={onClickPaymentModalGotItButton}
      isError={isError}
      setIsError={setIsError}
      onClickReUploadTruckDetailsCard={onClickReUploadTruckDetailsCard}
      onClickTruckNumberTruckDetailsCard={onClickTruckNumberTruckDetailsCard}
      onClickCreateDispatch={onClickCreateDispatch}
      downloadPdfModal={downloadPdfModal}
      toggleDownloadPdfModal={toggleDownloadPdfModal}
      preViewDocument={preViewDocument}
      selectedDocument={selectedDocument}
      onPressClosePreview={onPressClosePreview}
      saudaDetailsAssets={saudaDetailsAssets}
      onPressHelpErrorState={onPressHelpErrorState}
      onPressRetryErrorState={onPressRetryErrorState}
      showTourGuide={showTourGuide}
      onClickTourGuide={onClickTourGuide}
      truckDetailModalError={truckDetailModalError}
      onPressRetryModalErrorState={onPressRetryModalErrorState}
    />
  );
}
export default SaudaDetailsView;
