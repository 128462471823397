function useStyles() {
	return {
		drawerContainerProps: {
			borderRadius: "24px 24px 0 0",
			height: "auto",
		},
	};
}

export { useStyles };
