import { useState } from "react";
import type { GetOtp } from "./types";
import GetOtpApi from "@mono-farmart-web/farmart-web-common/modal/Traders/models/getOtp";
import encryptAsAES from "@mono-farmart-web/farmart-web-common/utils/encryption/aes";
import CreateTextForEncryption from "@mono-farmart-web/farmart-web-common/utils/mobileEncryption";
import ValidateOtp from "@mono-farmart-web/farmart-web-common/modal/Traders/models/validateOtp";
import UpdatePersonaApi from "@mono-farmart-web/farmart-web-common/modal/Traders/models/updatePersona";
import { setAuthTokenToStorage } from "@mono-farmart-web/farmart-web-common/modal/auth/models/auth.model";
import GetMerchantProfile from "@mono-farmart-web/farmart-web-common/modal/Traders/models/GetMerchantProfile";
import {
	trackEvent,
	addUniqueId,
} from "@mono-farmart-web/farmart-web-common/utils/moengage";
import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";
import CTUserTypeEnums from "@mono-farmart-web/farmart-web-common/constant/CTUserTypeEnums";
import { languageUrls } from "@mono-farmart-web/farmart-web-common/constant/languageUrls/index";
import getLang from "@mono-farmart-web/farmart-web-common/languages/tradeList.js";
import useAppLanguage from "@mono-farmart-web/farmart-web-common/utils/applanguage";

export default function UseGetOtp(): GetOtp {
	const [otpModalVisible, setOtpModalVisible] = useState<boolean>(false);
	const [mobileNumberModalVisible, setMobileNumberModalVisible] =
		useState<boolean>(false);
	const [personaModalVisible, setPersonaModalVisible] =
		useState<boolean>(false);
	const [userMobileNumber, setUserMobileNumber] = useState<string>("");
	const [userOtp, setUserOtp] = useState<string>("");
	const [userSelectedPersona, setUserSelectedPersona] = useState<string>("");
	const [error, setError] = useState<boolean>(false);
	const [isLocationModal, setLocationModal] = useState<boolean>(false);
	const langId = JSON.parse(localStorage.getItem("userLanguage"));

	const { language } = useAppLanguage(languageUrls?.tradeList, getLang);
	const timeLanguage = useAppLanguage(languageUrls?.timeAgo, getLang).language;

	const currentLanguageId = langId?.langCode || "HI";
	const [askForPermission, setAskFormPermission] = useState<boolean>(false);

	const [loading, setLoading] = useState<boolean>(false);
	const [userBlocked, setUserBlocked] = useState<boolean>(false);
	const [disableResend, setDisableResend] = useState<boolean>(true);

	const getMobileNumber = (number: string) => {
		setUserBlocked(false);
		setUserMobileNumber(number);
		if (error) {
			setError(false);
		}
	};

	function onClickLoginProceed() {
		if (userMobileNumber?.length < 10) {
			return setError(true);
		}
		trackEvent(Events.SGN_MOBILE_SUBMITTED, {
			mobile_number: userMobileNumber,
		});

		setUserOtp("");
		getOtp();
	}

	function onClickValidateOtpProceed() {
		if (userOtp?.length < 6) {
			return setError(true);
		}
		validateOtp();
	}

	const getUserOtpFromInput = (newOtp: string) => {
		setUserOtp(newOtp);
		if (error) {
			return setError(false);
		}
	};

	async function getOtp() {
		setLoading(true);
		const encryptedText = await encryptAsAES(
			CreateTextForEncryption(userMobileNumber),
		);
		try {
			await GetOtpApi({
				mobile_number: encryptedText,
				country_code: "91",
				message_id: "",
			});
			setError(false);
			trackEvent(Events.SGN_OTP_SUBMITTED, { api_faluire: true });
			setMobileNumberModalVisible(false);
			setOtpModalVisible(true);
		} catch (error) {
			if (error?.code === 404) {
				setUserBlocked(true);
			}
			trackEvent(Events.SGN_OTP_SUBMITTED, { api_faluire: false });
			setLoading(false);
			return setError(true);
		} finally {
			setLoading(false);
		}
	}

	function locationAccessCheck() {
		navigator.permissions.query({ name: "geolocation" }).then((result) => {
			if (result.state === "prompt") {
				setLocationModal(true);
			} else {
				setAskFormPermission(true);
			}
			// Don't do anything if the permission was denied.
		});
	}

	async function validateOtp() {
		setLoading(true);
		try {
			const otpValidateresponse = await ValidateOtp({
				mobile_number: userMobileNumber,
				otp: userOtp,
				role_id: 6,
			});
			if (typeof otpValidateresponse.token !== "string") {
				return setError(true);
			}
			setAuthTokenToStorage(otpValidateresponse.token);
			setOtpModalVisible(false);
			setError(false);
			addUniqueId(userMobileNumber);
			localStorage.setItem("loged", "true");
			const merchantProfile = await GetMerchantProfile();
			if (!merchantProfile?.merchant_occupation_id) {
				trackEvent(Events.SGN_OTP_SUBMITTED, { is_fresh_user: true });

				setPersonaModalVisible(true);
			} else {
				locationAccessCheck();
			}
		} catch (error) {
			setLoading(false);
			return setError(true);
		} finally {
			setLoading(false);
		}
	}

	function changeMobileNumber() {
		setUserMobileNumber("");
		trackEvent(Events.SGN_CHANGE_MOBILE, {});

		setOtpModalVisible(false);
		setError(false);
		setMobileNumberModalVisible(true);
	}

	function onClickPersonaCard(perosnaId: string) {
		return () => {
			setUserSelectedPersona(perosnaId);
		};
	}

	async function onClickProceedPersona() {
		setLoading(true);
		try {
			const otpValidateresponse = await UpdatePersonaApi({
				occupation_id: Number(userSelectedPersona),
			});
			trackEvent(Events.SGN_PROFILE_SUBMITTED, {
				user_type: CTUserTypeEnums.get(userSelectedPersona),
			});

			setPersonaModalVisible(false);
			locationAccessCheck();
		} catch (error) {
			console.log(error);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	}

	const resendOtp = () => {
		trackEvent(Events.SGN_RESEND_OTP, {});

		getOtp();
	};

	return {
		otpModalVisible,
		onClickLoginProceed,
		mobileNumberModalVisible,
		getMobileNumber,
		getUserOtpFromInput,
		userOtp,
		onClickValidateOtpProceed,
		changeMobileNumber,
		personaModalVisible,
		onClickPersonaCard,
		userSelectedPersona,
		onClickProceedPersona,
		resendOtp,
		setMobileNumberModalVisible,
		error,
		loading,
		userMobileNumber,
		language,
		isLocationModal,
		setLocationModal,
		userBlocked,
		currentLanguageId,
		disableResend,
		setDisableResend,
		askForPermission,
		timeLanguage,
	};
}
