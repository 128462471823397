import getConfigByKeys from "../../config/config";
import { configKeys } from "../../config/types";
import handleHttpError from "../../utils/api/httpErrorMapping";
import HttpStatusCode from "../types/httpStatusCodesEnums.type";
import type UnifiedResponse from "../types/unifiedResponse.type";

import { DEFAULT_LANGUAGE_ID } from "../../constant/language";

import { isFarmartWeb } from "../../utils/common";

import { getUserLanguageFromStorage } from "../../modal/auth/auth.model";
import { apiEndPoints } from "../endpoints";

const { API_URL } = getConfigByKeys([configKeys.API_URL]);

export default async function PostComment(payload): Promise<UnifiedResponse> {
	let userLanguage = getUserLanguageFromStorage()?.langId || null;

	if (!userLanguage) {
		userLanguage = DEFAULT_LANGUAGE_ID;
	}

  const path = apiEndPoints.getOTP;

	let configObj: any = {
		Accept: "application/json",
		"Content-Type": "application/json",
		language: userLanguage.toString(),
	};

	let formedUrl = `${API_URL}${path}?mobile_number=${payload.mobile_number}`;

  let formedRequestObj: any = {
		method: "get",
		headers: configObj,
	}

  // IF THE APP IS FARMART WEB;
  // THEN THE CONFIGURATION WOULD BE CHANGED;
	if (isFarmartWeb) {
		configObj = {
			Accept: "application/json",
			"Content-Type": "application/json",
			authorization: null,
			language: userLanguage.toString(),
		};

    formedUrl = `${API_URL}${path}`;

    formedRequestObj = {
      method: "post",
      headers: configObj,
      body: JSON.stringify(payload),
    }
	}

	const response = await fetch(formedUrl, formedRequestObj);

	if (response.status !== HttpStatusCode.Ok) {
		throw handleHttpError(response.status, path);
	}

	const data = await response.json();
	return { data, response };
}
