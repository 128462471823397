import React from "react";
import {
	Box,
	Column,
	Row,
} from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";
import IconButtonC from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/button/IconButton";

import EditIcon from "@mui/icons-material/Edit";
import { isSaudabookPWA } from "@mono-farmart-web/farmart-web-common/utils/common";
import { getTrackConsentScreenAssets } from '@mono-farmart-web/farmart-web-common/constant/imageUrls/index';

interface ProfileCardProps {
	onOpenDialog?: () => void;
	data?: any; // TYPE TO BE DEFINED LATER ON;
	language?: any;
	allowEditDetails?: boolean;
}

const ProfileCard = (props: ProfileCardProps) => {
	const {
		data: { driverName, driverNumber },
	} = props;

  const trackConsentAssets = getTrackConsentScreenAssets();
	return (
		<Box>
			<Row gap={3} alignItems={"center"}>
				<Row flex={1} gap={2}>
					{/* DRIVER IMG ICO */}
					<Row alignItems={"center"} justifyContent={"center"}>
						<img
							id="truckdriver_icon_trip_cancelledscreen"
							src={trackConsentAssets?.TruckDriverIcon}
							alt="driver-img-ico"
							width={40}
							height={40}
              style={{ borderRadius: "50%" }}
						/>
					</Row>

					{/* INFO */}
					<Box flex={1}>
						<Column gap={0.5}>
							<Text
								id="drivername_trip_cancelledscreen"
								level="title-md"
								textColor="text.primary"
							>
								{driverName || "Driver"}
							</Text>
							<Text
								id="drivernumber_trip_cancelledscreen"
								level="body-sm"
								textColor="text.secondary"
							>
								{driverNumber}
							</Text>
						</Column>
					</Box>
				</Row>

				{/* DETAIL EDIT BUTTON */}
				{props?.allowEditDetails && isSaudabookPWA ? (
					<IconButtonC
						id="editdriver_icon_consentpendingscreen"
						variant="outlined"
						sx={{
							borderRadius: "8px",
							width: "40px",
							height: "40px",
							border: "1px solid #019881",
						}}
						onClick={props?.onOpenDialog}
					>
						<EditIcon sx={{ color: "primary.outlinedColor" }} />
					</IconButtonC>
				) : null}
			</Row>
		</Box>
	);
};

export default ProfileCard;
