import React from 'react';
import Styles from './style.module.css';
import Modal from '@mono-farmart-web/farmart-web-common/ComponentV2/Modal';
import type {
  Crop,
  CropVariety,
} from '@mono-farmart-web/farmart-web-common/modal/Traders/types';
import Loading from '@mono-farmart-web/farmart-web-common/ComponentV2/Loading';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import CommonErrorState from '@mono-farmart-web/farmart-web-common/ComponentsV4/CommonErrorState';
import { Box, Checkbox } from '@mui/joy';
import MuiButton from '@mono-farmart-web/farmart-web-common/ComponentsV3/MuiButton';

interface PayloadType {
  isOpen: boolean;
  handleCropVarietyListModal: () => void;
  activeVariety: number[];
  cropVarietyList: CropVariety[];
  handleVarietySelection: (
    cropVarietyId: number,
    cropVarietyName: string,
  ) => void;
  cropVarietyLoading: boolean;
  activeCrop: Crop;
  handleCropVarietySearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  cropVarietyListSearchParam: string;
  language: { [key: string]: string };
  searchIcon: string;
  isModalErrorVisible: boolean;
  onRefreshCropVarietyList: () => void;
  onClickHelp: () => void;
  onClickVarietyFilterApply: () => void;
}

const SelectVarietyModal = ({
  isOpen,
  handleCropVarietyListModal,
  activeVariety,
  cropVarietyList,
  handleVarietySelection,
  cropVarietyLoading,
  activeCrop,
  handleCropVarietySearch,
  cropVarietyListSearchParam,
  language,
  searchIcon,
  isModalErrorVisible,
  onClickHelp,
  onRefreshCropVarietyList,
  onClickVarietyFilterApply,
}: PayloadType) => {
  const UiModal = (
    <div>
      {isModalErrorVisible ? (
        <Box margin={'8px 0px'}>
          <CommonErrorState
            onPressRetry={onRefreshCropVarietyList}
            onPressHelp={onClickHelp}
            type="modal"
            imageId="alertimage_alertmodal_tradescreen"
            subHeadingTextId="unabletoprocess_text_alertmodal_tradescreen"
            headingTextId="somethingwentwrong_text_alertmodal_tradescreen"
            onPressRetryTestId="retry_button_alertmodal_tradescreen"
          />
        </Box>
      ) : (
        <div className={Styles.wrapper}>
          <div className={Styles.searchWrapper}>
            <div className={Styles.searchField}>
              <div className={Styles.iconWrapper}>
                <img
                  src={searchIcon}
                  height={24}
                  width={25}
                  loading="eager"
                  alt="searchIcon"
                />
              </div>
              <input
                type="text"
                placeholder={language?.cropVarietyModalSearchPlaceholder}
                value={cropVarietyListSearchParam}
                onChange={handleCropVarietySearch}
                className={Styles.searchInput}
              />
            </div>
          </div>
          <div className={Styles.optionWrapper}>
            {cropVarietyLoading ? (
              <div className={Styles.loadingContainer}>
                <Loading size={20} color="grey" loaderWidth={3} />
              </div>
            ) : (
              cropVarietyList?.length > 0 && (
                <Box display={'flex'} flexDirection={'column'}>
                  <Box
                    gap={'16px'}
                    display={'flex'}
                    flexDirection={'column'}
                    paddingBottom={'32px'}
                  >
                    {cropVarietyList.map((item, index) => (
                      <Box key={index} className={Styles.listItem}>
                        <div
                          className={Styles.itemContainer}
                          onClick={() =>
                            handleVarietySelection(
                              item.id,
                              item.crop_variety_name,
                            )
                          }
                        >
                          <div className={Styles.inactiveVariety}>
                            {item.crop_variety_name}
                          </div>
                          <div>
                            <Checkbox
                              variant={
                                activeVariety.includes(item?.id)
                                  ? 'solid'
                                  : 'outlined'
                              }
                              color={
                                activeVariety.includes(item?.id)
                                  ? 'primary'
                                  : 'neutral'
                              }
                              size="md"
                              checked={activeVariety.includes(item?.id)}
                              value={
                                activeVariety.includes(item?.id)
                                  ? 'true'
                                  : 'false'
                              }
                            ></Checkbox>
                          </div>
                        </div>
                      </Box>
                    ))}
                  </Box>
                  <Box className={Styles.buttonWrapperContainer}>
                    <MuiButton
                      className={Styles.buttonContainer}
                      onClick={onClickVarietyFilterApply}
                    >
                      {language?.apply}
                    </MuiButton>
                  </Box>
                </Box>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Modal
      onClose={handleCropVarietyListModal}
      isCloseButtonOption={true}
      title={language.cropVarietyModalHeading}
      isOpen={isOpen}
      children={UiModal}
    />
  );
};

export default SelectVarietyModal;
