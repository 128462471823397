/* This code snippet is a functional component in a TypeScript React application called `HomeScreen`.
It serves as the main screen component that renders various UI elements based on the props passed to
it. Here's a breakdown of what the component does: */
import React from 'react';
import styles from './style.module.css';
import OccupationCard from './OccupationCard';
import Header from './Header/Header';
import GoodFoodChainLogo from './GoodFoodChain';
import CropAdvisoryPrimary from './CropAdvisoryPrimary';
import Weather from './Weather';
import UserPersonaModal from './UserPersonaModal';
import SecondaryFeature from './SecondaryFeature';
import Modal from '@mono-farmart-web/farmart-web-common/ComponentsV4/Modal';
import DownloadAppModalContent from './DownloadAppModalContent';
import ShopPromotionPrimary from './ShopPromotionPrimary';
import RakePrimary from './RakePrimary';
import BahiKhataPrimary from './BahiKhataPrimary';
import NewsfeedContainer from './NewsFeedContainer';
import { Box } from '@mui/material';
import { useLocation, LocationModalView } from '@mono-farmart-web/farmart-web-common/context/location';
import QuizView from './Quiz';
import AddFarmer from './AddFarmer';
import QuizAlert from './QuizAlert';
import { usePlatfromData } from '@mono-farmart-web/farmart-web-common/hooks/CrossPlatformActionHandler/platformChecker'; //Platform checker use to check whether user is on web or app platform
import PlatForms from '@mono-farmart-web/farmart-web-common/constant/platFormEnums';
import Toast from '@mono-farmart-web/farmart-web-common/ComponentV2/Toast';
import SkeletonLoading from '@mono-farmart-web/farmart-web-common/ComponentsV4/SkeletonLoading';

export default function HomeScreen({
  personaModalVisible,
  onClickPersonaCard,
  userSelectedPersona,
  onClickProceedPersona,
  usePerosnaProceedButtonLoading,
  isDownloadAppModalOpen,
  closeDownloadAppModal,
  language,
  onClickpersonaRole,
  onClickDownloadAppNow,
  onChangeLocation,
  homeScreenData,
  posts,
  onClap,
  onComment,
  onShare,
  onViewPost,
  weatherData,
  districtName,
  stateName,
  subscribedCropsAdvisoryList,
  totalCropSubscirbedCount,
  totalCredit,
  primaryFeatureModalType,
  onCLickViewRake,
  onClickViewCropAdvisoryPrimary,
  onClickBahiKhataPrimaryButton,
  onClickShopPromotionPrimaryButton,
  onClickSecondaryFeatureCard,
  onClickViewMorePost,
  isFetchingAllPosts,
  homeScreenLoading,
  weatherLoading,
  rakeData,
  totalSmsLeft,
  rakeLoading,
  subscribedCropAdvisoryLoading,
  bahiKhataLoading,
  smsLoading,
  shareOnWhatsApp,
  userLogedIn,
  quizImage,
  onClickQuiz,
  addFarmerVisible,
  onClickAddFarmer,
  onClickMyProfile,
  onClickSmsHistory,
  onClickSubCropAdvisory,
  onClickFaq,
  disableQuizClick,
  onClickCloseQuizAlertModal,
  quizAlert,
  onClickViewSubscribedCropsList,
  refreshPage,
  uniqueKey,
  homeScreenAssets,
}) {
  const locationProps = useLocation();
  const platform = usePlatfromData();

  const primaryFeature = {
    1: ShopPromotionPrimary,
    3: BahiKhataPrimary,
    4: CropAdvisoryPrimary,
    5: RakePrimary,
  };

  const RenderPrimaryFeature = (props) => {
    const featureId = props?.item?.features[0]?.id;
    if (!primaryFeature[featureId]) {
      return null;
    }
    props.key = props.item.features[0].name + 'primary';
    return primaryFeature[featureId](props);
  };

  const screen = {
    1: RenderPrimaryFeature,
    2: SecondaryFeature,
    3: NewsfeedContainer,
    4: Weather,
    5: QuizView,
  };

  const screenFunction = {
    1: {
      //Primary Feature
      language,
      subscribedCropsAdvisoryList,
      totalCropSubscirbedCount,
      totalCredit,
      primaryFeatureModalType,
      onCLickViewRake,
      onClickViewCropAdvisoryPrimary,
      onClickBahiKhataPrimaryButton,
      onClickShopPromotionPrimaryButton,
      homeScreenLoading,
      rakeData,
      stateName,
      districtName,
      totalSmsLeft,
      rakeLoading,
      subscribedCropAdvisoryLoading,
      bahiKhataLoading,
      smsLoading,
      userLogedIn,
      onClickSmsHistory,
      onClickSubCropAdvisory,
      onClickViewSubscribedCropsList,
    },
    2: { language, onClickSecondaryFeatureCard, homeScreenLoading }, //Secondary Feature
    3: {
      language,
      posts,
      onClap,
      onComment,
      onShare,
      onViewPost,
      onClickViewMorePost,
      isFetchingAllPosts,
      homeScreenLoading,
      homeScreenAssets,
    }, //NewsFeed
    4: {
      language,
      weatherData,
      stateName,
      districtName,
      weatherLoading,
      shareOnWhatsApp,
      homeScreenLoading,
      homeScreenAssets,
    }, //Weather
    5: { language, quizImage, onClickQuiz, disableQuizClick }, //quiz
  };

  return (
    <div className={styles?.mainContainer} key={uniqueKey}>
      <Header
        onChangeLocation={onChangeLocation}
        districtName={districtName}
        stateName={stateName}
        language={language}
        onClickMyProfile={onClickMyProfile}
        onClickFaq={onClickFaq}
      />
      {homeScreenLoading ? (
        <SkeletonLoading className={styles.loading} itemNumber={6} />
      ) : (
        <>
          {!userLogedIn && platform?.platform === PlatForms?.get('website') && (
            <OccupationCard
              onClickpersonaRole={onClickpersonaRole}
              language={language}
              userSelectedPersona={userSelectedPersona}
            />
          )}
          {homeScreenData?.map((item, index) => {
            const categoryEnumId = item?.category_enum_id;
            const screenComponent = screen[categoryEnumId];
            const screenProps = screenFunction[categoryEnumId];
            if (!categoryEnumId || !screenComponent || !screenProps) {
              return <></>; // Skip rendering if any of the required data is missing
            }
            return (
              <Box key={index + 'feature_card'}>
                {screenComponent && screenComponent({ item, ...screenProps })}
              </Box>
            );
          })}
          <Modal
            children={
              <DownloadAppModalContent
                closeModal={closeDownloadAppModal}
                featureType={primaryFeatureModalType}
                language={language}
                onClickDownloadAppNow={onClickDownloadAppNow}
                homeScreenAssets={homeScreenAssets}
              />
            }
            isOpen={isDownloadAppModalOpen}
            onClose={closeDownloadAppModal}
            id={'downloadAppModalId'}
          />
          <UserPersonaModal
            personaModalVisible={personaModalVisible}
            onClickPersonaCard={onClickPersonaCard}
            userSelectedPersona={userSelectedPersona}
            onClickProceedPersona={onClickProceedPersona}
            usePerosnaProceedButtonLoading={usePerosnaProceedButtonLoading}
            language={language}
            onClickpersonaRole={onClickpersonaRole}
          />
          <LocationModalView
            isLocationModal={locationProps?.isLocationModal}
            language={locationProps?.language}
            locationModalLoading={locationProps?.locationModalLoading}
            onClickAllowLocationModal={locationProps?.onClickAllowLocationModal}
            homeScreenAssets={homeScreenAssets}
          />
          <QuizAlert
            language={language}
            onClickCloseQuizAlertModal={onClickCloseQuizAlertModal}
            quizAlert={quizAlert}
          />
          <GoodFoodChainLogo
            language={language}
            homeScreenAssets={homeScreenAssets}
          />
          {addFarmerVisible && (
            <AddFarmer
              language={language}
              onClickAddFarmer={onClickAddFarmer}
              homeScreenAssets={homeScreenAssets}
            />
          )}
          <Toast
            isError={true}
            message={language?.locationEnableText}
            isOpen={locationProps?.locationToastPopup}
          />
          <Toast
            isError={true}
            message={language?.refreshPageMessage}
            isOpen={refreshPage}
          />
        </>
      )}
    </div>
  );
}
