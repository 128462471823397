import React from "react";
import {
	Box,
	Row,
} from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout";
import { Text } from "@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import type { CSSProperties } from "react";

interface MessageWrapperProps {
	type: string;
	message: string;
	sx?: CSSProperties;
}

const MessageWrapper = (props: MessageWrapperProps) => {
	const { type, message } = props;
	let icon: any = null;
	let textColor = "warning.softColor";
	if (type === "info") {
		icon = (
			<InfoIcon
				id="alerticon_consentpendingscreen"
				width={24}
				height={24}
				sx={{ color: "warning.softColor" }}
			/>
		);
	} else if (type === "success") {
		icon = (
			<CheckCircleIcon width={24} height={24} sx={{ color: "success.600" }} />
		);
		textColor = "success.600";
	} else if (type === "failure") {
		icon = (
			<ErrorIcon width={24} height={24} sx={{ color: "danger.softColor" }} />
		);
		textColor = "danger.softColor";
	} else if (type === "disabled") {
		icon = (
			<InfoOutlinedIcon width={24} height={24} sx={{ color: "#475467" }} />
		);
		textColor = "#475467";
	}

	return (
		<Box
			sx={{
				padding: "24px 16px 32px 16px",
				...props?.sx,
			}}
		>
			<Box
				bgcolor={
					type === "info"
						? "warning.softBg"
						: type === "success"
						  ? "success.plainHoverBg"
						  : type === "disabled"
							  ? "neutral.softBg"
							  : "danger.softBg"
				}
				padding={2}
				sx={{
					borderRadius: "8px",
				}}
			>
				<Row padding={2} gap={2} alignItems={"center"}>
					{icon}
					<Text
						id="takedriverconsent_consentpendingscreen"
						level="body-xxs"
						fontWeight={500}
						lineHeight={"16px"}
						sx={{
							color: textColor,
						}}
					>
						{message}
					</Text>
				</Row>
			</Box>
		</Box>
	);
};

export default MessageWrapper;
