// biome-ignore lint/style/useImportType: <explanation>
import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Column,
  Row,
} from '@mono-farmart-web/farmart-web-common/MuiComponents/atoms/layout';
import FormTitle from '@mono-farmart-web/farmart-web-common/MuiComponents/organisms/request/FormTitle';
import { Button, Drawer } from '@mui/joy';

import ButtonC from '@mono-farmart-web/farmart-web-common/MuiComponents/atoms/button/Button';
import { Text } from '@mono-farmart-web/farmart-web-common/MuiComponents/atoms/typography';
import { trackEvent } from '@mono-farmart-web/farmart-web-common/utils/moengage';
// import { useFetchUserAPI } from "../../hooks/general";

import InAppBrowser from '@mono-farmart-web/farmart-web-common/MuiComponents/organisms/InAppBrowser/InAppBrowser';
import FullScreenDialog from '@mono-farmart-web/farmart-web-common/MuiComponents/organisms/fullScreenDialog/FullScreenDialog';
import FormHeading from '@mono-farmart-web/farmart-web-common/MuiComponents/organisms/request/FormHeading';
import {
  useDemoVideo,
  useInterval,
} from '@mono-farmart-web/farmart-web-common/hooks/general';
import { useSelector } from 'react-redux';

import PageLoader from '@mono-farmart-web/farmart-web-common/MuiComponents/organisms/pageLoader/PageLoader';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import Events from '@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index';
// import useFetchUserAPI from "../../hooks/useFetchUserAPI";
import { createBridge } from '@mono-farmart-web/rpc-bridge';
import {
  LangEnums,
  NativeEnums,
} from '@mono-farmart-web/farmart-web-common/constant/enums/appEnums';
import bridgeFunctions from '@mono-farmart-web/rpc-bridge/utility';

import ComponentHeader from '../../pages/ComponentHeader';
import FormInput from '../../pages/FormInput';
import ConsentPending from '../../pages/ConsentPending';
import { useStyles } from './styles/style';
import TripCancelled from '../../pages/TripCancelled';
import useFetchTripDetails from '../../hooks/useFetchTripDetails';

import createTripImg from '../../assets/create_trip.png';
import createTripImgHindi from '../../assets/create_trip_hindi.png';
import driverConsentImg from '../../assets/driver_consent.png';
import driverConsentImgHindi from '../../assets/driver_consent_hindi.png';
import {
  isFarmartWeb,
  isSaudabookPWA,
} from '@mono-farmart-web/farmart-web-common/utils/common';
import PlatForms from '@mono-farmart-web/farmart-web-common/constant/platFormEnums';
import { usePlatfromData } from '@mono-farmart-web/farmart-web-common/hooks/CrossPlatformActionHandler/platformChecker';

interface DriverDetailProps {
  truck_number: string;
  driver_number: string;
  driver_name?: string;
}

interface ViewProps {
  trip_id: string | null;
  isInitialTrip?: boolean;
  language?: any;
  activeLanguage?: string;
  truck_id?: number | string;
  saudaId?: number | string;
  dispatchId?: number | string;
  saudaDetailData?: any
}

function switchKeys(key: string) {
  let switchedKey = '';
  if (key === 'driver_number') {
    switchedKey = 'Changed Driver mobile Number';
  } else if (key === 'driver_number') {
    switchedKey = 'Changed Driver Name';
  } else if (key === 'truck_number') {
    switchedKey = 'Changed Truck Number';
  }

  return switchedKey;
}

function fetchChangedDetails(detailObj: any, flagObj: any) {
  const finalObj: any = {};

  for (const key of Object.keys(flagObj)) {
    if (flagObj?.[key]) {
      finalObj[switchKeys(key)] = detailObj?.[key];
    }
  }

  return finalObj;
}

function millisToMinutesAndSeconds(millis: any) {
  const minutes = Math.floor(millis / 60000);
  const seconds: any = ((millis % 60000) / 1000).toFixed(0);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

const View = (props: ViewProps) => {
  const platform = usePlatfromData();
  const { language, activeLanguage, truck_id, saudaId, dispatchId, saudaDetailData } = props;

  const bridge = createBridge();
  // const { ipAddress, isIpLoading } = useFetchUserAPI();
  const classes = useStyles();
  // const activeLanguage = useSelector(
  // 	(state: any) => state.settings.activeLanguage,
  // );
  let ViewAccessedFrom: any = null;

  if (isFarmartWeb) {
    ViewAccessedFrom = platform?.platform?.toLowerCase();
  } else {
    ViewAccessedFrom = useSelector((state: any) => state.settings.accessedFrom);
  }

  const accessedFrom = ViewAccessedFrom?.includes(NativeEnums.APP)
    ? NativeEnums.APP
    : NativeEnums.WEB;

  // FETCHING TRIP DATA;
  const { tripDetails, error, isLoading } = useFetchTripDetails(
    props?.trip_id || '',
    truck_id,
  );

  // GENERATING YOUTUBE URL DETAILS;
  const youtubeHook: any = useDemoVideo({
    URL: '',
  });

  // STATES TO TRACK THE CHANGES IN VEHICLE AND DRIVER DETAILS;
  const [changedDetails, setChangedDetails] = useState({
    driver_name: false,
    driver_number: false,
    truck_number: false,
  });

  // TO HOLD THE SELECTED YOUTUBE VIDEO URL IN HELP SECTION;
  const [clickedVideoTab, setClickedVideoTab] = useState<any>({
    videoTitle: '',
    videoUrl: '',
  });

  const [consentGivenUnder24Hrs, setConsentGivenUnder24Hrs] = useState(true);

  // STATE - DRIVER DETAILS EDIT FLOW;
  const [openDriverDetailDialog, setOpenDriverDetailDialog] =
    useState<boolean>(false);
  const [allowDriverDetailsEdit, setAllowDetailsEdit] =
    useState<boolean>(false);
  const [driverDetails, setDriverDetails] = useState<DriverDetailProps | null>(
    null,
  );

  // STATES - TRIP CANCELLATION FLOW;
  const [cancelTrip, setCancelTrip] = useState<boolean>(false);
  const [allowCancelTrip, setAllowCancelTrip] = useState<boolean>(false);

  // STATES - HELP SECTION;
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  // METHODS - TO HANDLE DRIVER DETAILS EDIT FLOW;
  const handleCloseDriverDetailsDialog = () => {
    setAllowDetailsEdit(false);
    setOpenDriverDetailDialog(false);

    // RESETTING THE DRIVER DIALOG VALUES, IF CLOSED WITHOUT SAVING;
    setDriverDetails({
      driver_number: tripDetails?.trip_detail?.vehicle_details?.driver_number,
      truck_number: tripDetails?.trip_detail?.vehicle_details?.vehicle_number,
      driver_name: tripDetails?.trip_detail?.vehicle_details?.driver_name,
    });
  };

  const handleOpenDriverDetailsDialog = () => {
    setOpenDriverDetailDialog(true);

    // WHEN EDIT FDRIVER DETAIL BUTTON IS CLICKED;
    // THIS EVENT IS TRIGGERED;
    trackEvent(Events?.OnEditDriverDetailsClick, {
      // ipAddress,
    });

    // WHEN PROCEED TO EDIT BOTTOMSHEET IS OPENED;
    trackEvent(Events?.OnEditDriverProceedToEditOpened, {
      // ipAddress,
    });
  };

  const allowProceedToEditDriverDetails = () => {
    setAllowDetailsEdit(true);
  };

  const handleDriverDetailsSave = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event?.target;

    if (name === 'driver_number') {
      setDriverDetails((prevState: any) => ({
        ...prevState,
        [name]: value?.slice(0, 10),
      }));
    } else {
      setDriverDetails((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }

    setChangedDetails((prevState: any) => ({
      ...prevState,
      [name]: true,
    }));
  };

  // METHODS - TO HANDLE TRIP CANCELLATION;
  const handleCloseTripCancelWarningDialog = () => {
    // WHEN THE TRIP CANCELLATION IS ABORTED;
    trackEvent(Events?.OnCancelTripSubmitButtonClicked, {
      'Trip Cancelled': 'No',
      trip_detail_id: tripDetails?.trip_detail?.trip_number,
      // ipAddress,
    });

    setCancelTrip(false);
  };

  const handleOpenTripCancelWarningDialog = () => {
    // WHEN CANCEL TRIP BUTTON IS CLICKED;
    trackEvent(Events?.OnCancelTripClick, {
      // ipAddress,
    });

    setCancelTrip(true);
  };

  // REDIRECTS THE USER TO SUPPORT CHAT ON WHATSAPP;
  const redirectToWhatsapp = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (accessedFrom === NativeEnums.APP) {
      const text = ` &phone=${import.meta.env.VITE_TRIP_DETAIL_CONTACT_NUMBER}`;
      bridge.sendRequestToNative(
        'handleNativeWhatsappShare',
        {
          message: 'Whatsapp share inititated!',
          link: `whatsapp://send?text=${text}`,
        },
        (response: any) => {
          bridgeFunctions.PrintLog(response);
        },
      );
    } else {
      const WAlink = `https://wa.me/${
        import.meta.env.VITE_TRIP_DETAIL_CONTACT_NUMBER
      }`;
      window.location.href = WAlink;
    }
  };

  const handleTripCancellation = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/v1/trip/cancelTrip`,
        {
          trip_id: tripDetails?.trip_detail?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem('authToken')}`,
          },
        },
      );

      const { status } = response?.data;
      if (status) {
        // WHEN TRIP IS CANCELLED;
        trackEvent(Events?.OnCancelTripSubmitButtonClicked, {
          'Trip Cancelled': 'Yes',
          trip_detail_id: tripDetails?.trip_detail?.trip_number,
          // ipAddress,
        });

        setAllowCancelTrip(true);
        setCancelTrip(false);
      }
    } catch (error) {}
  };

  // METHODS - TO HANDLE HELP SECTION;
  const handleHelpDialogOpen = () => {
    setOpenHelp(true);
  };

  const handleHelpDialogClose: any = () => {
    setOpenHelp(false);
  };

  const handleDriverDetailsSubmit = async () => {
    // CHECKS AND RETURS THE CHANGED DETAILS;
    const changedDetailsObj: any = fetchChangedDetails(
      driverDetails,
      changedDetails,
    );

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/v2/trip/editVehicle`,
        {
          trip_id: tripDetails?.trip_detail?.id,
          driver_name: driverDetails?.driver_name,
          mobile_number: driverDetails?.driver_number,
          vehicle_number: driverDetails?.truck_number,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem('authToken')}`,
          },
        },
      );

      const { status } = response?.data;

      if (status) {
        // EVENT TO TRACK IF DETAILS HAVE BEEN SUBMITTED;
        trackEvent(Events?.OnEditDriverDetailsSubmit, {
          ...changedDetailsObj,
          trip_detail_id: tripDetails?.trip_detail?.trip_number,
          // ipAddress,
        });

        setAllowDetailsEdit(false);
        setOpenDriverDetailDialog(false);

        window.location.reload();
      }
    } catch (error) {}
  };

  // POPULATING THE VEHICLE DETAILS ON INITAL PAGE LOAD;
  useEffect(() => {
    setDriverDetails({
      driver_number: tripDetails?.trip_detail?.vehicle_details?.driver_number,
      truck_number: tripDetails?.trip_detail?.vehicle_details?.vehicle_number,
      driver_name: tripDetails?.trip_detail?.vehicle_details?.driver_name,
    });

    let isTripCancelled = tripDetails?.trip_status !== 2;

    if (!isSaudabookPWA) {
      isTripCancelled = tripDetails?.tripStatus > 7;
    }

    setAllowCancelTrip(isTripCancelled);

    // EVENT FOR INITIAL POINT WHEN A TRIP IS CREATED FOR THE FIRST TIME;
    if (typeof props?.isInitialTrip !== 'undefined' && props?.isInitialTrip) {
      trackEvent(Events?.OnTrackingCreatedOpened, {
        initial: true,
        'Consent Status': tripDetails?.consent_status,
        'Driver Mobile Number':
          tripDetails?.trip_detail?.vehicle_details?.driver_number,
        trip_details_id: tripDetails?.trip_detail?.trip_number,
        // ipAddress,
      });
    }
  }, [isLoading]);

  // THIS MODULE AUTO CANCELS TRIP AFTER 24 HOURS;
  // TIME MAY VARY FROM 24 -> 48 -> 72 HOURS;
  // BASED ON THE CLIENT DEMAND;
  const autoCancelTripAfter24Hours = () => {
    const dispatchedAt = tripDetails?.trip_detail?.dispatched_at;

    const dispatchTimeInMilliseconds = new Date(dispatchedAt)?.getTime();
    const currentTimeInMilliseconds = new Date()?.getTime();

    const milliSecondsDifference =
      currentTimeInMilliseconds - dispatchTimeInMilliseconds;
    const timeInMinutes = millisToMinutesAndSeconds(
      milliSecondsDifference,
    )?.split(':')?.[0];

    if (Number?.parseInt(timeInMinutes) >= 3600) {
      if (tripDetails?.trip_status === 2 && tripDetails?.consent_status === 1) {
        setConsentGivenUnder24Hrs(false);
        handleTripCancellation();
      }
    }
  };

  // TIMER RUNS AFTER
  useInterval(() => {
    autoCancelTripAfter24Hours();
  }, 60000);

  if (isLoading) return <PageLoader />;

  return (
    <>
      <Box bgcolor="neutral.outlinedBorder">
        <Column bgcolor={'inherit'}>
          {/* TOP HEADER */}
          <ComponentHeader
            language={language}
            isTripCancelled={allowCancelTrip}
            tripCancelled={allowCancelTrip}
            onOptionOneOpen={handleHelpDialogOpen}
            onOptionTwoOpen={handleOpenTripCancelWarningDialog}
            navigateObj={!isFarmartWeb ? {} : { saudaId, dispatchId, saudaDetailData }}
            bgColor={!allowCancelTrip ? 'success.plainHoverBg' : 'common.white'}
          />

          {!allowCancelTrip ? (
            <ConsentPending
              language={language}
              loader={isLoading}
              activeLanguage={props?.activeLanguage}
              encrypted_trip_id={tripDetails?.trip_detail?.encrypted_trip_id}
              trip_id={tripDetails?.trip_detail?.id}
              payload={{
                consent_status: tripDetails?.consent_status,
                trip_status: tripDetails?.trip_status,
                vehicle_details: tripDetails?.trip_detail?.vehicle_details,
                destination_address: {
                  ...tripDetails?.trip_detail?.destination_address,
                  expected_delivery: tripDetails?.trip_detail?.ETA,
                },
                source_address: {
                  ...tripDetails?.trip_detail?.source_address,
                  dispatched_at: tripDetails?.trip_detail?.dispatched_at,
                },
                estimated_distance: Math.floor(
                  tripDetails?.trip_detail?.distance / 1000,
                ),
                receiver_detail: tripDetails?.trip_detail?.receiver_detail,
                dispatchedDate: tripDetails?.trip_detail?.dispatched_at,
                trip_id: props?.trip_id,
                truck_id: props?.truck_id,
              }}
              onOpenDialog={handleOpenDriverDetailsDialog}
            />
          ) : (
            <TripCancelled
              language={language}
              loader={isLoading}
              payload={{
                consent_status: tripDetails?.consent_status,
                trip_status: tripDetails?.trip_status,
                vehicle_details: tripDetails?.trip_detail?.vehicle_details,
                destination_address: {
                  ...tripDetails?.trip_detail?.destination_address,
                  expected_delivery: tripDetails?.trip_detail?.ETA,
                },
                source_address: {
                  ...tripDetails?.trip_detail?.source_address,
                  dispatched_at: tripDetails?.trip_detail?.dispatched_at,
                },
                estimated_distance: Math.floor(
                  tripDetails?.trip_detail?.distance / 1000,
                ),

                receiver_detail: tripDetails?.trip_detail?.receiver_detail,
                dispatchedDate: tripDetails?.trip_detail?.dispatched_at,
              }}
              onOpenDialog={handleOpenDriverDetailsDialog}
              tripCancelledDueToNoConsent={!consentGivenUnder24Hrs}
            />
          )}
        </Column>
      </Box>

      {/* DRIVER DETAIL EDIT DIALOG */}
      <Drawer
        anchor="bottom"
        size="md"
        open={openDriverDetailDialog}
        onClose={() => {
          handleCloseDriverDetailsDialog();
        }}
        slotProps={{
          content: {
            sx: {
              borderTopLeftRadius: '24px',
              borderTopRightRadius: '24px',
              overflow: !allowDriverDetailsEdit ? 'hidden' : 'visible',
              height: 'auto',
            },
          },
        }}
      >
        {!allowDriverDetailsEdit ? (
          <>
            <Box paddingTop={6}>
              <Column gap={7}>
                {/* DETAIL SECTION */}
                <Box>
                  <Column gap={4}>
                    {/* TOP IMAGE */}
                    <Row justifyContent={'center'} alignItems={'center'}>
                      <Box
                        width={'80px'}
                        height={'80px'}
                        bgcolor="warning.outlinedHoverBg"
                        borderRadius={'8px'}
                        boxShadow={
                          '2px 2px 24px 0px rgba(218, 103, 3, 0.16) inset, -2px -2px 24px 0px rgba(205, 97, 3, 0.16) inset'
                        }
                      >
                        <Row
                          height={'100%'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          <WarningRoundedIcon
                            id="Alert_icon_warningscreen"
                            sx={{
                              width: '36px',
                              height: '36px',
                              color: 'warning.outlinedColor',
                            }}
                          />
                        </Row>
                      </Box>
                    </Row>

                    {/* INFORMATION */}
                    <Box padding={4}>
                      <Column
                        gap={1}
                        textAlign={'center'}
                        alignItems={'center'}
                      >
                        <Text
                          id="Editing_driver_numDetails_consentpendingscreen"
                          level="h4"
                          textColor="text.primary"
                          fontWeight={600}
                        >
                          {language?.editDriverDetailCondormationTitle}
                        </Text>
                        <Text
                          id="location_update_may_text_consentpendingscreen"
                          level="body-xxs"
                          textColor="text.secondary"
                          fontWeight={500}
                          lineHeight={'16px'}
                          width={'328px'}
                        >
                          {language?.editDriverDetailConfirmationDescription}
                        </Text>
                      </Column>
                    </Box>
                  </Column>
                </Box>

                {/* ACTION BUTTON SECTION */}
                <Box padding={4}>
                  <Column gap={3}>
                    <ButtonC
                      id="proceed_tab_consentpendingscreen"
                      size={'md'}
                      color="warning"
                      fullWidth
                      sx={{
                        fontFamily: 'Noto Sans',
                        fontWeight: 600,
                        borderRadius: '8px',
                        height: '40px',
                      }}
                      onClick={() => {
                        // TRACK EVENT FOR DRIVER DETAIL DIALOG CANCELLATION;
                        trackEvent(
                          Events?.OnEditDriverProceedToEditButtonClick,
                          {
                            'Proceed To Edit': 'Yes',
                            // ipAddress,
                          },
                        );

                        allowProceedToEditDriverDetails();
                      }}
                    >
                      {language?.proceedToExit}
                    </ButtonC>

                    <ButtonC
                      id="cancel_tab_consentpendingscreen"
                      size={'md'}
                      fullWidth
                      // color="neutral"
                      sx={{
                        fontFamily: 'Noto Sans',
                        fontWeight: 600,
                        borderRadius: '8px',
                        backgroundColor: '#F0F4F8',
                        color: '#000',
                        height: '40px',
                      }}
                      onClick={() => {
                        // TRACK EVENT FOR DRIVER DETAIL DIALOG CANCELLATION;
                        trackEvent(
                          Events?.OnEditDriverProceedToEditButtonClick,
                          {
                            'Proceed To Edit': 'No',
                            // ipAddress,
                          },
                        );

                        setOpenDriverDetailDialog(false);
                      }}
                    >
                      {language?.cancelEdit}
                    </ButtonC>
                  </Column>
                </Box>
              </Column>
            </Box>
          </>
        ) : (
          <>
            {/* DRAWER TITLE */}
            <FormTitle
              titleId="editTruck&driverText_edit_trucknum_drivernumscreen"
              descriptionId=""
              onCloseDialog={handleCloseDriverDetailsDialog}
              closeButton={true}
              align="left"
              title={language?.editDriverDetailDialogTitle}
              description={language?.editDriverDetailNote}
            />

            {/* DETAILS SECTION */}
            <Box>
              <Column>
                <FormInput
                  type="text"
                  titleId="truckNumberText_consentpendingscreen"
                  inputId="trucknum_inputfiled_edit_trucknum_drivernumscreen"
                  protected={false}
                  title={language?.truckNumber}
                  name="truck_number"
                  value={driverDetails?.truck_number}
                  placeholder={'Enter Truck Number'}
                  onDetailSave={handleDriverDetailsSave}
                />

                <FormInput
                  type="number"
                  titleId="drivernum_text_edit_trucknum_drivernumscreen"
                  inputId="drivernum_inputfield_edit_trucknum_drivernumscreen"
                  fieldDetailTextId="provide_the_driver_numText_edit_trucknum_drivernumscreen"
                  protected={false}
                  title={language?.driverNumber}
                  name="driver_number"
                  value={driverDetails?.driver_number}
                  placeholder={'Enter Driver Number'}
                  onDetailSave={handleDriverDetailsSave}
                  fieldDetailText={language?.driverNumberNote}
                />

                <FormInput
                  type="text"
                  titleId="drivernameText_edit_trucknum_drivernumscreen"
                  inputId="drivername_inputfiled_edit_trucknum_drivernumscreen"
                  protected={false}
                  title={language?.driverNameOptional}
                  name="driver_name"
                  value={driverDetails?.driver_name}
                  placeholder={'Enter Driver Name'}
                  onDetailSave={handleDriverDetailsSave}
                />
              </Column>
            </Box>

            {/* SAVE CHANGES BUTTON */}
            <Box
              sx={{
                padding: '12px 16px 16px 16px',
              }}
            >
              <Row>
                <ButtonC
                  id="save_changes_tab_edit_trucknum_drivernumscreen"
                  disabled={
                    !(
                      driverDetails?.truck_number &&
                      driverDetails?.driver_number &&
                      `${driverDetails?.driver_number}`?.length > 9
                    )
                  }
                  size="lg"
                  variant="solid"
                  color="primary"
                  sx={{
                    width: '100%',
                    fontFamily: 'Noto Sans',
                    borderRadius: '8px',
                  }}
                  onClick={handleDriverDetailsSubmit}
                >
                  {language?.saveChanges}
                </ButtonC>
              </Row>
            </Box>
          </>
        )}
      </Drawer>

      {/* TRIP CANCELLATION DIALOG */}
      <Drawer
        anchor="bottom"
        size="md"
        open={cancelTrip}
        onClose={() => {
          handleCloseTripCancelWarningDialog();
        }}
        slotProps={{
          content: {
            sx: {
              borderTopLeftRadius: '24px',
              borderTopRightRadius: '24px',
              overflow: 'hidden',
              height: 'auto',
            },
          },
        }}
      >
        {/* DRAWER TITLE */}
        <FormTitle
          id="canceltrip_text_canceltrip_screen"
          closeButton={false}
          align="center"
          title={`${language?.cancelTrip}?`}
        />

        {/* DRAWER MESSAGE */}
        <Box
          sx={{
            padding: '24px 16px 16px 16px',
          }}
        >
          <Row alignItems={'center'} justifyContent={'center'}>
            <Text
              id="areyousure_consentpendingscreen"
              level="title-sm"
              textColor="text.secondary"
              width={'328px'}
            >
              {language?.cancelTripConfirmationText}
            </Text>
          </Row>
        </Box>

        {/* ACTION BUTTON SECTION */}
        <Box padding={4}>
          <Column gap={3}>
            <ButtonC
              id="Yes,cancel_tab_canceltripscreen"
              size={'md'}
              color="danger"
              fullWidth
              sx={{
                fontFamily: 'Noto Sans',
                fontWeight: 600,
                borderRadius: '8px',
                height: '40px',
              }}
              onClick={() => handleTripCancellation()}
            >
              {language?.yesCancelTripOption}
            </ButtonC>

            <ButtonC
              size={'md'}
              fullWidth
              id="no_tab_canceltripscreen"
              // color="neutral"
              sx={{
                fontFamily: 'Noto Sans',
                fontWeight: 600,
                borderRadius: '8px',
                backgroundColor: '#F0F4F8',
                color: '#000',
                height: '40px',
              }}
              onClick={() => handleCloseTripCancelWarningDialog()}
            >
              {language?.noCancelTripOption}
            </ButtonC>
          </Column>
        </Box>
      </Drawer>

      {/* NEED HELP DIALOG */}
      <Drawer
        anchor="bottom"
        size="md"
        open={openHelp}
        onClose={() => handleHelpDialogClose()}
        slotProps={{
          content: {
            sx: classes.drawerContainerProps,
          },
        }}
      >
        <FormTitle title={language?.needHelp} />

        {/* LOWER CONTENT */}
        <Box>
          <Column
            gap={2}
            sx={{
              background: '#F0F4F8',
            }}
          >
            {/* UPPER VIDEO CONTENT SECTION */}
            <Box paddingBottom={6}>
              <Column>
                <FormHeading
                  text={language?.faqTitle}
                  sx={{
                    textAlign: 'left',
                    color: 'text.secondary',
                    fontWeight: 600,
                  }}
                />

                {/* OUTER CONTAINER FOR VIDEO LINKS */}
                <Box paddingLeft={4} paddingRight={4}>
                  <Row gap={4} alignItems={'center'} justifyContent={'center'}>
                    {/* BOX FOR VIDEO DETAILS */}
                    {/* VIDEO 1 INFO */}
                    <Box
                      onClick={() => {
                        setClickedVideoTab({
                          videoUrl:
                            'https://www.youtube.com/embed/CfqBRdM_9pc?si=oMvUiVJxfjxr_pV_',
                          videoTitle: language?.videoOneTitle,
                        });
                        youtubeHook?.toggleDisplayVideoModal();
                      }}
                    >
                      <Column gap={2}>
                        <Box
                          width={'156px'}
                          height={'140px'}
                          sx={{
                            borderRadius: '8px',
                          }}
                        >
                          <img
                            src={
                              activeLanguage === LangEnums.ENGLISH
                                ? createTripImg
                                : createTripImgHindi
                            }
                            width={156}
                            height={140}
                            alt="create-trip-ico"
                          />
                        </Box>

                        <Text
                          level="body-xxs"
                          fontWeight={500}
                          fontSize={'12px'}
                        >
                          {language?.videoOneTitle}
                        </Text>
                      </Column>
                    </Box>

                    {/* VIDEO 2 INFO */}
                    <Box
                      onClick={() => {
                        setClickedVideoTab({
                          videoUrl: 'https://www.youtube.com/embed/_uY9LHa0VEw',
                          videoTitle: 'How to get driver consent?',
                        });
                        youtubeHook?.toggleDisplayVideoModal();
                      }}
                    >
                      <Column gap={2}>
                        <Box
                          width={'156px'}
                          height={'140px'}
                          sx={{
                            borderRadius: '8px',
                          }}
                        >
                          <img
                            src={
                              activeLanguage === LangEnums.ENGLISH
                                ? driverConsentImg
                                : driverConsentImgHindi
                            }
                            width={156}
                            height={140}
                            alt="driver-consent-ico"
                          />
                        </Box>

                        <Text
                          level="body-xxs"
                          fontWeight={500}
                          fontSize={'12px'}
                        >
                          {language?.videoTwoTitle}
                        </Text>
                      </Column>
                    </Box>
                  </Row>
                </Box>
              </Column>
            </Box>

            {/* LOWER CONTACT SECTION */}
            <Box paddingBottom={4}>
              <Column>
                <FormHeading
                  text={language?.haveOtherIssues}
                  sx={{
                    textAlign: 'left',
                    color: 'text.secondary',
                    fontWeight: 600,
                  }}
                />

                <Box paddingLeft={4} paddingRight={4}>
                  <Button
                    size="md"
                    color="neutral"
                    startDecorator={<WhatsAppIcon />}
                    onClick={(e: any) => redirectToWhatsapp(e)}
                    sx={{
                      fontFamily: 'Noto Sans',
                      fontWeight: 600,
                      background: '#F0F4F8',
                      color: 'neutral.softColor',
                      width: '100%',
                      borderRadius: '8px',
                      padding: '4px 16px 4px 16px',
                      height: '40px',
                    }}
                  >
                    {language?.contactUs}
                  </Button>
                </Box>
              </Column>
            </Box>
          </Column>
        </Box>
      </Drawer>

      <FullScreenDialog
        open={youtubeHook?.showVideoModal}
        onClose={youtubeHook?.toggleDisplayVideoModal}
        titleText={clickedVideoTab?.videoTitle}
      >
        <video
          height={'100%'}
          width={'100%'}
          src={youtubeHook?.demoVideoURL}
          autoPlay
          loop
        />
      </FullScreenDialog>

      <InAppBrowser
        open={youtubeHook.showVideoModal}
        onClose={youtubeHook.toggleDisplayVideoModal}
        titleText={clickedVideoTab?.videoTitle}
      >
        <iframe
          id="iframe_homescreen"
          loading="lazy"
          src={clickedVideoTab?.videoUrl}
          title="IFrame"
          width="100%"
          style={{ display: 'flex', flex: 1, border: 'none' }}
          height="100%"
        />
      </InAppBrowser>
    </>
  );
};

export default View;
