import getConfigByKeys from "../../config/config";
import { configKeys } from "../../config/types";
import handleHttpError from "../../utils/api/httpErrorMapping";
import HttpStatusCode from "../types/httpStatusCodesEnums.type";
import type UnifiedResponse from "../types/unifiedResponse.type";

import { DEFAULT_LANGUAGE_ID } from "../../constant/language";

import {
	getAuthTokenFromStorage,
	getUserLanguageFromStorage,
} from "../../modal/auth/auth.model";
import { apiEndPoints } from "../endpoints";

const { API_URL } = getConfigByKeys([configKeys.API_URL]);

export default async function fetchCropVarietyList(
	payload,
): Promise<UnifiedResponse> {
	const Auth = getAuthTokenFromStorage();
	let userLanguage = getUserLanguageFromStorage()?.langId || null;
	// thrwong error because at this point of time
	// the user should be logged in
	if (!Auth) throw new Error("Auth token not found");

	if (!userLanguage) {
		userLanguage = DEFAULT_LANGUAGE_ID;
	}

	const path = apiEndPoints.fetchCropVarietyList;
	const response = await fetch(
		`${API_URL}${path}?${new URLSearchParams(payload)}`,
		{
			method: "get",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				authorization: "Bearer " + Auth,
				language: userLanguage.toString(),
			},
		},
	);

	if (response.status !== HttpStatusCode.Ok) {
		throw handleHttpError(response.status, path);
	}

	const data = await response.json();
	return { data, response };
}
