import React from 'react';
import SmsHistoryDetailView from '../../Pages/SmsHistoryDetail/SmsHistoryDetailView';

import SmsHistoryHook from '@mono-farmart-web/farmart-web-common/hooks/SmsHistoryHookDetailHook/smsHistoryDetailHook';

export default function AddFarmerScreen() {
  const {
    onPressBack,
    language,
    onContactUs,
    smsHistoryData,
    onClickInfo,
    initialLoading,
    smsHistoryDetailData,
    onCloseModal,
    showShopPromotionModal,
  } = SmsHistoryHook();
  return (
    <SmsHistoryDetailView
      onPressBack={onPressBack}
      language={language}
      onContactUs={onContactUs}
      smsHistoryData={smsHistoryData}
      onClickInfo={onClickInfo}
      initialLoading={initialLoading}
      smsHistoryDetailData={smsHistoryDetailData}
      showShopPromotionModal={showShopPromotionModal}
      onCloseModal={onCloseModal}
    />
  );
}
