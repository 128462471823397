import Trader from './viewmodal/trader/trader';
import BuyerDetails from './viewmodal/buyerdetails/buyerDetails';
import SaudaAcknowledgement from './viewmodal/saudaAcknowledgement/saudaAcknowledgement';
import MySauda from './viewmodal/mySauda/mySauda';
import SaudaDetail from './viewmodal/saudaDetails/saudaDetails';
import DocumentUpload from './viewmodal/documentUpload/documentUpload';
import CreateDispatch from './viewmodal/createDispatch/createDispatch';
import PaymentScreenMain from './viewmodal/PaymentScreen';
import BuyerSearch from './viewmodal/buyerSearch/buyerSearch';

const applicationRoutes = [
  {
    path: '/trades',
    component: Trader,
    exact: true,
  },
  {
    path: '/trades/:id',
    component: BuyerDetails,
    exact: true,
  },
  {
    path: '/trades/mySauda/confirm_sauda',
    component: SaudaAcknowledgement,
    exact: true,
  },
  {
    path: '/trades/mySauda',
    component: MySauda,
    exact: true,
  },
  {
    path: '/trades/mySauda/:id/:modalName?/:modalId?',
    component: SaudaDetail,
    exact: true,
  },
  {
    path: '/trades/mySauda/createDispatch/:dispatchId?/:saudaCreationType?',
    component: CreateDispatch,
    exact: true,
  },
  {
    path: '/trades/uploadDocument',
    component: DocumentUpload,
    exact: true,
  },
  {
    path: '/trades/mySauda/payment/:paymentId/:saudaId',
    component: PaymentScreenMain,
    exact: true,
  },
  {
    path: '/buyerSearch',
    component: BuyerSearch,
    exact: true,
  },
];

export default applicationRoutes;
