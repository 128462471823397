// should convert this structure to a map with key as languageNameGeneral

// dont change languageNameGeneral property
// if ever decided to remove hindi from languages then
// update the fallbackLanguageNameGenral property as well

// this will act as default and fallback language
const defaultLanguageNameGeneral = "hindi";

export const appLanguages = [
	{
		langCode: "HI",
		languageNameGeneral: defaultLanguageNameGeneral,
		languageNameInHindi: "हिंदी",
		languageNameInEnglish: "hindi",
		languageAlphabet: "आ",
		backendLanguageCode: 1,
	},
	{
		langCode: "EN",
		languageNameGeneral: "english",
		languageNameInHindi: "English",
		languageNameInEnglish: "english",
		languageAlphabet: "A",
		backendLanguageCode: 2,
	},
];

// this object is needed in languagae tool to fetch language dynamically
// we cant use array for dynamic design as if order/ index chnages in array
// during development then it can break the code
const allAppLanguagesIndex = {};
const allAppLanguagesCode = [];

// an index to hold the backend language code corresponding to language
const AllLanguageToBackendCodeEnum = new Map();

appLanguages.forEach((language) => {
	allAppLanguagesIndex[language?.languageNameGeneral] = language?.langCode;
	AllLanguageToBackendCodeEnum.set(
		language?.langCode,
		language?.backendLanguageCode,
	);
	allAppLanguagesCode.push(language?.langCode);
});

export const appLanguagesIndex = allAppLanguagesIndex;
export const LanguageToBackendCodeEnum = AllLanguageToBackendCodeEnum;
export const appLanguagesCode = allAppLanguagesCode;

// if user selected language not available in redux then use this
export const defaultLanguageCode =
	appLanguagesIndex[defaultLanguageNameGeneral];
