
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const View = (props: any) => {
	const [isLoading, setIsLoading] = useState(true);
    // const activeLanguage = useSelector((state: any) => state.settings.activeLanguage);
	const handleLoad = () => {
		setIsLoading(false);
	};

	return (
		<div style={{ position: "relative", height: "100dvh", width: "100%" }}>
			{/* {isLoading && (
        <Container
          position={"absolute"}
          height={"100%"}
          top={0}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress />
        </Container>
      )} */}
			<iframe
				id="iframe_tripdetails"
				loading="lazy"
				src={`${import.meta.env.VITE_TRACKING}/${"hi"}/tripdetail/${props?.tripId}`}
				title="IFrame"
				width="100%"
				style={{ display: "flex", flex: 1, border: "none" }}
				height="100%"
				onLoad={handleLoad}
			/>
		</div>
	);
};

export default View
