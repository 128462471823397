import React from 'react';
import Styles from './style.module.css';
import Header from './Header/Header';
import LocationCard from './LocationList/LocationCard';

import { Box } from '@mui/material';
import { BuyerSearchProps } from '../../hooks/types';

export default function PinCodeView(props: BuyerSearchProps) {
  const {
    language,
    onPressBack,
    buyerSearchScreenAssets,
    buyerList,
    searchedValue,
    onSearchBuyer,
    loading,
    onClickRemoveSearch,
    onClickBuyerName,
  } = props;
  return (
    <Box className={Styles.container} bgcolor={'background.body'}>
      <Header
        language={language}
        onPressBack={onPressBack}
        searchedValue={searchedValue}
        onSearchBuyer={onSearchBuyer}
        onClickRemoveSearch={onClickRemoveSearch}
      />

      <LocationCard
        language={language}
        buyerSearchScreenAssets={buyerSearchScreenAssets}
        buyerList={buyerList}
        loading={loading}
        searchedValue={searchedValue}
        onClickBuyerName={onClickBuyerName}
      />
    </Box>
  );
}
