/*
  we have a demo video feature in applcation.
  which will be displayed in a fullscreen modal
  we will be using this hook to use that video feature in app
  the goal to design this hook is to plug and play anywhere in our application
*/

import Events from "@mono-farmart-web/farmart-web-common/constant/MoengageEvents/index";
import { trackEvent } from "@mono-farmart-web/farmart-web-common/utils/moengage";
// import { useFetchUserAPI } from "../../hooks/general";

import { useCallback, useState } from "react";

interface Props {
	URL: string; // URL for the video, right now it doesnt support offline/local videos.
}

export default function useDemoVideo(props: Props) {
	const { URL } = props || {};
	const [videoURL, setVideoUrlState] = useState<string>(URL);
	const [showVideoModal, setShowVideoModal] = useState(false);

	const toggleDisplayVideoModal = useCallback(() => {
		setShowVideoModal(prev => {
			if (prev) {
				trackEvent(Events.ondemovideoopened, {});
			} else {
				trackEvent(Events.ondemovideoclosed, {});
			}

			return !prev;
		});
	}, []);

	const setVideoURL = useCallback((url: string) => {
		setVideoUrlState(url);
	}, []);

	return {
		toggleDisplayVideoModal,
		showVideoModal,
		setVideoURL,
		videoURL,
	};
}
