import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    tripCreated: "ट्रिप बन गयी।",
    takeDriverConsentMessage: "लोकेशन एक्सेस पाने के लिए अभी ड्राइवर की सहमति लें। नीचे देखें कि सहमति कैसे लें।",
    consentReminderSentMessage: "ड्राइवर को रिमाइंडर भेजा गया। हम ट्रिप शुरू करने के लिए सहमति की प्रतीक्षा कर रहे हैं।",
    consentReceivedMessage: "सहमति मिल गई! ट्रिप जल्द शुरू होगी",
    consentReminderButtonActive: "ड्राइवर को लोकेशन एक्सेस देने के लिए याद दिलाएँ",
    consentReminderButtonDisabled: "दूसरे अनुरोध तक 1 मिनट तक प्रतीक्षा करें",
    howToGetConsentFromDriver: "ड्राइवर से सहमति कैसे प्राप्त करें?",
    followTheStepsGivenBelowOrWatchVideo: "नीचे दिए गए स्टेप्स का पालन करें या वीडियो देखें",
    watchHowToTakeConsent: "देखें कि सहमति कैसे लें",
    takeConsentStepOneTitle: "आपके ड्राईवर को मैसेज प्राप्त होता है।",
    takeConsentStepOneDescription: "ड्राइवर विवरण में दिए गए नंबर पर आपके ड्राइवर को एक संदेश प्राप्त होगा।",
    takeConsentStepTwoTitle: "ड्राइवर से मिस्ड कॉल करने के लिए कहें",
    takeConsentStepTwoDescription: "ड्राइवर के सिम कार्ड कंपनी की जाँच करें और उसे नीचे दी गई सिम कार्ड कंपनी के अनुसार दिए गए स्टेप्स करने के लिए कहें।",
    ifDriverHasNoJioCard: "अगर ड्राइवर के पास JIO सिम कार्ड है",
    giveMissedCallTo: "मिस्ड कॉल दें",
    ifNoOtherSim: "अगर ड्राइवर के पास Airtel, BSNL और Vi सिम है",
    onceDriverCompleteTheseSteps: "एक बार जब ड्राइवर इन स्टेप्स को पूरा कर लेगा, तो ट्रैकिंग शुरू हो जाएगी!",
    deliverTo: "डिलीवर करें",
    truckRouteDetail: "ट्रक मार्ग का विवरण",
    dispatchedFrom: "डिस्पैच लोकेशन",
    expectedDelivery: "अनुमानित डिलीवरी",
    estimatedDistance: "अनुमानित दूरी",
    Kms: "किलोमीटर",
    consentMessage: "सहमति संदेश भेजा गया!",
    missedCallNoOtherSim: "<number> पर कॉल करें और 1 दबायें।",
    destinationAt: "डिलीवरी लोकेशन",
    truckDriverDetails: "ट्रक और ड्राइवर की डिटेल्स",
  },
  [AppLanguages.appLanguagesIndex.english]: {
    tripCreated: "Trip Created!",
    takeDriverConsentMessage: "Take Driver Consent now to get location access. Check below how to take consent.",
    consentReceivedMessage: "Consent received! Starting trip shortly",
    consentReminderSentMessage: "Reminder sent to driver. We are waiting for consent to start trip.",
    consentReminderButtonActive: "Remind driver to give location access",
    consentReminderButtonDisabled: "Wait for 1 minute till another request",
    howToGetConsentFromDriver: "How to get a consent from driver?",
    followTheStepsGivenBelowOrWatchVideo: "Follow the steps given below or watch video",
    watchHowToTakeConsent: "Watch how to take consent",
    takeConsentStepOneTitle: "Your driver receives SMS",
    takeConsentStepOneDescription: "Your driver will receive a message on the number provided in driver's details.",
    takeConsentStepTwoTitle: "Ask driver to give missed call",
    takeConsentStepTwoDescription: "Check driver SIM card company and ask him to do following steps according to SIM Card company given below.",
    ifDriverHasNoJioCard: "If driver have JIO SIM Card",
    giveMissedCallTo: "Give a missed call to",
    ifNoOtherSim: "If driver have Airtel, BSNL and VI SIM",
    onceDriverCompleteTheseSteps: "Once driver complete these steps, then tracking will start!",
    deliverTo: "Deliver to",
    truckRouteDetail: "Truck route details",
    dispatchedFrom: "Dispatched from",
    expectedDelivery: "Expected delivery",
    estimatedDistance: "Estimated Distance",
    Kms: "Kms",
    consentMessage: "Consent message sent!",
    missedCallNoOtherSim: "Call <number> and press 1",
    destinationAt: "Destination at",
    truckDriverDetails: "Truck & Driver Details",
  },
};

export default (language) => languages[language];
