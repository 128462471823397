import React from 'react';
import Style from './style.module.css';
import Button from '@mono-farmart-web/farmart-web-common/ComponentsV3/MuiButton';
import AddRounded from '@mui/icons-material/AddRounded';

interface DocumentUploadFooterProps {
  doneDocumentUpload: () => void;
  handleFileChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    index?: number,
  ) => void;
  handleUploadButtonClick?: (from?: string, index?: number) => void;
  fileInputRef: any;
  language: { [key: string]: any };
  disableDone?: boolean;
}

const DocumentUploadFooter = ({
  doneDocumentUpload,
  handleFileChange,
  handleUploadButtonClick,
  fileInputRef,
  language,
  disableDone,
}: DocumentUploadFooterProps) => {
  return (
    <div className={Style.footer}>
      <input
        type="file"
        accept=".pdf, .jpg, .jpeg, .png"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
      <Button
        variant="outlined"
        startDecorator={<AddRounded />}
        onClick={() => {
          handleUploadButtonClick('addMoreFiles');
        }}
        id="addMoreFilesButton"
        size={'lg'}
      >
        {language?.add_more_files}
      </Button>
      <Button
        onClick={doneDocumentUpload}
        id="onClickDoneDocumentUploadButton"
        size={'lg'}
        disabled={disableDone}
      >
        {language?.done}
      </Button>
    </div>
  );
};

export default DocumentUploadFooter;
