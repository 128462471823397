import React from 'react';
import Style from './style.module.css';
import ErrorRounded from '@mui/icons-material/ErrorRounded';
import Snackbar from '@mui/joy/Snackbar';
import MuiAlert from '@mono-farmart-web/farmart-web-common/ComponentsV3/MuiAlert';
import DocumentType from '@mono-farmart-web/farmart-web-common/constant/enums/docType';

interface FileListAlert {
  text: string;
}

const FileListAlert = ({ language, fileLengthAlert, setFileLengthAlert, docType }) => {
  const fileListAlertText = () => {
    if (DocumentType.get(docType) === 'buyerGRN' || DocumentType.get(docType) === 'unloadingWeightSlip') {
      return language?.unloadingFileListAlert
    }
    else {
      return language?.fileListAlert
    }
  }
  return (
    <>
      <Snackbar
        open={fileLengthAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        variant={'solid'}
        color="danger"
        sx={{
          padding: 0,
          zIndex: 20000,
          borderRadius: '8px',
          width: '90%',
        }}
        onClose={() => {
          setFileLengthAlert(false);
        }}
      >
        <MuiAlert
          size="sm"
          variant="solid"
          color="danger"
          sx={{ borderRadius: '8px' }}
        >
          <div className={Style.alert} style={{ border: '0px solid black' }}>
            <ErrorRounded color="action" />
            <div className={Style.alertText}>{fileListAlertText()}</div>
          </div>
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default FileListAlert;
