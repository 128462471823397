/* This code snippet is a TypeScript React component called `FarmerList`. It is a functional component
that takes in props of type `FarmerListProp`. */
import React from 'react';

import FarmerCard from './FarmerCard/FarmerCard';
import { Box } from '@mui/material';
import MuiTypography from '@mono-farmart-web/farmart-web-common/ComponentV3/Typography';

import { farmerCardData } from '@mono-farmart-web/farmart-web-common/hooks/SmsHistoryHookDetailHook/types';

interface FarmerListProp {
  smsHistoryData:
    | {
        message: string;
        farmers: farmerCardData[];
        sms_type: number;
        status: number;
        comment: string;
      }
    | undefined;
  language: { [key: string]: string };
}

export default function FarmerList(props: FarmerListProp) {
  const { smsHistoryData, language } = props;

  return (
    <Box
      margin={'16px'}
      display={'flex'}
      flexDirection={'column'}
      gap={'8px'}
      flex={1}
      boxSizing={'border-box'}
      alignSelf={'stretch'}
    >
      <Box>
        <MuiTypography level="title-sm" textColor={'text.secondary'}>
          {language?.farmerListTitle} {smsHistoryData?.farmers?.length}
        </MuiTypography>
      </Box>
      <Box>
        {smsHistoryData?.farmers?.map((item, index) => {
          return <FarmerCard item={item} key={index} />;
        })}
      </Box>
    </Box>
  );
}
