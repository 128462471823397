import {
  useEffect,
  useState,
  type Dispatch,
  type SetStateAction,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import type { PostList } from '../../modal/community/communityModel';

import {
  setPosts,
  setCurrentPostPageNumber as setPageNo,
  resetPostList, // reset post list
} from '../../modal/community/slice/community.slice';
import { trackEvent } from '../../utils/moengage';
import Events from '../../constant/MoengageEvents/index';
import type { RootState } from '../../store';
import { decodeParams } from '../../utils/handleUrlParams';
import useAuthenticationChecker from '../../utils/authChecker';
import { apiEndPoints } from '../../apis/endpoints';
import { useLocation } from 'react-router-dom';
import jsonParse from '../../utils/jsonParser';
import correctUrl from '../../utils/urlCorrection';

interface UseFetchCommunity {
  posts: PostList;
  isFetchingAllPosts: boolean;
  setPosts: Dispatch<SetStateAction<PostList>>;
  loadMorePost: () => void;
}

export default function useFetchCommunity(
  selectedPage: number,
  newsFeedcategoryEnum?: boolean,
): UseFetchCommunity {
  const location = useLocation();
  const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();
  const params = decodeParams(window?.location?.search);
  const isFirstTimeScreenOpen = useRef(false);
  const startLoadTime = new Date().getTime();
  const webStateId = jsonParse(localStorage.getItem('stateId'));

  const [isFetchingAllPosts, setIsFetchingAllPosts] = useState(false);

  const { posts, pageNo } = useSelector((state: RootState) => {
    return {
      posts: state.community.posts,
      pageNo: state.community.posts.currentPageNumber,
    };
  });
  const dispatch = useDispatch();
  const stateID: number = params?.location?.state?.id || webStateId;
  const language = JSON.parse(localStorage.getItem('userLanguage')); // get user language

  useEffect(() => {
    if (!isFirstTimeScreenOpen.current) {
      isFirstTimeScreenOpen.current = true;
      if (posts.rows.length) {
        return;
      }
    }

    if (newsFeedcategoryEnum && correctUrl(location?.pathname)=== '/') {
      fetchAllPost();
    } else if (correctUrl(location?.pathname)=== '/community') {
      fetchAllPost();
    }
  }, [selectedPage, pageNo, stateID, newsFeedcategoryEnum]);

  useEffect(() => {
    if (language?.langId && correctUrl(location?.pathname) === '/myProfile') {
      dispatch(resetPostList({ count: 0, rows: [] }));
    }
  }, [language?.langId]);

  async function fetchAllPost() {
    setIsFetchingAllPosts(true);
    try {
      const postsResult = await AuthenticationCheckerAndApiCaller({
        payload: {
          page_id: selectedPage,
          page_no: pageNo,
          limit: 20,
          state_id: stateID ? stateID : null,
        },
        uri: apiEndPoints?.fetchAllPosts,
        requestType: 'get',
      });

      postsResult?.data?.data && dispatch(setPosts(postsResult?.data?.data));
      setIsFetchingAllPosts(false);
    } catch (error) {
      // Handle other errors
      console.log(error);
      setIsFetchingAllPosts(false);
    } finally {
      setIsFetchingAllPosts(false);
    }
  }

  function loadMorePost() {
    posts.count > posts.rows.length && dispatch(setPageNo(pageNo + 1));
  }

  return {
    posts,
    isFetchingAllPosts,
    setPosts,
    loadMorePost,
  };
}
